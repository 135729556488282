import { Checkbox } from '@mantine/core';
import React from 'react';
import { BaseFieldProps, DisplayMode, isReadOnly } from '../../types/baseField';

interface CheckboxFieldProps extends BaseFieldProps<boolean> {}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  field,
  value = false,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    onChange?.(event.currentTarget.checked);
  };

  // Always show the Checkbox control, but make it readonly in display mode
  return (
    <Checkbox
      label={field.label}
      description={field.description}
      checked={value}
      onChange={handleChange}
      disabled={readonly}
      required={field.required}
    />
  );
};

export default CheckboxField;
