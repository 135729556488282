import { GlobalSearchResponse, WorkgroupResponse } from '@btrway/api-core';
import { DataTypeEnum } from '@btrway/api-workflow';
import { GlobalSearchTagInput } from '@btrway/global-search';
import { useUserRoles } from '@btrway/security-manager';
import {
  Button,
  Card,
  Group,
  Select,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';

const workgroupsData = (workgroups: WorkgroupResponse[]) => {
  return workgroups.map((wg) => ({
    value: wg.id.toString(),
    label: wg.displayName,
  }));
};

interface StartStepOthersProps {
  workgroups: WorkgroupResponse[];
  userRoles: Array<{ id: number }>;
  mountKey: number;
  onStart: (workgroupId: number, entityData: GlobalSearchResponse) => void;
  showHeader?: boolean;
}

export const StartStepOthers = ({
  workgroups,
  userRoles,
  mountKey,
  onStart,
  showHeader = false,
}: StartStepOthersProps) => {
  const [selectedWorkgroup, setSelectedWorkgroup] = useState<string | null>(
    null
  );
  const [selectedEntity, setSelectedEntity] =
    useState<GlobalSearchResponse | null>(null);
  const [showValidation, setShowValidation] = useState(false);
  const { getUserRolesByIds } = useUserRoles();

  const roleNames = useMemo(() => {
    const roles = getUserRolesByIds(userRoles.map((role) => role.id));
    const names = roles.map((role) => role.name);

    if (names.length === 0) return '';
    if (names.length === 1) return names[0];
    if (names.length === 2) return `${names[0]} and ${names[1]}`;

    const lastRole = names[names.length - 1];
    const otherRoles = names.slice(0, -1).join(', ');
    return `${otherRoles}, and ${lastRole}`;
  }, [getUserRolesByIds, userRoles]);

  useEffect(() => {
    if (workgroups.length > 0 && !selectedWorkgroup) {
      setSelectedWorkgroup(workgroups[0].id.toString());
    }
  }, [workgroups]);

  const handleStartClick = () => {
    if (!selectedEntity) {
      setShowValidation(true);
      return;
    }

    if (selectedWorkgroup && selectedEntity) {
      // Find the matching workgroup member from the selected entity
      const selectedWorkgroupMember = selectedEntity.workgroupMembers?.[0];

      // If we found a matching workgroup member, use its workgroup ID
      // Otherwise, fall back to the selectedWorkgroup
      const workgroupId =
        selectedWorkgroupMember?.workgroup?.id ??
        parseInt(selectedWorkgroup, 10);

      onStart(workgroupId, selectedEntity);
    }
  };

  const content = (
    <Stack>
      <div>
        {selectedWorkgroup && (
          <>
            {roleNames && (
              <Text size="md" mb="xl">
                This form can be launched for {roleNames}
              </Text>
            )}
            <GlobalSearchTagInput
              size="lg"
              key={`search-${mountKey}`}
              onSelect={(result) => {
                setSelectedEntity(result);
                setShowValidation(false);
              }}
              entityTypes={[DataTypeEnum.person]}
              filterByWorkgroupIds={[parseInt(selectedWorkgroup, 10)]}
              filterByUserRoleIds={userRoles.map((role) => role.id)}
              clearOnSelect
              placeholder="Search for a person..."
              onlyTopResults={true}
            />
            {workgroups.length > 1 && (
              <Group mt="xs" align="center" gap={4} justify="flex-end">
                <Text size="xs" c="gray.8">
                  Search Within:
                </Text>
                <Select
                  size="xs"
                  placeholder="Choose a workgroup"
                  data={workgroupsData(workgroups)}
                  value={selectedWorkgroup}
                  onChange={(value) => {
                    setSelectedWorkgroup(value);
                    setSelectedEntity(null);
                    setShowValidation(false);
                  }}
                  w={200}
                />
              </Group>
            )}
            {showValidation && !selectedEntity && (
              <Text size="sm" c="red" mt="xs">
                Please select a person before starting the form
              </Text>
            )}
          </>
        )}
      </div>
      <Button size="lg" mt="xl" onClick={handleStartClick} fullWidth>
        Start Form
      </Button>
    </Stack>
  );

  if (!showHeader) {
    return content;
  }

  return (
    <Card
      withBorder
      padding="xl"
      radius="lg"
      bg="blue.0"
      style={{ borderColor: 'var(--mantine-color-gray-5)' }}
    >
      <Group mb="md">
        <ThemeIcon size="xl" radius="xl">
          <IconUsers size={24} />
        </ThemeIcon>
        <Title order={3} fw={600}>
          Someone Else
        </Title>
      </Group>
      {content}
    </Card>
  );
};
