import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { useConfigFactory } from '@btrway/workflow-configuration-manager';
import React, { createContext, useMemo } from 'react';
import { DisplayLayout } from '../components/DisplayLayout/DisplayLayout';
import {
  createWorkflowBuilderReducer,
  initialState,
} from '../reducers/workflowBuilderReducer';
import { Action } from '../types/actions';
import { State } from '../types/state';

export interface WorkflowBuilderContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
}

export const WorkflowBuilderContext =
  createContext<WorkflowBuilderContextType | null>(null);

interface WorkflowBuilderStateProviderProps {
  children: React.ReactNode;
  parentWorkflow: CommonWorkflow | null;
}

export const WorkflowBuilderStateProvider: React.FC<
  WorkflowBuilderStateProviderProps
> = ({ children, parentWorkflow }) => {
  const configFactory = useConfigFactory();

  const reducer = useMemo(
    () => createWorkflowBuilderReducer({ configFactory }),
    [configFactory]
  );

  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    parentWorkflow: parentWorkflow ?? null,
  });

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <WorkflowBuilderContext.Provider value={contextValue}>
      <DisplayLayout>{children}</DisplayLayout>
    </WorkflowBuilderContext.Provider>
  );
};
