import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridApi, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
  DataTypeEnum,
  WorkflowInstanceGridRequest,
  WorkflowInstanceGridResponse,
  useGetWorkflowInstanceGrid,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { EntityTag } from '@btrway/entity-tags';
import { Stack } from '@mantine/core';
import { format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const AllFormsGrid: React.FC = () => {
  const gridApi = useRef<GridApi>();
  const { currentOrganization, rootWorkgroupId } = useAuthenticatedUser();
  const [workflowInstances, setWorkflowInstances] = useState<
    WorkflowInstanceGridResponse[]
  >([]);

  const requestParams: WorkflowInstanceGridRequest = {
    organizationId: currentOrganization.id,
    workgroupId: rootWorkgroupId,
    includeChildWorkgroups: true,
  };

  const { mutateAsync, isPending: isLoading } = useGetWorkflowInstanceGrid();

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await mutateAsync({ data: requestParams });
        setWorkflowInstances(result);
      } catch (error) {
        console.error('Error loading workflow instances:', error);
      }
    };

    loadData();
  }, [mutateAsync, currentOrganization.id, rootWorkgroupId]);

  const dateFormatter = (params: any) => {
    return params.value
      ? format(new Date(params.value), 'MM/dd/yyyy HH:mm')
      : '';
  };

  const entityTagRenderer = (params: any, entityType: string) => {
    if (!params.value) return '';
    return (
      <EntityTag
        dataType={entityType as keyof typeof DataTypeEnum}
        id={params.value}
        size="sm"
      />
    );
  };

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Form',
        field: 'workflowName',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      {
        headerName: 'Workgroup',
        field: 'workgroupName',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      {
        headerName: 'About',
        field: 'aboutEntityId',
        flex: 1,
        cellRenderer: (params: any) =>
          entityTagRenderer(params, params.data.aboutEntityType || 'person'),
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      {
        headerName: 'Current Step',
        field: 'currentStepTitle',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      {
        headerName: 'Submitted At',
        field: 'formSubmittedAt',
        flex: 1,
        valueFormatter: dateFormatter,
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      {
        headerName: 'Submitted By',
        field: 'submittedBy',
        flex: 1,
        cellRenderer: (params: any) => entityTagRenderer(params, 'person'),
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      {
        headerName: 'Awaiting',
        field: 'currentStepAssignedEntityId',
        flex: 1,
        cellRenderer: (params: any) =>
          entityTagRenderer(
            params,
            params.data.currentStepAssignedEntityType || 'person'
          ),
        suppressHeaderMenuButton: false,
        hide: false, // Always visible by default
      },
      // Additional columns hidden by default
      {
        headerName: 'Workflow Status',
        field: 'workflowStatus',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: true, // Hidden by default
      },
      {
        headerName: 'Last Step Completed',
        field: 'lastStepTitle',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: true, // Hidden by default
      },
      {
        headerName: 'Last Completed At',
        field: 'lastStepCompletedAt',
        flex: 1,
        valueFormatter: dateFormatter,
        suppressHeaderMenuButton: false,
        hide: true, // Hidden by default
      },
      {
        headerName: 'Last Completed By',
        field: 'lastStepCompletedBy',
        flex: 1,
        cellRenderer: (params: any) => entityTagRenderer(params, 'person'),
        suppressHeaderMenuButton: false,
        hide: true, // Hidden by default
      },
      {
        headerName: 'Workgroup Path',
        field: 'workgroupPath',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: true, // Hidden by default
      },
      {
        headerName: 'Year',
        field: 'year',
        flex: 1,
        suppressHeaderMenuButton: false,
        hide: true, // Hidden by default
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      suppressMenu: false,
    }),
    []
  );

  const onGridReady = useCallback((params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  }, []);

  return (
    <Stack h="100%">
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100% - 20px)', width: '100%' }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={workflowInstances}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          rowSelection="multiple"
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-no-rows-center">No workflow instances found</span>'
          }
          loading={isLoading}
        />
      </div>
    </Stack>
  );
};

export default AllFormsGrid;
