import { useGetEventTypesByOrganizationId } from '@btrway/api-calendar';
import { DataTypeEnum } from '@btrway/api-core';
import {
  FormAboutSettings,
  FormLaunchSetting,
  FormSettings,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { EventTypeHierarchicalSelector } from '@btrway/event-type-components';
import { UserRoleHierarchicalSelector } from '@btrway/security-components';
import { useWorkgroupTypes } from '@btrway/workgroup-manager';
import { Box, LoadingOverlay, Stack, Text, TextInput } from '@mantine/core';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { FormSettingsState } from '../../types/state';
import { EntityFilterSelector } from '../EntityFilterSelector/EntityFilterSelector';
import { EntityTypeSelector } from '../EntityTypeSelector/EntityTypeSelector';
import { LaunchCheckboxCard } from '../LaunchCheckboxCard/LaunchCheckboxCard';

export interface FormSettingsEditorProps {
  initialFormSettings?: FormSettings;
  onChange?: (formSettings: FormSettings) => void;
}

export const FormSettingsEditor: React.FC<FormSettingsEditorProps> = ({
  initialFormSettings,
  onChange,
}) => {
  const { currentOrganization } = useAuthenticatedUser();

  // Initialize UI state from initial form settings
  const [uiState, setUiState] = useState<FormSettingsState>(() => {
    const initialLaunchSettings = new Map(
      initialFormSettings?.launchSettings?.map((setting) => [
        setting.roleSlug,
        {
          self: setting.launchAboutSelf || false,
          others: setting.launchAboutOthers || false,
        },
      ]) || []
    );

    return {
      entityType: initialFormSettings?.aboutSettings?.aboutEntity || null,
      entityFilters: new Map<DataTypeEnum, string[]>([
        [
          DataTypeEnum.person,
          initialFormSettings?.aboutSettings?.aboutEntity ===
          DataTypeEnum.person
            ? initialFormSettings.aboutSettings.aboutEntityFilter || []
            : [],
        ],
        [
          DataTypeEnum.calendarEvent,
          initialFormSettings?.aboutSettings?.aboutEntity ===
          DataTypeEnum.calendarEvent
            ? initialFormSettings.aboutSettings.aboutEntityFilter || []
            : [],
        ],
        [
          DataTypeEnum.workgroup,
          initialFormSettings?.aboutSettings?.aboutEntity ===
          DataTypeEnum.workgroup
            ? initialFormSettings.aboutSettings.aboutEntityFilter || []
            : [],
        ],
      ]),
      entityDescription:
        initialFormSettings?.aboutSettings?.aboutEntityDescription || '',
      launchAboutSelf: Array.from(initialLaunchSettings.values()).some(
        (s) => s.self
      ),
      launchAboutOthers: Array.from(initialLaunchSettings.values()).some(
        (s) => s.others
      ),
      othersRoleSettings: new Set(
        Array.from(initialLaunchSettings.entries())
          .filter(([_, settings]) => settings.others)
          .map(([slug]) => slug)
      ),
    };
  });

  // Loading states
  const { isLoading: eventsLoading, data: eventTypes } =
    useGetEventTypesByOrganizationId(currentOrganization.id);
  const { isLoading: workgroupsLoading, workgroupTypes } = useWorkgroupTypes(
    currentOrganization.id
  );

  // Convert UI state to FormSettings and notify parent
  const updateFormSettings = () => {
    const settings: FormSettings = {};

    if (uiState.entityType && uiState.entityType !== 'nothing') {
      const aboutSettings: FormAboutSettings = {
        aboutEntity: uiState.entityType,
        aboutEntityFilter:
          uiState.entityFilters.get(uiState.entityType) || undefined,
        aboutEntityDescription: uiState.entityDescription || undefined,
      };
      settings.aboutSettings = aboutSettings;
    }

    if (uiState.entityType === DataTypeEnum.person) {
      const launchSettings: FormLaunchSetting[] = [];
      const currentFilters =
        uiState.entityFilters.get(DataTypeEnum.person) || [];

      // Add settings for self-launch
      if (uiState.launchAboutSelf) {
        currentFilters.forEach((roleSlug) => {
          const existingSetting = launchSettings.find(
            (s) => s.roleSlug === roleSlug
          );
          if (existingSetting) {
            existingSetting.launchAboutSelf = true;
          } else {
            launchSettings.push({
              roleSlug,
              launchAboutSelf: true,
              launchAboutOthers: false,
            });
          }
        });
      }

      // Add settings for others-launch
      if (uiState.launchAboutOthers) {
        Array.from(uiState.othersRoleSettings).forEach((roleSlug) => {
          const existingSetting = launchSettings.find(
            (s) => s.roleSlug === roleSlug
          );
          if (existingSetting) {
            existingSetting.launchAboutOthers = true;
          } else {
            launchSettings.push({
              roleSlug,
              launchAboutSelf: false,
              launchAboutOthers: true,
            });
          }
        });
      }

      if (launchSettings.length > 0) {
        settings.launchSettings = launchSettings;
      }
    }

    onChange?.(settings);
  };

  // Add effect to update parent when initial settings change
  useEffect(() => {
    updateFormSettings();
  }, [initialFormSettings]);

  const handleEntityTypeSelect = (type: DataTypeEnum | 'nothing' | null) => {
    setUiState((prev) => ({
      ...prev,
      entityType: type,
    }));
    // Defer the update to ensure state is updated
    setTimeout(updateFormSettings, 0);
  };

  const handleHierarchyChange = (selection: {
    ids: number[];
    slugs: string[];
    description?: string;
  }) => {
    if (!uiState.entityType) return;

    const newDescription = selection.description
      ? pluralize.singular(selection.description)
      : uiState.entityDescription;

    setUiState((prev) => ({
      ...prev,
      entityFilters: new Map(prev.entityFilters).set(
        uiState.entityType as DataTypeEnum,
        selection.slugs
      ),
      entityDescription: newDescription,
    }));
    setTimeout(updateFormSettings, 0);
  };

  const handleDescriptionChange = (value: string) => {
    setUiState((prev) => ({
      ...prev,
      entityDescription: value,
    }));
    setTimeout(updateFormSettings, 0);
  };

  const handleFilterChange = (values: string[]) => {
    if (!uiState.entityType || uiState.entityType === 'nothing') return;

    if (Object.values(DataTypeEnum).includes(uiState.entityType as any)) {
      setUiState((prev) => ({
        ...prev,
        entityFilters: new Map(prev.entityFilters).set(
          uiState.entityType as DataTypeEnum,
          values
        ),
      }));
      // Defer the update to ensure state is updated
      setTimeout(updateFormSettings, 0);
    }
  };

  const handleOthersRoleChange = (selection: { roleSlugs: string[] }) => {
    setUiState((prev) => ({
      ...prev,
      othersRoleSettings: new Set(selection.roleSlugs),
    }));
    // Defer the update to ensure state is updated
    setTimeout(updateFormSettings, 0);
  };

  const getEntityTypeLabel = () => {
    if (!uiState.entityType) return '';

    switch (uiState.entityType) {
      case DataTypeEnum.person:
        return 'Person';
      case DataTypeEnum.workgroup:
        return 'Workgroup';
      case DataTypeEnum.calendarEvent:
        return 'Event';
      default:
        return '';
    }
  };

  const isLoading = eventsLoading || workgroupsLoading;

  const showEntityFilter =
    uiState.entityType &&
    (uiState.entityType === DataTypeEnum.person ||
      uiState.entityType === DataTypeEnum.workgroup ||
      uiState.entityType === DataTypeEnum.calendarEvent);

  const showLaunchOptions = uiState.entityType === DataTypeEnum.person;
  const showOthersRoles =
    uiState.entityType === DataTypeEnum.person && uiState.launchAboutOthers;

  const getCurrentFilters = () => {
    if (!uiState.entityType || uiState.entityType === 'nothing') return [];
    if (!Object.values(DataTypeEnum).includes(uiState.entityType as any))
      return [];
    return uiState.entityFilters.get(uiState.entityType as DataTypeEnum) || [];
  };

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} />
      <Stack gap="lg">
        <Text fz="lg" fw={600}>
          What is this Form about?
        </Text>

        <EntityTypeSelector
          selectedType={uiState.entityType}
          onSelect={handleEntityTypeSelect}
          hideUnselected={true}
          variant="sm"
        />

        {showEntityFilter &&
          (uiState.entityType === DataTypeEnum.person ? (
            <Stack gap="md">
              <Text fz="lg" fw={600}>
                Limited to these roles
              </Text>
              <UserRoleHierarchicalSelector
                selectedRoleSlugs={getCurrentFilters()}
                onChange={(selection) =>
                  handleHierarchyChange({
                    ids: selection.roleIds,
                    slugs: selection.roleSlugs,
                    description: selection.description,
                  })
                }
              />
            </Stack>
          ) : uiState.entityType === DataTypeEnum.calendarEvent ? (
            <Stack gap="md">
              <Text fz="lg" fw={600}>
                Limited to these event types
              </Text>
              <EventTypeHierarchicalSelector
                selectedEventTypeSlugs={getCurrentFilters()}
                onChange={(selection) =>
                  handleHierarchyChange({
                    ids: selection.eventTypeIds,
                    slugs: selection.eventTypeSlugs,
                  })
                }
              />
            </Stack>
          ) : (
            <EntityFilterSelector
              entityType={uiState.entityType as DataTypeEnum}
              selectedFilters={getCurrentFilters()}
              onChange={handleFilterChange}
            />
          ))}

        <Stack gap="md">
          <Text fz="lg" fw={600}>
            How should we refer to this {getEntityTypeLabel()}?
          </Text>
          <TextInput
            value={uiState.entityDescription}
            onChange={(event) =>
              handleDescriptionChange(event.currentTarget.value)
            }
            placeholder={`Enter a description for this ${getEntityTypeLabel()}`}
          />
        </Stack>

        {showLaunchOptions && (
          <Stack gap="md">
            <Text fz="lg" fw={600}>
              How can this Form be started?
            </Text>
            <LaunchCheckboxCard
              title="Complete Form About Self"
              description="Any person of the above selected Person types can complete this form about themselves."
              checked={uiState.launchAboutSelf}
              variant="sm"
              onChange={(checked) => {
                console.log(
                  'LaunchCheckboxCard Complete Form About Self',
                  checked
                );
                setUiState((prev) => ({
                  ...prev,
                  launchAboutSelf: checked,
                }));
                updateFormSettings();
              }}
            />
            <LaunchCheckboxCard
              title="Complete Form About Someone Else"
              description="The form can be completed by someone else about any of the above selected Person types."
              checked={uiState.launchAboutOthers}
              variant="sm"
              onChange={(checked) => {
                console.log(
                  'LaunchCheckboxCard Complete Form About Someone Else',
                  checked
                );
                setUiState((prev) => ({
                  ...prev,
                  launchAboutOthers: checked,
                }));
                updateFormSettings();
              }}
            />
          </Stack>
        )}

        {showOthersRoles && (
          <Stack gap="md">
            <Text fz="lg" fw={600}>
              Roles that can launch about someone else
            </Text>
            <UserRoleHierarchicalSelector
              selectedRoleSlugs={Array.from(uiState.othersRoleSettings)}
              onChange={handleOthersRoleChange}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
