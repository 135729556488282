import { WorkgroupResponse } from '@btrway/api-core';
import {
  Anchor,
  Card,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { Link } from 'react-router-dom';

const MetricCard: React.FC<{ title: string; value: string | number }> = ({
  title,
  value,
}) => (
  <Card padding="xs" style={{ boxShadow: 'none', border: 'none' }}>
    <Text size="xl" fw={700} ta="center">
      {value}
    </Text>
    <Text size="sm" color="dimmed" ta="center">
      {title}
    </Text>
  </Card>
);

const WorkgroupSubgroupsCard: React.FC<{ workgroup: WorkgroupResponse }> = ({
  workgroup,
}) => (
  <Card
    component={Link}
    to={`/app/ops/${workgroup.id}`}
    padding="lg"
    radius="md"
    style={{ width: '100%' }}
    withBorder
  >
    <Group justify="space-between" align="flex-start">
      <Stack gap="xs">
        <Title order={4}>{workgroup.name}</Title>
        <Anchor component="span">
          <Text size="sm">(Primary Point of Contact)</Text>
        </Anchor>
      </Stack>
      <SimpleGrid cols={5} spacing="xs">
        <MetricCard title="Active Users" value={999} />
        <MetricCard title="Curriculum Watched" value={999} />
        <MetricCard title="Tasks Completed" value={999} />
        <MetricCard title="Tasks Overdue" value={999} />
        <MetricCard title="Ontime Percent" value="99%" />
      </SimpleGrid>
    </Group>
  </Card>
);

export default WorkgroupSubgroupsCard;
