// hooks/useApprovalsSteps.ts
import { TaskAssignmentConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useCallback, useState } from 'react';

export interface ApprovalStep {
  id: string;
  title: string;
  taskAssignmentConfig?: TaskAssignmentConfig;
}

export interface UseApprovalsStepsReturn {
  approvalSteps: ApprovalStep[];
  addApprovalStep: (config: TaskAssignmentConfig, description: string) => void;
  removeApprovalStep: (id: string) => void;
  updateApprovalStepTitle: (id: string, title: string) => void;
  getValidSteps: () => Array<{
    id: string;
    title: string;
    taskAssignmentConfig: TaskAssignmentConfig;
  }>;
}

export const useApprovalsSteps = (): UseApprovalsStepsReturn => {
  const [approvalSteps, setApprovalSteps] = useState<ApprovalStep[]>([
    { id: uuid(), title: '' },
  ]);

  const addApprovalStep = useCallback(
    (config: TaskAssignmentConfig, description: string) => {
      setApprovalSteps((prev) => {
        const nonEmptySteps = prev.filter((step) => step.taskAssignmentConfig);
        const newTitle = description ? `${description} Approval` : '';

        return [
          ...nonEmptySteps,
          {
            id: uuid(),
            title: newTitle,
            taskAssignmentConfig: config,
          },
          // Add empty step at the end
          { id: uuid(), title: '' },
        ];
      });
    },
    []
  );

  const removeApprovalStep = useCallback((id: string) => {
    setApprovalSteps((prev) => {
      const configuredSteps = prev.filter(
        (step) => step.taskAssignmentConfig && step.id !== id
      );

      // Always ensure there's one empty step at the end
      return [...configuredSteps, { id: uuid(), title: '' }];
    });
  }, []);

  const updateApprovalStepTitle = useCallback((id: string, title: string) => {
    setApprovalSteps((prev) =>
      prev.map((step) => (step.id === id ? { ...step, title } : step))
    );
  }, []);

  const getValidSteps = useCallback(() => {
    return approvalSteps
      .filter(
        (step): step is Required<ApprovalStep> =>
          !!step.taskAssignmentConfig && !!step.title.trim()
      )
      .map((step) => ({
        id: step.id,
        title: step.title,
        taskAssignmentConfig: step.taskAssignmentConfig,
      }));
  }, [approvalSteps]);

  return {
    approvalSteps,
    addApprovalStep,
    removeApprovalStep,
    updateApprovalStepTitle,
    getValidSteps,
  };
};
