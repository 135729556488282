import { StepTypeEnum, TransitionConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { useCallback } from 'react';

interface WorkflowInitializationActions {
  initializeWorkflow: (name: string) => void;
}

export const useWorkflowInitialization = (
  updateWorkflow: (updates: Partial<CommonWorkflow>) => void
): WorkflowInitializationActions => {
  const initializeWorkflow = useCallback(
    (name: string) => {
      const startStep = {
        stepKey: uuid(),
        title: 'Start',
        stepTypeCode: StepTypeEnum.flowStart,
        formConfig: { fields: [] },
      };

      const formStep = {
        stepKey: uuid(),
        title: 'Form Submission',
        stepTypeCode: StepTypeEnum.formSubmission,
        formConfig: { fields: [] },
      };

      const transition: TransitionConfig = {
        transitionKey: uuid(),
        sourceStepKey: startStep.stepKey,
        targetStepKey: formStep.stepKey,
        index: 0,
      };

      updateWorkflow({
        name,
        workflowConfiguration: {
          steps: [startStep, formStep],
          transitions: [transition],
        },
      });
    },
    [updateWorkflow]
  );

  return { initializeWorkflow };
};
