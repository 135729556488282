import {
  StepConfig,
  SubmitReviewTaskDTO,
  useGetTaskInstanceById,
  useSubmitReviewTask,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback, useMemo } from 'react';

export const useFormReview = (taskInstanceId: number) => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const { data: taskInstance, isLoading: isTaskLoading } =
    useGetTaskInstanceById(taskInstanceId);
  const { mutateAsync: submitReviewTask } = useSubmitReviewTask();

  const currentStep = useMemo<StepConfig | null>(() => {
    if (
      taskInstance?.workflowInstance?.workflowDefinition?.workflowConfiguration
        ?.steps
    ) {
      return (
        taskInstance.workflowInstance.workflowDefinition.workflowConfiguration.steps.find(
          (s) => s.stepKey === taskInstance.stepKey
        ) || null
      );
    }
    return null;
  }, [taskInstance]);

  const submitReview = useCallback(
    async (formData: any) => {
      if (!taskInstance || !currentStep) {
        throw new Error('Missing task instance or current step');
      }

      const workflowStepInstance = taskInstance.workflowInstance?.steps?.find(
        (step) => step.stepKey === taskInstance.stepKey
      );

      if (!workflowStepInstance) {
        throw new Error('Matching WorkflowStepInstance not found');
      }

      const submitReviewTaskData: SubmitReviewTaskDTO = {
        organizationId: currentOrganization.id,
        taskInstanceId: taskInstance.id,
        workflowStepInstanceId: workflowStepInstance.id,
        personId: currentPerson.id,
        values: formData,
        stepConfig: currentStep,
      };

      try {
        const response = await submitReviewTask({
          data: submitReviewTaskData,
        });
        console.log('Review submitted successfully', response);
        return response;
      } catch (error) {
        console.error('Error submitting review:', error);
        throw error;
      }
    },
    [taskInstance, currentStep, currentPerson, submitReviewTask]
  );

  return {
    taskInstance,
    currentStep,
    isLoading: isTaskLoading,
    submitReview,
  };
};
