import {
  CertificationConfig,
  CertificationTypeEnum,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { CertificationRequiredConfiguration } from '../../config-components/certification/CertificationRequiredConfiguration/CertificationRequiredConfiguration';
import { CertificationValidConfiguration } from '../../config-components/certification/CertificationValidConfiguration/CertificationValidConfiguration';
import { certificationComponentRegistry } from '../../config/componentRegistry';
import { ConfigEditorWrapper } from '../ConfigEditorWrapper/ConfigEditorWrapper';

interface CertificationConfigEditorProps {
  initialCertificationConfig: CertificationConfig;
  workflow: CommonWorkflow;
  onClose: () => void;
  onDelete?: () => void;
  onSave: (config: CertificationConfig) => Promise<void>;
  isNew?: boolean;
}

export const CertificationConfigEditor: React.FC<
  CertificationConfigEditorProps
> = ({
  initialCertificationConfig,
  workflow,
  onClose,
  onDelete,
  onSave,
  isNew,
}) => {
  const [currentConfig, setCurrentConfig] =
    useState<CertificationConfig | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (initialCertificationConfig) {
      setCurrentConfig(initialCertificationConfig);
    }
  }, [initialCertificationConfig]);

  // if (workflow.workflowType !== WorkflowTypeEnum.certification) {
  //   console.error(
  //     'CertificationConfigEditor can only be used with Certification workflows'
  //   );
  //   return null;
  // }

  if (!currentConfig) {
    return null;
  }

  const handleConfigChange = (updatedConfig: CertificationConfig) => {
    setCurrentConfig(updatedConfig);
    setHasUnsavedChanges(true);
  };

  const handleSave = async () => {
    if (currentConfig) {
      await onSave(currentConfig);
      setHasUnsavedChanges(false);
      onClose();
    }
  };

  const handleCancel = () => {
    setHasUnsavedChanges(false);
    onClose();
  };

  const CertificationTypeComponent =
    certificationComponentRegistry[currentConfig.certificationType]?.component;

  const showValidAndRequired =
    currentConfig.certificationType !== CertificationTypeEnum.personData &&
    currentConfig.certificationType !== CertificationTypeEnum.taskData;

  return (
    <ConfigEditorWrapper
      hasUnsavedChanges={hasUnsavedChanges}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
      onDelete={onDelete}
    >
      <Stack gap="xl">
        {CertificationTypeComponent && (
          <CertificationTypeComponent
            config={currentConfig}
            onConfigChange={handleConfigChange}
            // workflowType={workflow.workflowType}
          />
        )}

        {showValidAndRequired && (
          <>
            <CertificationValidConfiguration
              config={currentConfig}
              onConfigChange={handleConfigChange}
            />
            <CertificationRequiredConfiguration
              config={currentConfig}
              onConfigChange={handleConfigChange}
            />
          </>
        )}
      </Stack>
    </ConfigEditorWrapper>
  );
};
