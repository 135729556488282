import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { ActionIcon, Card, Collapse, Group, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import React from 'react';
import { MetricContext } from '../../types/metricContext';
import { EnrichedWorkgroupStats } from '../../types/stats';
import { PersonDetail } from '../PersonDetail/PersonDetail';
import { TaskStatisticMetric } from '../TaskStatisticMetric/TaskStatisticMetric';

interface CategoryStats {
  name: string;
  completedTaskLists?: number;
  totalTaskLists?: number;
  completedTasks?: number;
  totalTasks?: number;
  progressPercentage: number;
  showTaskLists: boolean;
  labelOverride?: string;
}

interface TaskStatisticsCardProps {
  statistics: EnrichedWorkgroupStats;
  isExpanded: boolean;
  onToggleExpand: () => void;
  metricContext: MetricContext;
  workflowType?: WorkflowTypeEnum;
  taskListType?: string;
}

export const TaskStatisticsCard: React.FC<TaskStatisticsCardProps> = ({
  statistics,
  isExpanded,
  onToggleExpand,
  metricContext,
  workflowType,
  taskListType,
}) => {
  const getCategoryStats = (
    workflowType: WorkflowTypeEnum,
    taskListType?: string
  ): CategoryStats => {
    const relevantWorkflows = statistics.workflows.filter(
      (w) =>
        w.workflowType === workflowType &&
        (!taskListType || w.taskListType === taskListType)
    );

    const totalTaskLists = relevantWorkflows.reduce(
      (sum, w) => sum + (w.totalTaskLists || 0),
      0
    );
    const completedTaskLists = relevantWorkflows.reduce(
      (sum, w) => sum + (w.completedTaskLists || 0),
      0
    );
    const totalTasks = relevantWorkflows.reduce(
      (sum, w) => sum + (w.totalTasks || 0),
      0
    );
    const completedTasks = relevantWorkflows.reduce(
      (sum, w) => sum + (w.completedTasks || 0),
      0
    );

    const isCertification = workflowType === WorkflowTypeEnum.certification;

    return {
      name: taskListType || workflowType,
      completedTaskLists,
      totalTaskLists,
      completedTasks,
      totalTasks,
      progressPercentage: isCertification
        ? totalTaskLists > 0
          ? (completedTaskLists / totalTaskLists) * 100
          : 0
        : totalTasks > 0
        ? (completedTasks / totalTasks) * 100
        : 0,
      showTaskLists: isCertification,
      labelOverride:
        workflowType === WorkflowTypeEnum.curriculum ? 'Courses' : undefined,
    };
  };

  const categoryStats = [
    {
      ...getCategoryStats(
        WorkflowTypeEnum.certification,
        'Coach Certification'
      ),
      name: 'Coach Certification',
    },
    {
      ...getCategoryStats(
        WorkflowTypeEnum.certification,
        'Student-Athlete Eligibility'
      ),
      name: 'Student-Athlete Eligibility',
    },
    {
      ...getCategoryStats(WorkflowTypeEnum.taskList),
      name: 'To-Dos',
    },
    {
      ...getCategoryStats(WorkflowTypeEnum.curriculum),
      name: 'Curriculums',
    },
  ];

  // For filtered view (when workflowType is specified)
  const headerMetrics = (
    <Group gap="lg">
      {metricContext.showTaskLists && (
        <>
          <TaskStatisticMetric
            value={statistics.totalTaskLists ?? 0}
            label={`Total ${metricContext.taskListLabel}`}
          />
          <TaskStatisticMetric
            value={statistics.completedTaskLists ?? 0}
            label={`${metricContext.taskListLabel} Completed`}
          />
          <TaskStatisticMetric
            value={`${
              statistics.taskListCompletionPercentage?.toFixed(1) ?? '0.0'
            }%`}
            label={`${metricContext.taskListLabel} Completion`}
          />
        </>
      )}
      {metricContext.showTasks && (
        <>
          <TaskStatisticMetric
            value={statistics.totalTasks ?? 0}
            label={`Total ${metricContext.taskLabel}`}
          />
          <TaskStatisticMetric
            value={statistics.completedTasks ?? 0}
            label={`${metricContext.taskLabel} Completed`}
          />
          <TaskStatisticMetric
            value={`${
              statistics.taskCompletionPercentage?.toFixed(1) ?? '0.0'
            }%`}
            label={`${metricContext.taskLabel} Completion`}
          />
        </>
      )}
    </Group>
  );

  // For summary view (no workflowType specified)
  const summaryMetrics = (
    <Group gap="lg">
      {categoryStats.map((stats, index) => (
        <TaskStatisticMetric
          key={index}
          value={`${
            stats.showTaskLists
              ? stats.completedTaskLists
              : stats.completedTasks
          }/${stats.showTaskLists ? stats.totalTaskLists : stats.totalTasks}`}
          label={stats.name}
        />
      ))}
    </Group>
  );

  return (
    <Card shadow="sm" padding="lg" withBorder radius="md">
      <Group justify="space-between" align="center" wrap="nowrap">
        <Text fw={700} size="lg">
          {statistics.workgroupName}
        </Text>
        <Group gap="xl" justify="flex-end" wrap="nowrap">
          {metricContext.taskLabel === 'Tasks' ? summaryMetrics : headerMetrics}
          <ActionIcon onClick={onToggleExpand} variant="subtle" size="xl">
            {isExpanded ? (
              <IconChevronUp size="1.75rem" stroke={1.5} />
            ) : (
              <IconChevronDown size="1.75rem" stroke={1.5} />
            )}
          </ActionIcon>
        </Group>
      </Group>

      <Collapse in={isExpanded}>
        {isExpanded && (
          <PersonDetail
            workgroupId={statistics.workgroupId}
            metricContext={metricContext}
            workflowType={workflowType}
            taskListType={taskListType}
          />
        )}
      </Collapse>
    </Card>
  );
};

{
  /* <Stack mt="lg" gap="xs">
          {categoryStats.map((stats, index) => (
            <Card key={index} padding="sm" radius="sm" withBorder>
              <Stack gap="xs">
                <Group justify="space-between">
                  <Text size="sm" fw={500}>
                    {stats.name}
                  </Text>
                  <Group gap="lg">
                    <Text size="sm" c="dimmed" fw={500}>
                      {stats.showTaskLists
                        ? `${stats.completedTaskLists} / ${stats.totalTaskLists} Lists`
                        : `${stats.completedTasks} / ${stats.totalTasks} ${
                            stats.labelOverride || 'Tasks'
                          }`}
                    </Text>
                  </Group>
                </Group>
                <Progress
                  value={stats.progressPercentage}
                  size="xs"
                  radius="sm"
                />
              </Stack>
            </Card>
          ))}
        </Stack> */
}
