import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';
import { useFormConfig } from '@btrway/form-configuration-manager';
import { Card, Grid, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useFieldDragging } from '../../../hooks/useFieldDragging';
import DropZone from '../../drag/DropZone/DropZone';
import DraggableField from '../../field/DraggableField/DraggableField';
import Field from '../../field/Field/Field';
import SelectedField from '../../field/SelectedField/SelectedField';

interface FormBuilderContentProps {
  fields: FieldConfig[];
  onUpdate: (fieldKey: string, updates: Partial<FieldConfig>) => void;
  onRemove: (fieldKey: string) => void;
  onFieldsReorder: (fields: FieldConfig[]) => void;
  onAddField: (
    type: FieldTypeEnum,
    options?: Partial<FieldConfig>,
    index?: number
  ) => void;
  readOnly?: boolean;
  parentFieldKey?: string;
  emptyFormMessage?: string;
}

const FormBuilderContent: React.FC<FormBuilderContentProps> = ({
  fields,
  onUpdate,
  onRemove,
  onFieldsReorder,
  onAddField,
  readOnly = false,
  parentFieldKey,
  emptyFormMessage = 'Drop fields here',
}) => {
  const { selectedFieldKey, selectField } = useFormConfig();

  const {
    isFormDragging,
    handleDragStart,
    handleDragEnd,
    handleDrop,
    handleNewField,
    handleNewConnectedField,
  } = useFieldDragging({
    fields,
    readOnly,
    onUpdate,
    onFieldsReorder,
    addField: onAddField,
    parentFieldKey,
  });

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      selectField(null);
    }
  };

  const renderFieldContent = (field: FieldConfig) => {
    const isSelected = selectedFieldKey === field.fieldKey;

    const baseField = (
      <Field field={field} onUpdate={onUpdate} readOnly={readOnly} />
    );

    return isSelected && !readOnly ? (
      <SelectedField field={field} readOnly={readOnly} onRemove={onRemove}>
        {baseField}
      </SelectedField>
    ) : (
      baseField
    );
  };

  const renderFields = () => {
    if (fields.length === 0) {
      return (
        <>
          <div
            style={{
              height: 300,
              minHeight: '100px',
              position: 'relative',
              // border: '1px solid gray',
            }}
          >
            <DropZone
              index={0}
              onDrop={handleDrop}
              onNewField={handleNewField}
              onNewConnectedField={handleNewConnectedField}
              dropType="horizontal"
              width="100%"
              isFormDragging={isFormDragging}
              parentFieldKey={parentFieldKey}
            />
            <Text
              ta="center"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            >
              {emptyFormMessage}
            </Text>
          </div>
        </>
      );
    }

    const result: React.ReactElement[] = [];
    let currentRow: React.ReactElement[] = [];
    let currentRowStartIndex = 0;

    const renderCurrentRow = (index: number) => {
      if (currentRow.length > 0) {
        result.push(
          <Grid key={`dropzone-${currentRowStartIndex}`} gutter={0}>
            <Grid.Col span={12}>
              <div style={{ height: '0px', position: 'relative' }}>
                <DropZone
                  index={currentRowStartIndex}
                  onDrop={handleDrop}
                  onNewField={handleNewField}
                  onNewConnectedField={handleNewConnectedField}
                  dropType="horizontal"
                  width="100%"
                  isFormDragging={isFormDragging}
                  parentFieldKey={parentFieldKey}
                />
              </div>
            </Grid.Col>
          </Grid>
        );

        result.push(
          <Grid key={`row-${currentRowStartIndex}`} gutter={0}>
            <Grid.Col span={12}>
              <Group gap={0} grow align="stretch">
                {currentRow}
              </Group>
            </Grid.Col>
          </Grid>
        );
        currentRow = [];
      }
    };

    fields.forEach((field, index) => {
      const showLeftDropZone = field.startWithNewLine !== false || index === 0;

      const fieldElement = (
        <div key={`field-${index}`} style={{ flex: 1, display: 'flex' }}>
          {showLeftDropZone && (
            <div style={{ width: '16px', position: 'relative' }}>
              <DropZone
                index={index}
                onDrop={handleDrop}
                onNewField={handleNewField}
                onNewConnectedField={handleNewConnectedField}
                dropType="left"
                isFormDragging={isFormDragging}
                parentFieldKey={parentFieldKey}
              />
            </div>
          )}

          <div style={{ flex: 1 }}>
            <DraggableField
              field={field}
              index={index}
              isFormDragging={isFormDragging}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              readOnly={readOnly}
              parentFieldKey={parentFieldKey}
            >
              {renderFieldContent(field)}
            </DraggableField>
          </div>

          <div style={{ width: '16px', position: 'relative' }}>
            <DropZone
              index={index + 1}
              onDrop={handleDrop}
              onNewField={handleNewField}
              onNewConnectedField={handleNewConnectedField}
              dropType="right"
              isFormDragging={isFormDragging}
              parentFieldKey={parentFieldKey}
            />
          </div>
        </div>
      );

      if (field.startWithNewLine === false && currentRow.length > 0) {
        currentRow.push(fieldElement);
      } else {
        renderCurrentRow(index);
        currentRowStartIndex = index;
        currentRow.push(fieldElement);
      }
    });

    renderCurrentRow(fields.length);

    result.push(
      <Grid key="final-drop" gutter={0}>
        <Grid.Col span={12}>
          <div style={{ height: '12px', position: 'relative' }}>
            <DropZone
              index={fields.length}
              onDrop={handleDrop}
              onNewField={handleNewField}
              onNewConnectedField={handleNewConnectedField}
              dropType="horizontal"
              width="100%"
              isFormDragging={isFormDragging}
              parentFieldKey={parentFieldKey}
            />
          </div>
        </Grid.Col>
      </Grid>
    );

    return result;
  };

  return (
    <Card
      radius="lg"
      bg="white"
      shadow="none"
      padding="md"
      style={{ flex: 1 }}
      onClick={handleBackgroundClick}
    >
      <Stack
        gap={0}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          flex: 1,
          position: 'relative',
        }}
      >
        {renderFields()}
      </Stack>
    </Card>
  );
};

export default FormBuilderContent;
