/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type RecurrenceModificationTypeEnum = typeof RecurrenceModificationTypeEnum[keyof typeof RecurrenceModificationTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecurrenceModificationTypeEnum = {
  thisEvent: 'thisEvent',
  thisAndFollowingEvents: 'thisAndFollowingEvents',
  allEvents: 'allEvents',
} as const;
