// import {
//   Box,
//   Button,
//   Card,
//   Group,
//   Stack,
//   Text,
//   Textarea,
//   ThemeIcon,
//   Title,
// } from '@mantine/core';
// import { Dropzone } from '@mantine/dropzone';
// import { IconTextWrap, IconUpload } from '@tabler/icons-react';
// import React, { useState } from 'react';
// import { FormAIPromptProps } from '../../types/formAIResponse';
// import styles from './FormAIPrompt.module.css';

// export const FormAIPrompt: React.FC<FormAIPromptProps> = ({
//   onGenerate,
//   onSkip,
//   status,
//   error,
// }) => {
//   const [description, setDescription] = useState('');
//   const [file, setFile] = useState<File | null>(null);

//   const handleGenerate = async () => {
//     if (!description && !file) return;
//     const input = file || description;
//     await onGenerate(input);
//   };

//   const handleDrop = (files: File[]) => {
//     setFile(files[0]);
//     setDescription('');
//   };

//   const clearFile = () => {
//     setFile(null);
//   };

//   return (
//     <Box>
//       <Text fz="lg" fw={500} ta="center" mb="xl">
//         Describe what you'd like to see in the form, or upload an existing
//         document.
//       </Text>

//       <Group align="stretch" gap="xl" style={{ minHeight: '400px' }}>
//         <Card
//           withBorder
//           padding="md"
//           radius="lg"
//           style={{
//             borderColor: 'var(--mantine-color-gray-5)',
//             flex: 1,
//             display: 'flex',
//             flexDirection: 'column',
//           }}
//         >
//           <Group mb="md">
//             <ThemeIcon size="xl" radius="xl" color="green.6">
//               <IconTextWrap size={24} />
//             </ThemeIcon>
//             <Title order={3} fw={600}>
//               Describe Your Form
//             </Title>
//           </Group>
//           <Stack style={{ flex: 1 }}>
//             <div className={styles.textareaWrapper}>
//               <Textarea
//                 placeholder="Describe your form here or copy and paste..."
//                 value={description}
//                 onChange={(event) => setDescription(event.currentTarget.value)}
//               />
//             </div>
//           </Stack>
//         </Card>

//         <Stack justify="center" align="center">
//           <Text fw={700} fz={36}>
//             OR
//           </Text>
//         </Stack>

//         <Card
//           withBorder
//           padding="md"
//           radius="lg"
//           style={{
//             borderColor: 'var(--mantine-color-gray-5)',
//             flex: 1,
//             display: 'flex',
//             flexDirection: 'column',
//           }}
//         >
//           <Group mb="md">
//             <ThemeIcon size="xl" radius="xl" color="orange.6">
//               <IconUpload size={24} />
//             </ThemeIcon>
//             <Title order={3} fw={600}>
//               Upload a File
//             </Title>
//           </Group>
//           <Stack style={{ flex: 1 }}>
//             <Dropzone
//               onDrop={handleDrop}
//               maxSize={5 * 1024 ** 2}
//               accept={['text/plain', 'application/pdf']}
//               multiple={false}
//               styles={() => ({
//                 root: {
//                   borderWidth: 1,
//                   borderStyle: 'dashed',
//                   borderColor: 'var(--mantine-color-gray-5)',
//                   backgroundColor: 'var(--mantine-color-gray-0)',
//                   borderRadius: 'var(--mantine-radius-xl)',
//                   flex: 1,
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   minHeight: '250px',
//                 },
//               })}
//             >
//               <Group
//                 justify="center"
//                 gap="xl"
//                 style={{ pointerEvents: 'none' }}
//               >
//                 <div>
//                   <Text ta="center">Drop your file here</Text>
//                   <Text size="sm" ta="center" c="dimmed">
//                     PDF or text file, up to 5MB
//                   </Text>
//                 </div>
//               </Group>
//             </Dropzone>

//             {file && (
//               <Group>
//                 <Text size="sm">Selected file: {file.name}</Text>
//                 <Button variant="subtle" size="sm" onClick={clearFile}>
//                   Remove
//                 </Button>
//               </Group>
//             )}
//           </Stack>
//         </Card>
//       </Group>

//       {error && (
//         <Text c="red" ta="center" mt="xl">
//           {error.message}
//         </Text>
//       )}

//       <Group justify="center" gap="md" mt="xl">
//         <Button
//           onClick={handleGenerate}
//           size="lg"
//           loading={status === 'loading'}
//           disabled={!description && !file}
//         >
//           Build This Form!
//         </Button>
//         <Button
//           onClick={onSkip}
//           variant="outline"
//           size="lg"
//           disabled={status === 'loading'}
//         >
//           Start From Scratch
//         </Button>
//       </Group>
//     </Box>
//   );
// };

// export default FormAIPrompt;

// FormAIPrompt.tsx
import {
  Box,
  Button,
  Card,
  Group,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconTextWrap, IconUpload } from '@tabler/icons-react';
import React, { useState } from 'react';
import { FormAIPromptProps } from '../../types/formAIResponse';
import styles from './FormAIPrompt.module.css';

export const FormAIPrompt: React.FC<FormAIPromptProps> = ({
  onGenerate,
  status,
  error,
}) => {
  const [description, setDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const handleGenerate = async () => {
    if (!description && !file) return;
    const input = file || description;
    await onGenerate(input);
  };

  const handleDrop = (files: File[]) => {
    setFile(files[0]);
    setDescription('');
  };

  const clearFile = () => {
    setFile(null);
  };

  return (
    <Box>
      {/* <Text fz="lg" fw={500} ta="center" mb="xl">
        Describe what you'd like to see in the form, or upload an existing
        document.
      </Text> */}

      <Group align="stretch" gap="xl" style={{ minHeight: '400px' }}>
        <Card
          withBorder
          padding="md"
          radius="lg"
          style={{
            borderColor: 'var(--mantine-color-gray-5)',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Group mb="md">
            <ThemeIcon size="xl" radius="xl" color="orange.6">
              <IconUpload size={24} />
            </ThemeIcon>
            <Title order={3} fw={600}>
              Upload a File
            </Title>
          </Group>
          <Stack style={{ flex: 1 }}>
            <Dropzone
              onDrop={handleDrop}
              maxSize={5 * 1024 ** 2}
              accept={['text/plain', 'application/pdf']}
              multiple={false}
              styles={() => ({
                root: {
                  borderWidth: 1,
                  borderStyle: 'dashed',
                  borderColor: 'var(--mantine-color-gray-5)',
                  backgroundColor: 'var(--mantine-color-gray-0)',
                  borderRadius: 'var(--mantine-radius-xl)',
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '250px',
                },
              })}
            >
              <Group
                justify="center"
                gap="xl"
                style={{ pointerEvents: 'none' }}
              >
                <div>
                  <Text ta="center">Drop your file here</Text>
                  <Text size="sm" ta="center" c="dimmed">
                    PDF or text file, up to 5MB
                  </Text>
                </div>
              </Group>
            </Dropzone>

            {file && (
              <Group>
                <Text size="sm">Selected file: {file.name}</Text>
                <Button variant="subtle" size="sm" onClick={clearFile}>
                  Remove
                </Button>
              </Group>
            )}
          </Stack>
        </Card>

        <Stack justify="center" align="center">
          <Text fw={700} fz={36}>
            OR
          </Text>
        </Stack>

        <Card
          withBorder
          padding="md"
          radius="lg"
          style={{
            borderColor: 'var(--mantine-color-gray-5)',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Group mb="md">
            <ThemeIcon size="xl" radius="xl" color="green.6">
              <IconTextWrap size={24} />
            </ThemeIcon>
            <Title order={3} fw={600}>
              Describe Your Form
            </Title>
          </Group>
          <Stack style={{ flex: 1 }}>
            <div className={styles.textareaWrapper}>
              <Textarea
                placeholder="Describe your form here or copy and paste..."
                value={description}
                onChange={(event) => setDescription(event.currentTarget.value)}
              />
            </div>
          </Stack>
        </Card>
      </Group>

      {error && (
        <Text c="red" ta="center" mt="xl">
          {error.message}
        </Text>
      )}

      {/* Hidden button for triggering generation */}
      <button
        data-ai-generate
        onClick={handleGenerate}
        style={{ display: 'none' }}
        disabled={!description && !file}
      />
    </Box>
  );
};

export default FormAIPrompt;
