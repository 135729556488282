import {
  TaskStatisticsGroupByEnum,
  TaskStatisticsRequest,
  TaskStatisticsResponse,
  useGetTaskStatistics,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useEffect, useState } from 'react';
import {
  EnrichedPersonStats,
  EnrichedWorkflowStats,
  WorkflowTypeTaskStatisticsResponse,
} from '../types/stats';

interface UseFetchPersonStatsResult {
  data: EnrichedPersonStats[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const calculateWorkflowTypeStats = (
  workflows: TaskStatisticsResponse[],
  workflowType: WorkflowTypeEnum
): WorkflowTypeTaskStatisticsResponse => {
  const filteredWorkflows = workflows.filter(
    (w) => w.workflowType === workflowType
  );

  return {
    completedTasks: filteredWorkflows.reduce(
      (sum, w) => sum + (w.completedTasks || 0),
      0
    ),
    completedTaskLists: filteredWorkflows.reduce(
      (sum, w) => sum + (w.completedTaskLists || 0),
      0
    ),
    overdueCompletedTasks: filteredWorkflows.reduce(
      (sum, w) => sum + (w.overdueCompletedTasks || 0),
      0
    ),
    totalTaskLists: filteredWorkflows.reduce(
      (sum, w) => sum + (w.totalTaskLists || 0),
      0
    ),
    totalTasks: filteredWorkflows.reduce(
      (sum, w) => sum + (w.totalTasks || 0),
      0
    ),
    workflowType,
  };
};

const calculateTaskListProgress = (
  completedTaskLists: number = 0,
  totalTaskLists: number = 0
): number => {
  return totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0;
};

const calculateTaskProgress = (
  completedTasks: number = 0,
  totalTasks: number = 0
): number => {
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};

const transformToEnrichedWorkflow = (
  workflow: TaskStatisticsResponse
): EnrichedWorkflowStats => {
  const completedTasks = workflow.completedTasks || 0;
  const totalTasks = workflow.totalTasks || 0;
  const completedTaskLists = workflow.completedTaskLists || 0;
  const totalTaskLists = workflow.totalTaskLists || 0;

  return {
    ...workflow,
    workflowKey: workflow.workflowKey,
    workflowName: workflow.workflowName,
    workflowType: workflow.workflowType,
    taskListType: workflow.taskListType,
    averageOverdueDays: workflow.averageOverdueDays,
    completedTaskLists,
    completedTasks,
    overdueCompletedTasks: workflow.overdueCompletedTasks,
    totalTaskLists,
    totalTasks,
    progressPercentage: calculateTaskProgress(completedTasks, totalTasks),
    taskListProgressPercentage: calculateTaskListProgress(
      completedTaskLists,
      totalTaskLists
    ),
    remainingTasks: totalTasks - completedTasks,
    remainingTaskLists: totalTaskLists - completedTaskLists,
    isOverdue: (workflow.averageOverdueDays || 0) > 0,
  };
};

const transformToEnrichedPerson = (
  personId: number,
  personName: string,
  workflows: TaskStatisticsResponse[]
): EnrichedPersonStats => {
  const workgroupInfo = workflows[0];

  const enrichedWorkflows = workflows.map((workflow) => ({
    ...transformToEnrichedWorkflow(workflow),
    taskListType: workflow.taskListType,
  }));

  const workflowTypes = [
    ...new Set(workflows.map((w) => w.workflowType).filter(Boolean)),
  ] as WorkflowTypeEnum[];

  const totalRemaining = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.remainingTasks,
    0
  );

  const totalRemainingTaskLists = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.remainingTaskLists,
    0
  );

  const totalTaskProgress = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.progressPercentage,
    0
  );

  const totalTaskListProgress = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.taskListProgressPercentage,
    0
  );

  const overdueCount = enrichedWorkflows.filter(
    (workflow) => workflow.isOverdue
  ).length;

  return {
    personId,
    personName,
    workgroupId: workgroupInfo.workgroupId || 0, // Add this
    workgroupName: workgroupInfo.workgroupName || '', // Add this
    workflows: enrichedWorkflows,
    workflowStatistics: enrichedWorkflows,
    workflowTypeStats: workflowTypes.map((type) =>
      calculateWorkflowTypeStats(workflows, type)
    ),
    totalRemainingTasks: totalRemaining,
    totalRemainingTaskLists: totalRemainingTaskLists,
    averageTaskProgress:
      enrichedWorkflows.length > 0
        ? totalTaskProgress / enrichedWorkflows.length
        : 0,
    averageTaskListProgress:
      enrichedWorkflows.length > 0
        ? totalTaskListProgress / enrichedWorkflows.length
        : 0,
    overdueWorkflowsCount: overdueCount,
  };
};

export const useFetchPersonStats = (
  request: TaskStatisticsRequest
): UseFetchPersonStatsResult => {
  const [data, setData] = useState<EnrichedPersonStats[] | undefined>(
    undefined
  );

  const {
    mutateAsync: getTaskStatistics,
    isPending: isLoading,
    isError,
    error: queryError,
  } = useGetTaskStatistics();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const personRequest: TaskStatisticsRequest = {
          ...request,
          groupBy: TaskStatisticsGroupByEnum.person,
        };

        const response = await getTaskStatistics({ data: personRequest });

        const statistics = Array.isArray(response) ? response : [response];

        // Group by person
        const personMap = new Map<number, TaskStatisticsResponse[]>();
        statistics.forEach((stat) => {
          if (!stat.personId) return;
          const existing = personMap.get(stat.personId) || [];
          personMap.set(stat.personId, [...existing, stat]);
        });

        // Transform to enriched persons
        const enrichedStats = Array.from(personMap.entries()).map(
          ([personId, workflows]) =>
            transformToEnrichedPerson(
              personId,
              workflows[0].personName || '',
              workflows
            )
        );

        setData(enrichedStats);
      } catch (err) {
        console.error('Error fetching person stats:', err);
        setData(undefined);
      }
    };

    fetchData();
  }, [getTaskStatistics, request]);

  const refetch = async () => {
    await getTaskStatistics({ data: request });
  };

  return {
    data,
    isLoading,
    isError,
    error: queryError instanceof Error ? queryError : null,
    refetch,
  };
};
