import {
  FieldConfig,
  FieldTypeEnum,
  PropertyReference,
} from '@btrway/api-workflow';
import {
  getDefaultFieldConfig,
  useFormConfig,
} from '@btrway/form-configuration-manager';
import { uuid } from '@btrway/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SHOW_DRAG_DEBUG } from '../constants/debug';
import { mustStartNewLine } from '../constants/fieldTypes';

type DropType = 'left' | 'right' | 'horizontal';

// Debug helper function
const debugLog = (message: string, data?: any) => {
  if (SHOW_DRAG_DEBUG) {
    console.log(`[FieldDragging] ${message}`, data || '');
  }
};

interface UseFieldDraggingProps {
  fields: FieldConfig[];
  readOnly?: boolean;
  onUpdate: (fieldKey: string, updates: Partial<FieldConfig>) => void;
  onFieldsReorder: (fields: FieldConfig[]) => void;
  addField: (
    type: FieldTypeEnum,
    options?: Partial<FieldConfig>,
    index?: number
  ) => void;
  parentFieldKey?: string;
}

export const useFieldDragging = ({
  fields,
  readOnly = false,
  onUpdate,
  onFieldsReorder,
  addField,
  parentFieldKey,
}: UseFieldDraggingProps) => {
  const [isFormDragging, setIsFormDragging] = useState(false);
  const latestFields = useRef(fields);
  const { moveFieldBetweenLevels } = useFormConfig();

  useEffect(() => {
    latestFields.current = fields;
  }, [fields]);

  const getDraggedFieldStartWithNewLine = useCallback(
    (type: FieldTypeEnum, dropType: DropType): boolean => {
      return (
        mustStartNewLine(type) ||
        dropType === 'horizontal' ||
        dropType === 'left'
      );
    },
    []
  );

  const handleDragStart = useCallback(() => {
    if (!readOnly) {
      setIsFormDragging(true);
    }
  }, [readOnly]);

  const handleDragEnd = useCallback(() => {
    setIsFormDragging(false);
  }, []);

  const handleDrop = useCallback(
    (dragItem: any, dropIndex: number, dropType: DropType) => {
      if (readOnly) return;

      // debugLog('Drop event details:', {
      //   dragItem,
      //   dropIndex,
      //   dropType,
      //   currentParentKey: parentFieldKey,
      //   draggedFromParentKey: dragItem.parentFieldKey,
      // });

      // Handle cross-level dragging
      if (dragItem.parentFieldKey !== parentFieldKey) {
        const startWithNewLine = getDraggedFieldStartWithNewLine(
          dragItem.field.type,
          dropType
        );

        moveFieldBetweenLevels(
          dragItem.field,
          dragItem.parentFieldKey,
          parentFieldKey,
          dropIndex,
          startWithNewLine
        );
        return;
      }

      // Same level reordering
      let newFields = [...latestFields.current];
      const draggedField = { ...newFields[dragItem.index] };
      newFields.splice(dragItem.index, 1);

      // Handle row adjustments for same-level moves
      const nextField = newFields[dragItem.index];
      if (nextField && !nextField.startWithNewLine) {
        newFields[dragItem.index].startWithNewLine = true;
      }

      let finalIndex = dropIndex;
      if (dragItem.index < dropIndex) {
        finalIndex--;
      }
      finalIndex = Math.max(0, Math.min(finalIndex, newFields.length));

      // Update startWithNewLine based on drop type
      draggedField.startWithNewLine = getDraggedFieldStartWithNewLine(
        draggedField.type,
        dropType
      );

      // Handle target field updates for left drops
      if (dropType === 'left' && finalIndex < newFields.length) {
        newFields[finalIndex] = {
          ...newFields[finalIndex],
          startWithNewLine: false,
        };
      }

      newFields.splice(finalIndex, 0, draggedField);
      onFieldsReorder(newFields);
    },
    [
      readOnly,
      parentFieldKey,
      getDraggedFieldStartWithNewLine,
      moveFieldBetweenLevels,
      onFieldsReorder,
    ]
  );

  const handleNewField = useCallback(
    (type: FieldTypeEnum, dropIndex: number, dropType: DropType) => {
      if (readOnly) return;

      const currentFields = [...latestFields.current];
      const insertionIndex = Math.max(
        0,
        Math.min(dropIndex, currentFields.length)
      );

      const newField: FieldConfig = {
        fieldKey: uuid(),
        type,
        label: `New ${type} Field`,
        startWithNewLine: dropType === 'left' || dropType === 'horizontal',
        required: false,
      };

      const updatedFields = [...currentFields];
      updatedFields.splice(insertionIndex, 0, newField);

      if (dropType === 'left' && insertionIndex < currentFields.length) {
        const targetField = updatedFields[insertionIndex + 1];
        if (targetField) {
          targetField.startWithNewLine = false;
        }
      }

      if (dropType === 'right' && insertionIndex > 0) {
        const prevField = updatedFields[insertionIndex - 1];
        if (prevField && prevField.startWithNewLine) {
          newField.startWithNewLine = false;
        }
      }

      latestFields.current = updatedFields;
      onFieldsReorder(updatedFields);
      handleDragEnd();
    },
    [readOnly, onFieldsReorder, handleDragEnd]
  );

  const handleNewConnectedField = useCallback(
    (
      propertyReference: PropertyReference,
      dropIndex: number,
      dropType: DropType
    ) => {
      if (readOnly) return;

      const currentFields = [...latestFields.current];
      const insertionIndex = Math.max(
        0,
        Math.min(dropIndex, currentFields.length)
      );

      const newField = getDefaultFieldConfig(propertyReference);
      newField.startWithNewLine =
        dropType === 'left' || dropType === 'horizontal';

      const updatedFields = [...currentFields];
      updatedFields.splice(insertionIndex, 0, newField);

      if (dropType === 'left' && insertionIndex < currentFields.length) {
        const targetField = updatedFields[insertionIndex + 1];
        if (targetField) {
          targetField.startWithNewLine = false;
        }
      }

      latestFields.current = updatedFields;
      onFieldsReorder(updatedFields);
      handleDragEnd();
    },
    [readOnly, onFieldsReorder, handleDragEnd]
  );

  useEffect(() => {
    const handleGlobalDragStart = () => {
      handleDragStart();
    };

    const handleGlobalDragEnd = () => {
      handleDragEnd();
    };

    window.addEventListener('dragstart', handleGlobalDragStart);
    window.addEventListener('dragend', handleGlobalDragEnd);

    return () => {
      window.removeEventListener('dragstart', handleGlobalDragStart);
      window.removeEventListener('dragend', handleGlobalDragEnd);
    };
  }, [handleDragStart, handleDragEnd]);

  return {
    isFormDragging,
    handleDragStart,
    handleDragEnd,
    handleDrop,
    handleNewField,
    handleNewConnectedField,
  };
};
