import { ActionIcon, Box } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { createContext, useRef, useState } from 'react';
import AddCloseButton from '../../buttons/AddCloseButton/AddCloseButton';

interface FlyoutMenuProps {
  children: React.ReactNode;
  width?: number;
  direction?: 'left' | 'right';
}

export const FlyoutAnimationContext = createContext({ isOpen: false });

export const FlyoutMenu: React.FC<FlyoutMenuProps> = ({
  children,
  width = 300,
  direction = 'right',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const menuVariants = {
    open: {
      width,
      height: 'calc(100vh - 96px)',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        duration: 0.2,
      },
    },
    closed: {
      width: 44,
      height: 44,
      transition: {
        type: 'spring',
        stiffness: 500,
        damping: 30,
        duration: 0.2,
      },
    },
  };

  return (
    <FlyoutAnimationContext.Provider value={{ isOpen }}>
      <Box w={44} h={44}>
        <motion.div
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          ref={containerRef}
          style={{
            position: 'absolute',
            top: 0,
            [direction === 'right' ? 'left' : 'right']: 0,
            zIndex: 1001,
            backgroundColor: 'var(--mantine-color-white)',
            borderRadius: isOpen ? 'var(--mantine-radius-lg)' : '50%',
            boxShadow: 'var(--mantine-shadow-md)',
            overflow: 'hidden',
            transformOrigin: direction === 'right' ? 'top left' : 'top right',
            display: 'flex',
            flexDirection: 'column',
          }}
          variants={menuVariants}
        >
          <Box
            component="button"
            onClick={() => setIsOpen(!isOpen)}
            style={{
              all: 'unset',
              position: 'absolute',
              top: 0,
              [direction === 'right' ? 'left' : 'right']: 0,
              zIndex: 2,
              cursor: 'pointer',
              width: 44,
              height: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ActionIcon
              size="xl"
              bg="white"
              color="white"
              variant="outline"
              radius="xl"
            >
              <AddCloseButton
                isOpen={isOpen}
                size={24}
                color="var(--mantine-color-dark-6)"
              />
            </ActionIcon>
          </Box>

          <AnimatePresence>
            {isOpen && (
              <Box
                component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{
                  position: 'absolute',
                  top: 44,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflowY: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {children}
              </Box>
            )}
          </AnimatePresence>
        </motion.div>
      </Box>
    </FlyoutAnimationContext.Provider>
  );
};

export default FlyoutMenu;
