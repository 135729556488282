import { FieldConfig, TriggerSetConfig } from '@btrway/api-workflow';
import { Button, Group } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { TriggerSetEditor } from '../TriggerSetEditor/TriggerSetEditor';
import { TriggerSetView } from '../TriggerSetView/TriggerSetView';

interface TriggerSetBuilderProps {
  triggerSet: TriggerSetConfig;
  availableFields: FieldConfig[];
  onUpdate: (triggerSet: TriggerSetConfig) => void;
  onRemove?: (triggerSet: TriggerSetConfig) => void;
  readOnly?: boolean;
  initialEditMode?: boolean;
}

export const TriggerSetBuilder: React.FC<TriggerSetBuilderProps> = ({
  triggerSet,
  availableFields,
  onUpdate,
  onRemove,
  readOnly = false,
  initialEditMode = false,
}) => {
  const [isEditing, setIsEditing] = useState(initialEditMode);

  // Effect to handle initialEditMode changes
  useEffect(() => {
    setIsEditing(initialEditMode);
  }, [initialEditMode]);

  const handleEditComplete = (updatedTriggerSet: TriggerSetConfig) => {
    onUpdate(updatedTriggerSet);
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    // If this is a new trigger set and it's being cancelled, we should remove it
    if (initialEditMode && onRemove) {
      onRemove(triggerSet);
    }
  };

  if (isEditing && !readOnly) {
    return (
      <TriggerSetEditor
        triggerSet={triggerSet}
        availableFields={availableFields}
        onSave={handleEditComplete}
        onCancel={handleEditCancel}
      />
    );
  }

  return (
    <TriggerSetView
      triggerSet={triggerSet}
      availableFields={availableFields}
      actions={
        !readOnly && (
          <Group justify="space-between" gap="xs">
            {onRemove && (
              <Button
                variant="subtle"
                size="xs"
                color="red"
                onClick={() => onRemove(triggerSet)}
              >
                <IconTrash size={16} />
              </Button>
            )}
            <Button
              variant="light"
              size="xs"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          </Group>
        )
      }
    />
  );
};
