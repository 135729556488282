import { Box, Radio, Stack } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { useState } from 'react';
import { useExpandedNodes } from '../../hooks/useExpandedNodes';
import { useHierarchicalNodes } from '../../hooks/useHierarchicalNodes';
import { useNodeSelection } from '../../hooks/useNodeSelection';
import {
  BaseHierarchicalItem,
  HierarchicalNode,
} from '../../types/hierarchicalItem';
import {
  areAllChildrenSelected,
  getEffectiveSelection,
  getParentNode,
} from '../../utils/hierarchicalUtils';
import { NodeCheckbox } from '../NodeCheckbox/NodeCheckbox';

export interface SelectionData<T> {
  ids: number[];
  additionalData?: Map<number, T>;
}

export interface HierarchicalSelectorProps<
  T extends BaseHierarchicalItem,
  U = void
> {
  items: T[];
  selectedIds?: number[];
  getAdditionalIds?: (items: T[]) => number[];
  onChange: (selection: SelectionData<U>) => void;
  renderLabel?: (item: T) => string;
  renderAdditionalContent?: (props: {
    node: HierarchicalNode<T>;
    isSelected: boolean;
    onAdditionalDataChange: (data: U) => void;
  }) => React.ReactNode;
  initialAdditionalData?: Map<number, U>;
  allowMultiple?: boolean;
}

export const HierarchicalSelector = <T extends BaseHierarchicalItem, U = void>({
  items,
  selectedIds,
  getAdditionalIds,
  onChange,
  renderLabel = (item) => item.name,
  renderAdditionalContent,
  initialAdditionalData,
  allowMultiple = true,
}: HierarchicalSelectorProps<T, U>) => {
  const hierarchicalNodes = useHierarchicalNodes(items);
  const [selectedNodeIds, setSelectedNodeIds] = useNodeSelection(
    hierarchicalNodes,
    selectedIds,
    getAdditionalIds
  );
  const [expandedNodes, toggleExpand] = useExpandedNodes();
  const [additionalData, setAdditionalData] = useState<Map<number, U>>(
    initialAdditionalData || new Map()
  );

  const handleAdditionalDataChange = (nodeId: number, data: U) => {
    const newData = new Map(additionalData);
    newData.set(nodeId, data);
    setAdditionalData(newData);

    const effectiveSelection = getEffectiveSelection(selectedNodeIds, items);
    onChange({
      ids: effectiveSelection,
      additionalData: newData,
    });
  };

  const handleSelectionChange = (
    node: HierarchicalNode<T>,
    checked: boolean
  ) => {
    if (allowMultiple) {
      handleMultipleSelection(node, checked);
    } else {
      handleSingleSelection(node);
    }
  };

  const handleSingleSelection = (node: HierarchicalNode<T>) => {
    const newSelection = new Set([node.id]);
    const newAdditionalData = new Map(additionalData);

    // Clear all other selections
    selectedNodeIds.forEach((id) => {
      if (id !== node.id) {
        newAdditionalData.delete(id);
      }
    });

    setSelectedNodeIds(newSelection);
    setAdditionalData(newAdditionalData);

    const effectiveSelection = getEffectiveSelection(newSelection, items);
    onChange({
      ids: effectiveSelection,
      additionalData: newAdditionalData,
    });
  };

  const handleMultipleSelection = (
    node: HierarchicalNode<T>,
    checked: boolean
  ) => {
    const newSelection = new Set(selectedNodeIds);
    const newAdditionalData = new Map(additionalData);

    if (checked) {
      newSelection.add(node.id);

      const addChildren = (parent: HierarchicalNode<T>) => {
        parent.children.forEach((child) => {
          newSelection.add(child.id);
          addChildren(child);
        });
      };
      addChildren(node);

      const checkAncestors = (currentNode: HierarchicalNode<T>) => {
        const parent = getParentNode(currentNode, hierarchicalNodes);
        if (parent && areAllChildrenSelected(parent, newSelection)) {
          newSelection.add(parent.id);
          checkAncestors(parent);
        }
      };
      checkAncestors(node);
    } else {
      const removeDescendants = (parent: HierarchicalNode<T>) => {
        newSelection.delete(parent.id);
        newAdditionalData.delete(parent.id);
        parent.children.forEach((child) => {
          removeDescendants(child);
        });
      };
      removeDescendants(node);

      const removeAncestors = (currentNode: HierarchicalNode<T>) => {
        const parent = getParentNode(currentNode, hierarchicalNodes);
        if (parent) {
          newSelection.delete(parent.id);
          newAdditionalData.delete(parent.id);
          removeAncestors(parent);
        }
      };
      removeAncestors(node);
    }

    setSelectedNodeIds(newSelection);
    setAdditionalData(newAdditionalData);

    const effectiveSelection = getEffectiveSelection(newSelection, items);
    onChange({
      ids: effectiveSelection,
      additionalData: newAdditionalData,
    });
  };

  const isIndirectlyChecked = (node: HierarchicalNode<T>): boolean => {
    const parent = getParentNode(node, hierarchicalNodes);
    return allowMultiple
      ? parent
        ? selectedNodeIds.has(parent.id)
        : false
      : false;
  };

  const renderNodes = (nodes: HierarchicalNode<T>[]): JSX.Element[] => {
    return nodes.map((node) => {
      const isChecked = selectedNodeIds.has(node.id);
      const isIndirect = isIndirectlyChecked(node);
      const hasChildren = node.children.length > 0;
      const isExpanded = expandedNodes.has(node.id);
      const SPACING_UNIT = 24;

      return (
        <Stack key={node.id} gap="xs">
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: node.level * SPACING_UNIT,
            }}
          >
            <Box
              style={{
                width: SPACING_UNIT,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {hasChildren && (
                <IconChevronRight
                  size={16}
                  style={{
                    transform: isExpanded ? 'rotate(90deg)' : 'none',
                    transition: 'transform 200ms ease',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => toggleExpand(node.id, e)}
                />
              )}
            </Box>

            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                flex: 1,
              }}
            >
              {allowMultiple ? (
                <NodeCheckbox
                  checked={isChecked}
                  isIndirect={isIndirect}
                  label={renderLabel(node)}
                  onChange={(checked) => handleSelectionChange(node, checked)}
                />
              ) : (
                <Radio
                  value={node.id.toString()}
                  label={renderLabel(node)}
                  checked={isChecked}
                  onChange={() => handleSelectionChange(node, true)}
                />
              )}

              {renderAdditionalContent &&
                isChecked &&
                renderAdditionalContent({
                  node,
                  isSelected: isChecked,
                  onAdditionalDataChange: (data: U) =>
                    handleAdditionalDataChange(node.id, data),
                })}
            </Box>
          </Box>

          {hasChildren && isExpanded && renderNodes(node.children)}
        </Stack>
      );
    });
  };

  return (
    <Stack gap="xs">
      {allowMultiple ? (
        renderNodes(hierarchicalNodes)
      ) : (
        <Radio.Group
          value={Array.from(selectedNodeIds)[0]?.toString() || ''}
          onChange={(value) => {
            const node = hierarchicalNodes.find(
              (n) => n.id.toString() === value
            );
            if (node) {
              handleSelectionChange(node, true);
            }
          }}
        >
          <Stack gap="xs">{renderNodes(hierarchicalNodes)}</Stack>
        </Radio.Group>
      )}
    </Stack>
  );
};
