import { useStateProvinces } from '@btrway/reference-data-manager';
import { Select, Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface StateProvinceFieldProps extends BaseFieldProps<string> {}

export const StateProvinceField: React.FC<StateProvinceFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const { stateProvinces, isLoading } = useStateProvinces();
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const stateOptions = React.useMemo(() => {
    return stateProvinces.map((state) => ({
      value: state.code,
      label: `${state.name} (${state.countryCode})`,
    }));
  }, [stateProvinces]);

  // Find state/province info for display
  const selectedState = React.useMemo(() => {
    if (!value) return null;
    return stateProvinces.find((state) => state.code === value);
  }, [value, stateProvinces]);

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={selectedState ? 'inherit' : 'dimmed'}>
          {selectedState
            ? `${selectedState.name} (${selectedState.countryCode})`
            : 'No state/province selected'}
        </Text>
      </Stack>
    );
  }

  const commonProps = {
    required: field.required,
    disabled: readonly,
  };

  return (
    <Select
      {...commonProps}
      data={stateOptions}
      value={value || null}
      onChange={onChange}
      searchable
      clearable
      nothingFoundMessage="No states or provinces available"
    />
  );
};

export default StateProvinceField;
