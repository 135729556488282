import { FormConfig, StepConfig } from '@btrway/api-workflow';
import { FormBuilder, FormBuilderRef } from '@btrway/form-builder';
import { Button, Card, Group, Stack } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React, { useRef } from 'react';
import styles from './FormCard.module.css';

interface FormCardProps {
  formStep: StepConfig;
  workflowTitle: string;
  isSelected?: boolean;
  onSelect: (stepKey: string) => void;
  onSave?: (formConfig: FormConfig) => void;
  onCancel?: () => void;
}

const FormCard: React.FC<FormCardProps> = ({
  formStep,
  workflowTitle,
  isSelected = false,
  onSelect,
  onSave,
  onCancel,
}) => {
  const formBuilderRef = useRef<FormBuilderRef>(null);

  const handleClick = () => {
    onSelect(formStep.stepKey);
  };

  const handleSave = () => {
    formBuilderRef.current?.handleSave();
  };

  const handleCancel = () => {
    formBuilderRef.current?.handleCancel();
  };

  return (
    <Card p="md" radius="md" withBorder shadow="xs" className={styles.card}>
      <Stack gap={0}>
        {isSelected ? (
          <div onClick={(e) => e.stopPropagation()}>
            <Group justify="flex-end" p={0}>
              <Button color="gray" variant="outline" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave} variant="filled" color="blue">
                Save Form
              </Button>
            </Group>

            <FormBuilder
              ref={formBuilderRef}
              stepKey={formStep.stepKey}
              initialConfig={formStep.formConfig}
              formTitle={workflowTitle}
              onSave={onSave}
              onCancel={onCancel}
              readOnly={false}
            />
          </div>
        ) : (
          <div className={styles.preview}>
            <div className={styles.content}>
              <FormBuilder
                initialConfig={formStep.formConfig}
                formTitle={workflowTitle}
                readOnly={true}
              />
            </div>
            <div className={styles.fadeOutOverlay} />
            <div className={styles.buttonContainer}>
              <Button
                variant="outline"
                size="lg"
                leftSection={<IconEdit size={16} />}
                onClick={handleClick}
              >
                Edit Form Fields
              </Button>
            </div>
          </div>
        )}
      </Stack>
    </Card>
  );
};

export default FormCard;
