import { IconPlus, IconX } from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

interface AddCloseButtonProps {
  isOpen: boolean;
  size?: number;
  color?: string;
  onClick?: () => void;
}

const AddCloseButton: React.FC<AddCloseButtonProps> = ({
  isOpen,
  size = 18,
  color = 'currentColor',
  onClick,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '24px',
        height: '24px',
      }}
      onClick={onClick}
    >
      <AnimatePresence initial={false}>
        {!isOpen ? (
          <motion.div
            key="plus"
            initial={{ opacity: 0, rotate: -90 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: 90 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconPlus size={size} color={color} />
          </motion.div>
        ) : (
          <motion.div
            key="x"
            initial={{ opacity: 0, rotate: -90 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: 90 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconX size={size} color={color} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AddCloseButton;
