import React, { createContext, useContext } from 'react';
import { WorkflowSourceType } from '../types/commonType';

interface WorkflowCommonContextValue {
  sourceType: WorkflowSourceType;
}

const WorkflowCommonContext = createContext<WorkflowCommonContextValue | null>(
  null
);

interface WorkflowCommonProviderProps {
  children: React.ReactNode;
  sourceType: WorkflowSourceType;
}

export const WorkflowCommonProvider: React.FC<WorkflowCommonProviderProps> = ({
  children,
  sourceType,
}) => {
  return (
    <WorkflowCommonContext.Provider value={{ sourceType }}>
      {children}
    </WorkflowCommonContext.Provider>
  );
};

export const useWorkflowCommon = (): WorkflowCommonContextValue => {
  const context = useContext(WorkflowCommonContext);
  if (!context) {
    throw new Error(
      'useWorkflowCommon must be used within a WorkflowCommonProvider'
    );
  }
  return context;
};
