import { HeightFieldProperties } from '@btrway/api-workflow';
import { HeightData, HeightInput } from '@btrway/measurable-inputs';
import { Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface HeightFieldProps extends BaseFieldProps<HeightData> {}

export const HeightField: React.FC<HeightFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const heightFieldProperties = field.fieldProperties as HeightFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  // Format height value for display
  const formatHeight = (data: HeightData | undefined): string => {
    if (!data) return 'No height entered';

    if (data.system === 'imperial') {
      const { feet, inches, fraction } = data.imperial;
      if (heightFieldProperties?.allowFractionalInches) {
        const fractionStr = fraction > 0 ? `${fraction}/8` : '';
        return `${feet}' ${inches}${fractionStr}"`;
      }
      return `${feet}' ${inches}"`;
    } else {
      const { centimeters } = data.metric;
      const decimalPlaces = heightFieldProperties?.metricDecimalPlaces ?? 2;
      return `${centimeters.toFixed(decimalPlaces)} cm`;
    }
  };

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value ? 'inherit' : 'dimmed'}>
          {value ? formatHeight(value) : 'No height entered'}
        </Text>
      </Stack>
    );
  }

  const handleChange = (newValue: HeightData) => {
    if (readonly) return;
    onChange?.(newValue);
  };

  return (
    <HeightInput
      onChange={handleChange}
      imperialEnabled={heightFieldProperties?.allowImperial ?? true}
      metricEnabled={heightFieldProperties?.allowMetric ?? true}
      allowFractionalInches={
        heightFieldProperties?.allowFractionalInches ?? true
      }
      decimalPlaces={heightFieldProperties?.metricDecimalPlaces ?? 2}
      value={value}
      readOnly={readonly}
    />
  );
};
