// import { formatPhoneNumber } from '@btrway/utils';
// import { Text, TextInput, TextInputProps } from '@mantine/core';
// import { IconPhone } from '@tabler/icons-react';
// import { parsePhoneNumber } from 'libphonenumber-js';
// import { useState } from 'react';

// type PhoneNumberInputProps = Omit<TextInputProps, 'error'> & {
//   onPhoneChange?: (phone: string | null) => void;
// };

// export const PhoneNumberInput = ({
//   value,
//   onChange,
//   onPhoneChange,
//   description,
//   ...props
// }: PhoneNumberInputProps) => {
//   const [error, setError] = useState<string | null>(null);
//   const [displayValue, setDisplayValue] = useState(
//     formatPhoneNumber(value as string)
//   );

//   const validatePhone = (phone: string): boolean => {
//     try {
//       const parsedNumber = parsePhoneNumber(phone);
//       return parsedNumber.isValid();
//     } catch {
//       return false;
//     }
//   };

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const newValue = event.target.value;
//     const cleanValue = newValue.replace(/[^\d+]/g, '');
//     setDisplayValue(formatPhoneNumber(cleanValue));
//     onChange?.(event);

//     if (!cleanValue) {
//       setError(null);
//       onPhoneChange?.(null);
//       return;
//     }
//     onPhoneChange?.(cleanValue);
//   };

//   const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
//     const cleanValue = event.target.value.replace(/[^\d+]/g, '');

//     if (!cleanValue) {
//       setError(null);
//       onPhoneChange?.(null);
//       return;
//     }

//     const phoneWithPrefix = cleanValue.startsWith('+')
//       ? cleanValue
//       : `+1${cleanValue}`;

//     if (validatePhone(phoneWithPrefix)) {
//       setError(null);
//       onPhoneChange?.(phoneWithPrefix);
//       setDisplayValue(formatPhoneNumber(phoneWithPrefix));
//     } else {
//       setError('Please enter a valid phone number');
//       onPhoneChange?.(cleanValue);
//     }
//   };

//   const defaultDescription =
//     'Enter a phone number with or without country code';

//   return (
//     <div>
//       <TextInput
//         {...props}
//         value={displayValue}
//         onChange={handleChange}
//         onBlur={handleBlur}
//         error={error}
//         leftSection={<IconPhone size={16} />}
//       />
//       {(description || defaultDescription) && (
//         <Text fz="xs" c="dimmed" mt={4}>
//           {description || defaultDescription}
//         </Text>
//       )}
//     </div>
//   );
// };

import { formatPhoneNumber } from '@btrway/utils';
import { Text, TextInput, TextInputProps } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';

type PhoneNumberInputProps = Omit<TextInputProps, 'error'> & {
  onPhoneChange?: (phone: string | null) => void;
};

export const PhoneNumberInput = ({
  value,
  onChange,
  onPhoneChange,
  description,
  ...props
}: PhoneNumberInputProps) => {
  const [error, setError] = useState<string | null>(null);
  const [displayValue, setDisplayValue] = useState(
    formatPhoneNumber(value as string)
  );

  // Update displayValue when value prop changes
  useEffect(() => {
    if (value) {
      const formatted = formatPhoneNumber(value as string);
      setDisplayValue(formatted);
    } else {
      setDisplayValue('');
    }
  }, [value]);

  const validatePhone = (phone: string): boolean => {
    try {
      const parsedNumber = parsePhoneNumber(phone);
      return parsedNumber.isValid();
    } catch {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const cleanValue = newValue.replace(/[^\d+]/g, '');
    setDisplayValue(formatPhoneNumber(cleanValue));
    onChange?.(event);

    if (!cleanValue) {
      setError(null);
      onPhoneChange?.(null);
      return;
    }
    onPhoneChange?.(cleanValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value.replace(/[^\d+]/g, '');

    if (!cleanValue) {
      setError(null);
      onPhoneChange?.(null);
      return;
    }

    const phoneWithPrefix = cleanValue.startsWith('+')
      ? cleanValue
      : `+1${cleanValue}`;

    if (validatePhone(phoneWithPrefix)) {
      setError(null);
      onPhoneChange?.(phoneWithPrefix);
      setDisplayValue(formatPhoneNumber(phoneWithPrefix));
    } else {
      setError('Please enter a valid phone number');
      onPhoneChange?.(cleanValue);
    }
  };

  const defaultDescription =
    'Enter a phone number with or without country code';

  return (
    <div>
      <TextInput
        {...props}
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        leftSection={<IconPhone size={16} />}
      />
      {(description || defaultDescription) && (
        <Text fz="xs" c="dimmed" mt={4}>
          {description || defaultDescription}
        </Text>
      )}
    </div>
  );
};
