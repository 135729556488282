import {
  TaskStatisticsGroupByEnum,
  TaskStatisticsRequest,
  TaskStatisticsResponse,
  useGetTaskStatistics,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useEffect, useState } from 'react';
import {
  EnrichedWorkflowStats,
  EnrichedWorkgroupStats,
  WorkflowTypeTaskStatisticsResponse,
} from '../types/stats';

interface UseFetchWorkgroupStatsResult {
  data: EnrichedWorkgroupStats[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const calculateWorkflowTypeStats = (
  workflows: TaskStatisticsResponse[],
  workflowType: WorkflowTypeEnum
): WorkflowTypeTaskStatisticsResponse => {
  const filteredWorkflows = workflows.filter(
    (w) => w.workflowType === workflowType
  );

  return {
    completedTasks: filteredWorkflows.reduce(
      (sum, w) => sum + (w.completedTasks || 0),
      0
    ),
    completedTaskLists: filteredWorkflows.reduce(
      (sum, w) => sum + (w.completedTaskLists || 0),
      0
    ),
    overdueCompletedTasks: filteredWorkflows.reduce(
      (sum, w) => sum + (w.overdueCompletedTasks || 0),
      0
    ),
    totalTaskLists: filteredWorkflows.reduce(
      (sum, w) => sum + (w.totalTaskLists || 0),
      0
    ),
    totalTasks: filteredWorkflows.reduce(
      (sum, w) => sum + (w.totalTasks || 0),
      0
    ),
    workflowType,
  };
};

const calculateTaskListProgress = (
  completedTaskLists: number = 0,
  totalTaskLists: number = 0
): number => {
  return totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0;
};

const calculateTaskProgress = (
  completedTasks: number = 0,
  totalTasks: number = 0
): number => {
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};
const transformToEnrichedWorkflow = (
  workflow: TaskStatisticsResponse
): EnrichedWorkflowStats => {
  const completedTasks = workflow.completedTasks || 0;
  const totalTasks = workflow.totalTasks || 0;
  const completedTaskLists = workflow.completedTaskLists || 0;
  const totalTaskLists = workflow.totalTaskLists || 0;

  return {
    ...workflow, // Make sure we spread all properties first
    workflowKey: workflow.workflowKey,
    workflowName: workflow.workflowName,
    workflowType: workflow.workflowType,
    taskListType: workflow.taskListType, // Explicitly include taskListType
    averageOverdueDays: workflow.averageOverdueDays,
    completedTaskLists,
    completedTasks,
    overdueCompletedTasks: workflow.overdueCompletedTasks,
    totalTaskLists,
    totalTasks,
    progressPercentage: calculateTaskProgress(completedTasks, totalTasks),
    taskListProgressPercentage: calculateTaskListProgress(
      completedTaskLists,
      totalTaskLists
    ),
    remainingTasks: totalTasks - completedTasks,
    remainingTaskLists: totalTaskLists - completedTaskLists,
    isOverdue: (workflow.averageOverdueDays || 0) > 0,
  };
};

const transformToEnrichedWorkgroup = (
  workgroupId: number,
  workgroupName: string,
  workflows: TaskStatisticsResponse[]
): EnrichedWorkgroupStats => {
  // When mapping workflows, ensure we preserve all properties including taskListType
  const enrichedWorkflows = workflows.map((workflow) => ({
    ...transformToEnrichedWorkflow(workflow),
    taskListType: workflow.taskListType, // Explicitly ensure taskListType is preserved
  }));

  const workflowTypes = [
    ...new Set(workflows.map((w) => w.workflowType).filter(Boolean)),
  ] as WorkflowTypeEnum[];

  const totalRemaining = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.remainingTasks,
    0
  );

  const totalRemainingTaskLists = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.remainingTaskLists,
    0
  );

  const totalTaskProgress = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.progressPercentage,
    0
  );

  const totalTaskListProgress = enrichedWorkflows.reduce(
    (sum, workflow) => sum + workflow.taskListProgressPercentage,
    0
  );

  const overdueCount = enrichedWorkflows.filter(
    (workflow) => workflow.isOverdue
  ).length;

  return {
    workgroupId,
    workgroupName,
    workflows: enrichedWorkflows,
    workflowStatistics: enrichedWorkflows,
    workflowTypeStats: workflowTypes.map((type) =>
      calculateWorkflowTypeStats(workflows, type)
    ),
    totalRemainingTasks: totalRemaining,
    totalRemainingTaskLists: totalRemainingTaskLists,
    averageTaskProgress:
      enrichedWorkflows.length > 0
        ? totalTaskProgress / enrichedWorkflows.length
        : 0,
    averageTaskListProgress:
      enrichedWorkflows.length > 0
        ? totalTaskListProgress / enrichedWorkflows.length
        : 0,
    overdueWorkflowsCount: overdueCount,
  };
};

export const useFetchWorkgroupStats = (
  request: TaskStatisticsRequest
): UseFetchWorkgroupStatsResult => {
  const [data, setData] = useState<EnrichedWorkgroupStats[] | undefined>(
    undefined
  );

  const {
    mutateAsync: getTaskStatistics,
    isPending: isLoading,
    isError,
    error: queryError,
  } = useGetTaskStatistics();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const workgroupRequest: TaskStatisticsRequest = {
          ...request,
          groupBy: TaskStatisticsGroupByEnum.workgroup,
        };

        const response = await getTaskStatistics({ data: workgroupRequest });
        const statistics = Array.isArray(response) ? response : [response];

        // Group by workgroup
        const workgroupMap = new Map<number, TaskStatisticsResponse[]>();
        statistics.forEach((stat) => {
          if (!stat.workgroupId) return;
          const existing = workgroupMap.get(stat.workgroupId) || [];
          workgroupMap.set(stat.workgroupId, [...existing, stat]);
        });

        // Transform to enriched workgroups
        const enrichedStats = Array.from(workgroupMap.entries()).map(
          ([workgroupId, workflows]) =>
            transformToEnrichedWorkgroup(
              workgroupId,
              workflows[0].workgroupName || '',
              workflows
            )
        );

        setData(enrichedStats);
      } catch (err) {
        console.error('Error fetching workgroup stats:', err);
        setData(undefined);
      }
    };

    fetchData();
  }, [getTaskStatistics, request]);

  const refetch = async () => {
    await getTaskStatistics({ data: request });
  };

  return {
    data,
    isLoading,
    isError,
    error: queryError instanceof Error ? queryError : null,
    refetch,
  };
};
