/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type NumberFieldType = typeof NumberFieldType[keyof typeof NumberFieldType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberFieldType = {
  decimal: 'decimal',
  integer: 'integer',
  currency: 'currency',
} as const;
