import { Alert, Group, NumberInput, Stack, Text } from '@mantine/core';
import { useMeasurementSystem } from '../../hooks/useMeasurementSystem';
import { useWeight } from '../../hooks/useWeight';
import { BaseInputProps, WeightData } from '../../types/types';
import { SystemSwitcher } from '../SystemSwitcher/SystemSwitcher';

export interface WeightInputProps extends BaseInputProps {
  value?: WeightData;
}

export const WeightInput = ({
  onChange,
  imperialEnabled = true,
  metricEnabled = true,
  decimalPlaces = 2,
  value,
  readOnly = false,
}: WeightInputProps) => {
  if (!imperialEnabled && !metricEnabled) {
    return (
      <Alert color="red">At least one measurement system must be enabled</Alert>
    );
  }

  const { system, handleSystemChange } = useMeasurementSystem({
    initialSystem: value?.system || (imperialEnabled ? 'imperial' : 'metric'),
    imperialEnabled,
    metricEnabled,
    readOnly,
  });

  const { pounds, kilograms, calculateValues } = useWeight({
    value,
    system,
    onChange,
    decimalPlaces,
  });

  const handleNumberInputChange = (value: number | string) => {
    if (readOnly) return;
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      calculateValues(isNaN(parsed) ? undefined : parsed);
    } else {
      calculateValues(value);
    }
  };

  return (
    <Stack gap={4}>
      <Group gap="xs" align="flex-start">
        {system === 'imperial' ? (
          <Stack gap={2}>
            <NumberInput
              value={pounds}
              onChange={handleNumberInputChange}
              min={0}
              decimalScale={decimalPlaces}
              placeholder="lb"
              styles={{ input: { width: 120 } }}
              disabled={readOnly}
            />
            <Text fz="sm" c="dimmed">
              Pounds
            </Text>
          </Stack>
        ) : (
          <Stack gap={2}>
            <NumberInput
              value={kilograms}
              onChange={handleNumberInputChange}
              min={0}
              decimalScale={decimalPlaces}
              placeholder="kg"
              styles={{ input: { width: 120 } }}
              disabled={readOnly}
            />
            <Text fz="sm" c="dimmed">
              Kilograms
            </Text>
          </Stack>
        )}
      </Group>
      {imperialEnabled && metricEnabled && (
        <SystemSwitcher
          currentSystem={system}
          onSystemChange={handleSystemChange}
          disabled={readOnly}
        />
      )}
    </Stack>
  );
};
