import { StepConfig, WorkflowStepInstanceResponse } from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { FormRenderer } from '@btrway/form-renderer';
import { formatDateTime } from '@btrway/utils';
import { Box, Button, Divider, Group, Stack, Text } from '@mantine/core';
import React from 'react';

interface WorkflowStepInstanceDetailProps {
  workflowStepInstance: WorkflowStepInstanceResponse;
  workflowStep?: StepConfig;
  isOnlyStep: boolean;
}

const WorkflowStepInstanceDetail: React.FC<WorkflowStepInstanceDetailProps> = ({
  workflowStepInstance,
  workflowStep,
  isOnlyStep,
}) => {
  return (
    <Box
      style={{
        height: isOnlyStep ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack justify="space-between" style={{ flex: 1 }}>
        <Stack gap="md">
          <Stack gap="xs">
            <Text size="xl" fw={700}>
              {workflowStep?.title}
            </Text>
            <Divider />
          </Stack>
          {workflowStep?.formConfig && workflowStepInstance.formData && (
            <FormRenderer
              formConfig={workflowStep.formConfig}
              initialValues={workflowStepInstance.formData}
              readOnly={true}
            />
          )}
        </Stack>
        <Stack gap="md" mt="auto">
          <Divider />
          <Group justify="space-between">
            <Button size="xs" variant="outline">
              Send Back
            </Button>
            <Text>
              Submitted at:{' '}
              {workflowStepInstance.endedAt
                ? formatDateTime(workflowStepInstance.endedAt)
                : 'Not submitted'}
              {workflowStepInstance.endedAt &&
                workflowStepInstance.completedBy && (
                  <>
                    {' by '}
                    <EntityTag
                      dataType="person"
                      id={workflowStepInstance.completedBy}
                      allowClick={false}
                    />
                  </>
                )}
            </Text>
          </Group>
        </Stack>
      </Stack>
    </Box>
  );
};

export default WorkflowStepInstanceDetail;
