import { FieldConfig } from '@btrway/api-workflow';
import { useFormConfig } from '@btrway/form-configuration-manager';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface DraggableFieldProps {
  field: FieldConfig;
  index: number;
  isFormDragging: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
  readOnly?: boolean;
  parentFieldKey?: string;
  children: React.ReactNode;
}

const DraggableField: React.FC<DraggableFieldProps> = ({
  field,
  index,
  isFormDragging,
  onDragStart,
  onDragEnd,
  readOnly = false,
  parentFieldKey,
  children,
}) => {
  const { selectedFieldKey, selectField } = useFormConfig();
  const isSelected = selectedFieldKey === field.fieldKey;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'field',
      item: () => {
        if (!readOnly) {
          onDragStart();
          const dragItem = {
            type: 'field' as const,
            index,
            field: JSON.parse(JSON.stringify(field)),
            parentFieldKey,
            isNew: false,
          };
          return dragItem;
        }
        return undefined;
      },
      canDrag: !readOnly && isSelected,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        onDragEnd();
      },
    }),
    [field, index, readOnly, parentFieldKey, isSelected, onDragStart, onDragEnd]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const handleClick = (e: React.MouseEvent) => {
    console.log('DraggableField handleClick');
    // Check if click originated from within the editor
    const target = e.target as HTMLElement;
    const isEditorClick = target.closest('.ProseMirror') !== null;

    if (!readOnly && !isEditorClick) {
      e.stopPropagation();
      selectField(field.fieldKey);
    }
  };

  return (
    <div
      ref={!readOnly ? drag : null}
      onClick={handleClick}
      style={{
        opacity: isDragging ? 0.3 : 1,
        cursor: isSelected && !readOnly ? 'move' : 'pointer',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

export default DraggableField;
