import { FieldTypeEnum, PropertyReference } from '@btrway/api-workflow';
import React from 'react';
import { useDrop } from 'react-dnd';
import { SHOW_DRAG_DEBUG } from '../../../constants/debug';
import { mustStartNewLine } from '../../../constants/fieldTypes';
import { FormDragItem } from '../../../types/dragTypes';

const Z_INDEX = {
  HORIZONTAL: 5,
  VERTICAL: 10,
} as const;

const HORIZONTAL_EXTENSION = 50;
const BASE_VERTICAL_EXTENSION = 125;

interface DropZoneProps {
  index: number;
  onDrop: (
    dragItem: any,
    dropIndex: number,
    dropType: 'left' | 'right' | 'horizontal'
  ) => void;
  onNewField: (
    type: FieldTypeEnum,
    dropIndex: number,
    dropType: 'left' | 'right' | 'horizontal'
  ) => void;
  onNewConnectedField: (
    propertyReference: PropertyReference,
    dropIndex: number,
    dropType: 'left' | 'right' | 'horizontal'
  ) => void;
  dropType: 'left' | 'right' | 'horizontal';
  width?: string;
  isFormDragging: boolean;
  fieldsInRow?: number;
  parentFieldKey?: string;
}

const DropZone: React.FC<DropZoneProps> = ({
  index,
  onDrop,
  onNewField,
  onNewConnectedField,
  dropType,
  width = '100%',
  isFormDragging,
  fieldsInRow = 1,
  parentFieldKey,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ['field', 'new-field', 'connected-field'],
      canDrop: (item: FormDragItem) => {
        // Always allow drops at the first position for horizontal drop zones
        if (dropType === 'horizontal' && index === 0) {
          return true;
        }

        // For connected fields
        if (item.type === 'connected-field') {
          return dropType === 'horizontal' || dropType === 'left';
        }

        // For new fields from toolbox
        if (item.type === 'new-field') {
          return dropType === 'horizontal' || !mustStartNewLine(item.fieldType);
        }

        // For existing fields being dragged
        if (item.type === 'field') {
          // Don't allow dropping onto itself if in same parent
          if (item.parentFieldKey === parentFieldKey && item.index === index) {
            return false;
          }
          return (
            dropType === 'horizontal' || !mustStartNewLine(item.field.type)
          );
        }

        return true;
      },
      drop: (item: FormDragItem) => {
        if (item.type === 'connected-field') {
          onNewConnectedField(item.propertyReference, index, dropType);
        } else if (item.type === 'new-field') {
          onNewField(item.fieldType, index, dropType);
        } else {
          onDrop(item, index, dropType);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [dropType, index, onDrop, onNewField, onNewConnectedField, parentFieldKey]
  );

  const isHorizontal = dropType === 'horizontal';
  const VERTICAL_EXTENSION = BASE_VERTICAL_EXTENSION / fieldsInRow;

  const defaultPosition = isHorizontal
    ? {
        top: '0px',
        bottom: '0px',
        left: 0,
        right: 0,
      }
    : dropType === 'left'
    ? {
        top: 0,
        bottom: 0,
        left: '0px',
        right: '0px',
      }
    : {
        top: 0,
        bottom: 0,
        left: '0px',
        right: '0px',
      };

  const dragPosition = isHorizontal
    ? {
        top: `-${HORIZONTAL_EXTENSION}px`,
        bottom: `-${HORIZONTAL_EXTENSION}px`,
        left: 0,
        right: 0,
      }
    : dropType === 'left'
    ? {
        top: 0,
        bottom: 0,
        left: `-${VERTICAL_EXTENSION}px`,
        right: `-${VERTICAL_EXTENSION}px`,
      }
    : {
        top: 0,
        bottom: 0,
        left: `-${VERTICAL_EXTENSION}px`,
        right: `-${VERTICAL_EXTENSION}px`,
      };

  const extendedAreaStyle: React.CSSProperties = {
    position: 'absolute',
    ...(isFormDragging ? dragPosition : defaultPosition),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: SHOW_DRAG_DEBUG
      ? isOver && canDrop
        ? 'rgba(227, 242, 253, 0.8)'
        : 'rgba(248, 250, 255, 0.4)'
      : 'transparent',
    border: SHOW_DRAG_DEBUG ? '1px solid rgba(187, 222, 251, 1)' : 'none',
    transition: 'all 0.2s ease',
    zIndex: isHorizontal ? Z_INDEX.HORIZONTAL : Z_INDEX.VERTICAL,
    pointerEvents: canDrop ? 'auto' : 'none',
  };

  const indicatorStyle: React.CSSProperties = {
    position: 'absolute',
    ...(isHorizontal
      ? {
          left: 0,
          right: 0,
          height: '4px',
          top: '50%',
          transform: 'translateY(-50%)',
        }
      : {
          top: 0,
          bottom: 0,
          width: '4px',
          left: '50%',
          transform: 'translateX(-50%)',
        }),
    background: 'rgba(34, 139, 230, 0.6)',
    transition: 'opacity 0.2s ease',
    opacity: isOver && canDrop ? 1 : 0,
    visibility: isOver && canDrop ? 'visible' : 'hidden',
  };

  const debugTextStyle: React.CSSProperties = {
    position: 'absolute',
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.3)',
    pointerEvents: 'none',
    zIndex: -1,
    ...(isHorizontal
      ? {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }
      : {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-90deg)',
        }),
  };

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: isHorizontal ? '12px' : '100%',
        zIndex: isHorizontal ? Z_INDEX.HORIZONTAL : Z_INDEX.VERTICAL,
      }}
    >
      <div style={extendedAreaStyle}>
        {SHOW_DRAG_DEBUG && (
          <div style={debugTextStyle}>
            {index}:{dropType[0].toUpperCase()}
            {!canDrop && ' (disabled)'}
          </div>
        )}
        <div style={indicatorStyle} />
      </div>
    </div>
  );
};

export default DropZone;
