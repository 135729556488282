import { StepConfig, StepTypeEnum, WorkflowConfig } from '@btrway/api-workflow';
import { useCallback, useMemo } from 'react';

interface UseStepNavigationProps {
  workflowConfiguration: WorkflowConfig;
}

export const useStepNavigation = ({
  workflowConfiguration,
}: UseStepNavigationProps) => {
  // Find the initial starting step of the workflow
  const findStartStep = useCallback((): StepConfig | null => {
    return (
      workflowConfiguration.steps?.find(
        (step) =>
          step.stepTypeCode === StepTypeEnum.flowStart &&
          !workflowConfiguration.transitions?.some(
            (transition) => transition.targetStepKey === step.stepKey
          )
      ) || null
    );
  }, [workflowConfiguration]);

  // Get the next step based on current step
  const getNextStep = useCallback(
    (currentStep: StepConfig): StepConfig | null => {
      const nextTransition = workflowConfiguration.transitions?.find(
        (transition) => transition.sourceStepKey === currentStep.stepKey
      );
      if (nextTransition) {
        return (
          workflowConfiguration.steps?.find(
            (step) => step.stepKey === nextTransition.targetStepKey
          ) || null
        );
      }
      return null;
    },
    [workflowConfiguration]
  );

  // Get all steps in sequence
  const stepSequence = useMemo(() => {
    const sequence: StepConfig[] = [];
    const startStep = findStartStep();

    if (startStep) {
      sequence.push(startStep);
      let currentStep = startStep;

      while (true) {
        const nextStep = getNextStep(currentStep);
        if (!nextStep) break;
        sequence.push(nextStep);
        currentStep = nextStep;
      }
    }

    return sequence;
  }, [findStartStep, getNextStep]);

  // Get total number of steps
  const totalSteps = useMemo(() => stepSequence.length, [stepSequence]);

  // Get current step index
  const getCurrentStepIndex = useCallback(
    (currentStep: StepConfig): number => {
      return stepSequence.findIndex(
        (step) => step.stepKey === currentStep.stepKey
      );
    },
    [stepSequence]
  );

  return {
    findStartStep,
    getNextStep,
    stepSequence,
    totalSteps,
    getCurrentStepIndex,
  };
};
