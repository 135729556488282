import { EventConfig, StepConfig, WorkflowConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useWorkflowUpdater } from '@btrway/workflow-common-provider';
import { useWorkflowConfigUtilities } from '@btrway/workflow-configuration-utilities';
import { useCallback } from 'react';
import { ProcessActions } from '../types/actions';

export const useProcessActions = (
  workflowUpdater: ReturnType<typeof useWorkflowUpdater>,
  workflowConfig: WorkflowConfig,
  setWorkflowConfig: React.Dispatch<React.SetStateAction<WorkflowConfig>>
): ProcessActions => {
  const {
    getStep,
    getEvent,
    getOrderedSteps,
    getOutgoingTransitions,
    getNextStep,
    getPreviousStep,
  } = useWorkflowConfigUtilities(workflowConfig);

  const updateStep = useCallback(
    async (stepKey: string, updatedStep: Partial<StepConfig>) => {
      const steps = workflowConfig.steps || [];
      const existingStepIndex = steps.findIndex(
        (step) => step.stepKey === stepKey
      );

      if (existingStepIndex === -1) {
        throw new Error(`Step with key ${stepKey} not found`);
      }

      const updatedStepConfig = {
        ...steps[existingStepIndex],
        ...updatedStep,
        stepKey,
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        steps:
          prev.steps?.map((step) =>
            step.stepKey === stepKey ? updatedStepConfig : step
          ) || [],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { steps: [updatedStepConfig] },
      });

      return updatedStepConfig;
    },
    [workflowConfig.steps, workflowUpdater]
  );

  const appendStep = useCallback(
    async (newStep: StepConfig) => {
      const stepWithKey = {
        ...newStep,
        stepKey: newStep.stepKey || uuid(),
      };

      const lastStep = workflowConfig.steps?.[workflowConfig.steps.length - 1];
      const newTransition = lastStep
        ? [
            {
              transitionKey: uuid(),
              sourceStepKey: lastStep.stepKey,
              targetStepKey: stepWithKey.stepKey,
              index: workflowConfig.transitions?.length || 0,
            },
          ]
        : [];

      setWorkflowConfig((prev) => ({
        ...prev,
        steps: [...(prev.steps || []), stepWithKey],
        transitions: [...(prev.transitions || []), ...newTransition],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: {
          steps: [stepWithKey],
          transitions: newTransition,
        },
      });

      return stepWithKey;
    },
    [workflowConfig, workflowUpdater]
  );

  const removeStep = useCallback(
    async (stepKeyToRemove: string) => {
      if (!workflowConfig.steps) {
        throw new Error('Steps array is undefined');
      }

      const updatedSteps = workflowConfig.steps.filter(
        (step) => step.stepKey !== stepKeyToRemove
      );

      // Rebuild transitions for remaining steps
      const updatedTransitions = updatedSteps
        .slice(0, -1)
        .map((step, index) => ({
          transitionKey: uuid(),
          sourceStepKey: step.stepKey,
          targetStepKey: updatedSteps[index + 1].stepKey,
          index,
        }));

      setWorkflowConfig((prev) => ({
        ...prev,
        steps: updatedSteps,
        transitions: updatedTransitions,
      }));

      await workflowUpdater.updateConfig({
        deleteConfigs: [stepKeyToRemove],
      });
    },
    [workflowConfig, workflowUpdater]
  );

  const insertStep = useCallback(
    async (newStep: StepConfig, newIndex: number) => {
      const stepWithKey = {
        ...newStep,
        stepKey: newStep.stepKey || uuid(),
      };

      let updatedSteps = [...(workflowConfig.steps || [])];
      updatedSteps.splice(newIndex, 0, stepWithKey);

      // Rebuild transitions for the new step order
      const updatedTransitions = updatedSteps
        .slice(0, -1)
        .map((step, index) => ({
          transitionKey: uuid(),
          sourceStepKey: step.stepKey,
          targetStepKey: updatedSteps[index + 1].stepKey,
          index,
        }));

      setWorkflowConfig((prev) => ({
        ...prev,
        steps: updatedSteps,
        transitions: updatedTransitions,
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: {
          steps: [stepWithKey],
          transitions: updatedTransitions,
        },
      });

      return stepWithKey;
    },
    [workflowConfig, workflowUpdater]
  );

  const reorderStep = useCallback(
    async (stepKey: string, newIndex: number) => {
      if (!workflowConfig.steps) {
        return;
      }

      const steps = [...workflowConfig.steps];
      const oldIndex = steps.findIndex((step) => step.stepKey === stepKey);

      if (oldIndex === -1 || oldIndex === newIndex) {
        return;
      }

      const [movedStep] = steps.splice(oldIndex, 1);
      steps.splice(newIndex, 0, movedStep);

      // Rebuild transitions for the new order
      const updatedTransitions = steps.slice(0, -1).map((step, index) => ({
        transitionKey: uuid(),
        sourceStepKey: step.stepKey,
        targetStepKey: steps[index + 1].stepKey,
        index,
      }));

      setWorkflowConfig((prev) => ({
        ...prev,
        steps,
        transitions: updatedTransitions,
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: {
          steps,
          transitions: updatedTransitions,
        },
      });
    },
    [workflowConfig, workflowUpdater]
  );

  const addEvent = useCallback(
    async (newEvent: EventConfig) => {
      const eventWithKey = {
        ...newEvent,
        eventKey: newEvent.eventKey || uuid(),
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        events: [...(prev.events || []), eventWithKey],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { events: [eventWithKey] },
      });

      return eventWithKey;
    },
    [workflowUpdater]
  );

  const removeEvent = useCallback(
    async (eventKey: string) => {
      setWorkflowConfig((prev) => ({
        ...prev,
        events:
          prev.events?.filter((event) => event.eventKey !== eventKey) || [],
      }));

      await workflowUpdater.updateConfig({
        deleteConfigs: [eventKey],
      });
    },
    [workflowUpdater]
  );

  const updateEvent = useCallback(
    async (eventKey: string, updatedEvent: Partial<EventConfig>) => {
      if (!workflowConfig.events) {
        throw new Error('Events array is undefined');
      }

      const existingEventIndex = workflowConfig.events.findIndex(
        (event) => event.eventKey === eventKey
      );

      if (existingEventIndex === -1) {
        throw new Error(`Event with key ${eventKey} not found`);
      }

      const updatedEventConfig = {
        ...workflowConfig.events[existingEventIndex],
        ...updatedEvent,
        eventKey,
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        events:
          prev.events?.map((event) =>
            event.eventKey === eventKey ? updatedEventConfig : event
          ) || [],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { events: [updatedEventConfig] },
      });

      return updatedEventConfig;
    },
    [workflowConfig.events, workflowUpdater]
  );

  return {
    getStep,
    getEvent,
    getOrderedSteps,
    getOutgoingTransitions,
    getNextStep,
    getPreviousStep,
    addEvent,
    removeEvent,
    updateEvent,
    updateStep,
    appendStep,
    removeStep,
    insertStep,
    reorderStep,
  };
};
