import { useCourses } from '@btrway/courseware-manager';
import {
  CertificationConfigEditor,
  CourseSelector,
  CurriculumEditor,
  EventConfigEditor,
  FormPacketEditor,
  StepConfigEditor,
  TaskConfigEditor,
  TaskListDateConfiguration,
  WorkflowSelector,
} from '@btrway/workflow-builder-editors';
import { ToolboxManager } from '@btrway/workflow-builder-toolbox';
import {
  useWorkflowService,
  WorkflowServiceProvider,
} from '@btrway/workflow-common-provider';
import React from 'react';
import { useWorkflowBuilderState } from '../../hooks/useWorkflowBuilderState';
import { ScreenHandlers } from '../../types/handlers';
import { Screen } from '../../types/screen';
import { createEditorSaveHandler } from '../../utils/saveHandler';

interface ScreenRendererProps {
  screen: Screen;
  handlers: ScreenHandlers;
}

export const ScreenRenderer: React.FC<ScreenRendererProps> = ({
  screen,
  handlers,
}) => {
  const { courses } = useCourses();
  const { state } = useWorkflowBuilderState();

  const workflowService = useWorkflowService();
  const { workflows } = workflowService.getWorkflows();
  // Add guard clause
  if (!handlers) {
    console.error('Handlers is undefined in ScreenRenderer');
    return null;
  }

  switch (screen.type) {
    case 'TOOLBOX':
      return (
        <ToolboxManager
          mode={screen.data.mode}
          workflowType={screen.data.workflowType}
          onSelect={handlers.onToolboxSelect}
        />
      );

    case 'TASK_CONFIG':
      if (!state.parentWorkflow) {
        console.error('No workflow available for TaskConfigEditor');
        return null;
      }

      return (
        <TaskConfigEditor
          initialTaskConfig={screen.data.config}
          workflow={state.parentWorkflow!}
          isNew={screen.data.isNew}
          onClose={handlers.onClose}
          onDelete={
            !screen.data.isNew && handlers.onDelete
              ? () => handlers.onDelete!(screen.data.config.taskKey)
              : undefined
          }
          onSave={createEditorSaveHandler.task(handlers.onSave)}
        />
      );

    case 'STEP_CONFIG':
      return (
        <StepConfigEditor
          initialStepConfig={screen.data.config}
          workflow={state.parentWorkflow!}
          isNew={screen.data.isNew}
          onClose={handlers.onClose}
          onDelete={
            !screen.data.isNew && handlers.onDelete
              ? () => handlers.onDelete!(screen.data.config.stepKey)
              : undefined
          }
          onSave={createEditorSaveHandler.step(handlers.onSave)}
        />
      );

    case 'CERTIFICATION_CONFIG':
      return (
        <CertificationConfigEditor
          initialCertificationConfig={screen.data.config}
          workflow={state.parentWorkflow!}
          isNew={screen.data.isNew}
          onClose={handlers.onClose}
          onDelete={
            !screen.data.isNew && handlers.onDelete
              ? () => handlers.onDelete!(screen.data.config.certificationKey)
              : undefined
          }
          onSave={createEditorSaveHandler.certification(handlers.onSave)}
        />
      );

    case 'EVENT_CONFIG':
      return (
        <EventConfigEditor
          initialEventConfig={screen.data.config}
          workflow={state.parentWorkflow!}
          isNew={screen.data.isNew}
          onClose={handlers.onClose}
          onDelete={
            !screen.data.isNew && handlers.onDelete
              ? () => handlers.onDelete!(screen.data.config.eventKey)
              : undefined
          }
          onSave={createEditorSaveHandler.event(handlers.onSave)}
        />
      );

    case 'WORKFLOW_SELECTOR':
      return (
        <WorkflowSelector
          workflows={workflows || []}
          workflowType={screen.data.workflowType}
          selectedWorkflowKey={screen.data.selectedWorkflowKey}
          onClose={handlers.onClose}
          onWorkflowSelect={handlers.onWorkflowSelect}
        />
      );

    case 'COURSE_SELECTOR':
      return (
        <CourseSelector
          courses={courses || []}
          selectedCourseUid={null}
          onCourseSelect={handlers.onCourseSelect}
          onClose={handlers.onClose}
        />
      );

    case 'CURRICULUM_EDITOR':
      return (
        <WorkflowServiceProvider workflowKey={screen.data.workflowKey}>
          <CurriculumEditor
            initialSelectedTasks={screen.data.initialSelectedTasks}
            workflowKey={screen.data.workflowKey}
            workflowName={screen.data.workflowName}
            onClose={handlers.onClose}
            onSave={(addedTasks, removedTaskKeys) => {
              handlers.onEditorSave(
                addedTasks,
                removedTaskKeys,
                screen.data.workflowName,
                screen.data.workflowKey,
                screen.data.showTaskConfigAfterSave
              );
            }}
          />
        </WorkflowServiceProvider>
      );

    case 'FORM_PACKET_EDITOR':
      return (
        <WorkflowServiceProvider workflowKey={screen.data.workflowKey}>
          <FormPacketEditor
            initialSelectedTasks={screen.data.initialSelectedTasks}
            workflowKey={screen.data.workflowKey}
            workflowName={screen.data.workflowName}
            onClose={handlers.onClose}
            onSave={(addedTasks, removedTaskKeys) => {
              handlers.onEditorSave(
                addedTasks,
                removedTaskKeys,
                screen.data.workflowName,
                screen.data.workflowKey,
                screen.data.showTaskConfigAfterSave
              );
            }}
          />
        </WorkflowServiceProvider>
      );

    case 'TASK_LIST_DATE_CONFIG':
      console.log('opening task list date configuration');
      return (
        <TaskListDateConfiguration
          workflowKey={screen.data.workflowKey}
          initialConfig={screen.data.initialConfig}
          onClose={handlers.onClose}
        />
      );

    default:
      return null;
  }
};
