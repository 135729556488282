/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type SelectFieldRenderType = typeof SelectFieldRenderType[keyof typeof SelectFieldRenderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectFieldRenderType = {
  picklist: 'picklist',
  chip: 'chip',
  radioCheckbox: 'radioCheckbox',
  segmented: 'segmented',
} as const;
