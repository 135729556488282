import { ResponsiveModal } from '@btrway/shared-components';
import {
  Box,
  Button,
  Container,
  Group,
  Loader,
  Space,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { useProcessBuilder } from '../../providers/ProcessBuilderProvider';

interface ProcessBuilderWizardProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  onSubmit: (workflowKey: string) => void | Promise<void>;
}

const StepComponentWrapper: React.FC<{
  component: React.ComponentType<any>;
}> = ({ component: Component }) => {
  return <Component />;
};

export const ProcessBuilderWizard: React.FC<ProcessBuilderWizardProps> = ({
  opened,
  onClose,
  title,
  onSubmit,
}) => {
  const {
    activeStep,
    maxReachedStep,
    nextStep,
    prevStep,
    goToStep,
    isLastStep,
    currentStep,
    steps,
    workflow,
    saveWorkflow,
    isFormGenerating,
  } = useProcessBuilder();

  const handleSubmit = async () => {
    try {
      if (!workflow.workflowConfiguration) {
        throw new Error('Workflow configuration is required');
      }

      await saveWorkflow();

      // Pass the workflow key back to the parent
      const workflowKey = workflow.workflowKey || workflow.templateKey;
      if (workflowKey) {
        await onSubmit(workflowKey);
      }

      onClose();
    } catch (error) {
      console.error('Error submitting workflow:', error);
    }
  };

  const renderStepButtons = () => {
    if (currentStep.key === 'existingFile') {
      return (
        <Group justify="center">
          <Button variant="outline" size="lg" onClick={nextStep}>
            Skip
          </Button>
          <Button
            size="lg"
            onClick={() => {
              const aiStep = document.querySelector('[data-ai-generate]');
              if (aiStep) {
                (aiStep as HTMLButtonElement).click();
              }
            }}
          >
            Next Step
          </Button>
        </Group>
      );
    }

    return (
      <Group justify="center">
        {!isLastStep ? (
          <Button size="lg" onClick={nextStep}>
            Next step
          </Button>
        ) : (
          <Button
            size="lg"
            onClick={handleSubmit}
            disabled={isFormGenerating}
            rightSection={isFormGenerating ? <Loader size="xs" /> : null}
          >
            {isFormGenerating ? 'AI Still Generating Form...' : 'Create Form'}
          </Button>
        )}
      </Group>
    );
  };

  return (
    <ResponsiveModal
      opened={opened}
      onClose={onClose}
      footer={
        <div style={{ width: '100%', position: 'relative' }}>
          <Button
            size="lg"
            variant="default"
            onClick={prevStep}
            disabled={activeStep === 0}
            style={{ position: 'absolute', left: 0 }}
          >
            Back
          </Button>
          <Group justify="center" style={{ width: '100%' }}>
            {renderStepButtons()}
          </Group>
        </div>
      }
    >
      <Container size="xl">
        <Stepper
          active={activeStep}
          onStepClick={(index) => {
            if (index <= maxReachedStep) {
              goToStep(index);
            }
          }}
          allowNextStepsSelect={false}
          mb="xl"
        >
          {steps.map((step) => (
            <Stepper.Step
              key={step.key}
              label={
                <Text fz="xl" fw={600}>
                  {step.label}
                </Text>
              }
            />
          ))}
        </Stepper>
        <Space h="xl" />
        <Box mb="xl">
          <Title ta="center" order={1}>
            {currentStep.description}
          </Title>
          <Space h="xl" />
          <StepComponentWrapper component={currentStep.component} />
        </Box>
      </Container>
    </ResponsiveModal>
  );
};
