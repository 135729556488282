import { getFormattedDuration } from '@btrway/utils';
import { Anchor, Box, Button, Group, Modal, Text } from '@mantine/core';
import MuxPlayer from '@mux/mux-player-react';
import MuxUploader, {
  MuxUploaderDrop,
  MuxUploaderFileSelect,
} from '@mux/mux-uploader-react';
import React, { useCallback, useRef, useState } from 'react';
import { useMuxUpload } from '../../hooks/useMuxUpload';
import { MuxAssetList } from '../MuxAssetList/MuxAssetList';
import styles from './MuxVideo.module.css';

interface MuxVideoProps {
  playbackIdentifier: string | undefined;
  duration: number | undefined;
  onVideoUpdate: (newPlaybackIdentifier: string, duration: number) => void;
}

type ProgressEventHandler = (
  event: CustomEvent<number> | React.SyntheticEvent<HTMLElement, Event>
) => void;

export const MuxVideo: React.FC<MuxVideoProps> = ({
  playbackIdentifier,
  duration,
  onVideoUpdate,
}) => {
  const [isUploading, setIsUploading] = useState(!playbackIdentifier);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { endpointFunction, handleUploadStart, handleUploadSuccess } =
    useMuxUpload();
  const uploaderId = useRef(
    `mux-video-uploader-${Math.random().toString(36).substr(2, 9)}`
  );

  const onUploadSuccess = useCallback(async () => {
    try {
      const result = await handleUploadSuccess();
      if (result) {
        const { playbackId, duration } = result;
        onVideoUpdate(playbackId, duration);
        setIsUploading(false);
        setUploadProgress(0);
      }
    } catch (error) {
      console.error('Error handling upload success:', error);
    }
  }, [handleUploadSuccess, onVideoUpdate]);

  const handleUploadProgress: ProgressEventHandler = useCallback((event) => {
    if (event instanceof CustomEvent) {
      setUploadProgress(event.detail);
    }
  }, []);

  const handleVideoSelect = useCallback(
    (newPlaybackId: string, newDuration: number) => {
      onVideoUpdate(newPlaybackId, newDuration);
      setIsModalOpen(false);
      setIsUploading(false);
    },
    [onVideoUpdate]
  );

  return (
    <Box className={styles.container}>
      {!isUploading && playbackIdentifier ? (
        <>
          <MuxPlayer
            streamType="on-demand"
            playbackId={playbackIdentifier}
            metadataVideoTitle="Chapter Video"
            primaryColor="#FFFFFF"
            secondaryColor="#000000"
            theme="microvideo"
            style={{ width: '100%', height: 150 }}
          />
          <Group justify="space-between" mt="sm" align="flex-start">
            {duration && (
              <Text fz={12}>Duration: {getFormattedDuration(duration)}</Text>
            )}
            <Anchor onClick={() => setIsUploading(true)} fz={12}>
              Replace Video
            </Anchor>
          </Group>
        </>
      ) : (
        <>
          <MuxUploader
            id={uploaderId.current}
            className={styles.hidden}
            endpoint={endpointFunction}
            onSuccess={onUploadSuccess}
            onUploadStart={handleUploadStart}
            onProgress={handleUploadProgress}
          />
          <MuxUploaderDrop
            muxUploader={uploaderId.current}
            className={styles.dropZone}
            overlay
            overlayText="Release to upload"
          >
            <span slot="heading" className={styles.dropHeading}>
              Drop your video here
            </span>
            <span slot="separator" className={styles.separator}>
              — or —
            </span>

            <MuxUploaderFileSelect muxUploader={uploaderId.current}>
              <button className={styles.button}>
                Select from your computer
              </button>
            </MuxUploaderFileSelect>
          </MuxUploaderDrop>
          {isUploading && uploadProgress > 0 && (
            <Box className={styles.progress}>
              <Text>Uploading: {Math.round(uploadProgress)}%</Text>
            </Box>
          )}
          <Button onClick={() => setIsModalOpen(true)} mt="sm" size="sm">
            Choose Video
          </Button>
        </>
      )}
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Choose a Video"
        size="xl"
      >
        <MuxAssetList onSelect={handleVideoSelect} isSelector={true} />
      </Modal>
    </Box>
  );
};
