// src/types/state.ts
import { DataTypeEnum } from '@btrway/api-calendar';

export type EntityType = DataTypeEnum | 'nothing' | null;

export interface FormSettingsState {
  entityType: EntityType;
  entityFilters: Map<DataTypeEnum, string[]>;
  entityDescription: string;
  launchAboutSelf: boolean;
  launchAboutOthers: boolean;
  othersRoleSettings: Set<string>;
}

// We can also add some type guards if needed
export const isDataTypeEnum = (type: EntityType): type is DataTypeEnum => {
  return (
    type !== null &&
    type !== 'nothing' &&
    Object.values(DataTypeEnum).includes(type)
  );
};
