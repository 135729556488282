// import { EventConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
// import {
//   CommonWorkflow,
//   useSelectedItem,
// } from '@btrway/workflow-configuration-manager';
// import { Stack, Text } from '@mantine/core';
// import React, { useEffect, useState } from 'react';
// import { eventComponentRegistry } from '../../config/componentRegistry';
// import { ConfigEditorWrapper } from '../ConfigEditorWrapper/ConfigEditorWrapper';

// interface EventConfigEditorProps {
//   workflow: CommonWorkflow;
//   onClose: () => void;
//   onDelete?: () => void;
// }

// export const EventConfigEditor: React.FC<EventConfigEditorProps> = ({
//   workflow,
//   onClose,
//   onDelete,
// }) => {
//   const { selectedConfig, updateSelectedConfig } = useSelectedItem();
//   const [currentConfig, setCurrentConfig] = useState<EventConfig | null>(null);
//   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

//   useEffect(() => {
//     if (selectedConfig && 'eventTypeCode' in selectedConfig) {
//       setCurrentConfig(selectedConfig);
//     }
//   }, [selectedConfig]);

//   // Only allow Automation workflow type
//   if (workflow.workflowType !== WorkflowTypeEnum.automation) {
//     console.error(
//       'EventConfigEditor can only be used with Automation workflows'
//     );
//     return null;
//   }

//   if (!currentConfig) {
//     return null;
//   }

//   const handleConfigChange = (updatedConfig: EventConfig) => {
//     setCurrentConfig(updatedConfig);
//     setHasUnsavedChanges(true);
//   };

//   const handleSave = () => {
//     updateSelectedConfig(currentConfig);
//     setHasUnsavedChanges(false);
//     onClose();
//   };

//   const handleCancel = () => {
//     if (selectedConfig && 'eventTypeCode' in selectedConfig) {
//       setCurrentConfig(selectedConfig);
//     }
//     setHasUnsavedChanges(false);
//     onClose();
//   };

//   // Get the event-specific component from registry
//   const EventTypeComponent =
//     eventComponentRegistry[currentConfig.eventTypeCode]?.component;

//   if (!EventTypeComponent) {
//     return (
//       <Text c="red">
//         No configuration component found for event type:{' '}
//         {currentConfig.eventTypeCode}
//       </Text>
//     );
//   }

//   return (
//     <ConfigEditorWrapper
//       hasUnsavedChanges={hasUnsavedChanges}
//       onSave={handleSave}
//       onCancel={handleCancel}
//       onClose={onClose}
//       onDelete={onDelete}
//     >
//       <Stack gap="xl">
//         <EventTypeComponent
//           config={currentConfig}
//           onConfigChange={handleConfigChange}
//           workflow={workflow}
//           workflowType={workflow.workflowType}
//         />
//       </Stack>
//     </ConfigEditorWrapper>
//   );
// };

import { EventConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { eventComponentRegistry } from '../../config/componentRegistry';
import { ConfigEditorWrapper } from '../ConfigEditorWrapper/ConfigEditorWrapper';

interface EventConfigEditorProps {
  initialEventConfig: EventConfig;
  workflow: CommonWorkflow;
  onClose: () => void;
  onDelete?: () => void;
  onSave: (config: EventConfig, isNew?: boolean) => Promise<void>;
  isNew?: boolean;
}

export const EventConfigEditor: React.FC<EventConfigEditorProps> = ({
  initialEventConfig,
  workflow,
  onClose,
  onDelete,
  onSave,
  isNew = false,
}) => {
  const workflowType = workflow.workflowType;

  const [currentConfig, setCurrentConfig] = useState<EventConfig | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (initialEventConfig) {
      setCurrentConfig(initialEventConfig);
    }
  }, [initialEventConfig]);

  if (workflowType !== WorkflowTypeEnum.automation) {
    console.error(
      'EventConfigEditor can only be used with Automation workflows'
    );
    return null;
  }

  if (!currentConfig) {
    return null;
  }

  const handleConfigChange = (updatedConfig: EventConfig) => {
    setCurrentConfig(updatedConfig);
    setHasUnsavedChanges(true);
  };

  const handleSave = async () => {
    if (currentConfig) {
      await onSave(currentConfig, isNew);
      setHasUnsavedChanges(false);
      onClose();
    }
  };

  const handleCancel = () => {
    setCurrentConfig(initialEventConfig);
    setHasUnsavedChanges(false);
    onClose();
  };

  const EventTypeComponent =
    eventComponentRegistry[currentConfig.eventTypeCode]?.component;

  if (!EventTypeComponent) {
    return (
      <Text c="red">
        No configuration component found for event type:{' '}
        {currentConfig.eventTypeCode}
      </Text>
    );
  }

  return (
    <ConfigEditorWrapper
      hasUnsavedChanges={hasUnsavedChanges}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
      onDelete={onDelete}
    >
      <Stack gap="xl">
        <EventTypeComponent
          config={currentConfig}
          onConfigChange={handleConfigChange}
          workflowType={workflowType}
        />
      </Stack>
    </ConfigEditorWrapper>
  );
};
