import { FieldTypeEnum } from '@btrway/api-workflow';
import { Box, Group, Text } from '@mantine/core';
import React from 'react';
import { getFieldIcon, getFieldLabel } from '../../utils/toolbox';

interface FormFieldSelectItemProps {
  type: FieldTypeEnum;
  isSelected: boolean;
  onSelect: () => void;
}

export const FormFieldSelectItem: React.FC<FormFieldSelectItemProps> = ({
  type,
  isSelected,
  onSelect,
}) => {
  return (
    <Box
      onClick={onSelect}
      p="xs"
      style={(theme) => ({
        cursor: 'pointer',
        backgroundColor: isSelected ? theme.colors.blue[0] : undefined,
        borderRadius: theme.radius.sm,
        '&:hover': {
          backgroundColor: isSelected
            ? theme.colors.blue[1]
            : theme.colors.gray[0],
        },
      })}
    >
      <Group gap="sm" wrap="nowrap">
        <Box
          p={4}
          style={(theme) => ({
            color: theme.colors.blue[6],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          {getFieldIcon(type)}
        </Box>
        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {getFieldLabel(type)}
          </Text>
        </div>
      </Group>
    </Box>
  );
};
