import { FieldConfig } from '@btrway/api-workflow';
import { useFormConfig } from '@btrway/form-configuration-manager';
import { Card, Stack, Text } from '@mantine/core';
import React from 'react';
import FormBuilderContent from '../../builder/FormBuilderContent/FormBuilderContent';

interface GroupFieldProps {
  field: FieldConfig;
  readOnly?: boolean;
}

const GroupField: React.FC<GroupFieldProps> = ({ field, readOnly = false }) => {
  const { updateField } = useFormConfig();
  const isReadOnly = false;

  const handleUpdate = (fieldKey: string, updates: Partial<FieldConfig>) => {
    const updatedChildFields =
      field.childFields?.map((childField) =>
        childField.fieldKey === fieldKey
          ? { ...childField, ...updates }
          : childField
      ) || [];

    updateField(field.fieldKey, { childFields: updatedChildFields });
  };

  const handleRemove = (fieldKey: string) => {
    const updatedChildFields =
      field.childFields?.filter(
        (childField) => childField.fieldKey !== fieldKey
      ) || [];

    updateField(field.fieldKey, { childFields: updatedChildFields });
  };

  // This will now be called only for same-level reordering within the group
  const handleFieldsReorder = (newFields: FieldConfig[]) => {
    updateField(field.fieldKey, { childFields: newFields });
  };

  const handleAddField = (
    type: any,
    options?: Partial<FieldConfig>,
    index?: number
  ) => {
    const currentFields = [...(field.childFields || [])];
    if (typeof index === 'number') {
      currentFields.splice(index, 0, options as FieldConfig);
    } else {
      currentFields.push(options as FieldConfig);
    }
    updateField(field.fieldKey, { childFields: currentFields });
  };

  return (
    <Card
      withBorder
      radius="md"
      padding={0}
      style={{
        minHeight: '200px',
        background: '#f8f9fa',
        border: '1px solid var(--mantine-color-gray-5)',
      }}
    >
      <Stack gap="md" mih={200} h="100%">
        {(field.childFields?.length ?? 0) === 0 && (
          <Text
            c="dimmed"
            ta="center"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              pointerEvents: 'none',
              userSelect: 'none',
            }}
          >
            Drop fields here
          </Text>
        )}
        <FormBuilderContent
          fields={field.childFields || []}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
          onFieldsReorder={handleFieldsReorder}
          onAddField={handleAddField}
          readOnly={isReadOnly}
          parentFieldKey={field.fieldKey}
        />
      </Stack>
    </Card>
  );
};

export default GroupField;
