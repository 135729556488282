import {
  DataTypeEnum,
  GlobalSearchRequest,
  GlobalSearchResponse,
  useSearchGlobal,
} from '@btrway/api-core';
import { PropertyReference } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { usePropertyReferences } from '@btrway/property-reference-manager';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import {
  Box,
  Card,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';

interface PersonSelectorProps {
  initialPersonId?: number | PropertyReference;
  onPersonSelect: (
    personId: number | PropertyReference,
    description: string
  ) => void;
  onClose: () => void;
}

const PersonSelector: React.FC<PersonSelectorProps> = ({
  initialPersonId,
  onPersonSelect,
  onClose,
}) => {
  const { rootWorkgroupId } = useAuthenticatedUser();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
  const [searchResults, setSearchResults] = useState<GlobalSearchResponse[]>(
    []
  );
  const [selectedPersonId, setSelectedPersonId] = useState<number | undefined>(
    typeof initialPersonId === 'number' ? initialPersonId : undefined
  );
  const [selectedPropertyReference, setSelectedPropertyReference] = useState<
    PropertyReference | undefined
  >(typeof initialPersonId !== 'number' ? initialPersonId : undefined);
  const { currentOrganization } = useAuthenticatedUser();
  const { mutate: searchGlobal, isPending } = useSearchGlobal();

  const { selectedStepKey } = useWorkflowConfig();
  const { bindingPropertyReferences } = usePropertyReferences(
    selectedStepKey || ''
  );

  useEffect(() => {
    if (typeof initialPersonId === 'number') {
      setSelectedPersonId(initialPersonId);
    } else {
      setSelectedPropertyReference(initialPersonId);
    }
  }, [initialPersonId]);

  const filteredPreviousStepProperties = useMemo(() => {
    return bindingPropertyReferences.filter(
      (prop) => prop.dataType === DataTypeEnum.person && !prop.entityProperty
    );
  }, [bindingPropertyReferences]);

  const propertyOptions = useMemo(() => {
    return filteredPreviousStepProperties.map((prop) => ({
      value: `${prop.stepKey}-${prop.propertyKey}`,
      label: prop.label || prop.propertyKey,
      reference: prop,
    }));
  }, [filteredPreviousStepProperties]);

  useEffect(() => {
    if (debouncedSearchValue && currentOrganization) {
      const searchRequest: GlobalSearchRequest = {
        organizationId: currentOrganization.id,
        searchValue: debouncedSearchValue,
        filterByWorkgroupIds: [rootWorkgroupId],
        // entityTypes: [DataTypeEnum.person],
      };
      searchGlobal(
        { data: searchRequest },
        {
          onSuccess: (data: unknown) => {
            const searchResponse = data as GlobalSearchResponse[];
            setSearchResults(searchResponse);
          },
        }
      );
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchValue, currentOrganization, searchGlobal]);

  const handlePersonSelect = (result: GlobalSearchResponse) => {
    setSelectedPersonId(result.entityId);
    setSelectedPropertyReference(undefined);
    onPersonSelect(result.entityId, result.displayName);
    onClose();
  };

  const handlePropertyReferenceSelect = (
    propertyReference: PropertyReference
  ) => {
    setSelectedPropertyReference(propertyReference);
    setSelectedPersonId(undefined);
    onPersonSelect(
      propertyReference,
      propertyReference.label || propertyReference.propertyKey
    );
    onClose();
  };

  const isPropertyReferenceSelected = (reference: PropertyReference) => {
    return (
      selectedPropertyReference &&
      reference.stepKey === selectedPropertyReference.stepKey &&
      reference.propertyKey === selectedPropertyReference.propertyKey
    );
  };

  const hasPropertyOptions = propertyOptions.length > 0;

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box p="md" style={{ position: 'relative' }}>
        <Group justify="space-between" mb="xs">
          <Text fz="md" fw={700}>
            Select Person
          </Text>
        </Group>
      </Box>

      <ScrollArea style={{ flex: 1 }}>
        <Stack gap="md" p="md" pt={0}>
          {hasPropertyOptions && (
            <Box>
              <Text fz="sm" fw={500} mb="xs">
                Choose from People Involved:
              </Text>
              <Stack gap="xs">
                {propertyOptions.map((option) => (
                  <Card
                    key={option.value}
                    padding="sm"
                    radius="md"
                    withBorder
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isPropertyReferenceSelected(
                        option.reference
                      )
                        ? 'var(--mantine-color-blue-light)'
                        : undefined,
                    }}
                    onClick={() =>
                      handlePropertyReferenceSelect(option.reference)
                    }
                  >
                    {option.label}
                  </Card>
                ))}
              </Stack>
            </Box>
          )}

          <Box mt="md">
            {hasPropertyOptions && (
              <Text fz="sm" fw={500} mb="xs">
                Or Choose a Specific Person:
              </Text>
            )}
            <TextInput
              autoFocus
              placeholder="Search people by name"
              value={searchValue}
              onChange={(event) => setSearchValue(event.currentTarget.value)}
              leftSection={<IconSearch size={18} stroke={1.5} />}
              mb="md"
            />
            <Stack gap="xs">
              {isPending ? (
                <Loader size="sm" />
              ) : (
                searchResults.map((result) => (
                  <Card
                    key={result.entityId}
                    padding="sm"
                    radius="md"
                    withBorder
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        selectedPersonId === result.entityId
                          ? 'var(--mantine-color-blue-light)'
                          : undefined,
                    }}
                    onClick={() => handlePersonSelect(result)}
                  >
                    {result.displayName}
                  </Card>
                ))
              )}
            </Stack>
          </Box>
        </Stack>
      </ScrollArea>
    </Box>
  );
};

export default PersonSelector;
