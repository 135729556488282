import { useEffect, useState } from 'react';
import {
  BaseHierarchicalItem,
  HierarchicalNode,
} from '../types/hierarchicalItem';

export const useNodeSelection = <T extends BaseHierarchicalItem>(
  hierarchicalNodes: HierarchicalNode<T>[],
  initialSelectedIds?: number[],
  getAdditionalIds?: (items: T[]) => number[]
) => {
  const [nodeIds, setNodeIds] = useState<Set<number>>(new Set());

  useEffect(() => {
    const newSelection = new Set<number>();

    const addNodeAndDescendants = (nodeId: number) => {
      newSelection.add(nodeId);

      const findAndAddChildren = (nodes: HierarchicalNode<T>[]) => {
        nodes.forEach((node) => {
          if (node.id === nodeId) {
            const addDescendants = (parent: HierarchicalNode<T>) => {
              parent.children.forEach((child) => {
                newSelection.add(child.id);
                addDescendants(child);
              });
            };
            addDescendants(node);
          } else {
            findAndAddChildren(node.children);
          }
        });
      };

      findAndAddChildren(hierarchicalNodes);
    };

    if (initialSelectedIds) {
      initialSelectedIds.forEach((id) => addNodeAndDescendants(id));
    } else if (getAdditionalIds) {
      const flattenedItems = hierarchicalNodes.flatMap((node) => {
        const result: T[] = [];
        const flatten = (n: HierarchicalNode<T>) => {
          // Create a new object with only the original T properties
          const baseItem = Object.keys(n)
            .filter((key) => !['children', 'level', 'parentId'].includes(key))
            .reduce((obj, key) => {
              obj[key as keyof T] = n[key as keyof T];
              return obj;
            }, {} as T);

          result.push(baseItem);
          n.children.forEach(flatten);
        };
        flatten(node);
        return result;
      });

      const additionalIds = getAdditionalIds(flattenedItems);
      additionalIds.forEach((id) => addNodeAndDescendants(id));
    }

    setNodeIds(newSelection);
  }, [initialSelectedIds, getAdditionalIds, hierarchicalNodes]);

  return [nodeIds, setNodeIds] as const;
};
