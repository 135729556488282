import { useState } from 'react';

export const useExpandedNodes = () => {
  const [expandedNodes, setExpandedNodes] = useState<Set<number>>(new Set());

  const toggleExpand = (nodeId: number, e: React.MouseEvent) => {
    e.stopPropagation();
    const newExpanded = new Set(expandedNodes);
    if (newExpanded.has(nodeId)) {
      newExpanded.delete(nodeId);
    } else {
      newExpanded.add(nodeId);
    }
    setExpandedNodes(newExpanded);
  };

  return [expandedNodes, toggleExpand] as const;
};
