import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { Group, Text } from '@mantine/core';
import React from 'react';

interface WorkflowDefinitionMetadataViewProps {
  workflow: CommonWorkflow;
}

export const WorkflowDefinitionMetadataView: React.FC<
  WorkflowDefinitionMetadataViewProps
> = ({ workflow }) => {
  return (
    <Group justify="space-between" align="center">
      <Text size="xl" c="white" fw={700}>
        {workflow?.name || ''}
      </Text>
    </Group>
  );
};
