import { WorkflowTemplateSettings } from '@btrway/api-workflow';
import { Drawer } from '@mantine/core';
import React from 'react';
import { DeploymentFilterEdit } from '../DeploymentFilterEdit/DeploymentFilterEdit';

interface DeploymentFilterDrawerProps {
  opened: boolean;
  onClose: () => void;
  onSave: (templateSettings: WorkflowTemplateSettings) => void;
  templateSettings?: WorkflowTemplateSettings | null;
}

export const DeploymentFilterDrawer: React.FC<DeploymentFilterDrawerProps> = ({
  opened,
  onClose,
  onSave,
  templateSettings,
}) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      padding={0}
      withOverlay={false}
      closeOnClickOutside={false}
      trapFocus={false}
      size={400}
      shadow="lg"
      withCloseButton={false}
      lockScroll={false}
      styles={{
        content: {
          marginTop: 70,
          marginBottom: 10,
          marginRight: 10,
          borderRadius: 'var(--mantine-radius-lg)',
          height: 'calc(100vh - 80px)',
        },
        inner: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        header: {
          borderTopLeftRadius: 'var(--mantine-radius-md)',
          borderTopRightRadius: 'var(--mantine-radius-md)',
        },
      }}
    >
      <DeploymentFilterEdit
        templateSettings={templateSettings}
        onSave={onSave}
        onCancel={onClose}
      />
    </Drawer>
  );
};
