import { DataTypeEnum } from '@btrway/api-core';
import type { FormAboutSettings, FormSettings } from '@btrway/api-workflow';
import { EventTypeHierarchicalSelector } from '@btrway/event-type-components';
import {
  EntityFilterSelector,
  EntityType,
  EntityTypeSelector,
  isDataTypeEnum,
} from '@btrway/form-settings';
import { UserRoleHierarchicalSelector } from '@btrway/security-components';
import { Stack, Text, TextInput } from '@mantine/core';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';
import { useProcessBuilder } from '../../providers/ProcessBuilderProvider';

export const FormAboutStep: React.FC = () => {
  const { updateWorkflow, workflow } = useProcessBuilder();
  const formSettings = workflow.workflowConfiguration?.settings as FormSettings;

  const [selectedType, setSelectedType] = useState<EntityType>(
    formSettings?.aboutSettings?.aboutEntity || null
  );
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    formSettings?.aboutSettings?.aboutEntityFilter || []
  );
  const [selectionDescription, setSelectionDescription] = useState<string>(
    formSettings?.aboutSettings?.aboutEntityDescription || ''
  );

  const updateSettings = useCallback(
    (aboutSettings: FormAboutSettings | undefined) => {
      updateWorkflow({
        workflowConfiguration: {
          ...workflow.workflowConfiguration,
          settings: aboutSettings
            ? {
                ...workflow.workflowConfiguration?.settings,
                aboutSettings,
              }
            : undefined,
        },
      });
    },
    [updateWorkflow, workflow.workflowConfiguration]
  );

  const handleTypeSelect = (type: EntityType) => {
    setSelectedType(type);
    setSelectedFilters([]);
    setSelectionDescription('');

    if (isDataTypeEnum(type)) {
      updateSettings({
        aboutEntity: type,
        aboutEntityFilter: [],
        aboutEntityDescription: '',
      });
    } else {
      updateSettings(undefined);
    }
  };

  const handleFilterChange = (values: string[]) => {
    if (isDataTypeEnum(selectedType)) {
      setSelectedFilters(values);
      updateSettings({
        aboutEntity: selectedType,
        aboutEntityFilter: values,
        aboutEntityDescription: selectionDescription,
      });
    }
  };

  const handleHierarchyChange = (selection: {
    ids: number[];
    slugs: string[];
    description?: string;
  }) => {
    if (isDataTypeEnum(selectedType)) {
      setSelectedFilters(selection.slugs);
      if (selection.description) {
        setSelectionDescription(pluralize.singular(selection.description));
      }
      updateSettings({
        aboutEntity: selectedType,
        aboutEntityFilter: selection.slugs,
        aboutEntityDescription: selection.description || selectionDescription,
      });
    }
  };

  const handleDescriptionChange = (value: string) => {
    setSelectionDescription(value);
    if (isDataTypeEnum(selectedType)) {
      updateSettings({
        aboutEntity: selectedType,
        aboutEntityFilter: selectedFilters,
        aboutEntityDescription: value,
      });
    }
  };

  const getFilterTitle = () => {
    if (!selectedType || !isDataTypeEnum(selectedType)) return '';

    switch (selectedType) {
      case DataTypeEnum.person:
        return 'Person';
      case DataTypeEnum.workgroup:
        return 'Workgroup';
      case DataTypeEnum.calendarEvent:
        return 'Event';
      default:
        return '';
    }
  };

  const renderFilterSelector = () => {
    if (!selectedType) return null;

    switch (selectedType) {
      case DataTypeEnum.person:
        return (
          <UserRoleHierarchicalSelector
            selectedRoleSlugs={selectedFilters}
            onChange={(selection) =>
              handleHierarchyChange({
                ids: selection.roleIds,
                slugs: selection.roleSlugs,
                description: selection.description,
              })
            }
          />
        );

      case DataTypeEnum.calendarEvent:
        return (
          <EventTypeHierarchicalSelector
            selectedEventTypeSlugs={selectedFilters}
            onChange={(selection) =>
              handleHierarchyChange({
                ids: selection.eventTypeIds,
                slugs: selection.eventTypeSlugs,
                description: undefined,
              })
            }
          />
        );

      case DataTypeEnum.workgroup:
        return (
          <EntityFilterSelector
            entityType={selectedType}
            selectedFilters={selectedFilters}
            onChange={handleFilterChange}
          />
        );

      default:
        return null;
    }
  };

  const filterComponent = renderFilterSelector();

  return (
    <Stack gap="xl" style={{ marginTop: '2rem' }}>
      <EntityTypeSelector
        heading="What is this Form about?"
        selectedType={selectedType}
        onSelect={handleTypeSelect}
        hideUnselected={true}
        variant="lg"
      />

      {filterComponent && (
        <Stack>
          <Text size="lg" fw={500}>
            Do you want to narrow to a specific type of {getFilterTitle()}?
          </Text>
          {filterComponent}
        </Stack>
      )}

      {selectedType && (
        <Stack>
          <Text size="lg" fw={500}>
            How should we refer to this {getFilterTitle()}?
          </Text>
          <TextInput
            value={selectionDescription}
            onChange={(event) =>
              handleDescriptionChange(event.currentTarget.value)
            }
            placeholder={`Enter a description for this ${getFilterTitle()}`}
          />
        </Stack>
      )}
    </Stack>
  );
};
