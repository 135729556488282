/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type MultipleFieldRenderType = typeof MultipleFieldRenderType[keyof typeof MultipleFieldRenderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultipleFieldRenderType = {
  form: 'form',
  grid: 'grid',
} as const;
