import { Anchor, List, Stack, Text, Title } from '@mantine/core';
import React from 'react';

interface RenderNodeProps {
  node: any;
}

const getTextStyles = (marks: any[] = []): Record<string, any> => {
  const styles: Record<string, any> = {};

  marks?.forEach((mark) => {
    switch (mark.type) {
      case 'bold':
        styles.fw = 700;
        break;
      case 'italic':
        styles.fs = 'italic';
        break;
      case 'underline':
        styles.td = 'underline';
        break;
      case 'strike':
        styles.td = 'line-through';
        break;
      case 'code':
        styles.ff = 'monospace';
        styles.bg = 'gray.1';
        styles.px = 'xs';
        styles.py = '2px';
        styles.fz = 'sm';
        styles.style = { borderRadius: '3px' };
        break;
      case 'textStyle':
        if (mark.attrs?.color) {
          styles.c = mark.attrs.color;
        }
        break;
      case 'highlight':
        styles.bg = 'yellow.2';
        break;
      case 'subscript':
        styles.style = {
          ...styles.style,
          verticalAlign: 'sub',
          fontSize: '0.8em',
        };
        break;
      case 'superscript':
        styles.style = {
          ...styles.style,
          verticalAlign: 'super',
          fontSize: '0.8em',
        };
        break;
    }
  });

  return styles;
};

const TextNode: React.FC<{ node: any }> = ({ node }) => {
  const styles = getTextStyles(node.marks);
  return (
    <Text component="span" {...styles}>
      {node.text}
    </Text>
  );
};

const RenderNode: React.FC<RenderNodeProps> = ({ node }) => {
  switch (node.type) {
    case 'paragraph':
      return (
        <Text mb={8}>
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Text>
      );

    case 'heading':
      return (
        <Title order={node.attrs.level as 1 | 2 | 3 | 4 | 5 | 6} mb={8}>
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Title>
      );

    case 'text':
      return <TextNode node={node} />;

    case 'bulletList':
      return (
        <List mb={8}>
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </List>
      );

    case 'orderedList':
      return (
        <List mb={8}>
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </List>
      );

    case 'listItem':
      return (
        <List.Item>
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </List.Item>
      );

    case 'link':
      return (
        <Anchor
          href={node.attrs.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Anchor>
      );

    case 'image':
      return (
        <img
          src={node.attrs.src}
          alt={node.attrs.alt || ''}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      );

    case 'blockquote':
      return (
        <Text
          component="blockquote"
          mb={8}
          pl="md"
          style={{
            borderLeft: '4px solid var(--mantine-color-gray-4)',
            margin: 0,
          }}
        >
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Text>
      );

    case 'codeBlock':
      return (
        <Text
          component="pre"
          ff="monospace"
          bg="gray.1"
          p="md"
          mb={8}
          style={{ borderRadius: 'var(--mantine-radius-md)' }}
        >
          {node.content?.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Text>
      );

    case 'hardBreak':
      return <br />;

    case 'horizontalRule':
      return (
        <hr
          style={{
            margin: '8px 0',
            border: 'none',
            borderTop: '1px solid var(--mantine-color-gray-3)',
          }}
        />
      );

    default:
      console.warn(`Unhandled node type: ${node.type}`);
      return null;
  }
};

interface ContentRendererProps {
  content: string;
  title?: string;
}

export const ContentRenderer: React.FC<ContentRendererProps> = ({
  content,
  title,
}) => {
  let parsedContent;

  try {
    parsedContent = JSON.parse(content || '{}');
  } catch (error) {
    console.error('Failed to parse content:', error);
    return <Text c="red">Error: Invalid content format</Text>;
  }

  return (
    <>
      {title && (
        <Title order={1} mb={8}>
          {title}
        </Title>
      )}
      <Stack gap={0}>
        {parsedContent.content && Array.isArray(parsedContent.content) ? (
          parsedContent.content.map((node: any, index: number) => (
            <RenderNode key={index} node={node} />
          ))
        ) : (
          <Text>No content available or invalid content structure</Text>
        )}
      </Stack>
    </>
  );
};

export default ContentRenderer;
