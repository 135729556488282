import { WorkgroupResponse } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback } from 'react';

const getDisplayName = (
  workgroup: WorkgroupResponse,
  allWorkgroups: WorkgroupResponse[],
  rootWorkgroupId: number
): string => {
  // If this is the root workgroup, return its name
  if (workgroup.id === rootWorkgroupId) {
    return workgroup.name;
  }

  // For immediate children of root (schools), return just their name
  if (workgroup.pathList.length === 2) {
    return workgroup.name;
  }

  // For deeper levels (sports teams etc), build compound name
  const rootStartIndex = workgroup.pathList.indexOf(rootWorkgroupId);
  if (rootStartIndex === -1) {
    return workgroup.name;
  }

  const relevantPathList = workgroup.pathList.slice(rootStartIndex + 1);
  const displayParts = relevantPathList
    .map((id) => {
      const ancestor = allWorkgroups.find((wg) => wg.id === id);
      return ancestor?.name || '';
    })
    .filter((name) => name !== '');

  return displayParts.join(' ');
};

export const useWorkgroupDisplayNames = () => {
  const { rootWorkgroupId } = useAuthenticatedUser();

  const getWorkgroupDisplayName = useCallback(
    (
      workgroup: WorkgroupResponse,
      allWorkgroups: WorkgroupResponse[]
    ): string => {
      return getDisplayName(workgroup, allWorkgroups, rootWorkgroupId);
    },
    [rootWorkgroupId]
  );

  return { getDisplayName: getWorkgroupDisplayName };
};
