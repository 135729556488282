import { FormAIPrompt, useFormAIGeneration } from '@btrway/form-ai-generation';
import { Alert, Box, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useProcessBuilder } from '../../providers/ProcessBuilderProvider';

export const AIGenerationStep: React.FC = () => {
  const {
    nextStep,
    // updateWorkflow,
    workflow,
    isFormGenerating,
    setFormGenerationStatus,
    updateFormConfig,
  } = useProcessBuilder();
  const { generateForm } = useFormAIGeneration();
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [error, setError] = useState<Error | null>(null);

  const handleGenerate = async (input: string | File) => {
    setStatus('loading');
    setError(null);
    setFormGenerationStatus(true);

    try {
      // Verify workflow configuration exists
      if (!workflow.workflowConfiguration?.steps?.length) {
        throw new Error('Workflow configuration not properly initialized');
      }

      // Start form generation asynchronously
      generateForm(input)
        .then((formConfig) => {
          // Find the form submission step and update its formConfig
          updateFormConfig(formConfig); // Just update the form config

          // const updatedSteps =
          //   workflow.workflowConfiguration?.steps?.map((step) =>
          //     step.stepTypeCode === StepTypeEnum.formSubmission
          //       ? { ...step, formConfig }
          //       : step
          //   ) || [];

          // // Update the workflow with the new steps
          // updateWorkflow({
          //   workflowConfiguration: {
          //     ...workflow.workflowConfiguration,
          //     steps: updatedSteps,
          //   },
          // });

          setStatus('success');
        })
        .catch((err) => {
          setError(err);
          setStatus('error');
        })
        .finally(() => {
          setFormGenerationStatus(false);
        });

      // Proceed to next step immediately
      nextStep();
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error('Unknown error occurred')
      );
      setStatus('error');
      setFormGenerationStatus(false);
    }
  };

  const handleSkip = () => {
    // Simply proceed to next step since workflow is already initialized
    nextStep();
  };

  return (
    <Box>
      {isFormGenerating && (
        <Alert color="blue" mb="md">
          <Text>
            Generating form fields... You can continue with the next steps while
            we process your request.
          </Text>
        </Alert>
      )}

      <FormAIPrompt
        onGenerate={handleGenerate}
        onSkip={handleSkip}
        status={status}
        error={error}
      />
    </Box>
  );
};

export default AIGenerationStep;
