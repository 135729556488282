import { CurrentUserResponse, WorkgroupResponse } from '@btrway/api-core';
import { UserRoleResponse } from '@btrway/api-security';
import { FormSettings } from '@btrway/api-workflow';
import { useUserRoles } from '@btrway/security-manager';
import { useWorkgroups } from '@btrway/workgroup-manager';
import { useEffect, useState } from 'react';

export const useFormStart = (
  currentUser: CurrentUserResponse,
  formSettings: FormSettings
) => {
  const { userRoles, isLoading: isLoadingRoles } = useUserRoles();
  const {
    workgroups,
    isLoading: isLoadingWorkgroups,
    getWorkgroup,
  } = useWorkgroups();

  const [state, setState] = useState({
    canStartForSelf: false,
    canStartForOthers: false,
    workgroupsForSelf: [] as WorkgroupResponse[],
    workgroupsForOthers: [] as WorkgroupResponse[],
    userRolesForOthers: [] as UserRoleResponse[],
  });

  // Helper function to check if a role path is a parent of another role path
  const isRoleParent = (parentPath: string, childPath: string): boolean => {
    return childPath.startsWith(parentPath + '.');
  };

  // Helper function to get all child workgroups
  const getChildWorkgroups = (workgroupPath: string): WorkgroupResponse[] => {
    return (
      workgroups?.filter((wg) => wg.path.startsWith(workgroupPath + '.')) || []
    );
  };

  useEffect(() => {
    // Only calculate if we have both userRoles and workgroups
    if (
      isLoadingRoles ||
      isLoadingWorkgroups ||
      !userRoles?.length ||
      !workgroups?.length
    ) {
      return;
    }

    // Get user's role IDs from workgroup members
    const userRoleIds = currentUser.workgroupMembers.map(
      (member) => member.userRoleId
    );

    // Get user's role paths
    const userRolePaths = userRoleIds
      .map((roleId) => userRoles?.find((role) => role.id === roleId)?.path)
      .filter((path): path is string => path !== undefined);

    // Check if user has a role that is a child of the target role
    const hasRoleOrParent = (roleSlug: string): boolean => {
      const settingRole = userRoles?.find((role) => role.slug === roleSlug);
      if (!settingRole) {
        console.log(`No role found for slug: ${roleSlug}`);
        return false;
      }

      return userRolePaths.some(
        (userPath) =>
          userPath === settingRole.path ||
          isRoleParent(settingRole.path, userPath)
      );
    };

    // Calculate canStartForSelf
    const canStartForSelf =
      formSettings.launchSettings?.some(
        (setting) =>
          setting.launchAboutSelf && hasRoleOrParent(setting.roleSlug)
      ) ?? false;

    // Calculate canStartForOthers
    const canStartForOthers =
      formSettings.launchSettings?.some(
        (setting) =>
          setting.launchAboutOthers && hasRoleOrParent(setting.roleSlug)
      ) ?? false;

    // Calculate workgroupsForSelf
    const workgroupsForSelf = (() => {
      if (!canStartForSelf) return [];

      const validWorkgroups = new Set<WorkgroupResponse>();
      currentUser.workgroupMembers.forEach((member) => {
        const memberRole = userRoles?.find(
          (role) => role.id === member.userRoleId
        );
        const workgroup = getWorkgroup(member.workgroup.id);

        if (
          memberRole &&
          workgroup &&
          formSettings.launchSettings?.some((setting) => {
            const settingRole = userRoles?.find(
              (role) => role.slug === setting.roleSlug
            );
            return (
              setting.launchAboutSelf &&
              settingRole &&
              (setting.roleSlug === memberRole.slug ||
                isRoleParent(settingRole.path, memberRole.path))
            );
          })
        ) {
          validWorkgroups.add(workgroup);
        }
      });

      return Array.from(validWorkgroups);
    })();

    // Calculate workgroupsForOthers
    const workgroupsForOthers = (() => {
      if (!canStartForOthers) return [];

      const validWorkgroups = new Set<WorkgroupResponse>();
      currentUser.workgroupMembers.forEach((member) => {
        const memberRole = userRoles?.find(
          (role) => role.id === member.userRoleId
        );
        const workgroup = getWorkgroup(member.workgroup.id);

        if (
          memberRole &&
          workgroup &&
          formSettings.launchSettings?.some((setting) => {
            const settingRole = userRoles?.find(
              (role) => role.slug === setting.roleSlug
            );
            if (!settingRole || !setting.launchAboutOthers) return false;

            return (
              setting.roleSlug === memberRole.slug ||
              isRoleParent(settingRole.path, memberRole.path)
            );
          })
        ) {
          validWorkgroups.add(workgroup);
          getChildWorkgroups(workgroup.path).forEach((child) =>
            validWorkgroups.add(child)
          );
        }
      });

      return Array.from(validWorkgroups);
    })();

    // Calculate userRolesForOthers
    const userRolesForOthers = (() => {
      if (!canStartForOthers || !formSettings.aboutSettings?.aboutEntityFilter)
        return [];

      return (
        userRoles?.filter((role) =>
          formSettings.aboutSettings?.aboutEntityFilter?.includes(role.slug)
        ) ?? []
      );
    })();

    setState({
      canStartForSelf,
      canStartForOthers,
      workgroupsForSelf,
      workgroupsForOthers,
      userRolesForOthers,
    });
  }, [
    currentUser,
    formSettings,
    userRoles,
    workgroups,
    isLoadingRoles,
    isLoadingWorkgroups,
    getWorkgroup,
  ]);

  return {
    ...state,
    isLoading: isLoadingRoles || isLoadingWorkgroups,
  };
};
