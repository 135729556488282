import { FormConfig } from '@btrway/api-workflow';
import OpenAI from 'openai';
import { ChatCompletionTool } from 'openai/resources';
import { FormAIResponse } from '../types/formAIResponse';
import { convertToFormConfig } from '../utils/convertToFormConfig';
import { formatExtractedText, processFile } from '../utils/fileProcessing';

export const useFormAIGeneration = () => {
  const formatFormFunction: ChatCompletionTool = {
    type: 'function',
    function: {
      name: 'formatForm',
      description: 'Format a form structure based on the provided prompt',
      parameters: {
        type: 'object',
        properties: {
          fields: {
            type: 'array',
            description: 'Array of form fields',
            items: {
              type: 'object',
              properties: {
                type: {
                  type: 'string',
                  enum: [
                    'heading',
                    'text',
                    'number',
                    'select',
                    'boolean',
                    'date',
                    'time',
                    'address',
                    'dateTime',
                    'stateProvince',
                    'country',
                    'address',
                    'height',
                    'weight',
                    'signature',
                    'email',
                    'phone',
                    'textarea',
                  ],
                  description: 'The type of form field',
                },
                title: {
                  type: 'string',
                  description: 'The label or title of the form field',
                },
                options: {
                  type: 'array',
                  items: { type: 'string' },
                  description: 'Array of options for select fields',
                },
                required: {
                  type: 'boolean',
                  description: 'Whether the field is required',
                },
              },
              required: ['type', 'title'],
            },
          },
        },
        required: ['fields'],
      },
    },
  };

  const generateForm = async (input: string | File): Promise<FormConfig> => {
    const openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    try {
      // Process input content
      const content = input instanceof File ? await processFile(input) : input;

      // Format content
      const formattedContent = formatExtractedText(content);

      const response = await openai.chat.completions.create({
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant that helps users create form structures.
            Create appropriate form fields based on the user's description.
            For select fields, provide an array of string options.
            Use appropriate field types based on the content requirements.            
            Focus on creating practical, user-friendly form fields.
            Use Heading fields at your discretion to group together similar fields.
            Do not return fields for individual address elements. Group address related fields into a single field of type address.
            Ensure field titles user friendly title cased.`,
          },
          { role: 'user', content: formattedContent },
        ],
        tools: [formatFormFunction],
        tool_choice: {
          type: 'function',
          function: { name: 'formatForm' },
        },
        temperature: 0.7,
      });

      const functionCall = response.choices[0].message.tool_calls?.[0];
      if (functionCall && functionCall.function.name === 'formatForm') {
        const formAIResponse: FormAIResponse = JSON.parse(
          functionCall.function.arguments
        );
        return convertToFormConfig(formAIResponse);
      }

      throw new Error('Unexpected response format from OpenAI API');
    } catch (error) {
      console.error('Error generating form:', error);
      throw error;
    }
  };

  return { generateForm };
};
