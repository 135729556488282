import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
import { useGlobalSearch } from '@btrway/global-search-manager';
import { ScrollArea, Stack, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { atom, useAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router';
import { getEntityPath } from '../../utils/entityUtils';
import { SearchResultCard } from '../SearchResultCard/SearchResultCard';

// Define Jotai atoms for persisting state
const selectedItemsAtom = atom<GlobalSearchResponse[]>([]);

interface GlobalSearchSideBarProps {
  onSelect: (entityType: DataTypeEnum, entityId: number) => void;
}

// First, let's update the GlobalSearchSideBarProps interface to include all useGlobalSearch options
interface GlobalSearchSideBarProps {
  onSelect: (entityType: DataTypeEnum, entityId: number) => void;
  debounceMs?: number;
  entityTypes?: DataTypeEnum[];
  filterByWorkgroupIds?: number[];
  filterByUserRoleIds?: number[];
  includeChildWorkgroups?: boolean;
  includeChildUserRoles?: boolean;
  minimumScore?: number;
  limit?: number;
  onlyTopResults?: boolean;
}

// Update the GlobalSearchSideBar component to use the new props
const GlobalSearchSideBar: React.FC<GlobalSearchSideBarProps> = ({
  onSelect,
  debounceMs,
  entityTypes,
  filterByWorkgroupIds,
  filterByUserRoleIds,
  includeChildWorkgroups = true,
  includeChildUserRoles,
  minimumScore,
  limit,
  onlyTopResults = false,
}) => {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useAtom(selectedItemsAtom);

  const { searchValue, setSearchValue, searchResults } = useGlobalSearch({
    initialResults: selectedItems,
    showInitialResultsOnEmpty: true,
    debounceMs,
    entityTypes,
    filterByWorkgroupIds,
    filterByUserRoleIds,
    includeChildWorkgroups,
    includeChildUserRoles,
    minimumScore,
    limit,
    onlyTopResults,
  });

  const handleEntitySelect = (entity: GlobalSearchResponse) => {
    setSelectedItems((prevItems) => {
      if (
        !prevItems.some(
          (item) =>
            item.entityType === entity.entityType &&
            item.entityId === entity.entityId
        )
      ) {
        return [entity, ...prevItems.slice(0, 4)]; // Keep only the 5 most recent items
      }
      return prevItems;
    });
    onSelect(entity.entityType as DataTypeEnum, entity.entityId);
    navigate(getEntityPath(entity.entityType as DataTypeEnum, entity.entityId));
  };

  return (
    <Stack h="100%">
      <TextInput
        placeholder="Search..."
        value={searchValue}
        onChange={(event) => setSearchValue(event.currentTarget.value)}
        leftSection={<IconSearch size={18} stroke={1.5} />}
        autoFocus
      />
      <ScrollArea style={{ flex: 1 }}>
        <Stack gap="xs">
          {searchResults.map((result) => (
            <SearchResultCard
              key={`${result.entityType}:${result.entityId}`}
              result={result}
              onClick={handleEntitySelect}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

export default GlobalSearchSideBar;
