import { DisplayMode } from '@btrway/form-fields';
import { Box } from '@mantine/core';
import React from 'react';

interface ReadOnlyWrapperProps {
  children: React.ReactNode;
  displayMode?: DisplayMode;
  isSelected?: boolean;
}

const ReadOnlyWrapper: React.FC<ReadOnlyWrapperProps> = ({
  children,
  displayMode = DisplayMode.Input,
  isSelected = false,
}) => {
  const isBuilder =
    displayMode === DisplayMode.Builder ||
    displayMode === DisplayMode.BuilderReadOnly;

  if (!isBuilder) {
    return <>{children}</>;
  }

  return (
    <Box pos="relative">
      {children}
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        style={{
          backgroundColor: 'transparent',
          cursor: isSelected ? 'move' : 'pointer',
          zIndex: 1,
          // Prevent text selection
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          userSelect: 'none',
          // Preserve pointer events but prevent input
          pointerEvents: 'auto',
          '& *': {
            pointerEvents: 'none',
          },
          // Ensure consistent cursor
          '&:hover, &:focus, &:active': {
            cursor: isSelected ? 'move' : 'pointer',
          },
        }}
        onClick={(e) => {
          // Prevent bubbling but allow parent DraggableField to handle selection
          e.preventDefault();
          const parentDraggable = e.currentTarget.closest(
            '[role="presentation"]'
          ) as HTMLElement;
          if (parentDraggable) {
            parentDraggable.click();
          }
        }}
        onMouseDown={(e) => {
          // Allow mousedown to propagate for drag initiation
          if (isSelected) {
            e.stopPropagation();
          }
        }}
        onMouseUp={(e) => {
          // Prevent any default behavior
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Box>
  );
};

export default ReadOnlyWrapper;
