import {
  StepTypeEnum,
  TaskInstanceResponse,
  WorkflowStepInstanceResponse,
} from '@btrway/api-workflow';
import { useWorkflowConfigUtilities } from '@btrway/workflow-configuration-utilities';
import { Box, Card, Flex, ScrollArea, Stack } from '@mantine/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import WorkflowInstanceSidebar from '../WorkflowInstanceSidebar/WorkflowInstanceSidebar';
import WorkflowStepInstanceDetail from '../WorkflowStepInstanceDetail/WorkflowStepInstanceDetail';

interface WorkflowInstanceViewProps {
  taskInstance: TaskInstanceResponse;
}

const WorkflowInstanceView: React.FC<WorkflowInstanceViewProps> = ({
  taskInstance,
}) => {
  const [selectedStepInstance, setSelectedStepInstance] =
    useState<WorkflowStepInstanceResponse | null>(null);
  const [isManualScrolling, setIsManualScrolling] = useState(false);
  const detailRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const workflowInstance = taskInstance.workflowInstance;
  const workflowConfiguration =
    workflowInstance?.workflowDefinition?.workflowConfiguration;

  const { getOrderedSteps, getStep } = useWorkflowConfigUtilities(
    workflowConfiguration || {}
  );

  // Filter and sort the steps
  const sortedSteps = useMemo(() => {
    if (!workflowInstance?.steps) return [];

    // Get the ordered configuration steps
    const orderedConfigSteps = getOrderedSteps();

    // Filter out the current task's step and flow start steps
    const filteredSteps = workflowInstance.steps.filter((step) => {
      if (!step.stepKey) return false;

      const workflowStep = getStep(step.stepKey);
      return (
        step.stepKey !== taskInstance.stepKey &&
        workflowStep?.stepTypeCode !== StepTypeEnum.flowStart
      );
    });

    // Sort the filtered steps based on the order from orderedConfigSteps
    return [...filteredSteps].sort((a, b) => {
      if (!a.stepKey || !b.stepKey) return 0;

      const aIndex = orderedConfigSteps.findIndex(
        (config) => config.stepKey === a.stepKey
      );
      const bIndex = orderedConfigSteps.findIndex(
        (config) => config.stepKey === b.stepKey
      );

      // If either step isn't found in the ordered steps, put it at the end
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;

      return aIndex - bIndex;
    });
  }, [workflowInstance?.steps, getOrderedSteps, getStep, taskInstance.stepKey]);

  const handleStepClick = useCallback(
    (stepInstance: WorkflowStepInstanceResponse) => {
      setSelectedStepInstance(stepInstance);
      setIsManualScrolling(false);
      const element = detailRefs.current[stepInstance.id.toString()];
      if (element && scrollAreaRef.current) {
        const scrollTop = element.offsetTop - 16;
        scrollAreaRef.current.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    },
    []
  );

  const updateSelectedStepInstance = useCallback(() => {
    if (!scrollAreaRef.current || !sortedSteps.length) return;

    const scrollPosition = scrollAreaRef.current.scrollTop;
    const viewportHeight = scrollAreaRef.current.clientHeight;
    const scrollCenter = scrollPosition + viewportHeight / 2;

    let closestStepInstance = sortedSteps[0];
    let closestDistance = Infinity;

    sortedSteps.forEach((stepInstance) => {
      const element = detailRefs.current[stepInstance.id.toString()];
      if (element) {
        const elementCenter = element.offsetTop + element.clientHeight / 2;
        const distance = Math.abs(elementCenter - scrollCenter);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestStepInstance = stepInstance;
        }
      }
    });

    setSelectedStepInstance(closestStepInstance);
  }, [sortedSteps]);

  const handleScroll = useCallback(() => {
    setIsManualScrolling(true);
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      updateSelectedStepInstance();
      setIsManualScrolling(false);
    }, 100);
  }, [updateSelectedStepInstance]);

  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollArea) {
        scrollArea.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, [handleScroll]);

  if (!workflowInstance) {
    return null;
  }

  const hasOneStep = sortedSteps.length === 1;
  return (
    <Box
      style={{
        height: '100%',
        border: '1px solid var(--mantine-color-dark-4)',
      }}
    >
      <Flex style={{ height: '100%' }}>
        {!hasOneStep && (
          <WorkflowInstanceSidebar
            onStepClick={handleStepClick}
            selectedStepInstance={selectedStepInstance}
            workflowStepInstances={sortedSteps}
            workflowConfiguration={workflowConfiguration}
          />
        )}
        <ScrollArea
          style={{ flex: 1 }}
          viewportRef={scrollAreaRef}
          styles={{
            root: { backgroundColor: 'var(--mantine-color-dark-4)' },
            viewport: { padding: 'var(--mantine-spacing-md)', paddingTop: 0 },
            corner: { backgroundColor: 'var(--mantine-color-dark-4)' },
          }}
        >
          <Box pt="md">
            <Stack gap="md">
              {sortedSteps.map((stepInstance) => (
                <Card
                  key={stepInstance.id}
                  shadow="sm"
                  padding="lg"
                  radius="md"
                  withBorder
                  ref={(el) => {
                    detailRefs.current[stepInstance.id.toString()] = el;
                  }}
                >
                  <WorkflowStepInstanceDetail
                    workflowStepInstance={stepInstance}
                    workflowStep={
                      stepInstance.stepKey
                        ? getStep(stepInstance.stepKey)
                        : undefined
                    }
                    isOnlyStep={hasOneStep}
                  />
                </Card>
              ))}
            </Stack>
          </Box>
        </ScrollArea>
      </Flex>
    </Box>
  );
};

export default WorkflowInstanceView;
