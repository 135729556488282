import { getGetCountries1QueryKey, useGetCountries1 } from '@btrway/api-core';
import { useCallback } from 'react';

export const useCountries = () => {
  const {
    data: countries,
    isLoading,
    error,
    refetch,
  } = useGetCountries1({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetCountries1QueryKey(),
    },
  });

  const getCountryByCode = useCallback(
    (countryCode: string) => {
      return countries?.find((country) => country.code === countryCode);
    },
    [countries]
  );

  const getCountryByName = useCallback(
    (name: string) => {
      return countries?.find((country) => country.name === name);
    },
    [countries]
  );

  return {
    countries: countries ?? [],
    isLoading,
    error,
    getCountryByCode,
    getCountryByName,
    refetchStateProvinces: refetch,
  };
};
