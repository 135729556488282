import { SelectFieldRenderType } from '@btrway/api-workflow';
import {
  Box,
  Checkbox,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React from 'react';

interface RenderStyleProps {
  value: SelectFieldRenderType;
  onChange: (value: SelectFieldRenderType) => void;
  allowsMultiple: boolean;
  onAllowMultipleChange: (value: boolean) => void;
  allowsOther: boolean;
  onAllowOtherChange: (value: boolean) => void;
  sortAlpha: boolean;
  onSortAlphaChange: (value: boolean) => void;
}

const RenderStyleOption: React.FC<{
  title: string;
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}> = ({ title, selected, onClick, children }) => (
  <UnstyledButton
    onClick={onClick}
    style={{
      width: '50%',
      height: '85px',
      padding: 'var(--mantine-spacing-xs)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      border: '1px solid var(--mantine-color-gray-3)',
      backgroundColor: selected
        ? 'var(--mantine-color-green-1)'
        : 'var(--mantine-color-gray-0)',
      '&:hover': {
        backgroundColor: selected
          ? 'var(--mantine-color-green-2)'
          : 'var(--mantine-color-gray-1)',
      },
    }}
  >
    <Text size="sm" fw={600} mb="xs">
      {title}
    </Text>
    <Box
      style={{
        opacity: 0.75,
        transform: 'scale(0.9)',
        transformOrigin: 'top left',
      }}
    >
      {children}
    </Box>
  </UnstyledButton>
);

// Demo components remain the same
const demoOptions = [{ label: 'Option 1' }, { label: 'Option 2' }];

const ChipDemo = () => (
  <Group gap={4} style={{ pointerEvents: 'none' }}>
    <Box
      style={{
        padding: '4px 8px',
        backgroundColor: 'var(--mantine-color-gray-7)',
        borderRadius: 'var(--mantine-radius-xl)',
        color: 'white',
      }}
    >
      <Text size="xs">Option 1</Text>
    </Box>
    <Box
      style={{
        padding: '4px 8px',
        backgroundColor: 'var(--mantine-color-gray-1)',
        borderRadius: 'var(--mantine-radius-xl)',
        border: '1px solid var(--mantine-color-gray-3)',
      }}
    >
      <Text size="xs">Option 2</Text>
    </Box>
  </Group>
);

const CheckboxDemo = () => (
  <Stack gap={4} style={{ pointerEvents: 'none' }}>
    <Group gap="xs">
      <Box
        style={{
          width: '12px',
          height: '12px',
          borderRadius: 'var(--mantine-radius-xs)',
          border: '1px solid var(--mantine-color-gray-4)',
          backgroundColor: 'var(--mantine-color-gray-7)',
        }}
      />
      <Text size="xs">Option 1</Text>
    </Group>
    <Group gap="xs">
      <Box
        style={{
          width: '12px',
          height: '12px',
          borderRadius: 'var(--mantine-radius-xs)',
          border: '1px solid var(--mantine-color-gray-4)',
          background: 'white',
        }}
      />
      <Text size="xs">Option 2</Text>
    </Group>
  </Stack>
);

const SegmentedDemo = () => (
  <Group
    gap={0}
    style={{
      border: '1px solid var(--mantine-color-gray-4)',
      borderRadius: 'var(--mantine-radius-sm)',
      overflow: 'hidden',
      pointerEvents: 'none',
      backgroundColor: 'var(--mantine-color-gray-2)',
      padding: '2px',
      whiteSpace: 'nowrap',
    }}
  >
    <Box
      style={{
        flex: 1,
        padding: '6px 12px',
        backgroundColor: 'white',
        textAlign: 'center',
        borderRadius: 'var(--mantine-radius-xs)',
        boxShadow: 'var(--mantine-shadow-xs)',
      }}
    >
      <Text size="xs" style={{ color: 'var(--mantine-color-dark-8)' }}>
        Option 1
      </Text>
    </Box>
    <Box
      style={{
        flex: 1,
        padding: '6px 6px',
        backgroundColor: 'transparent',
        textAlign: 'center',
        borderRadius: 'var(--mantine-radius-xs)',
      }}
    >
      <Text size="xs" style={{ color: 'var(--mantine-color-gray-8)' }}>
        Option 2
      </Text>
    </Box>
  </Group>
);

const DropdownDemo = () => (
  <Group
    justify="space-between"
    style={{
      border: '1px solid var(--mantine-color-gray-4)',
      borderRadius: 'var(--mantine-radius-sm)',
      padding: '6px',
      backgroundColor: 'white',
      pointerEvents: 'none',
    }}
  >
    <Text size="xs">Select option...</Text>
    <IconChevronDown size={12} />
  </Group>
);

export const RenderStyleSelector: React.FC<RenderStyleProps> = ({
  value,
  onChange,
  allowsMultiple,
  onAllowMultipleChange,
  allowsOther,
  onAllowOtherChange,
  sortAlpha,
  onSortAlphaChange,
}) => {
  const handleStyleSelect = (newStyle: SelectFieldRenderType) => {
    onChange(newStyle);
    if (newStyle === SelectFieldRenderType.segmented) {
      onAllowMultipleChange(false);
    }
  };

  return (
    <Stack gap="md">
      <Group
        style={{
          border: '1px solid var(--mantine-color-gray-4)',
          borderRadius: 'var(--mantine-radius-sm)',
          overflow: 'hidden',
          display: 'flex',
          flexWrap: 'wrap',
        }}
        gap={0}
      >
        <RenderStyleOption
          title="Picklist"
          selected={value === SelectFieldRenderType.picklist}
          onClick={() => handleStyleSelect(SelectFieldRenderType.picklist)}
        >
          <DropdownDemo />
        </RenderStyleOption>

        <RenderStyleOption
          title="Radio/Checkbox"
          selected={value === SelectFieldRenderType.radioCheckbox}
          onClick={() => handleStyleSelect(SelectFieldRenderType.radioCheckbox)}
        >
          <CheckboxDemo />
        </RenderStyleOption>

        <RenderStyleOption
          title="Segmented"
          selected={value === SelectFieldRenderType.segmented}
          onClick={() => handleStyleSelect(SelectFieldRenderType.segmented)}
        >
          <SegmentedDemo />
        </RenderStyleOption>

        <RenderStyleOption
          title="Chip"
          selected={value === SelectFieldRenderType.chip}
          onClick={() => handleStyleSelect(SelectFieldRenderType.chip)}
        >
          <ChipDemo />
        </RenderStyleOption>
      </Group>

      <Stack gap="xs">
        <Checkbox
          label="Allow Multiple Selection"
          checked={allowsMultiple}
          onChange={(event) =>
            onAllowMultipleChange(event.currentTarget.checked)
          }
          disabled={value === SelectFieldRenderType.segmented}
        />
        <Checkbox
          label="Allow Other"
          checked={allowsOther}
          onChange={(event) => onAllowOtherChange(event.currentTarget.checked)}
        />
        <Checkbox
          label="Sort Alphabetically"
          checked={sortAlpha}
          onChange={(event) => onSortAlphaChange(event.currentTarget.checked)}
        />
      </Stack>
    </Stack>
  );
};

export default RenderStyleSelector;
