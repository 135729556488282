import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';
import {
  IconAdjustments,
  IconArrowsVertical,
  IconCalendar,
  IconCalendarClock,
  IconCheck,
  IconClock,
  IconForms,
  IconHeading,
  IconLine,
  IconLink,
  IconList,
  IconMap,
  IconMapPin,
  IconNumbers,
  IconPhoto,
  IconRuler,
  IconSignature,
  IconStars,
  IconTypography,
  IconUpload,
  IconUserSearch,
  IconVideo,
  IconWeight,
} from '@tabler/icons-react';

// Import all field components
import { DefaultFieldConfigs } from '@btrway/form-configuration-manager';
import AddressField from '../field-type-controls/AddressField/AddressField';
import BooleanField from '../field-type-controls/BooleanField/BooleanField';
import CheckboxField from '../field-type-controls/CheckboxField/CheckboxField';
import CountryField from '../field-type-controls/CountryField/CountryField';
import DateField from '../field-type-controls/DateField/DateField';
import DateTimeField from '../field-type-controls/DateTimeField/DateTimeField';
import DividerField from '../field-type-controls/DividerField/DividerField';
import FileUploadField from '../field-type-controls/FileUploadField/FileUploadField';
import HeadingField from '../field-type-controls/HeadingField/HeadingField';
import { HeightField } from '../field-type-controls/HeightField/HeightField';
import ImageUploadField from '../field-type-controls/ImageUploadField/ImageUploadField';
import LinkField from '../field-type-controls/LinkField/LinkField';
import NumberField from '../field-type-controls/NumberField/NumberField';
import ParagraphField from '../field-type-controls/ParagraphField/ParagraphField';
import PersonField from '../field-type-controls/PersonField/PersonField';
import RatingField from '../field-type-controls/RatingField/RatingField';
import SelectField from '../field-type-controls/SelectField/SelectField';
import SignatureField from '../field-type-controls/SignatureField/SignatureField';
import SliderField from '../field-type-controls/SliderField/SliderField';
import SpaceField from '../field-type-controls/SpaceField/SpaceField';
import StateProvinceField from '../field-type-controls/StateProvinceField/StateProvinceField';
import TextField from '../field-type-controls/TextField/TextField';
import TimeField from '../field-type-controls/TimeField/TimeField';
import { VideoField } from '../field-type-controls/VideoField/VideoField';
import { WeightField } from '../field-type-controls/WeightField/WeightField';

export interface FieldRegistryItem {
  type: FieldTypeEnum;
  component: React.ComponentType<any>;
  icon?: React.ComponentType<any>;
  defaultConfig: Partial<FieldConfig>;
  settings?: {
    supportedValidations: string[];
    supportsPropertyReference: boolean;
    customSettings?: React.ComponentType<any>;
    [key: string]: any;
  };
}

class FieldRegistry {
  private registry: Map<FieldTypeEnum, FieldRegistryItem> = new Map();

  constructor() {
    this.registerDefaultFields();
  }

  private registerDefaultFields() {
    // Layout fields
    this.register({
      type: FieldTypeEnum.heading,
      component: HeadingField,
      icon: IconHeading,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.heading],
      settings: {
        supportedValidations: [],
        supportsPropertyReference: false,
      },
    });

    this.register({
      type: FieldTypeEnum.paragraph,
      component: ParagraphField,
      icon: IconTypography,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.paragraph],
      settings: {
        supportedValidations: [],
        supportsPropertyReference: false,
      },
    });

    this.register({
      type: FieldTypeEnum.link,
      component: LinkField,
      icon: IconLink,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.link],
      settings: {
        supportedValidations: [],
        supportsPropertyReference: false,
      },
    });

    this.register({
      type: FieldTypeEnum.text,
      component: TextField,
      icon: IconForms,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.text],
      settings: {
        supportedValidations: ['required', 'minLength', 'maxLength', 'pattern'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.number,
      component: NumberField,
      icon: IconNumbers,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.number],
      settings: {
        supportedValidations: [],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.boolean,
      component: BooleanField,
      icon: IconCheck,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.boolean],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.checkbox,
      component: CheckboxField,
      icon: IconCheck,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.checkbox],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.select,
      component: SelectField,
      icon: IconList,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.select],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.date,
      component: DateField,
      icon: IconCalendar,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.date],
      settings: {
        supportedValidations: ['required', 'minDate', 'maxDate'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.dateTime,
      component: DateTimeField,
      icon: IconCalendarClock,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.dateTime],
      settings: {
        supportedValidations: ['required', 'minDate', 'maxDate'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.time,
      component: TimeField,
      icon: IconClock,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.time],
      settings: {
        supportedValidations: ['required', 'minDate', 'maxDate'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.file,
      component: FileUploadField,
      icon: IconUpload,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.file],
      settings: {
        supportedValidations: ['required', 'maxSize', 'fileTypes'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.image,
      component: ImageUploadField,
      icon: IconPhoto,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.image],
      settings: {
        supportedValidations: ['required', 'maxSize', 'imageTypes'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.signature,
      component: SignatureField,
      icon: IconSignature,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.signature],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.height,
      component: HeightField,
      icon: IconRuler,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.height],
      settings: {
        supportedValidations: ['required', 'min', 'max'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.weight,
      component: WeightField,
      icon: IconWeight,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.weight],
      settings: {
        supportedValidations: ['required', 'min', 'max'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.rating,
      component: RatingField,
      icon: IconStars,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.rating],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.slider,
      component: SliderField,
      icon: IconAdjustments,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.slider],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.address,
      component: AddressField,
      icon: IconMapPin,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.address],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.stateProvince,
      component: StateProvinceField,
      icon: IconMapPin,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.stateProvince],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.country,
      component: CountryField,
      icon: IconMap,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.country],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.divider,
      component: DividerField,
      icon: IconLine,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.divider],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.video,
      component: VideoField,
      icon: IconVideo,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.video],
      settings: {
        supportedValidations: ['required'],
        supportsPropertyReference: true,
      },
    });

    this.register({
      type: FieldTypeEnum.space,
      component: SpaceField,
      icon: IconArrowsVertical,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.space],
      settings: {
        supportedValidations: [],
        supportsPropertyReference: false,
      },
    });

    this.register({
      type: FieldTypeEnum.person,
      component: PersonField,
      icon: IconUserSearch,
      defaultConfig: DefaultFieldConfigs[FieldTypeEnum.person],
      settings: {
        supportedValidations: [],
        supportsPropertyReference: false,
      },
    });
  }

  register(item: FieldRegistryItem) {
    this.registry.set(item.type, item);
  }

  get(type: FieldTypeEnum): FieldRegistryItem | undefined {
    return this.registry.get(type);
  }

  getAll(): FieldRegistryItem[] {
    return Array.from(this.registry.values());
  }

  getComponent(type: FieldTypeEnum): React.ComponentType<any> | undefined {
    return this.registry.get(type)?.component;
  }

  getDefaultConfig(type: FieldTypeEnum): Partial<FieldConfig> | undefined {
    return this.registry.get(type)?.defaultConfig;
  }

  getSupportedValidations(type: FieldTypeEnum): string[] {
    return this.registry.get(type)?.settings?.supportedValidations || [];
  }

  supportsPropertyReference(type: FieldTypeEnum): boolean {
    return (
      this.registry.get(type)?.settings?.supportsPropertyReference || false
    );
  }
}

export const fieldRegistry = new FieldRegistry();

export default fieldRegistry;
