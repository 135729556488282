import MuxPlayer from '@mux/mux-player-react';
import React from 'react';
import ReactPlayer from 'react-player';

type UniversalVideoPlayerProps = {
  url?: string;
  playbackIdentifier?: string;
  aspectRatio?: string;
  width?: string | number;
  height?: string | number;
};

export const UniversalVideoPlayer: React.FC<UniversalVideoPlayerProps> = ({
  url,
  playbackIdentifier,
  aspectRatio = '16/9',
  width = '100%',
  height = 'auto',
}) => {
  // If playbackIdentifier is provided, render MuxPlayer
  if (playbackIdentifier) {
    return (
      <MuxPlayer
        streamType="on-demand"
        playbackId={playbackIdentifier}
        style={{
          width: width,
          maxWidth: '100%',
          height: height,
          aspectRatio: aspectRatio,
        }}
      />
    );
  }

  // If URL is provided, render ReactPlayer
  if (url) {
    return (
      <div className="player-wrapper" style={{ aspectRatio }}>
        <ReactPlayer
          url={url}
          width={width}
          height={height}
          controls={true}
          muted={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                modestbranding: 1,
              },
            },
            vimeo: {
              playerOptions: {
                byline: true,
                responsive: true,
              },
            },
          }}
        />
      </div>
    );
  }

  // If neither is provided, return null or an error message
  return (
    <div className="text-red-500">
      Error: Either url or playbackIdentifier must be provided
    </div>
  );
};
