// import { Card, Group, Skeleton, Stack, Text } from '@mantine/core';
// import React, { useEffect } from 'react';

// import {
//   useTaskFetcher,
//   useTaskManager,
//   useTaskModal,
// } from '@btrway/task-manager';
// import { TaskTypeIcon } from '@btrway/workflow-components';

// import { DataTypeEnum } from '@btrway/api-core';
// import {
//   EntityTaskResponse,
//   TaskRetrievalRequestCompletionStatus,
//   TaskTypeEnum,
// } from '@btrway/api-workflow';
// import { useAuthenticatedUser } from '@btrway/current-user';
// import { EntityTag } from '@btrway/entity-tags';
// import { formatDateTime } from '@btrway/utils';

// interface ApprovalsViewProps {
//   personId?: number; // Optional - if not provided, uses current user
// }

// const MIN_LOADING_DURATION = 400; // Minimum time to show loading state
// const LOADING_DELAY = 200; // Delay before showing skeleton

// const LoadingContent = () => (
//   <div style={{ transition: 'opacity 0.2s ease-in-out' }}>
//     <Stack gap="xs">
//       {Array.from({ length: 10 }).map((_, i) => (
//         <Card key={i} padding="sm" radius="sm" withBorder shadow="none">
//           <Group justify="space-between" mb="xs">
//             <Group gap="sm">
//               <Skeleton circle height={20} />
//               <Skeleton height={18} width={200} />
//             </Group>
//             <Skeleton height={18} width={100} />
//           </Group>
//           <Skeleton height={16} width="40%" />
//         </Card>
//       ))}
//     </Stack>
//   </div>
// );

// const ApprovalsView: React.FC<ApprovalsViewProps> = ({ personId }) => {
//   console.log('Approvals View');
//   const { openTask } = useTaskModal();
//   const { fetchTasksWithCriteria } = useTaskFetcher();
//   const { currentPerson } = useAuthenticatedUser();
//   const { tasks } = useTaskManager();
//   const [isLoading, setIsLoading] = React.useState(true);
//   const [showLoading, setShowLoading] = React.useState(false); // Start as false
//   const [error, setError] = React.useState<Error | null>(null);
//   const loadingTimerRef = React.useRef<NodeJS.Timeout>();
//   const hideLoadingTimerRef = React.useRef<NodeJS.Timeout>();

//   const targetPersonId = personId || currentPerson.id;

//   useEffect(() => {
//     if (!targetPersonId) return;

//     const startTime = Date.now();
//     setIsLoading(true);

//     // Clear any existing timers
//     if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
//     if (hideLoadingTimerRef.current) clearTimeout(hideLoadingTimerRef.current);

//     // Set timer to show loading state after delay
//     loadingTimerRef.current = setTimeout(() => {
//       setShowLoading(true);
//     }, LOADING_DELAY);

//     fetchTasksWithCriteria(
//       [targetPersonId],
//       {
//         taskTypes: [TaskTypeEnum.completeWorkflowStep],
//         completionStatus: TaskRetrievalRequestCompletionStatus.NOT_COMPLETED,
//         includeRoleBasedTasks: true,
//       },
//       {
//         onSuccess: () => {
//           const elapsedTime = Date.now() - startTime;
//           const remainingTime = Math.max(0, MIN_LOADING_DURATION - elapsedTime);

//           // If load finished before loading delay, clear the loading timer
//           if (elapsedTime < LOADING_DELAY) {
//             clearTimeout(loadingTimerRef.current);
//             setShowLoading(false);
//             setIsLoading(false);
//             return;
//           }

//           // Ensure loading state shows for at least MIN_LOADING_DURATION
//           hideLoadingTimerRef.current = setTimeout(() => {
//             setIsLoading(false);
//             // Add slight delay before hiding loading state for fade out
//             setTimeout(() => setShowLoading(false), 200);
//           }, remainingTime);
//         },
//         onError: (err) => {
//           clearTimeout(loadingTimerRef.current);
//           setError(err as Error);
//           setIsLoading(false);
//           setShowLoading(false);
//         },
//       }
//     );

//     // Cleanup timers on unmount or when dependencies change
//     return () => {
//       if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
//       if (hideLoadingTimerRef.current)
//         clearTimeout(hideLoadingTimerRef.current);
//     };
//   }, [targetPersonId, fetchTasksWithCriteria]);

// const approvalTasks = React.useMemo(
//   () =>
//     tasks.filter(
//       (task) =>
//         !task.completed &&
//         task.taskType === TaskTypeEnum.completeWorkflowStep &&
//         ((task.assignedEntityType === DataTypeEnum.person &&
//           task.assignedEntityId === targetPersonId) ||
//           task.assignedEntityType === DataTypeEnum.userRole)
//     ),
//   [tasks, targetPersonId]
// );

//   const handleTaskClick = (task: EntityTaskResponse) => {
//     openTask({
//       taskProperties: task.taskProperties || {},
//       taskInstanceId: task.taskInstanceId,
//       assignedEntityType: task.assignedEntityType,
//       assignedEntityId: task.assignedEntityId,
//       workgroupId: task.workgroupId,
//       derivedWorkflowKey: task.parentWorkflowKey,
//       taskKey: task.taskKey,
//       taskType: task.taskType,
//     });
//   };

//   if (showLoading) {
//     return (
//       <div
//         style={{
//           opacity: isLoading ? 1 : 0,
//           transition: 'opacity 0.2s ease-in-out',
//         }}
//       >
//         <LoadingContent />
//       </div>
//     );
//   }

//   if (error) {
//     return <Text c="red">Error loading tasks: {error.message}</Text>;
//   }

//   if (!approvalTasks.length) {
//     return (
//       <Text c="dimmed" ta="center">
//         No pending approvals
//       </Text>
//     );
//   }

//   return (
//     <div
//       style={{
//         opacity: 1,
//         transition: 'opacity 0.2s ease-in-out',
//       }}
//     >
//       <Stack gap="xs" style={{ width: '100%' }}>
//         {approvalTasks.map((task) => (
//           <Card
//             key={task.taskInstanceId || task.taskKey}
//             padding="sm"
//             radius="sm"
//             withBorder
//             shadow="none"
//             onClick={() => handleTaskClick(task)}
//             style={{ cursor: 'pointer' }}
//           >
//             <Group justify="space-between" mb="xs">
//               <Group gap="sm">
//                 {task.taskType && (
//                   <TaskTypeIcon iconName={TaskTypeEnum.submitForm} size={20} />
//                 )}
//                 <Text fw={500}>{task.taskTitle}</Text>
//               </Group>
//               {task.workgroupId && (
//                 <EntityTag
//                   dataType={DataTypeEnum.workgroup}
//                   id={task.workgroupId}
//                   allowClick={false}
//                 />
//               )}
//             </Group>

//             {task.assignedAt && (
//               <Text size="sm" c="dimmed">
//                 Assigned: {formatDateTime(task.assignedAt)}
//               </Text>
//             )}
//           </Card>
//         ))}
//       </Stack>
//     </div>
//   );
// };

// export default ApprovalsView;

import { DataTypeEnum } from '@btrway/api-core';
import { EntityTaskResponse, TaskTypeEnum } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { EntityTag } from '@btrway/entity-tags';
import {
  useApprovalTasks,
  useTaskManager,
  useTaskModal,
} from '@btrway/task-manager';
import { formatDateTime } from '@btrway/utils';
import { TaskTypeIcon } from '@btrway/workflow-components';
import { Card, Group, Skeleton, Stack, Text } from '@mantine/core';
import React from 'react';

interface ApprovalsViewProps {
  personId?: number;
}

const MIN_LOADING_DURATION = 400;
const LOADING_DELAY = 200;

const LoadingContent = () => (
  <div style={{ transition: 'opacity 0.2s ease-in-out' }}>
    <Stack gap="xs">
      {Array.from({ length: 10 }).map((_, i) => (
        <Card key={i} padding="sm" radius="sm" withBorder shadow="none">
          <Group justify="space-between" mb="xs">
            <Group gap="sm">
              <Skeleton circle height={20} />
              <Skeleton height={18} width={200} />
            </Group>
            <Skeleton height={18} width={100} />
          </Group>
          <Skeleton height={16} width="40%" />
        </Card>
      ))}
    </Stack>
  </div>
);

const ApprovalsView: React.FC<ApprovalsViewProps> = ({ personId }) => {
  const { openTask } = useTaskModal();
  const { currentPerson } = useAuthenticatedUser();
  const { tasks } = useTaskManager();
  const { isLoading, error } = useApprovalTasks({ personId });

  const [showLoading, setShowLoading] = React.useState(false);
  const loadingTimerRef = React.useRef<NodeJS.Timeout>();
  const hideLoadingTimerRef = React.useRef<NodeJS.Timeout>();

  const targetPersonId = personId || currentPerson.id;

  // Filter tasks from the jotai atom
  const approvalTasks = React.useMemo(
    () =>
      tasks.filter(
        (task) =>
          !task.completed &&
          task.taskType === TaskTypeEnum.completeWorkflowStep &&
          ((task.assignedEntityType === DataTypeEnum.person &&
            task.assignedEntityId === targetPersonId) ||
            task.assignedEntityType === DataTypeEnum.userRole)
      ),
    [tasks, targetPersonId]
  );

  // Manage loading states with minimum duration
  React.useEffect(() => {
    if (!isLoading) {
      if (hideLoadingTimerRef.current) {
        clearTimeout(hideLoadingTimerRef.current);
      }
      hideLoadingTimerRef.current = setTimeout(() => {
        setShowLoading(false);
      }, MIN_LOADING_DURATION);
    } else {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
      loadingTimerRef.current = setTimeout(() => {
        setShowLoading(true);
      }, LOADING_DELAY);
    }

    return () => {
      if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
      if (hideLoadingTimerRef.current)
        clearTimeout(hideLoadingTimerRef.current);
    };
  }, [isLoading]);

  const handleTaskClick = (task: EntityTaskResponse) => {
    openTask({
      taskProperties: task.taskProperties || {},
      taskInstanceId: task.taskInstanceId,
      assignedEntityType: task.assignedEntityType,
      assignedEntityId: task.assignedEntityId,
      workgroupId: task.workgroupId,
      derivedWorkflowKey: task.parentWorkflowKey,
      taskKey: task.taskKey,
      taskType: task.taskType,
    });
  };

  if (showLoading) {
    return <LoadingContent />;
  }

  if (error) {
    return <Text c="red">Error loading tasks: {error.message}</Text>;
  }

  if (!approvalTasks.length) {
    return (
      <Text c="dimmed" ta="center">
        No pending approvals
      </Text>
    );
  }

  return (
    <Stack gap="xs" style={{ width: '100%' }}>
      {approvalTasks.map((task) => (
        <Card
          key={task.taskInstanceId || task.taskKey}
          padding="sm"
          radius="sm"
          withBorder
          shadow="none"
          onClick={() => handleTaskClick(task)}
          style={{ cursor: 'pointer' }}
        >
          <Group justify="space-between" mb="xs">
            <Group gap="sm">
              {task.taskType && (
                <TaskTypeIcon iconName={TaskTypeEnum.submitForm} size={20} />
              )}
              <Text fw={500}>{task.taskTitle}</Text>
            </Group>
            {task.workgroupId && (
              <EntityTag
                dataType={DataTypeEnum.workgroup}
                id={task.workgroupId}
                allowClick={false}
              />
            )}
          </Group>

          {task.assignedAt && (
            <Text size="sm" c="dimmed">
              Assigned: {formatDateTime(task.assignedAt)}
            </Text>
          )}
        </Card>
      ))}
    </Stack>
  );
};

export default ApprovalsView;
