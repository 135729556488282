import { LinkFieldProperties } from '@btrway/api-workflow';
import { UrlTextInput } from '@btrway/shared-components';
import { Anchor, Stack, Text } from '@mantine/core';
import React from 'react';
import { BaseFieldProps, DisplayMode, isReadOnly } from '../../types/baseField';

interface LinkFieldProps extends BaseFieldProps<string> {}

export const LinkField: React.FC<LinkFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const linkProperties = field.fieldProperties as LinkFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const handleUrlChange = (url: string | null) => {
    if (readonly) return;
    onChange?.(url);
  };

  // Show clickable link in display or readonly modes
  if (readonly) {
    const url = linkProperties?.url || value;
    if (!url) {
      return (
        <Stack gap="xs">
          <Text size="sm" c="dimmed">
            No link provided
          </Text>
        </Stack>
      );
    }
    return (
      <Stack gap="xs">
        <Anchor href={url} target="_blank" rel="noopener noreferrer">
          {linkProperties?.displayText || url}
        </Anchor>
      </Stack>
    );
  }

  // Show URL input in input/builder modes
  return (
    <Stack gap="xs">
      <UrlTextInput
        value={linkProperties?.url || value || ''}
        onUrlChange={handleUrlChange}
        required={field.required}
      />
    </Stack>
  );
};

export default LinkField;
