import { AddressRequest, AddressResponse } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  useCountries,
  useStateProvinces,
} from '@btrway/reference-data-manager';
import { useEffect, useMemo, useRef, useState } from 'react';
import { countryConfigurations } from '../config/countries';

interface UseAddressInputProps {
  value?: AddressResponse;
  onChange?: (value: AddressRequest) => void;
  defaultCountryCode?: string;
}

export const useAddressInput = ({
  value,
  onChange,
  defaultCountryCode,
}: UseAddressInputProps) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { countries } = useCountries();
  const { stateProvinces } = useStateProvinces();
  const isInitializing = useRef(true);

  const [address, setAddress] = useState<AddressRequest>(() => {
    if (value) {
      const { base, ...rest } = value;
      return rest;
    }
    return {};
  });

  // Update local state when value prop changes
  useEffect(() => {
    if (value) {
      const { base, ...rest } = value;
      setAddress(rest);
    }
  }, [value]);

  // Set default country if specified
  useEffect(() => {
    if (defaultCountryCode && !address.countryCode) {
      setAddress((prev) => ({
        ...prev,
        countryCode: defaultCountryCode,
        organizationId: prev.organizationId || currentOrganization.id,
      }));
    }

    // Allow changes to trigger onChange after initial setup
    const timer = setTimeout(() => {
      isInitializing.current = false;
    }, 0);
    return () => clearTimeout(timer);
  }, [defaultCountryCode]);

  const handleFieldChange = (
    field: keyof AddressRequest,
    newValue: string | null
  ) => {
    const updatedAddress = {
      ...address,
      [field]: newValue || undefined,
      organizationId: address.organizationId || currentOrganization.id,
    };

    // Clear stateProvinceCode if country changes
    if (field === 'countryCode') {
      updatedAddress.stateProvinceCode = undefined;
    }

    setAddress(updatedAddress);

    // Only trigger onChange after initialization
    if (!isInitializing.current) {
      onChange?.(updatedAddress);
    }
  };
  const countryOptions = useMemo(
    () =>
      countries
        ?.sort((a, b) => {
          const configA = countryConfigurations[a.code];
          const configB = countryConfigurations[b.code];

          // If both have sort orders, compare them
          if (
            configA?.sortOrder !== undefined &&
            configB?.sortOrder !== undefined
          ) {
            return configA.sortOrder - configB.sortOrder;
          }

          // If only one has a sort order, it should come first
          if (configA?.sortOrder !== undefined) return -1;
          if (configB?.sortOrder !== undefined) return 1;

          // If neither has a sort order, sort alphabetically by name
          return a.name.localeCompare(b.name);
        })
        .map((country) => ({
          value: country.code,
          label: country.name,
        })) || [],
    [countries]
  );

  const filteredStateProvinces = useMemo(() => {
    const filtered =
      stateProvinces
        ?.filter((sp) => sp.countryCode === address.countryCode)
        .map((sp) => ({
          value: sp.code,
          label: `${sp.code} - ${sp.name}`,
        })) || [];
    return filtered;
  }, [stateProvinces, address.countryCode]);

  return {
    address,
    handleFieldChange,
    countryOptions,
    filteredStateProvinces,
  };
};

export default useAddressInput;
