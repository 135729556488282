import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
import { Badge, Group, Text } from '@mantine/core';
import { IconFile, IconUser, IconUsers } from '@tabler/icons-react';
import React from 'react';
import { getEntityTypeColor } from '../../utils/entityUtils';

interface SearchResultOptionProps {
  result: GlobalSearchResponse;
  showSimilarity?: boolean;
  showBadge?: boolean;
  variant?: 'default' | 'compact';
}

const getEntityTypeIcon = (entityType: DataTypeEnum) => {
  switch (entityType) {
    case DataTypeEnum.person:
      return <IconUser size={16} />;
    case DataTypeEnum.entityGroup:
      return <IconUsers size={16} />;
    default:
      return <IconFile size={16} />;
  }
};

export const SearchResultOption: React.FC<SearchResultOptionProps> = ({
  result,
  showSimilarity = false,
  showBadge = false,
  variant = 'default',
}) => (
  <Group justify="space-between" wrap="nowrap">
    <Group gap="xs" wrap="nowrap">
      {getEntityTypeIcon(result.entityType as DataTypeEnum)}
      <Group gap={4}>
        <Text fz="sm">{result.displayName}</Text>
        {result.workgroupMembers?.[0] && (
          <Text fz="sm" c="dimmed">
            {result.workgroupMembers[0].userRole?.name} at{' '}
            {result.workgroupMembers[0].workgroup?.name}
          </Text>
        )}
      </Group>
    </Group>
    <Group gap="xs" wrap="nowrap">
      {showBadge && (
        <Badge
          color={getEntityTypeColor(result.entityType as DataTypeEnum)}
          size="sm"
          variant="light"
        >
          {result.entityType}
        </Badge>
      )}
      {showSimilarity && (
        <Text size="xs" c="dimmed">
          {Math.round(result.similarity * 100)}%
        </Text>
      )}
    </Group>
  </Group>
);
