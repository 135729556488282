import {
  AssistantBar,
  useGetAssistantConfig,
} from '@btrway/assistant-components';
import { GlobalSearchSidebar } from '@btrway/global-search';
import { HelpSidebar } from '@btrway/help-sidebar';
import { useThemeScheme } from '@btrway/theme-scheme-provider';
import { ActionIcon, Box, Flex, Stack } from '@mantine/core';
import { IconHelp, IconSearch, IconWand, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface AsideBarProps {
  minimized: boolean;
  setMinimized: (minimized: boolean) => void;
}

const AsideBar: React.FC<AsideBarProps> = ({ minimized, setMinimized }) => {
  const { getColorValue } = useThemeScheme();
  const appShellBackground = getColorValue('appShellBackground');
  const assistantConfig = useGetAssistantConfig();

  const [activeContent, setActiveContent] = useState<
    'help' | 'search' | 'wand' | null
  >(null);

  useEffect(() => {
    if (!assistantConfig && activeContent === 'wand') {
      setMinimized(true);
      setActiveContent(null);
    }
  }, [assistantConfig, activeContent, setMinimized]);

  const toggleAside = (content: 'help' | 'search' | 'wand') => {
    if (minimized || activeContent !== content) {
      setMinimized(false);
      setActiveContent(content);
    } else {
      setMinimized(true);
      setActiveContent(null);
    }
  };

  const closeAside = () => {
    setMinimized(true);
    setActiveContent(null);
  };

  const AsideContent = () => (
    <Box p="md" style={{ height: 'calc(100% - 40px)' }}>
      {activeContent === 'search' && (
        <GlobalSearchSidebar
          onlyTopResults={true}
          limit={200}
          onSelect={() => {}}
        />
      )}
      {activeContent === 'help' && <HelpSidebar />}
      {activeContent === 'wand' && assistantConfig && <AssistantBar />}
    </Box>
  );

  return (
    <Box
      style={{
        width: minimized ? '40px' : '100%',
        height: '100%',
        backgroundColor: appShellBackground,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {minimized ? (
        <Stack justify="flex-start" align="center" h="100%" gap="md">
          <ActionIcon
            variant="subtle"
            color="dark"
            onClick={() => toggleAside('search')}
          >
            <IconSearch size={20} stroke={3.5} />
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            color="dark"
            onClick={() => toggleAside('help')}
          >
            <IconHelp size={20} stroke={2.5} />
          </ActionIcon>
          {assistantConfig && (
            <ActionIcon variant="subtle" onClick={() => toggleAside('wand')}>
              <IconWand size={20} stroke={2} />
            </ActionIcon>
          )}
        </Stack>
      ) : (
        <>
          <Flex justify="space-between" align="center" p="md" h={40}>
            <Box>
              {activeContent === 'help' && <span>Help</span>}
              {activeContent === 'wand' && <span>Assistant</span>}
            </Box>
            <ActionIcon variant="light" onClick={closeAside}>
              <IconX size="1.1rem" />
            </ActionIcon>
          </Flex>
          <AsideContent />
        </>
      )}
    </Box>
  );
};

export default AsideBar;
