import {
  BaseHierarchicalItem,
  HierarchicalNode,
} from '../types/hierarchicalItem';

export const createHierarchicalNodes = <T extends BaseHierarchicalItem>(
  items: T[]
): HierarchicalNode<T>[] => {
  const nodeMap = new Map<string, HierarchicalNode<T>>();
  const rootNodes: HierarchicalNode<T>[] = [];

  // Initialize all nodes
  items.forEach((item) => {
    const pathParts = item.path.split('.');
    const parentId =
      pathParts.length > 1
        ? parseInt(pathParts[pathParts.length - 2])
        : undefined;

    nodeMap.set(item.id.toString(), {
      ...item,
      level: pathParts.length - 1,
      children: [],
      parentId,
    });
  });

  // Build hierarchy
  items.forEach((item) => {
    const currentNode = nodeMap.get(item.id.toString())!;

    if (currentNode.parentId) {
      const parent = nodeMap.get(currentNode.parentId.toString());
      if (parent) {
        parent.children.push(currentNode);
      }
    } else {
      rootNodes.push(currentNode);
    }
  });

  return sortNodes(rootNodes);
};

const sortNodes = <T extends BaseHierarchicalItem>(
  nodes: HierarchicalNode<T>[]
): HierarchicalNode<T>[] => {
  nodes.sort((a, b) => a.name.localeCompare(b.name));
  nodes.forEach((node) => sortNodes(node.children));
  return nodes;
};

const flattenNodes = <T extends BaseHierarchicalItem>(
  nodes: HierarchicalNode<T>[]
): HierarchicalNode<T>[] => {
  const result: HierarchicalNode<T>[] = [];
  const flatten = (node: HierarchicalNode<T>) => {
    result.push(node);
    node.children.forEach(flatten);
  };
  nodes.forEach(flatten);
  return result;
};

export const getParentNode = <T extends BaseHierarchicalItem>(
  node: HierarchicalNode<T>,
  hierarchicalNodes: HierarchicalNode<T>[]
): HierarchicalNode<T> | undefined => {
  if (!node.parentId) return undefined;
  return flattenNodes(hierarchicalNodes).find((n) => n.id === node.parentId);
};

export const areAllChildrenSelected = (
  node: HierarchicalNode<BaseHierarchicalItem>,
  selection: Set<number>
): boolean => {
  if (node.children.length === 0) return true;
  return node.children.every((child) => selection.has(child.id));
};

export const getEffectiveSelection = (
  selection: Set<number>,
  items: BaseHierarchicalItem[] | undefined
): number[] => {
  const itemsWithSelectedParent = new Set<number>();

  items?.forEach((item) => {
    const pathParts = item.path.split('.');
    if (pathParts.length > 1) {
      for (let i = 0; i < pathParts.length - 1; i++) {
        const ancestorId = parseInt(pathParts[i]);
        if (selection.has(ancestorId)) {
          itemsWithSelectedParent.add(item.id);
          break;
        }
      }
    }
  });

  return Array.from(selection).filter((id) => !itemsWithSelectedParent.has(id));
};
