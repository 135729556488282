import {
  DateFieldProperties,
  DateFieldRenderType,
  FieldConfig,
} from '@btrway/api-workflow';
import { Chip, Group, NumberInput, Stack, Text } from '@mantine/core';
import React from 'react';

interface DateFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<DateFieldProperties>) => void;
}

const defaultDateProperties: DateFieldProperties = {
  renderType: DateFieldRenderType.input,
  maximumDaysAgo: undefined,
  minimumDaysAgo: undefined,
};

export const DateFieldSettings: React.FC<DateFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const dateProps = {
    ...defaultDateProperties,
    ...(field.fieldProperties as DateFieldProperties),
  };

  const handleSingleChange = (key: keyof DateFieldProperties, value: any) => {
    onChange({
      ...dateProps,
      [key]: value,
    });
  };

  const handleRenderTypeChange = (value: string) => {
    onChange({
      ...dateProps,
      renderType: value as DateFieldRenderType,
    });
  };

  return (
    <Stack gap="md">
      <Text fz="sm" fw={600}>
        Display Type
      </Text>
      <Group wrap="nowrap" gap="xs">
        <Chip.Group
          value={dateProps.renderType}
          onChange={handleRenderTypeChange}
          multiple={false}
        >
          <Group gap="xs">
            <Chip value={DateFieldRenderType.input}>Input with Picker</Chip>
            <Chip value={DateFieldRenderType.picker}>Picker Only</Chip>
          </Group>
        </Chip.Group>
      </Group>

      <Text fz="sm" fw={600}>
        Date Range Constraints
      </Text>

      <NumberInput
        label="Maximum Days Ago"
        description="Maximum number of days in the past that can be selected"
        value={dateProps.maximumDaysAgo}
        onChange={(value) => handleSingleChange('maximumDaysAgo', value)}
        min={0}
        step={1}
        allowNegative={false}
      />

      <NumberInput
        label="Minimum Days Ago"
        description="Minimum number of days in the past that can be selected"
        value={dateProps.minimumDaysAgo}
        onChange={(value) => handleSingleChange('minimumDaysAgo', value)}
        min={0}
        step={1}
        allowNegative={false}
      />
    </Stack>
  );
};

export default DateFieldSettings;
