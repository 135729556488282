import { FormLaunchSetting, FormSettings } from '@btrway/api-workflow';
import { LaunchCheckboxCard } from '@btrway/form-settings';
import { UserRoleHierarchicalSelector } from '@btrway/security-components';
import { Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useProcessBuilder } from '../../providers/ProcessBuilderProvider';

export const CompletionMethodStep: React.FC = () => {
  const { workflow, updateWorkflow } = useProcessBuilder();
  const formSettings = workflow.workflowConfiguration?.settings as FormSettings;

  // Initialize state from workflow settings
  const [launchAboutSelf, setLaunchAboutSelf] = useState<boolean>(
    formSettings?.launchSettings?.some((setting) => setting.launchAboutSelf) ||
      false
  );
  const [launchAboutOthers, setLaunchAboutOthers] = useState<boolean>(
    formSettings?.launchSettings?.some(
      (setting) => setting.launchAboutOthers
    ) || false
  );
  const [othersRoleSettings, setOthersRoleSettings] = useState<Set<string>>(
    new Set(
      formSettings?.launchSettings
        ?.filter((setting) => setting.launchAboutOthers)
        .map((setting) => setting.roleSlug) || []
    )
  );

  const handleSelfChange = (checked: boolean) => {
    setLaunchAboutSelf(checked);
    updateLaunchSettings(checked, launchAboutOthers, othersRoleSettings);
  };

  const handleOthersChange = (checked: boolean) => {
    setLaunchAboutOthers(checked);
    updateLaunchSettings(launchAboutSelf, checked, othersRoleSettings);
  };

  const handleOthersRoleChange = (selection: { roleSlugs: string[] }) => {
    const newOthersRoleSettings = new Set(selection.roleSlugs);
    setOthersRoleSettings(newOthersRoleSettings);
    updateLaunchSettings(
      launchAboutSelf,
      launchAboutOthers,
      newOthersRoleSettings
    );
  };

  const updateLaunchSettings = (
    self: boolean,
    others: boolean,
    otherRoles: Set<string>
  ) => {
    const launchSettings: FormLaunchSetting[] = [];

    // Add settings for self-launch
    if (self && formSettings?.aboutSettings?.aboutEntityFilter) {
      formSettings.aboutSettings.aboutEntityFilter.forEach((roleSlug) => {
        launchSettings.push({
          roleSlug,
          launchAboutSelf: true,
          launchAboutOthers: false,
        });
      });
    }

    // Add settings for others-launch
    if (others) {
      Array.from(otherRoles).forEach((roleSlug) => {
        const existingSetting = launchSettings.find(
          (s) => s.roleSlug === roleSlug
        );
        if (existingSetting) {
          existingSetting.launchAboutOthers = true;
        } else {
          launchSettings.push({
            roleSlug,
            launchAboutSelf: false,
            launchAboutOthers: true,
          });
        }
      });
    }

    // Update the workflow configuration
    updateWorkflow({
      workflowConfiguration: {
        ...workflow.workflowConfiguration,
        settings: {
          ...workflow.workflowConfiguration?.settings,
          launchSettings:
            launchSettings.length > 0 ? launchSettings : undefined,
        },
      },
    });
  };

  return (
    <Group gap="md" align="flex-start">
      <LaunchCheckboxCard
        title="Complete Form About Self"
        description="Any person of the selected Person types can complete this form about themselves."
        checked={launchAboutSelf}
        onChange={handleSelfChange}
      />
      <Stack gap="xl">
        <LaunchCheckboxCard
          title="Complete Form About Someone Else"
          description="The form can be completed by someone else about any of the selected Person types."
          checked={launchAboutOthers}
          onChange={handleOthersChange}
        />
        {launchAboutOthers && (
          <>
            <Text fz="lg" fw={600}>
              Who can complete this form about someone else?
            </Text>
            <UserRoleHierarchicalSelector
              selectedRoleSlugs={Array.from(othersRoleSettings)}
              onChange={handleOthersRoleChange}
            />
          </>
        )}
      </Stack>
    </Group>
  );
};

export default CompletionMethodStep;
