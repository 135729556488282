import {
  DataTypeEnum,
  StepCompletionDTO,
  StepConfig,
  StepTypeEnum,
  SubmitFormTaskDTO,
  TaskProperties,
  WorkflowConfig,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  useFormSubmission,
  useStepNavigation,
} from '@btrway/form-completion-manager';
import { Transition } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import FormTaskFormStepView from '../FormStep/FormStep';
import FormTaskStartStepView from '../StartStep/StartStep';

interface FormSubmissionViewProps {
  workflowConfiguration: WorkflowConfig;
  workflowDefinitionId: number;
  workflowDefinition: WorkflowDefinitionResponse;
  parentTaskListId?: number;
  taskProperties?: TaskProperties;
  onClose: () => void;
  onSubmitSuccess?: () => Promise<void>;
}

const FormSubmissionView: React.FC<FormSubmissionViewProps> = ({
  workflowConfiguration,
  workflowDefinitionId,
  workflowDefinition,
  parentTaskListId,
  taskProperties,
  onClose,
  onSubmitSuccess,
}) => {
  const { currentOrganization, currentPerson } = useAuthenticatedUser();
  const { submitStepData } = useFormSubmission();

  const { findStartStep, getNextStep } = useStepNavigation({
    workflowConfiguration,
  });

  const [currentStep, setCurrentStep] = useState<StepConfig | null>(null);
  const [currentView, setCurrentView] = useState<'start' | 'form'>('start');
  const [prefilledData, setPrefilledData] = useState<any>(null);
  const [allStepData, setAllStepData] = useState<StepCompletionDTO[]>([]);
  const [workflowData, setWorkflowData] =
    useState<Partial<SubmitFormTaskDTO> | null>(null);
  const [transitionMounted, setTransitionMounted] = useState(true);

  useEffect(() => {
    const startStep = findStartStep();
    if (startStep) {
      setCurrentStep(startStep);
    }
  }, [findStartStep]);

  const handleStartStepComplete = async (
    stepData: StepCompletionDTO,
    workgroupId: number,
    aboutEntityId: number,
    aboutEntityType: DataTypeEnum
  ) => {
    try {
      // Store the workflow data for future submissions
      const newWorkflowData: Partial<SubmitFormTaskDTO> = {
        organizationId: currentOrganization.id,
        workflowDefinitionId,
        workgroupId,
        submittedBy: currentPerson.id,
        aboutEntityId,
        aboutEntityType,
      };
      setWorkflowData(newWorkflowData);

      const submitData: SubmitFormTaskDTO = {
        ...newWorkflowData,
        stepData: [stepData],
      } as SubmitFormTaskDTO;

      const response = (await submitStepData(
        submitData,
        true
      )) as SubmitFormTaskDTO;
      setAllStepData([stepData]);

      const nextStepData = response.stepData?.[response.stepData.length - 1];
      if (nextStepData) {
        setPrefilledData(nextStepData.values);
      }

      const nextStep = getNextStep(currentStep!);
      if (nextStep && nextStep.stepTypeCode === StepTypeEnum.formSubmission) {
        setTransitionMounted(false);
        setTimeout(() => {
          setCurrentStep(nextStep);
          setCurrentView('form');
          setTransitionMounted(true);
        }, 300);
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Error submitting initial step data:', error);
    }
  };

  const handleFormStepComplete = async (formStepData: StepCompletionDTO) => {
    try {
      const updatedStepData = [...allStepData, formStepData];

      const submitData: SubmitFormTaskDTO = {
        ...workflowData,
        stepData: updatedStepData,
      } as SubmitFormTaskDTO;

      console.log('handleFormStepComplete, SubmitStepData', submitData);

      const response = (await submitStepData(
        submitData,
        false
      )) as SubmitFormTaskDTO;
      setAllStepData(updatedStepData);

      const nextStepData = response.stepData?.[response.stepData.length - 1];
      if (nextStepData) {
        setPrefilledData(nextStepData.values);
      }

      const nextStep = getNextStep(currentStep!);
      if (nextStep && nextStep.stepTypeCode === StepTypeEnum.formSubmission) {
        setTransitionMounted(false);
        setTimeout(() => {
          setCurrentStep(nextStep);
          setTransitionMounted(true);
        }, 300);
      } else {
        try {
          await onSubmitSuccess?.();
        } finally {
          onClose();
        }
      }
    } catch (error) {
      console.error('Error submitting workflow:', error);
    }
  };

  if (!currentStep) {
    return null;
  }

  return (
    <div
      style={{
        // position: 'fixed',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        paddingTop: 'var(--mantine-header-height, 0px)',
      }}
    >
      <Transition
        mounted={transitionMounted}
        transition="fade"
        duration={300}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={{
              ...styles,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {currentView === 'start' ? (
              <FormTaskStartStepView
                workflowDefinition={workflowDefinition}
                currentStep={currentStep}
                onStartClick={handleStartStepComplete}
                workflowConfiguration={workflowConfiguration}
                onCancel={onClose}
              />
            ) : (
              <FormTaskFormStepView
                workflowDefinition={workflowDefinition}
                currentStep={currentStep}
                prefilledData={prefilledData}
                onSubmit={handleFormStepComplete}
                onCancel={onClose}
                onSaveForLater={onClose}
              />
            )}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default FormSubmissionView;
