import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';
import { useFormCompletion } from '@btrway/form-completion-manager';
import { DisplayMode, fieldRegistry } from '@btrway/form-fields';
import { Text } from '@mantine/core';
import React from 'react';
import FormFieldGroup from '../FormFieldGroup/FormFieldGroup';
import { MultipleFieldRenderer } from '../MultipleFieldRenderer/MultipleFieldRenderer';

interface FormFieldInputProps {
  field: FieldConfig;
  value: any;
  onChange: (value: any) => void;
  readOnly?: boolean; // When true maps to DisplayMode.Display, when false maps to DisplayMode.Input
}

export const FormFieldInput: React.FC<FormFieldInputProps> = ({
  field,
  value,
  onChange,
  readOnly = false,
}) => {
  const isReadOnly =
    readOnly ||
    field.type === FieldTypeEnum.paragraph ||
    field.type === FieldTypeEnum.heading;
  const formContext = useFormCompletion();

  // First check if there's a registered component for this field type
  const RegisteredComponent = fieldRegistry.getComponent(field.type);
  if (RegisteredComponent) {
    return (
      <RegisteredComponent
        field={field}
        value={value}
        onChange={onChange}
        displayMode={isReadOnly ? DisplayMode.Display : DisplayMode.Input}
        formContext={formContext}
      />
    );
  }

  // Fall back to basic implementations for remaining types
  const commonProps = {
    description: field.description,
    required: field.required,
    disabled: readOnly || field.readOnly,
    value: value || '',
  };

  // Render basic field implementations for types without registered components
  switch (field.type) {
    case FieldTypeEnum.group:
      return (
        <FormFieldGroup
          field={field}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      );
    case FieldTypeEnum.multiple:
      return <MultipleFieldRenderer field={field} readOnly={readOnly} />;

    default:
      console.warn(`No implementation found for field type: ${field.type}`);
      return <Text c="red">Field type {field.type} not supported yet</Text>;
  }
};

export default FormFieldInput;
