import {
  WorkgroupRequest,
  WorkgroupResponse,
  useDeleteWorkgroup,
  useGetWorkgroupsByOrganizationId,
  useSaveWorkgroup,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { workgroupHierarchyAtom } from '../atoms/workgroupHierarchyAtom';
import { workgroupsAtom } from '../atoms/workgroupsAtom';
import { useWorkgroupDisplayNames } from './useWorkgroupDisplayNames';

export const useWorkgroups = (organizationId?: number) => {
  const { currentOrganization } = useAuthenticatedUser();
  const effectiveOrganizationId = organizationId ?? currentOrganization.id;

  const [workgroups, setWorkgroups] = useAtom(workgroupsAtom);
  const [hierarchicalWorkgroups] = useAtom(workgroupHierarchyAtom);
  const {
    data: fetchedWorkgroups,
    isLoading,
    error,
  } = useGetWorkgroupsByOrganizationId(effectiveOrganizationId);
  const { getDisplayName } = useWorkgroupDisplayNames();

  //TODO: 5 minute cache with invalidation on save (add/update/delete)

  const { mutateAsync: saveWorkgroupMutation } = useSaveWorkgroup();
  const { mutateAsync: deleteWorkgroupMutation } = useDeleteWorkgroup();

  useEffect(() => {
    if (fetchedWorkgroups) {
      const workgroupsWithDisplayNames = fetchedWorkgroups.map((workgroup) => {
        // Get the display name
        const displayName = getDisplayName(workgroup, fetchedWorkgroups);

        // Create new workgroup object with guaranteed displayName
        return {
          ...workgroup,
          displayName: displayName || workgroup.name, // Fallback to name if displayName is empty
        };
      });

      setWorkgroups(workgroupsWithDisplayNames);
    }
  }, [fetchedWorkgroups, setWorkgroups, getDisplayName]);

  const addWorkgroup = useCallback(
    async (workgroupRequest: WorkgroupRequest) => {
      try {
        const savedWorkgroup = (await saveWorkgroupMutation({
          data: workgroupRequest,
        })) as WorkgroupResponse;
        setWorkgroups((prevWorkgroups) => [...prevWorkgroups, savedWorkgroup]);
        return savedWorkgroup;
      } catch (error) {
        console.error('Error saving workgroup:', error);
        throw error;
      }
    },
    [saveWorkgroupMutation, setWorkgroups]
  );

  const updateWorkgroup = useCallback(
    (updatedWorkgroup: WorkgroupResponse) => {
      setWorkgroups((prevWorkgroups) =>
        prevWorkgroups.map((wg) =>
          wg.id === updatedWorkgroup.id ? updatedWorkgroup : wg
        )
      );
    },
    [setWorkgroups]
  );

  const deleteWorkgroup = useCallback(
    async (workgroupId: number) => {
      try {
        await deleteWorkgroupMutation({ workgroupId: workgroupId });
        setWorkgroups((prevWorkgroups) =>
          prevWorkgroups.filter((wg) => wg.id !== workgroupId)
        );
      } catch (error) {
        console.error('Error deleting workgroup:', error);
        throw error;
      }
    },
    [deleteWorkgroupMutation, setWorkgroups]
  );

  const getWorkgroup = useCallback(
    (workgroupId: number): WorkgroupResponse | undefined => {
      return workgroups.find((wg) => wg.id === workgroupId);
    },
    [workgroups]
  );

  return {
    workgroups,
    hierarchicalWorkgroups,
    isLoading,
    error,
    addWorkgroup,
    updateWorkgroup,
    deleteWorkgroup,
    getWorkgroup,
  };
};
