import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';
import { useFormConfig } from '@btrway/form-configuration-manager';
import { DisplayMode, fieldRegistry } from '@btrway/form-fields';
import { Box, TextInput } from '@mantine/core';
import React from 'react';
import GroupField from '../GroupField/GroupField';
import MultipleField from '../MultipleField/MultipleField';
import ReadOnlyWrapper from '../ReadOnlyWrapper/ReadOnlyWrapper';

interface FieldComponentProps {
  field: FieldConfig;
  readOnly?: boolean;
  onUpdate?: (updates: Partial<FieldConfig>) => void;
}

const FieldComponent: React.FC<FieldComponentProps> = ({
  field,
  readOnly = false,
  onUpdate,
}) => {
  const { selectedFieldKey } = useFormConfig();
  const isSelected = selectedFieldKey === field.fieldKey;

  const displayMode = readOnly
    ? DisplayMode.BuilderReadOnly
    : DisplayMode.Builder;

  // First check if there's a registered component for this field type
  const RegisteredComponent = fieldRegistry.getComponent(field.type);

  const renderContent = () => {
    if (RegisteredComponent) {
      return (
        <RegisteredComponent
          field={field}
          displayMode={displayMode}
          onChange={onUpdate}
        />
      );
    }

    // Fall back to the basic Mantine components for remaining types
    const commonProps = {
      required: field.required,
      description: field.description,
      disabled: readOnly,
    };

    switch (field.type) {
      case FieldTypeEnum.organization:
      case FieldTypeEnum.workgroup:
      case FieldTypeEnum.facility:
        return <TextInput {...commonProps} />;

      default:
        return null;
    }
  };

  // Special handling for fields that don't need ReadOnlyWrapper
  if (field.type === FieldTypeEnum.group) {
    return (
      <Box mt={8}>
        <GroupField field={field} readOnly={readOnly} />
      </Box>
    );
  }

  if (field.type === FieldTypeEnum.multiple) {
    return (
      <Box mt={8}>
        <MultipleField field={field} readOnly={readOnly} />
      </Box>
    );
  }

  if (
    field.type === FieldTypeEnum.heading ||
    field.type === FieldTypeEnum.paragraph
  ) {
    return <Box>{renderContent()}</Box>;
  }

  // For all other field types, wrap with ReadOnlyWrapper
  return (
    <Box mt={8}>
      <ReadOnlyWrapper displayMode={displayMode} isSelected={isSelected}>
        {renderContent()}
      </ReadOnlyWrapper>
    </Box>
  );
};

export default FieldComponent;
