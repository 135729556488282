import { ActionIcon, Tooltip } from '@mantine/core';
import { IconAsterisk } from '@tabler/icons-react';

interface RequiredToggleProps {
  required?: boolean;
  onChange: (required: boolean) => void;
}

const RequiredToggle = ({
  required = false,
  onChange,
}: RequiredToggleProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(!required);
  };

  return (
    <Tooltip label={required ? 'Make field optional' : 'Make field required'}>
      <ActionIcon
        variant="subtle"
        size="sm"
        color={required ? 'red' : 'gray'}
        onClick={handleClick}
      >
        <IconAsterisk size={12} strokeWidth={3} />
      </ActionIcon>
    </Tooltip>
  );
};

export default RequiredToggle;
