import { FieldConfig } from '@btrway/api-workflow';
import { useCallback } from 'react';
import { useFormCompletion } from '../providers/FormCompletionProvider';
import { DependencyNode } from '../types/fieldDependency';
import { compareValues } from '../utils/fieldConditionUtils';

export const useFieldVisibility = () => {
  const { formValues, dependencyGraph } = useFormCompletion();

  const getFieldValue = useCallback(
    (fieldKey: string, recordIndex?: number) => {
      // For fields within a Multiple field record
      if (recordIndex !== undefined) {
        // Find the parent Multiple field key
        const multipleFieldKey = Object.keys(formValues).find(
          (key) =>
            Array.isArray(formValues[key]) &&
            formValues[key][recordIndex] &&
            fieldKey in formValues[key][recordIndex]
        );

        if (multipleFieldKey) {
          return formValues[multipleFieldKey][recordIndex][fieldKey];
        }
      }

      // For global fields
      return formValues[fieldKey];
    },
    [formValues]
  );

  const evaluateFieldConditions = useCallback(
    (field: FieldConfig, recordIndex?: number): boolean => {
      // If no trigger sets, field is always visible
      if (!field.triggerSets?.length) {
        return true;
      }

      // Check if any trigger set's conditions are met
      return field.triggerSets.some((triggerSet) => {
        // Skip invalid trigger sets
        if (!triggerSet?.triggers?.length) {
          return false;
        }

        // All triggers in a set must be true
        const allTriggersMatch = triggerSet.triggers.every((trigger) => {
          // Skip invalid triggers
          if (!trigger?.property?.propertyKey || !trigger.ruleOperator) {
            return false;
          }

          const propertyKey = trigger.property.propertyKey;
          const fieldValue = getFieldValue(propertyKey, recordIndex);

          // Field is hidden by default if dependent field has no value
          if (
            fieldValue === undefined ||
            fieldValue === null ||
            fieldValue === ''
          ) {
            return false;
          }

          const ruleValue = trigger.ruleValues?.[0]?.value;

          // Handle boolean values
          if (trigger.property.dataType === 'boolean') {
            const matches = fieldValue === ruleValue;

            return matches;
          }

          // For all other types, use compareValues
          const matches = compareValues(
            fieldValue,
            ruleValue,
            trigger.property.dataType,
            trigger.ruleOperator
          );

          return matches;
        });

        return allTriggersMatch;
      });
    },
    [getFieldValue]
  );

  const isFieldVisible = useCallback(
    (fieldKey: string, recordIndex?: number): boolean => {
      if (!dependencyGraph?.findNode) {
        return false;
      }

      // Find the field node based on context (record vs global)
      let node: DependencyNode | undefined;

      // If we have a recordIndex, first check if this is a field within that record
      if (recordIndex !== undefined) {
        const parts = fieldKey.split('.');
        const multipleKey = parts[0];
        const multipleNode = dependencyGraph.findNode(
          dependencyGraph.dependencyGraph,
          multipleKey
        );

        if (multipleNode) {
          node = dependencyGraph.findNodeInRecord(
            multipleNode,
            fieldKey,
            recordIndex
          );
        }
      }

      // If we haven't found the node in a record, try to find it globally
      if (!node) {
        node = dependencyGraph.findNode(
          dependencyGraph.dependencyGraph,
          fieldKey
        );
      }

      if (!node?.field) {
        return false;
      }

      // Check dependency chain visibility
      const isDependencyChainVisible = (
        currentNode: DependencyNode
      ): boolean => {
        // Check all dependencies of the current node
        for (const dependency of currentNode.dependencies) {
          let dependencyNode: DependencyNode | undefined;

          // Get the dependency node based on scope
          if (
            dependency.scope === 'record' &&
            currentNode.recordIndex !== undefined
          ) {
            // Find the parent Multiple field node
            const parentMultipleNode = currentNode.parent?.parent;
            if (!parentMultipleNode) {
              return false;
            }

            dependencyNode = dependencyGraph.findNodeInRecord(
              parentMultipleNode,
              dependency.fieldKey,
              currentNode.recordIndex
            );
          } else {
            dependencyNode = dependencyGraph.findNode(
              dependencyGraph.dependencyGraph,
              dependency.fieldKey
            );
          }

          if (!dependencyNode?.field) {
            return false;
          }

          // First check if the dependency itself is visible
          const isDependencyVisible = evaluateFieldConditions(
            dependencyNode.field,
            dependency.scope === 'record' ? currentNode.recordIndex : undefined
          );

          if (!isDependencyVisible) {
            return false;
          }

          // Then recursively check the dependency's own dependencies
          if (!isDependencyChainVisible(dependencyNode)) {
            return false;
          }
        }

        return true;
      };

      // First check if all dependencies in the chain are visible
      const dependencyChainVisible = isDependencyChainVisible(node);

      if (!dependencyChainVisible) {
        return false;
      }

      // Check parent visibility recursively
      const isParentVisible = (currNode: DependencyNode): boolean => {
        if (!currNode.parent || !currNode.parent.field) {
          return true;
        }

        const parentVisible = evaluateFieldConditions(
          currNode.parent.field,
          currNode.parent.recordIndex
        );

        if (!parentVisible) {
          return false;
        }

        return isParentVisible(currNode.parent);
      };

      const parentChainVisible = isParentVisible(node);
      const selfVisible = evaluateFieldConditions(node.field, recordIndex);

      const finalVisibility = parentChainVisible && selfVisible;

      return finalVisibility;
    },
    [dependencyGraph, evaluateFieldConditions]
  );

  return {
    isFieldVisible,
    getVisibleFields: useCallback(
      (fields: FieldConfig[]): FieldConfig[] => {
        const visibleFields = fields.filter((field) =>
          isFieldVisible(field.fieldKey)
        );

        return visibleFields;
      },
      [isFieldVisible]
    ),
  };
};
