import {
  FieldConfig,
  TriggerConfig,
  TriggerSetConfig,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { Button, Card, Group, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { TriggerConditionEditor } from '../TriggerConditionEditor/TriggerConditionEditor';

interface TriggerSetEditorProps {
  triggerSet: TriggerSetConfig;
  availableFields: FieldConfig[];
  onSave: (triggerSet: TriggerSetConfig) => void;
  onCancel: () => void;
}

export const TriggerSetEditor: React.FC<TriggerSetEditorProps> = ({
  triggerSet,
  availableFields,
  onSave,
  onCancel,
}) => {
  const [workingTriggerSet, setWorkingTriggerSet] =
    useState<TriggerSetConfig>(triggerSet);

  const handleTriggerUpdate = (
    triggerIndex: number,
    updatedTrigger: TriggerConfig
  ) => {
    const newTriggers = [...(workingTriggerSet.triggers || [])];
    newTriggers[triggerIndex] = updatedTrigger;
    setWorkingTriggerSet({ ...workingTriggerSet, triggers: newTriggers });
  };

  const handleTriggerRemove = (triggerIndex: number) => {
    const newTriggers = workingTriggerSet.triggers?.filter(
      (_, index) => index !== triggerIndex
    );
    setWorkingTriggerSet({ ...workingTriggerSet, triggers: newTriggers });
  };

  const addNewTrigger = () => {
    const newTrigger: TriggerConfig = {
      triggerKey: uuid(),
      property: undefined,
      ruleOperator: undefined,
      ruleValues: [],
    };

    setWorkingTriggerSet({
      ...workingTriggerSet,
      triggers: [...(workingTriggerSet.triggers || []), newTrigger],
    });
  };

  const handleSave = () => {
    onSave(workingTriggerSet);
  };

  return (
    <Card withBorder shadow="xs" padding="sm" radius="md">
      <Stack>
        {(workingTriggerSet.triggers || []).map((trigger, triggerIndex) => (
          <TriggerConditionEditor
            key={trigger.triggerKey}
            trigger={trigger}
            availableFields={availableFields}
            onUpdate={(updatedTrigger) =>
              handleTriggerUpdate(triggerIndex, updatedTrigger)
            }
            onRemove={() => handleTriggerRemove(triggerIndex)}
            isFirst={triggerIndex === 0}
          />
        ))}

        {workingTriggerSet.triggers &&
          workingTriggerSet.triggers?.length > 0 && (
            <Group>
              <Button variant="light" size="sm" onClick={addNewTrigger}>
                + AND
              </Button>
            </Group>
          )}

        <Group justify="flex-end" gap="xs">
          <Button size="xs" variant="subtle" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="xs" onClick={handleSave}>
            Save
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

export default TriggerSetEditor;
