import { Group, List, Progress, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';
import React from 'react';
import { CardWrapper } from '../CardWrapper/CardWrapper';

interface TaskStatusCardProps {
  title: string;
  backgroundColor: string;
  icon: React.FC<IconProps>;
  details?: string[];
  progress?: number;
  onClick?: () => void;
}

export const TaskStatusCard: React.FC<TaskStatusCardProps> = ({
  title,
  backgroundColor,
  icon: Icon,
  details = [],
  progress = 0,
  onClick,
}) => {
  return (
    <CardWrapper bg={backgroundColor} onClick={onClick}>
      <Stack justify="space-between" h="100%">
        <Group align="flex-start" justify="space-between" wrap="nowrap">
          <Stack gap="xs">
            <Text fz="lg" fw={700}>
              {title}
            </Text>
            <List size="sm" spacing="xs">
              {details.slice(0, 2).map((detail, index) => (
                <List.Item key={index}>{detail}</List.Item>
              ))}
            </List>
          </Stack>
          <ThemeIcon radius="xl" size="xl" color="gray.8">
            <Icon style={{ width: '60%', height: '60%' }} />
          </ThemeIcon>
        </Group>

        <Stack gap={0}>
          <Text fz={28} fw={700} ta="right">
            {Math.round(progress)}%
          </Text>
          <Progress value={progress} size="lg" color="dark" radius="md" />
        </Stack>
      </Stack>
    </CardWrapper>
  );
};
