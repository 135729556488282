import { Box, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { useProcessBuilder } from '../../providers/ProcessBuilderProvider';

export const FormNameStep: React.FC = () => {
  const { initializeWorkflow, workflow, nextStep } = useProcessBuilder();
  const [name, setName] = useState(workflow.name || '');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && name.trim()) {
      initializeWorkflow(name.trim());
      nextStep();
    }
  };

  const handleBlur = () => {
    if (name.trim()) {
      initializeWorkflow(name.trim());
    }
  };

  return (
    <Box
      style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
    >
      <TextInput
        autoFocus
        data-autofocus
        size="xl"
        placeholder="Enter form name"
        value={name}
        onChange={handleNameChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        style={{ maxWidth: '32rem', width: '100%' }}
      />
    </Box>
  );
};

export default FormNameStep;
