import { StepConfig } from '@btrway/api-workflow';
import { Stack, Title } from '@mantine/core';
import React from 'react';
import { taskComponentRegistry } from '../../../config/componentRegistry';
import { StepConfigProps } from '../../../types/editors';
import { TaskAssignmentConfiguration } from '../TaskAssignmentConfiguration/TaskAssignmentConfiguration';

export const AssignTaskConfiguration: React.FC<StepConfigProps> = ({
  config,
  onConfigChange,
  ...otherProps
}) => {
  const handleTaskConfigChange = (updatedTaskConfig: any) => {
    onConfigChange?.({
      ...config,
      taskConfig: updatedTaskConfig,
    });
  };

  const handleAssignmentConfigChange = (updatedConfig: StepConfig) => {
    onConfigChange?.({
      ...updatedConfig,
      taskConfig: config.taskConfig, // Preserve the task config
    });
  };

  // Get the appropriate task component based on the taskConfig's type
  const TaskComponent = config.taskConfig
    ? taskComponentRegistry[config.taskConfig.taskType]?.component
    : null;

  return (
    <Stack gap="xl">
      <div>
        <Title order={5} mb="md">
          Task Assignment!
        </Title>
        <TaskAssignmentConfiguration
          config={config}
          onConfigChange={handleAssignmentConfigChange}
          {...otherProps}
        />
      </div>

      {TaskComponent && config.taskConfig && (
        <div>
          <Title order={5} mb="md">
            Task Configuration
          </Title>
          <TaskComponent
            config={config.taskConfig}
            onConfigChange={handleTaskConfigChange}
            {...otherProps}
          />
        </div>
      )}
    </Stack>
  );
};

export default AssignTaskConfiguration;
