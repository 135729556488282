import { CountryConfig, CountryConfigurations } from '../types/types';

// Default configuration for any country not specifically configured
export const defaultCountryConfig: CountryConfig = {
  showPostalCode: true,
  showStateProvince: false,
  postalCodeRequired: false,
  postalCodeFormat: /^.+$/, // Any non-empty string
  stateProvinceRequired: false,
  labels: {
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'City',
    postalCode: 'Postal Code',
    stateProvince: 'State/Province',
  },
};

export const countryConfigurations: CountryConfigurations = {
  US: {
    ...defaultCountryConfig,
    sortOrder: 1, // US appears first
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{5}(-\d{4})?$/, // 12345 or 12345-6789
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      postalCode: 'ZIP Code',
      stateProvince: 'State',
    },
  },
  CA: {
    ...defaultCountryConfig,
    sortOrder: 2, // Canada appears second
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, // A1A 1A1 or A1A-1A1
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      stateProvince: 'Province',
    },
  },
  // Rest of the configurations remain the same, without sortOrder
  GB: {
    ...defaultCountryConfig,
    postalCodeRequired: true,
    postalCodeFormat: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i, // UK postal code format
  },
  JP: {
    ...defaultCountryConfig,
    showStateProvince: true,
    postalCodeRequired: true,
    stateProvinceRequired: true,
    postalCodeFormat: /^\d{3}-\d{4}$/, // 123-4567
    labels: {
      ...defaultCountryConfig.labels,
      stateProvince: 'Prefecture',
    },
  },
  AU: {
    ...defaultCountryConfig,
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{4}$/, // 4 digits
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      stateProvince: 'State/Territory',
    },
  },
  DE: {
    ...defaultCountryConfig,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{5}$/, // 5 digits
  },
  FR: {
    ...defaultCountryConfig,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{5}$/, // 5 digits
  },
  IN: {
    ...defaultCountryConfig,
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{6}$/, // 6 digits
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      postalCode: 'PIN Code',
      stateProvince: 'State',
    },
  },
  BR: {
    ...defaultCountryConfig,
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{5}-\d{3}$/, // 12345-678
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      stateProvince: 'State',
      postalCode: 'CEP',
    },
  },
  CN: {
    ...defaultCountryConfig,
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{6}$/, // 6 digits
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      stateProvince: 'Province',
    },
  },
  MX: {
    ...defaultCountryConfig,
    showStateProvince: true,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{5}$/, // 5-digit Mexican postal code
    stateProvinceRequired: true,
    labels: {
      ...defaultCountryConfig.labels,
      stateProvince: 'State',
    },
  },
  IT: {
    ...defaultCountryConfig,
    showStateProvince: false,
    postalCodeRequired: true,
    postalCodeFormat: /^\d{5}$/, // 5-digit Italian postal code
    stateProvinceRequired: false,
    labels: {
      ...defaultCountryConfig.labels,
    },
  },
};
