import {
  FormConfig,
  StepTypeEnum,
  TaskAssignmentConfig,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { uuid } from '@btrway/utils';
import {
  CommonWorkflow,
  useWorkflowCommon,
  useWorkflowService,
} from '@btrway/workflow-common-provider';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import React, { createContext, useContext, useState } from 'react';
import {
  useApprovalsSteps,
  UseApprovalsStepsReturn,
} from '../hooks/useApprovalSteps';
import { useWizardSteps } from '../hooks/useWizardSteps';
import { useWorkflowInitialization } from '../hooks/useWorkflowInitialization';
import { WizardStep } from '../types/wizardStep';

export interface ProcessBuilderContextType {
  // Navigation state
  activeStep: number;
  maxReachedStep: number;

  // Workflow state
  workflow: CommonWorkflow;

  // Navigation methods
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (stepIndex: number) => void;

  // Workflow methods
  updateWorkflow: (updates: Partial<CommonWorkflow>) => void;
  formConfig: FormConfig | null;
  updateFormConfig: (config: FormConfig) => void;

  // Initialization method
  initializeWorkflow: (name: string) => void;
  isFormGenerating: boolean;
  formGenerationError: Error | null;
  setFormGenerationStatus: (isGenerating: boolean) => void;
  setFormGenerationError: (error: Error | null) => void;

  // Approvals
  approvalSteps: UseApprovalsStepsReturn;

  // Status
  isLoading: boolean;
  error: Error | null;

  // Computed properties
  isLastStep: boolean;
  currentStep: WizardStep;
  steps: WizardStep[];

  // Save methods
  saveWorkflow: () => Promise<void>;
}

const ProcessBuilderContext = createContext<
  ProcessBuilderContextType | undefined
>(undefined);

interface ProcessBuilderProviderProps {
  children: React.ReactNode;
  workflowType?: WorkflowTypeEnum;
  initialWorkflow?: Partial<CommonWorkflow>;
}

export const ProcessBuilderProvider: React.FC<ProcessBuilderProviderProps> = ({
  children,
  workflowType = WorkflowTypeEnum.form,
  initialWorkflow = {},
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { sourceType } = useWorkflowCommon();
  const workflowService = useWorkflowService();
  const { processActions, isLoading } = useWorkflowConfig();

  // Core workflow state
  const initializedWorkflow = {
    sourceType,
    workflowKey: sourceType === 'definition' ? uuid() : undefined,
    templateKey: sourceType === 'template' ? uuid() : undefined,
    workflowType,
    enabled: true,
    published: false,
    ...initialWorkflow,
  };
  const [workflow, setWorkflow] = useState<CommonWorkflow>(initializedWorkflow);
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);

  const [error, setError] = useState<Error | null>(null);
  const [isFormGenerating, setIsFormGenerating] = useState(false);
  const [formGenerationError, setFormGenerationError] = useState<Error | null>(
    null
  );

  // Use the wizard steps hook
  const {
    activeStep,
    maxReachedStep,
    nextStep,
    prevStep,
    goToStep,
    isLastStep,
    currentStep,
    steps,
  } = useWizardSteps();

  // Workflow update method
  const updateWorkflow = (updates: Partial<CommonWorkflow>) => {
    setWorkflow((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  // Use the workflow initialization hook
  const { initializeWorkflow } = useWorkflowInitialization(updateWorkflow);

  const updateFormConfig = (config: FormConfig) => {
    setFormConfig(config);
  };

  const approvalStepsHandler = useApprovalsSteps();

  const buildWorkflow = (
    baseWorkflow: CommonWorkflow,
    formConfig: FormConfig | null,
    approvalConfigs: Array<{
      id: string;
      title: string;
      taskAssignmentConfig: TaskAssignmentConfig;
    }>
  ): CommonWorkflow => {
    // Get existing flow start and form submission steps
    const existingSteps = baseWorkflow.workflowConfiguration?.steps || [];
    const startStep = existingSteps.find(
      (step) => step.stepTypeCode === StepTypeEnum.flowStart
    );
    const formStep = existingSteps.find(
      (step) => step.stepTypeCode === StepTypeEnum.formSubmission
    );

    if (!startStep || !formStep) {
      throw new Error('Workflow not properly initialized');
    }

    // Update form step with AI-generated config if it exists
    const updatedFormStep = formConfig ? { ...formStep, formConfig } : formStep;

    // Create approval steps
    const approvalSteps = approvalConfigs
      .filter((config) => config.taskAssignmentConfig && config.title.trim())
      .map((config) => ({
        stepKey: config.id,
        title: config.title,
        stepTypeCode: StepTypeEnum.assignTask,
        inputProperties: {
          taskAssignmentConfig: config.taskAssignmentConfig,
        },
        taskConfig: {
          taskKey: uuid(),
          taskType: TaskTypeEnum.completeWorkflowStep,
          metadata: {
            title: config.title,
          },
        },
      }));

    // Combine all steps
    const allSteps = [startStep, updatedFormStep, ...approvalSteps];

    // Create transitions
    const transitions = allSteps.slice(0, -1).map((step, index) => ({
      transitionKey: uuid(),
      sourceStepKey: step.stepKey,
      targetStepKey: allSteps[index + 1].stepKey,
      index: 0,
    }));

    // Return complete workflow while preserving settings
    return {
      ...baseWorkflow,
      workflowConfiguration: {
        ...baseWorkflow.workflowConfiguration,
        steps: allSteps,
        transitions,
      },
    };
  };

  const saveWorkflow = async () => {
    try {
      setError(null);
      const validSteps = approvalStepsHandler.getValidSteps();

      const workflowToSave = buildWorkflow(
        {
          ...workflow,
          ...(workflow.sourceType === 'definition' && currentOrganization?.id
            ? { organizationId: currentOrganization.id }
            : {}),
        },
        formConfig,
        validSteps
      );

      await workflowService.updateWorkflow(workflowToSave);
      setWorkflow(workflowToSave);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error('Failed to save workflow')
      );
      throw err;
    }
  };

  const value = {
    // Navigation state
    activeStep,
    maxReachedStep,

    // Workflow state
    workflow,

    // Navigation methods
    nextStep,
    prevStep,
    goToStep,

    // Workflow methods
    updateWorkflow,
    formConfig,
    updateFormConfig,

    // Form generation status
    isFormGenerating,
    formGenerationError,
    setFormGenerationStatus: setIsFormGenerating,
    setFormGenerationError,

    // Status
    isLoading,
    error,

    // Initialization method
    initializeWorkflow,

    // Approvals
    approvalSteps: approvalStepsHandler,

    // Computed properties
    isLastStep,
    currentStep,
    steps,

    // Save method
    saveWorkflow,
  };

  return (
    <ProcessBuilderContext.Provider value={value}>
      {children}
    </ProcessBuilderContext.Provider>
  );
};

export const useProcessBuilder = () => {
  const context = useContext(ProcessBuilderContext);
  if (!context) {
    throw new Error(
      'useProcessBuilder must be used within ProcessBuilderProvider'
    );
  }
  return context;
};
