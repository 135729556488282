import { PropertyReference } from '@btrway/api-workflow';
import { Stack, Text } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import React from 'react';
import { RenderNodeProps } from '../../types/types';
import {
  getDataTypeColor,
  isPropertyReferenceMatchingNodeValue,
} from '../../utils/utils';

interface TreeNodeComponentProps extends RenderNodeProps {
  onSelect?: (property: PropertyReference | undefined) => void;
  selectedProperty?: PropertyReference;
  tree: any; // Replace 'any' with the correct Mantine tree type if available
}

export const TreeNode: React.FC<TreeNodeComponentProps> = ({
  node,
  hasChildren,
  expanded,
  level,
  onSelect,
  selectedProperty,
  tree,
}) => {
  const isGroup = level === 1;
  const isSelected =
    selectedProperty &&
    !isGroup &&
    isPropertyReferenceMatchingNodeValue(selectedProperty, node.value);

  // Rest of the component remains the same
  const nodeStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    marginBottom: '4px',
    paddingLeft: `${(level - 1) * 20 + 8}px`,
    cursor: isGroup ? 'default' : 'pointer',
    borderRadius: 'var(--mantine-radius-sm)',
    transition: 'background-color 0.2s ease',
    backgroundColor: isSelected ? 'var(--mantine-color-blue-0)' : 'transparent',
    borderColor: isSelected ? 'var(--mantine-color-blue-6)' : undefined,
  };

  const chevronStyle: React.CSSProperties = {
    width: '18px',
    height: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px',
  };

  const handleClick = () => {
    if (!isGroup && node.property && onSelect) {
      console.log('node.property', node.property);
      isSelected ? onSelect(undefined) : onSelect(node.property);
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isGroup) {
      e.currentTarget.style.backgroundColor = 'var(--mantine-color-gray-1)';
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isGroup) {
      e.currentTarget.style.backgroundColor = isSelected
        ? 'var(--mantine-color-blue-0)'
        : 'transparent';
    }
  };

  const handleChevronClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (hasChildren) {
      e.stopPropagation();
      expanded ? tree.collapse(node.value) : tree.expand(node.value);
    }
  };

  return (
    <div
      style={nodeStyle}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span style={chevronStyle} onClick={handleChevronClick}>
        {hasChildren ? (
          expanded ? (
            <IconChevronDown size={18} />
          ) : (
            <IconChevronRight size={18} />
          )
        ) : null}
      </span>
      <Stack gap={0}>
        <Text fz="sm">{node.label}</Text>
        {/* <Text fz="xs" c="red">
          {node.value}
        </Text> */}
      </Stack>
      {!isGroup && node.property && (
        <span
          style={{
            marginLeft: '8px',
            padding: '2px 6px',
            borderRadius: 'var(--mantine-radius-sm)',
            backgroundColor: `var(--mantine-color-${getDataTypeColor(
              node.property.dataType
            )}-1)`,
            color: `var(--mantine-color-${getDataTypeColor(
              node.property.dataType
            )}-7)`,
            fontSize: 'var(--mantine-font-size-xs)',
          }}
        >
          {node.property.dataType}
        </span>
      )}
    </div>
  );
};
