import {
  DataTypeEnum,
  GlobalSearchResponse,
  useSearchGlobal,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { filterTopResults } from '../utils/similarity';

export interface UseGlobalSearchOptions {
  debounceMs?: number;
  entityTypes?: DataTypeEnum[];
  filterByWorkgroupIds?: number[];
  filterByUserRoleIds?: number[];
  includeChildWorkgroups?: boolean;
  includeChildUserRoles?: boolean;
  initialResults?: GlobalSearchResponse[];
  showInitialResultsOnEmpty?: boolean;
  minimumScore?: number;
  limit?: number;
  onlyTopResults?: boolean;
}

export function useGlobalSearch({
  debounceMs = 300,
  entityTypes,
  filterByWorkgroupIds,
  filterByUserRoleIds,
  includeChildWorkgroups,
  includeChildUserRoles,
  initialResults = [],
  showInitialResultsOnEmpty = false,
  minimumScore,
  limit,
  onlyTopResults = false,
}: UseGlobalSearchOptions = {}) {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, debounceMs);
  const [searchResults, setSearchResults] =
    useState<GlobalSearchResponse[]>(initialResults);
  const { currentOrganization, rootWorkgroupId } = useAuthenticatedUser();
  const { mutate: search } = useSearchGlobal();
  const lastRequestRef = useRef<string>('');

  useEffect(() => {
    if (!debouncedSearchValue && !lastRequestRef.current) {
      return;
    }

    if (!debouncedSearchValue || !currentOrganization) {
      if (debouncedSearchValue === '' && showInitialResultsOnEmpty) {
        setSearchResults(initialResults);
      } else {
        setSearchResults([]);
      }
      return;
    }

    const searchRequest = {
      organizationId: currentOrganization.id,
      searchValue: debouncedSearchValue,
      filterByWorkgroupIds: filterByWorkgroupIds ?? [rootWorkgroupId],
      ...(filterByUserRoleIds && { filterByUserRoleIds }),
      ...(entityTypes && { filterByEntityTypes: entityTypes }),
      ...(includeChildWorkgroups !== undefined && { includeChildWorkgroups }),
      ...(includeChildUserRoles !== undefined && { includeChildUserRoles }),
      ...(minimumScore !== undefined && { minimumScore }),
      ...(limit !== undefined && { limit }),
    };

    const requestSignature = JSON.stringify(searchRequest);

    if (requestSignature === lastRequestRef.current) {
      return;
    }

    lastRequestRef.current = requestSignature;

    search(
      { data: searchRequest },
      {
        onSuccess: (data) => {
          const results = data as GlobalSearchResponse[];
          const finalResults = onlyTopResults
            ? filterTopResults(results)
            : results;
          setSearchResults(finalResults);
        },
        onError: (error) => {
          console.error('Search failed:', error);
          lastRequestRef.current = '';
        },
      }
    );
  }, [
    debouncedSearchValue,
    currentOrganization,
    search,
    entityTypes,
    filterByWorkgroupIds,
    filterByUserRoleIds,
    includeChildWorkgroups,
    includeChildUserRoles,
    showInitialResultsOnEmpty,
    initialResults,
    rootWorkgroupId,
    minimumScore,
    limit,
    onlyTopResults,
  ]);

  return {
    searchValue,
    setSearchValue,
    searchResults,
    setSearchResults,
  };
}
