import {
  FieldConfig,
  MultipleFieldProperties,
  MultipleFieldRenderType,
} from '@btrway/api-workflow';
import {
  Chip,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React from 'react';

interface MultipleFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<MultipleFieldProperties>) => void;
}

const defaultMultipleFieldProperties: MultipleFieldProperties = {
  itemDescription: '',
  minimumItems: 0,
  maximumItems: 10,
  renderType: MultipleFieldRenderType.form,
};

export const MultipleFieldSettings: React.FC<MultipleFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const multipleProps = {
    ...defaultMultipleFieldProperties,
    ...(field.fieldProperties as MultipleFieldProperties),
  };

  const handleChange = (key: keyof MultipleFieldProperties, value: any) => {
    onChange({
      ...multipleProps,
      [key]: value,
    });
  };

  return (
    <Stack gap="md">
      <Text fz="sm" fw={600}>
        Layout
      </Text>
      <Group wrap="nowrap" gap="xs">
        <Chip.Group
          value={multipleProps.renderType}
          onChange={(value) => handleChange('renderType', value)}
        >
          <Group gap="xs">
            <Chip value={MultipleFieldRenderType.form}>Form</Chip>
            <Chip value={MultipleFieldRenderType.grid}>Grid</Chip>
          </Group>
        </Chip.Group>
      </Group>

      <TextInput
        label="Item Description"
        value={multipleProps.itemDescription}
        onChange={(event) =>
          handleChange('itemDescription', event.currentTarget.value)
        }
        style={{ flex: 1 }}
      />

      <Group gap="xs" wrap="nowrap">
        <NumberInput
          label="Min Items"
          value={multipleProps.minimumItems}
          min={0}
          step={1}
          onChange={(value) => handleChange('minimumItems', value)}
        />
        <NumberInput
          label="Max Items"
          value={multipleProps.maximumItems}
          min={multipleProps.minimumItems}
          step={1}
          onChange={(value) => handleChange('maximumItems', value)}
        />
      </Group>
    </Stack>
  );
};

export default MultipleFieldSettings;
