import { useEffect, useState } from 'react';
import { FRACTIONS, INCH_TO_CM } from '../types/constants';
import { HeightData, MeasurementSystem } from '../types/types';

interface UseHeightProps {
  value?: HeightData;
  system: MeasurementSystem;
  onChange?: (data: HeightData) => void;
  allowFractionalInches: boolean;
  decimalPlaces: number;
  readOnly: boolean;
}

export const useHeight = ({
  value,
  system,
  onChange,
  allowFractionalInches,
  decimalPlaces,
  readOnly,
}: UseHeightProps) => {
  const [feet, setFeet] = useState<number | undefined>(value?.imperial.feet);
  const [inches, setInches] = useState<number | undefined>(
    value?.imperial.inches
  );
  const [fraction, setFraction] = useState<string>(
    (value?.imperial.fraction ?? 0).toString()
  );
  const [centimeters, setCentimeters] = useState<number | undefined>(
    value?.metric.centimeters
  );

  useEffect(() => {
    if (value) {
      setFeet(value.imperial.feet);
      setInches(value.imperial.inches);
      setFraction(value.imperial.fraction.toString());
      setCentimeters(value.metric.centimeters);
    }
  }, [value]);

  const calculateImperialValues = (
    newFeet: number | undefined,
    newInches: number | undefined,
    newFraction: string
  ) => {
    if (newFeet !== undefined || newInches !== undefined) {
      const feetValue = newFeet ?? 0;
      const inchesValue = newInches ?? 0;
      const totalInches =
        feetValue * 12 + inchesValue + parseFloat(newFraction);
      const calculatedCm = +(totalInches * INCH_TO_CM).toFixed(decimalPlaces);

      setCentimeters(calculatedCm);

      if (onChange) {
        const newData: HeightData = {
          system,
          imperial: {
            feet: feetValue,
            inches: inchesValue,
            fraction: parseFloat(newFraction),
            totalInches,
          },
          metric: {
            centimeters: calculatedCm,
          },
        };
        onChange(newData);
      }
    }
  };

  const calculateMetricValues = (newCentimeters: number | undefined) => {
    if (newCentimeters === undefined) {
      if (onChange) {
        onChange({
          system,
          imperial: { feet: 0, inches: 0, fraction: 0, totalInches: 0 },
          metric: { centimeters: 0 },
        });
      }
      return;
    }

    const totalInches = newCentimeters / INCH_TO_CM;
    const calculatedFeet = Math.floor(totalInches / 12);
    const remainingInches = totalInches % 12;
    const wholeInches = Math.floor(remainingInches);
    const fractionalPart = allowFractionalInches
      ? remainingInches - wholeInches
      : 0;

    const closestFraction = allowFractionalInches
      ? FRACTIONS.reduce((prev, curr) =>
          Math.abs(parseFloat(curr.value) - fractionalPart) <
          Math.abs(parseFloat(prev.value) - fractionalPart)
            ? curr
            : prev
        )
      : FRACTIONS[0];

    setFeet(calculatedFeet);
    setInches(wholeInches);
    setFraction(closestFraction.value);

    if (onChange) {
      const newData: HeightData = {
        system,
        imperial: {
          feet: calculatedFeet,
          inches: wholeInches,
          fraction: parseFloat(closestFraction.value),
          totalInches,
        },
        metric: {
          centimeters: newCentimeters,
        },
      };
      onChange(newData);
    }
  };

  const parseNumberInput = (value: number | string): number | undefined => {
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? undefined : parsed;
    }
    return value;
  };

  const handleFeetChange = (value: number | string) => {
    if (readOnly) return;
    const newValue = parseNumberInput(value);
    setFeet(newValue);
    calculateImperialValues(newValue, inches, fraction);
  };

  const handleInchesChange = (value: number | string) => {
    if (readOnly) return;

    const numVal = typeof value === 'string' ? parseInt(value) : value;

    if (numVal !== undefined && numVal >= 0) {
      if (numVal >= 12) {
        const adjustedFeet = Math.floor(numVal / 12);
        const adjustedInches = numVal % 12;
        const newFeet = (feet || 0) + adjustedFeet;
        setFeet(newFeet);
        setInches(adjustedInches);
        calculateImperialValues(newFeet, adjustedInches, fraction);
      } else {
        setInches(numVal);
        calculateImperialValues(feet, numVal, fraction);
      }
    } else {
      setInches(undefined);
      calculateImperialValues(feet, undefined, fraction);
    }
  };

  const handleFractionChange = (value: string | null) => {
    if (readOnly) return;
    const newFraction = value ?? '0';
    setFraction(newFraction);
    calculateImperialValues(feet, inches, newFraction);
  };

  const handleCentimetersChange = (value: number | string) => {
    if (readOnly) return;
    const newValue = parseNumberInput(value);
    setCentimeters(newValue);
    calculateMetricValues(newValue);
  };

  return {
    feet,
    inches,
    fraction,
    centimeters,
    handleFeetChange,
    handleInchesChange,
    handleFractionChange,
    handleCentimetersChange,
  };
};
