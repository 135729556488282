import { TaskConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { Center, Stack, Text } from '@mantine/core';
import React from 'react';
import TaskCard from '../TaskCard/TaskCard';

interface TaskGroupProps {
  label: string;
  tasks: TaskConfig[];
  workflow: CommonWorkflow;
  onTaskSelected: (taskKey: string) => void;
  onTaskDeleted: (taskKey: string) => void;
}

const TaskGroup: React.FC<TaskGroupProps> = ({
  label,
  tasks,
  workflow,
  onTaskSelected,
  onTaskDeleted,
}) => {
  const renderTaskCards = () => {
    return tasks.map((task, index) => (
      <React.Fragment key={task.taskKey}>
        <TaskCard
          workflow={workflow}
          taskConfig={task}
          index={index}
          isLast={index === tasks.length - 1}
          onClick={() => onTaskSelected(task.taskKey)}
          //onDelete={() => onTaskDeleted(task.taskKey)}
        />
        {workflow.workflowType === WorkflowTypeEnum.taskOption &&
          index < tasks.length - 1 && (
            <Center>
              <Text fw={700} fz="lg" c="dimmed">
                OR
              </Text>
            </Center>
          )}
      </React.Fragment>
    ));
  };

  if (!label) {
    return <>{renderTaskCards()}</>;
  }

  return (
    <Stack gap="xs">
      <Text fw={700} fz="md">
        {label}
      </Text>
      {renderTaskCards()}
    </Stack>
  );
};

export default TaskGroup;
