import {
  FormSettings,
  PropertyReference,
  StepTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useWorkflowService } from '@btrway/workflow-common-provider';
import { useDataTypes, useStepTypes } from '@btrway/workflow-manager';
import { useMemo } from 'react';

import {
  addDataTypeProperties,
  getFormFieldsAsProperties,
  getPreviousSteps,
  getStepTypeProperties,
} from '../utils/propertyUtils';

export interface PropertyReferencesResult {
  allPropertyReferences: PropertyReference[];
  rulePropertyReferences: PropertyReference[];
  bindingPropertyReferences: PropertyReference[];
  aboutEntityProperties: PropertyReference[];
}

export const usePropertyReferences = (
  stepKey: string
): PropertyReferencesResult => {
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();
  const { getDataType } = useDataTypes();
  const { getStepType } = useStepTypes();

  return useMemo(() => {
    const workflowConfig = workflow?.workflowConfiguration;
    if (!workflowConfig?.steps) {
      return {
        allPropertyReferences: [],
        rulePropertyReferences: [],
        bindingPropertyReferences: [],
        aboutEntityProperties: [],
      };
    }

    const getAboutEntityProperties = (): PropertyReference[] => {
      if (workflow?.workflowType !== WorkflowTypeEnum.form) return [];

      const formSettings = workflowConfig.settings as FormSettings;
      if (!formSettings?.aboutSettings?.aboutEntity) return [];

      // Find the flowStart step
      const flowStartStep = workflowConfig.steps?.find(
        (step) => step.stepTypeCode === StepTypeEnum.flowStart
      );

      const aboutSettings = formSettings.aboutSettings;
      const aboutEntityType = aboutSettings.aboutEntity;
      const aboutEntityDataType = getDataType(aboutEntityType);
      if (!aboutEntityDataType?.properties) return [];

      return aboutEntityDataType.properties.map((prop) => ({
        propertyKey: `aboutEntity`,
        label: prop.name,
        dataType: prop.dataTypeCode,
        entityProperty: prop.key,
        stepKey: flowStartStep?.stepKey,
      }));
    };

    // Get current step
    const currentStep = workflowConfig.steps.find(
      (step) => step.stepKey === stepKey
    );
    if (!currentStep) {
      return {
        allPropertyReferences: [],
        rulePropertyReferences: [],
        bindingPropertyReferences: [],
        aboutEntityProperties: [],
      };
    }

    // Get properties for current step
    const currentStepFormFields = addDataTypeProperties(
      getFormFieldsAsProperties(currentStep),
      getDataType
    );

    // Get previous steps and their properties
    const previousSteps = getPreviousSteps(stepKey, workflowConfig);
    const previousStepsFormFields = previousSteps.flatMap((step) =>
      addDataTypeProperties(getFormFieldsAsProperties(step), getDataType)
    );

    // Get about entity properties
    const aboutEntityProperties = getAboutEntityProperties();

    // Get step type properties from previous steps
    const stepTypeProperties = previousSteps.flatMap((step) =>
      addDataTypeProperties(
        getStepTypeProperties(step, getStepType),
        getDataType
      )
    );

    // Combine all property references
    const rulePropertyReferences = [
      ...currentStepFormFields,
      ...previousStepsFormFields,
    ];

    const bindingPropertyReferences = [
      ...aboutEntityProperties,
      ...stepTypeProperties,
    ];

    const allPropertyReferences = [
      ...rulePropertyReferences,
      ...bindingPropertyReferences,
    ];

    return {
      allPropertyReferences,
      rulePropertyReferences,
      bindingPropertyReferences,
      aboutEntityProperties,
    };
  }, [workflow, stepKey, getDataType, getStepType]);
};
