import {
  FieldConfig,
  VideoFieldProperties,
  VideoFieldSourceType,
} from '@btrway/api-workflow';
import { UniversalVideoPlayer } from '@btrway/shared-components';
import { Stack, Text } from '@mantine/core';
import React from 'react';
import { BaseFieldProps, DisplayMode } from '../../types/baseField';

interface VideoFieldProps extends BaseFieldProps<never, Partial<FieldConfig>> {}

const defaultVideoProperties: VideoFieldProperties = {
  sourceType: VideoFieldSourceType.url,
  videoUrl: undefined,
  playbackIdentifier: undefined,
  playerHeight: undefined,
};

export const VideoField: React.FC<VideoFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const videoProperties = {
    ...defaultVideoProperties,
    ...(field.fieldProperties as VideoFieldProperties),
  };

  // Show empty state in builder modes when no video is set
  if (!videoProperties.playbackIdentifier && !videoProperties.videoUrl) {
    if (displayMode === DisplayMode.Builder) {
      return (
        <Stack gap="xs">
          <Text size="sm" c="dimmed" ta="center">
            No video configured
          </Text>
        </Stack>
      );
    }
    return null;
  }

  const effectivePlaybackIdentifier = videoProperties.playbackIdentifier;
  const effectiveUrl = videoProperties.videoUrl;

  // Always show the video player, but size it appropriately based on mode
  return (
    <UniversalVideoPlayer
      playbackIdentifier={effectivePlaybackIdentifier}
      url={effectiveUrl}
      height={videoProperties.playerHeight}
      width={600}
      // Add any additional display-specific props if needed
    />
  );
};

// Type guard to check if field is a video field
export const isVideoField = (field: FieldConfig): boolean => {
  return field.type === 'video';
};
