import { FieldConfig } from '@btrway/api-workflow';

export enum DisplayMode {
  Input = 'input',
  Builder = 'builder',
  BuilderReadOnly = 'builder-readonly',
  Display = 'display',
}

export interface BaseFieldProps<TValue = any, TOnChangeValue = TValue> {
  field: FieldConfig;
  value?: TValue;
  onChange?: (value: TOnChangeValue | null) => void;
  displayMode?: DisplayMode;
}

// Helper function to determine if the field is in a readonly state
export const isReadOnly = (displayMode?: DisplayMode): boolean => {
  return (
    displayMode === DisplayMode.BuilderReadOnly ||
    displayMode === DisplayMode.Display
  );
};

// Helper function to determine if the field should be rendered in display mode
export const isDisplayMode = (displayMode?: DisplayMode): boolean => {
  return displayMode === DisplayMode.Display;
};
