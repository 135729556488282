import type { FieldOption } from '@btrway/api-workflow';
import { ActionIcon, Button, Stack, TextInput } from '@mantine/core';
import { IconGripVertical, IconTrash } from '@tabler/icons-react';
import React from 'react';

interface FieldOptionsProps {
  options: FieldOption[];
  onChange: (options: FieldOption[]) => void;
  sortAlpha?: boolean;
}

interface OptionProps {
  option: FieldOption;
  index: number;
  onChange: (index: number, label: string) => void;
  onDelete: (index: number) => void;
}

const Option = ({ option, index, onChange, onDelete }: OptionProps) => {
  return (
    <div style={{ marginBottom: '8px' }}>
      <div
        style={{
          display: 'flex',
          border: '1px solid var(--mantine-color-gray-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          overflow: 'hidden',
          background: 'white',
        }}
      >
        <ActionIcon
          variant="filled"
          style={{
            height: '36px',
            width: '36px',
            borderRadius: 0,
            borderRight: '1px solid var(--mantine-color-gray-3)',
            backgroundColor: 'var(--mantine-color-gray-2)',
          }}
        >
          <IconGripVertical size={16} color="black" />
        </ActionIcon>
        <TextInput
          value={option.label}
          autoFocus
          onChange={(e) => onChange(index, e.currentTarget.value)}
          placeholder="Enter option label"
          styles={{
            root: { flex: 1 },
            input: {
              borderRadius: 0,
              border: 'none',
              height: '36px',
              '&:focus': {
                outline: 'none',
                border: 'none',
              },
            },
            wrapper: {
              height: '36px',
            },
          }}
        />
        <ActionIcon
          variant="light"
          color="red"
          onClick={() => onDelete(index)}
          style={{
            height: '36px',
            width: '36px',
            borderRadius: 0,
            borderLeft: '1px solid var(--mantine-color-gray-3)',
          }}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </div>
    </div>
  );
};

export const FieldOptions: React.FC<FieldOptionsProps> = ({
  options,
  onChange,
  sortAlpha = false,
}) => {
  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = { ...newOptions[index], label: value };
    onChange(newOptions);
  };

  const handleDeleteOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);
    onChange(newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...options, { label: '', order: options.length }];
    onChange(newOptions);
  };

  const displayOptions = sortAlpha
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : options;

  return (
    <Stack gap="md">
      <div>
        {displayOptions.map((option, index) => (
          <Option
            key={index}
            option={option}
            index={index}
            onChange={handleOptionChange}
            onDelete={handleDeleteOption}
          />
        ))}
      </div>

      <Button variant="light" onClick={handleAddOption} fullWidth>
        Add Option
      </Button>
    </Stack>
  );
};

export default FieldOptions;
