import {
  DataTypeEnum,
  FieldConfig,
  PropertyReference,
} from '@btrway/api-workflow';
import { PropertyGroup } from '@btrway/property-reference-manager';
import { PropertyTreeNodeData } from '../types/types';
import { filterPropertyReferences } from './properties';

export const getDataTypeColor = (dataType: DataTypeEnum): string => {
  switch (dataType) {
    case DataTypeEnum.text:
      return 'blue';
    case DataTypeEnum.number:
    case DataTypeEnum.integer:
      return 'green';
    case DataTypeEnum.boolean:
      return 'yellow';
    case DataTypeEnum.date:
    case DataTypeEnum.dateTime:
    case DataTypeEnum.time:
      return 'grape';
    case DataTypeEnum.person:
    case DataTypeEnum.workgroupMember:
      return 'orange';
    default:
      return 'gray';
  }
};

export const getPropertyNodeValue = (property: PropertyReference): string => {
  const values = [
    property.stepKey,
    property.propertyKey,
    property.entityProperty,
  ].filter(Boolean);

  return values.join('.');
};

export const isPropertyReferenceMatchingNodeValue = (
  property: PropertyReference,
  nodeValue: string
): boolean => {
  return getPropertyNodeValue(property) === nodeValue;
};

export const convertPropertyGroupToTreeData = (
  group: PropertyGroup,
  field: FieldConfig
): PropertyTreeNodeData => ({
  value: group.key,
  label: group.label,
  children: filterPropertyReferences(field, group.properties).map((prop) => ({
    value: getPropertyNodeValue(prop),
    label: prop.label,
    property: prop,
  })),
});
