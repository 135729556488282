import { Stack, Text, TextInput, TextInputProps } from '@mantine/core';
import { useState } from 'react';

type UrlTextInputProps = Omit<TextInputProps, 'error'> & {
  onUrlChange?: (url: string | null) => void;
};

export const UrlTextInput = ({
  value,
  onChange,
  onUrlChange,
  description,
  ...props
}: UrlTextInputProps) => {
  const [error, setError] = useState<string | null>(null);

  const URL_REGEX =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

  const validateUrl = (url: string): boolean => {
    try {
      if (!url) return true;

      if (!URL_REGEX.test(url)) {
        return false;
      }

      const newUrl = new URL(url);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // Always call the original onChange if provided
    onChange?.(event);

    if (!newValue) {
      setError(null);
      onUrlChange?.(null);
      return;
    }

    // Just update the value while typing
    onUrlChange?.(newValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (!newValue) {
      setError(null);
      onUrlChange?.(null);
      return;
    }

    // Add protocol if missing
    const urlWithProtocol = newValue.match(/^https?:\/\//)
      ? newValue
      : `https://${newValue}`;

    if (validateUrl(urlWithProtocol)) {
      setError(null);
      onUrlChange?.(urlWithProtocol);
    } else {
      setError('Please enter a valid website URL (e.g., https://example.com)');
      onUrlChange?.(newValue); // Keep the invalid value for editing
    }
  };

  return (
    <Stack gap={0}>
      <TextInput
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        description={description}
      />
      <Text fz="xs" c="dimmed">
        URLs will automatically be prefixed with https:// if no protocol is
        specified
      </Text>
    </Stack>
  );
};
