import { GlobalSearchResponse } from '@btrway/api-core';
import { Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';

interface SearchResultCardProps {
  result: GlobalSearchResponse;
  onClick: (result: GlobalSearchResponse) => void;
}

export const SearchResultCard: React.FC<SearchResultCardProps> = ({
  result,
  onClick,
}) => {
  const primaryWorkgroupMember = result.workgroupMembers?.[0];

  return (
    <Card
      shadow="sm"
      padding="sm"
      radius="md"
      withBorder
      onClick={() => onClick(result)}
      style={{ cursor: 'pointer' }}
    >
      <Stack gap={0}>
        <Group justify="space-between" wrap="nowrap">
          <Text fw={500} lineClamp={1}>
            {result.displayName}
          </Text>
          <Text size="xs" c="dimmed">
            {Math.round(result.similarity * 100)}% match
          </Text>
        </Group>

        <Group gap="xs">
          {primaryWorkgroupMember && (
            <Text size="sm" c="dimmed" lineClamp={1}>
              {primaryWorkgroupMember.userRole?.name} at{' '}
              {primaryWorkgroupMember.workgroup?.name}
            </Text>
          )}
        </Group>
      </Stack>
    </Card>
  );
};
