import { Stack, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface DateTimeFieldProps extends BaseFieldProps<Date | null, Date> {}

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;

  // Format datetime for display
  const formatDateTime = (date: Date | null): string => {
    if (!date) return '';
    return dayjs(date).format('MM/DD/YYYY hh:mm A');
  };

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value ? 'inherit' : 'dimmed'}>
          {value ? formatDateTime(value) : 'No date/time selected'}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap="xs">
      <DateTimePicker
        required={field.required}
        disabled={readonly}
        value={value}
        onChange={onChange}
        clearable={!readonly}
        placeholder="Select date and time"
        valueFormat="MM/DD/YYYY hh:mm A"
      />
    </Stack>
  );
};

export default DateTimeField;
