import { ActionIcon, Box, Center, Flex, Text, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';

interface AddStepProps {
  hasSteps: boolean;
  onAdd: () => void;
}

export const AddStep: React.FC<AddStepProps> = ({ hasSteps, onAdd }) => {
  if (hasSteps) {
    return (
      <Flex justify="center" mt="md">
        <Tooltip label="Add Task Assignment or Automated Action">
          <ActionIcon
            variant="light"
            color="blue"
            size="lg"
            radius="xl"
            onClick={onAdd}
          >
            <IconPlus size={20} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Box
      w="100%"
      h={240}
      style={(theme) => ({
        border: `2px dashed ${theme.colors.gray[5]}`,
        borderRadius: theme.radius.md,
        padding: theme.spacing.xl,
        textAlign: 'center',
      })}
    >
      <Center h="100%" style={{ flexDirection: 'column', gap: '1rem' }}>
        <Text fz="xl" fw={700} c="dimmed">
          Let's Pick Something to Do!
        </Text>
        <ActionIcon
          variant="light"
          color="blue"
          size="xl"
          radius="xl"
          onClick={onAdd}
        >
          <IconPlus size={24} />
        </ActionIcon>
      </Center>
    </Box>
  );
};
