import { FieldConfig, SignatureFieldProperties } from '@btrway/api-workflow';
import { Stack, Switch } from '@mantine/core';
import React from 'react';

interface SignatureFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<SignatureFieldProperties>) => void;
}

const defaultSignatureFieldProperties: SignatureFieldProperties = {
  allowTextEntry: true,
};

export const SignatureFieldSettings: React.FC<SignatureFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const signatureProps = {
    ...defaultSignatureFieldProperties,
    ...(field.fieldProperties as SignatureFieldProperties),
  };

  const handleChange = (key: keyof SignatureFieldProperties, value: any) => {
    onChange({
      ...signatureProps,
      [key]: value,
    });
  };

  return (
    <Stack gap="md">
      <Switch
        label="Allow Typed Signatures"
        description="Whether users can type their signature instead of drawing it"
        checked={signatureProps.allowTextEntry}
        onChange={(event) =>
          handleChange('allowTextEntry', event.currentTarget.checked)
        }
      />
    </Stack>
  );
};

export default SignatureFieldSettings;
