import {
  Box,
  CloseButtonProps,
  Modal,
  ModalStylesNames,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { ReactNode } from 'react';

interface ResponsiveModalProps {
  opened: boolean;
  onClose: () => void;
  title?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  background?: string;
  darkBackground?: boolean;
}

export const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  opened,
  onClose,
  title,
  children,
  footer,
  background,
  darkBackground = false,
}) => {
  const theme = useMantineTheme();
  const isLargerThanSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
  const closeButtonColor = darkBackground ? 'white' : 'black';

  const getResponsiveStyles = <T extends React.CSSProperties>(
    smallStyle: T,
    largeStyle: T
  ): T => {
    const result = isLargerThanSm ? largeStyle : smallStyle;
    return result;
  };

  const modalStyles: Partial<Record<ModalStylesNames, React.CSSProperties>> = {
    inner: {
      padding: 0,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      ...(getResponsiveStyles(
        {
          borderRadius: 0,
        },
        {
          borderRadius: theme.radius.md,
          margin: '40px',
          height: 'calc(100vh - 80px)',
          width: 'calc(100vw - 80px)',
          maxWidth: 'calc(100vw - 80px)',
        }
      ) as React.CSSProperties),
      background: background || theme.white,
      padding: 0,
      overflow: 'hidden',
    },
    header: {
      background: 'transparent',
      marginBottom: 0,
      ...(getResponsiveStyles(
        { padding: `${theme.spacing.md} ${theme.spacing.md} 0` },
        { padding: `${theme.spacing.md} ${theme.spacing.xl} 0` }
      ) as React.CSSProperties),
    },
    title: {
      color: darkBackground ? theme.white : theme.black,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: 0,
      overflow: 'hidden',
    },
    close: {
      color: closeButtonColor,
    },
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
      fullScreen
      styles={modalStyles}
      closeButtonProps={
        {
          variant: 'transparent',
        } as CloseButtonProps
      }
    >
      <Box
        style={{
          flex: 1,
          overflowY: 'auto',
          // ...(getResponsiveStyles(
          //   { padding: theme.spacing.md },
          //   { padding: theme.spacing.xl }
          // ) as React.CSSProperties),
        }}
      >
        {children}
      </Box>
      {footer && (
        <Box
          style={{
            borderTop: background
              ? 'none'
              : `1px solid ${theme.colors.gray[3]}`,
            background: background || 'transparent',
            ...(getResponsiveStyles(
              { padding: theme.spacing.md },
              { padding: theme.spacing.xl }
            ) as React.CSSProperties),
          }}
        >
          {footer}
        </Box>
      )}
    </Modal>
  );
};
