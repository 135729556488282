import { FieldConfig, SpaceFieldProperties } from '@btrway/api-workflow';
import { NumberInput, Stack } from '@mantine/core';
import React from 'react';

interface SpaceFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<SpaceFieldProperties>) => void;
}

const defaultSpaceFieldProperties: SpaceFieldProperties = {
  height: 20,
};

export const SpaceFieldSettings: React.FC<SpaceFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const spaceProps = {
    ...defaultSpaceFieldProperties,
    ...(field.fieldProperties as SpaceFieldProperties),
  };

  const handleChange = (key: keyof SpaceFieldProperties, value: any) => {
    onChange({
      ...spaceProps,
      [key]: value,
    });
  };

  return (
    <Stack gap="md">
      <NumberInput
        label="Space Height"
        description="The vertical space height in pixels"
        value={spaceProps.height}
        min={1}
        max={200}
        onChange={(value) => handleChange('height', value)}
      />
    </Stack>
  );
};

export default SpaceFieldSettings;
