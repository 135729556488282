import { FieldConfig } from '@btrway/api-workflow';
import {
  Card,
  Popover,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import classes from './FieldPicker.module.css';

interface FieldPickerProps {
  selectedField?: FieldConfig;
  availableFields: FieldConfig[];
  onFieldSelect: (fieldKey: string) => void;
}

export const FieldPicker: React.FC<FieldPickerProps> = ({
  selectedField,
  availableFields,
  onFieldSelect,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredFields = useMemo(() => {
    return availableFields.filter((field) =>
      field.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [availableFields, searchQuery]);

  // Find the selected field in available fields to ensure we have the latest state
  const currentField = useMemo(() => {
    // If we have a selected field, use it directly
    if (selectedField) {
      return selectedField;
    }
    return undefined;
  }, [selectedField]);

  const handleFieldSelect = (fieldKey: string) => {
    onFieldSelect(fieldKey);
    close();
  };

  return (
    <Popover
      position="bottom-start"
      width={300}
      opened={opened}
      onChange={close}
      withinPortal={true}
      zIndex={1100}
    >
      <Popover.Target>
        <Card
          radius="xl"
          padding="xs"
          withBorder
          className={classes.selectionCard}
          onClick={open}
        >
          <Text size="sm">{currentField?.label || 'Select field'}</Text>
        </Card>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack gap="xs">
          <TextInput
            placeholder="Search fields..."
            leftSection={<IconSearch size={14} />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
          />
          <ScrollArea h={200}>
            <Stack gap="xs">
              {filteredFields.map((field) => (
                <UnstyledButton
                  key={field.fieldKey}
                  onClick={() => handleFieldSelect(field.fieldKey)}
                  className={classes.optionButton}
                >
                  <Text size="sm">{field.label}</Text>
                </UnstyledButton>
              ))}
            </Stack>
          </ScrollArea>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
