import { AIGenerationStep } from '../step-components/AIGenerationStep/AIGenerationStep';
import { ApprovalsStep } from '../step-components/ApprovalsStep/ApprovalsStep';
import { CompletionMethodStep } from '../step-components/CompletionMethodStep/CompletionMethodStep';
import { FormAboutStep } from '../step-components/FormAboutStep/FormAboutStep';
import { FormNameStep } from '../step-components/FormNameStep/FormNameStep';
import { WizardStep } from '../types/wizardStep';

export const WIZARD_STEPS: WizardStep[] = [
  {
    key: 'formName',
    label: 'Name',
    description: 'What do you want to name this form?',
    component: FormNameStep,
  },
  {
    key: 'existingFile',
    label: 'Existing Form',
    description: 'Do you have an existing file or document to start with?',
    component: AIGenerationStep,
  },
  {
    key: 'formAbout',
    label: 'About',
    description: 'What is this form about and who can use it?',
    component: FormAboutStep,
  },
  {
    key: 'completionMethod',
    label: 'Completion Options',
    description: 'How and by whom can this form be completed?',
    component: CompletionMethodStep,
  },
  {
    key: 'approvals',
    label: 'Approvals',
    description: 'Is there an approval process after this form is submitted?',
    component: ApprovalsStep,
  },
];
