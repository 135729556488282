import {
  DataTypeEnum,
  FieldConfig,
  FieldTypeEnum,
  PropertyReference,
  TextFieldDataType,
  TextFieldProperties,
} from '@btrway/api-workflow';

// Define specific number-based field types
const numberFieldTypes = [
  FieldTypeEnum.number,
  FieldTypeEnum.rating,
  FieldTypeEnum.slider,
] as const;

type NumberFieldType = (typeof numberFieldTypes)[number];

// Define allowed data types
const allowedDataTypes = [
  DataTypeEnum.text,
  DataTypeEnum.number,
  DataTypeEnum.integer,
  DataTypeEnum.email,
  DataTypeEnum.phone,
  DataTypeEnum.boolean,
  DataTypeEnum.date,
  DataTypeEnum.dateTime,
  DataTypeEnum.time,
] as const;

type AllowedDataType = (typeof allowedDataTypes)[number];

// Define field types that support styles
export const fieldTypesWithStyles = [
  FieldTypeEnum.heading,
  FieldTypeEnum.group,
] as const;

type FieldTypeWithStyles = (typeof fieldTypesWithStyles)[number];

/**
 * Type guard to check if a field type supports styles
 */
export const supportsFieldStyles = (
  fieldType: FieldTypeEnum
): fieldType is FieldTypeWithStyles => {
  return fieldTypesWithStyles.includes(fieldType as FieldTypeWithStyles);
};

/**
 * Type guard to check if a field type is a number field type
 */
const isNumberFieldType = (
  fieldType: FieldTypeEnum
): fieldType is NumberFieldType => {
  return numberFieldTypes.includes(fieldType as NumberFieldType);
};

/**
 * Type guard to check if a data type is an allowed data type
 */
const isAllowedDataType = (
  dataType: DataTypeEnum
): dataType is AllowedDataType => {
  return allowedDataTypes.includes(dataType as AllowedDataType);
};

/**
 * Filters property references based on field type and properties
 */
export const filterPropertyReferences = (
  field: FieldConfig,
  propertyReferences: PropertyReference[]
): PropertyReference[] => {
  // Handle text fields with specific data types
  if (field.type === FieldTypeEnum.text && field.fieldProperties) {
    const textProperties = field.fieldProperties as TextFieldProperties;

    // Filter by email data type
    if (textProperties.dataType === TextFieldDataType.email) {
      return propertyReferences.filter(
        (prop) => prop.dataType === DataTypeEnum.email
      );
    }

    // Filter by phone data type
    if (textProperties.dataType === TextFieldDataType.phone) {
      return propertyReferences.filter(
        (prop) => prop.dataType === DataTypeEnum.phone
      );
    }
  }

  // Handle number-based fields
  if (isNumberFieldType(field.type)) {
    return propertyReferences.filter(
      (prop) => prop.dataType === DataTypeEnum.number
    );
  }

  // Default case: allow common data types
  return propertyReferences.filter((prop) => isAllowedDataType(prop.dataType));
};
