/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type DateFieldRenderType = typeof DateFieldRenderType[keyof typeof DateFieldRenderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateFieldRenderType = {
  input: 'input',
  picker: 'picker',
} as const;
