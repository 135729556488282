import { FieldTypeEnum } from '@btrway/api-workflow';
import { Box, Group, Popover, Text, UnstyledButton } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useState } from 'react';
import { getFieldIcon, getFieldLabel } from '../../utils/toolbox';
import { FormFieldSelectOptions } from '../FormFieldSelectOptions/FormFieldSelectOptions';

interface FormFieldSelectProps {
  currentFieldType: FieldTypeEnum;
  onFieldTypeSelect: (fieldType: FieldTypeEnum) => void;
}

export const FormFieldSelect: React.FC<FormFieldSelectProps> = ({
  currentFieldType,
  onFieldTypeSelect,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      offset={5}
      width={320}
      trapFocus
      withArrow={false}
    >
      <Popover.Target>
        <UnstyledButton
          onClick={() => setOpened((o) => !o)}
          style={(theme) => ({
            width: '100%',
            border: `1px solid ${theme.colors.gray[opened ? 6 : 4]}`,
            borderRadius: theme.radius.sm,
            padding: '10px 12px',
            backgroundColor: 'white',
            transition: 'border-color 100ms ease',
            '&:hover': {
              borderColor: theme.colors.gray[6],
            },
          })}
        >
          <Group justify="space-between">
            <Group gap="sm">
              <Box
                style={(theme) => ({
                  color: theme.colors.blue[6],
                  display: 'flex',
                  alignItems: 'center',
                })}
              >
                {getFieldIcon(currentFieldType)}
              </Box>
              <Text size="sm">{getFieldLabel(currentFieldType)}</Text>
            </Group>
            <IconChevronDown
              size={16}
              style={{
                transform: opened ? 'rotate(180deg)' : 'none',
                transition: 'transform 200ms ease',
              }}
            />
          </Group>
        </UnstyledButton>
      </Popover.Target>

      <Popover.Dropdown>
        <FormFieldSelectOptions
          currentFieldType={currentFieldType}
          onFieldTypeSelect={(fieldType) => {
            onFieldTypeSelect(fieldType);
            setOpened(false);
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
