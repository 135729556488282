import {
  FieldConfig,
  TextFieldDataType,
  TextFieldProperties,
} from '@btrway/api-workflow';
import {
  Chip,
  Group,
  NumberInput,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import React from 'react';

interface TextFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<TextFieldProperties>) => void;
}

const defaultTextProperties: TextFieldProperties = {
  dataType: undefined,
  multiLine: false,
  rows: 3,
  placeholder: '', // Add default placeholder
};

export const TextFieldSettings: React.FC<TextFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const textProps = {
    ...defaultTextProperties,
    ...(field.fieldProperties as TextFieldProperties),
  };

  const handleSingleChange = (key: keyof TextFieldProperties, value: any) => {
    onChange({
      ...textProps,
      [key]: value,
    });
  };

  const handleDataTypeChange = (value: string | string[]) => {
    // Handle both single string and array inputs
    const newDataType = Array.isArray(value)
      ? (value[value.length - 1] as TextFieldDataType | undefined)
      : value === 'none'
      ? undefined
      : (value as TextFieldDataType | undefined);

    onChange({
      ...textProps,
      dataType: newDataType,
    });
  };

  return (
    <Stack gap="md">
      <Text fz="sm" fw={600}>
        Formatting
      </Text>
      <Group wrap="nowrap" gap="xs">
        <Chip.Group
          value={textProps.dataType || 'none'}
          onChange={handleDataTypeChange}
        >
          <Group gap="xs">
            <Chip value="none">None</Chip>
            <Chip value={TextFieldDataType.email}>Email</Chip>
            <Chip value={TextFieldDataType.phone}>Phone</Chip>
          </Group>
        </Chip.Group>
      </Group>

      <TextInput
        label="Placeholder"
        description="Text to show when the field is empty"
        value={textProps.placeholder || ''}
        onChange={(event) =>
          handleSingleChange('placeholder', event.currentTarget.value)
        }
      />

      <Text fz="sm" fw={600}>
        Multi-line Input
      </Text>

      <Switch
        label="Allow multiple lines of text input"
        checked={textProps.multiLine}
        onChange={(event) =>
          handleSingleChange('multiLine', event.currentTarget.checked)
        }
      />

      {textProps.multiLine && (
        <NumberInput
          label="Minimum Rows"
          description="Initial number of visible text rows"
          value={textProps.rows}
          onChange={(value) => handleSingleChange('rows', value)}
          min={1}
          max={12}
          step={1}
        />
      )}
    </Stack>
  );
};

export default TextFieldSettings;
