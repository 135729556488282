import { Alert, Group, NumberInput, Select, Stack, Text } from '@mantine/core';
import { useHeight } from '../../hooks/useHeight';
import { useMeasurementSystem } from '../../hooks/useMeasurementSystem';
import { FRACTIONS } from '../../types/constants';
import { BaseInputProps, HeightData } from '../../types/types';
import { SystemSwitcher } from '../SystemSwitcher/SystemSwitcher';

export interface HeightInputProps extends BaseInputProps {
  value?: HeightData;
  allowFractionalInches?: boolean;
}

export const HeightInput = ({
  onChange,
  imperialEnabled = true,
  metricEnabled = true,
  allowFractionalInches = true,
  decimalPlaces = 2,
  value,
  readOnly = false,
}: HeightInputProps) => {
  if (!imperialEnabled && !metricEnabled) {
    return (
      <Alert color="red">At least one measurement system must be enabled</Alert>
    );
  }

  const { system, handleSystemChange } = useMeasurementSystem({
    initialSystem: value?.system || (imperialEnabled ? 'imperial' : 'metric'),
    imperialEnabled,
    metricEnabled,
    readOnly,
  });

  const {
    feet,
    inches,
    fraction,
    centimeters,
    handleFeetChange,
    handleInchesChange,
    handleFractionChange,
    handleCentimetersChange,
  } = useHeight({
    value,
    system,
    onChange,
    allowFractionalInches,
    decimalPlaces,
    readOnly,
  });

  return (
    <Stack gap="xs">
      <Group gap="xs" justify="flex-start" align="flex-start">
        {system === 'imperial' ? (
          <>
            <Stack gap={2}>
              <NumberInput
                value={feet}
                onChange={handleFeetChange}
                min={0}
                placeholder="ft"
                styles={{ input: { width: 80 } }}
                disabled={readOnly}
              />
              <Text fz="sm" c="dimmed">
                Feet
              </Text>
            </Stack>
            <Stack gap={2}>
              <NumberInput
                value={inches}
                onChange={handleInchesChange}
                min={0}
                clampBehavior="strict"
                allowDecimal={false}
                placeholder="in"
                styles={{ input: { width: 80 } }}
                disabled={readOnly}
              />
              <Text fz="sm" c="dimmed">
                Inches
              </Text>
            </Stack>
            {allowFractionalInches && (
              <Stack gap={2}>
                <Select
                  value={fraction}
                  onChange={handleFractionChange}
                  data={FRACTIONS}
                  styles={{ input: { width: 90 } }}
                  disabled={readOnly}
                />
              </Stack>
            )}
          </>
        ) : (
          <Stack gap={2}>
            <NumberInput
              value={centimeters}
              onChange={handleCentimetersChange}
              min={0}
              max={300}
              decimalScale={decimalPlaces}
              placeholder="cm"
              styles={{ input: { width: 120 } }}
              disabled={readOnly}
            />
            <Text fz="sm" c="dimmed">
              Centimeters
            </Text>
          </Stack>
        )}
      </Group>
      {imperialEnabled && metricEnabled && (
        <SystemSwitcher
          currentSystem={system}
          onSystemChange={handleSystemChange}
          disabled={readOnly}
        />
      )}
    </Stack>
  );
};
