import { Select } from '@mantine/core';

// Configuration for demo accounts and credentials
const DEMO_PASSWORD = 'betterway24';

// Transform CSV data into format for select component
const userGroups = [
  {
    group: 'NC - Alamance-Burlington School System',
    items: [
      {
        value: 'xiomara.pinnock34765@outlook.com',
        label: 'Xiomara Pinnock (Head Football Coach)',
      },
      {
        value: 'shay.hopkins34731@hotmail.com',
        label: 'Shay Hopkins (Athletic Director)',
      },
    ],
  },
  {
    group: 'FL - Orange County Public Schools',
    items: [
      {
        value: 'nellie.wright500162@aol.com',
        label: 'Nellie Wright (System AD)',
      },
      {
        value: 'lottie.drake500214@outlook.com',
        label: 'Lottie Drake (Athletic Director)',
      },
      {
        value: 'christy.clare505081@me.com',
        label: 'Christy Clare (Head Coach)',
      },
      {
        value: 'wilma.alngindabu505082@yahoo.com',
        label: 'Wilma Alngindabu (Assistant Coach)',
      },
      {
        value: 'sammie.aitken505095@yahoo.com',
        label: 'Sammie Aitken (Student Athlete)',
      },
    ],
  },
  {
    group: 'CO - Academy District 20',
    items: [
      {
        value: 'gemma.begum3200@yahoo.com',
        label: 'Gemma Begum (System AD)',
      },
      {
        value: 'amelie.brownless3212@hotmail.com',
        label: 'Amelie Brownless (Athletic Director)',
      },
      {
        value: 'jalisa.abrams5345@yahoo.com',
        label: 'Jalisa Abrams (Head Coach)',
      },
      {
        value: 'herta.aguilar4394@yahoo.com',
        label: 'Herta Aguilar (Assistant Coach)',
      },
      {
        value: 'bernice.aaron5013@yahoo.com',
        label: 'Bernice Aaron (Student Athlete)',
      },
    ],
  },
  {
    group: 'GA - Atlanta Public Schools',
    items: [
      {
        value: 'lewis.dill144794@hotmail.com',
        label: 'Lewis Dill (System Athletic Director)',
      },
      {
        value: 'bryan.spruill144814@me.com',
        label: 'Bryan Spruill (Athletic Director)',
      },
      {
        value: 'abbie.alcorn144877@gmail.com',
        label: 'Abbie Alcorn (Head Coach)',
      },
      {
        value: 'lindsay.abbott147152@gmail.com',
        label: 'Lindsay Abbott (Assistant Coach)',
      },
      {
        value: 'mina.abel144875@company.com',
        label: 'Mina Abel (Student Athlete)',
      },
    ],
  },
  {
    group: 'NC - Alamance-Burlington Schools',
    items: [
      {
        value: 'robyn.alley34586@hotmail.com',
        label: 'Robyn Alley (System AD)',
      },
      {
        value: 'jasmine.down34594@aol.com',
        label: 'Jasmine Down (Athletic Director)',
      },
      {
        value: 'kurt.ashby34606@me.com',
        label: 'Kurt Ashby (Head Coach)',
      },
      {
        value: 'pedro.beck36038@hotmail.com',
        label: 'Pedro Beck (Assistant Coach)',
      },
      {
        value: 'my.abels34674@biz.com',
        label: 'My Abels (Student Athlete)',
      },
    ],
  },
  {
    group: 'PA - Allentown School District',
    items: [
      {
        value: 'vinicius.mahony68093@gmail.com',
        label: 'Vinicius Mahony (System AD)',
      },
      {
        value: 'louie.lynch68097@hotmail.com',
        label: 'Louie Lynch (Athletic Director)',
      },
      {
        value: 'pat.kelly68183@aol.com',
        label: 'Pat Kelly (Head Coach)',
      },
      {
        value: 'lora.ogg68511@me.com',
        label: 'Lora Ogg (Assistant Coach)',
      },
      {
        value: 'rene.ashby68494@msn.com',
        label: 'Rene Ashby (Student Athlete)',
      },
    ],
  },
  {
    group: 'SC - Aiken County Public Schools',
    items: [
      {
        value: 'dale.paille17932@biz.com',
        label: 'Dale Paille (System AD)',
      },
      {
        value: 'colin.tweddle17944@gmail.com',
        label: 'Colin Tweddle (Athletic Director)',
      },
      {
        value: 'luiz.bent19516@company.com',
        label: 'Luiz Bent (Head Coach)',
      },
      {
        value: 'robin.baker19182@biz.com',
        label: 'Robin Baker (Assistant Coach)',
      },
      {
        value: 'otto.addis18792@outlook.com',
        label: 'Otto Addis (Student Athlete)',
      },
    ],
  },
  {
    group: 'TN - Alcoa City Schools',
    items: [
      {
        value: 'dina.lawton58693@yahoo.com',
        label: 'Dina Lawton (System AD)',
      },
      {
        value: 'yu.kirkhope58697@biz.com',
        label: 'Yu Kirkhope (Athletic Director)',
      },
      {
        value: 'keon.arnold58718@company.com',
        label: 'Keon Arnold (Head Coach)',
      },
      {
        value: 'amy.beit58777@hotmail.com',
        label: 'Amy Beit (Assistant Coach)',
      },
      {
        value: 'alec.amos58751@biz.com',
        label: 'Alec Amos (Student Athlete)',
      },
    ],
  },
  {
    group: 'VA - Albemarle Schools',
    items: [
      {
        value: 'thomas.jacoby44017@biz.com',
        label: 'Thomas Jacoby (System AD)',
      },
      {
        value: 'cathleen.halligan44025@company.com',
        label: 'Cathleen Halligan (Athletic Director)',
      },
      {
        value: 'jordan.blanch44570@aol.com',
        label: 'Jordan Blanch (Head Coach)',
      },
      {
        value: 'toby.bird45082@hotmail.com',
        label: 'Toby Bird (Assistant Coach)',
      },
      {
        value: 'patsy.anton44657@school.edu',
        label: 'Patsy Anton (Student Athlete)',
      },
    ],
  },
];

interface DemoAccountsProps {
  onSelect: (email: string, password: string) => void;
}

export function DemoAccounts({ onSelect }: DemoAccountsProps) {
  const handleSelect = (email: string | null) => {
    if (email) {
      onSelect(email, DEMO_PASSWORD);
    }
  };

  const flattenedData = userGroups.map((group) => ({
    group: group.group,
    items: group.items.map((item) => ({
      value: item.value,
      label: `${item.label}`,
    })),
  }));

  return (
    <>
      <Select
        placeholder="Select a demo account"
        data={flattenedData}
        searchable
        clearable
        onChange={handleSelect}
        styles={(theme) => ({
          input: {
            backgroundColor: 'white',
            borderColor: '#ced4da',
          },
        })}
      />
    </>
  );
}
