// import { MantineSpacing } from '@mantine/core';
// import React, { createContext, ReactNode, useContext, useState } from 'react';
// import FlyoutPanel from './FlyoutPanel';

// interface PanelConfig {
//   width?: number;
//   radius?: MantineSpacing;
//   m?: MantineSpacing;
//   mt?: MantineSpacing;
//   mb?: MantineSpacing;
//   ml?: MantineSpacing;
//   mr?: MantineSpacing;
//   p?: MantineSpacing;
//   pt?: MantineSpacing;
//   pb?: MantineSpacing;
//   pl?: MantineSpacing;
//   pr?: MantineSpacing;
// }

// interface FlyoutPanelContextType {
//   isOpen: boolean;
//   openPanel: (content: ReactNode, config?: PanelConfig) => void;
//   closePanel: () => void;
//   contentKey: number;
// }

// const FlyoutPanelContext = createContext<FlyoutPanelContextType>({
//   isOpen: false,
//   openPanel: () => {},
//   closePanel: () => {},
//   contentKey: 0,
// });

// export const useFlyoutPanel = () => {
//   const context = useContext(FlyoutPanelContext);
//   if (!context) {
//     throw new Error('useFlyoutPanel must be used within a FlyoutPanelProvider');
//   }
//   return context;
// };

// interface FlyoutPanelProviderProps {
//   children: ReactNode;
//   defaultWidth?: number;
//   defaultRadius?: MantineSpacing;
//   defaultMargin?: MantineSpacing;
//   defaultPadding?: MantineSpacing;
// }

// export const FlyoutPanelProvider: React.FC<FlyoutPanelProviderProps> = ({
//   children,
//   defaultWidth = 300,
//   defaultRadius = 'lg',
//   defaultMargin = 'md',
//   defaultPadding = 0,
// }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [content, setContent] = useState<ReactNode | null>(null);
//   const [contentKey, setContentKey] = useState(0);
//   const [currentConfig, setCurrentConfig] = useState<PanelConfig>({
//     width: defaultWidth,
//     radius: defaultRadius,
//     m: defaultMargin,
//     mt: 0,
//   });

//   const openPanel = (newContent: ReactNode, config?: PanelConfig) => {
//     console.log('calling openPanel to render: ', JSON.stringify(newContent));
//     setContentKey((prev) => prev + 1);
//     setContent(newContent);

//     const baseMargin = config?.m ?? defaultMargin;
//     const basePadding = config?.p ?? defaultPadding;

//     setCurrentConfig({
//       width: config?.width ?? defaultWidth,
//       radius: config?.radius ?? defaultRadius,
//       m: baseMargin,
//       mt: config?.mt ?? baseMargin,
//       mb: config?.mb ?? baseMargin,
//       ml: config?.ml ?? baseMargin,
//       mr: config?.mr ?? baseMargin,
//       p: basePadding,
//       pt: config?.pt ?? basePadding,
//       pb: config?.pb ?? basePadding,
//       pl: config?.pl ?? basePadding,
//       pr: config?.pr ?? basePadding,
//     });
//     setIsOpen(true);
//   };

//   const closePanel = () => {
//     setIsOpen(false);
//     setTimeout(() => setContent(null), 200);
//   };

//   return (
//     <FlyoutPanelContext.Provider
//       value={{ isOpen, openPanel, closePanel, contentKey }}
//     >
//       {children}
//       <FlyoutPanel
//         width={currentConfig.width!}
//         radius={currentConfig.radius}
//         isOpen={isOpen}
//         m={currentConfig.m}
//         mt={currentConfig.mt}
//         mb={currentConfig.mb}
//         ml={currentConfig.ml}
//         mr={currentConfig.mr}
//         p={currentConfig.p}
//         pt={currentConfig.pt}
//         pb={currentConfig.pb}
//         pl={currentConfig.pl}
//         pr={currentConfig.pr}
//       >
//         {content}
//       </FlyoutPanel>
//     </FlyoutPanelContext.Provider>
//   );
// };

import { MantineSpacing } from '@mantine/core';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import FlyoutPanel from './FlyoutPanel';

interface PanelConfig {
  width?: number;
  radius?: MantineSpacing;
  m?: MantineSpacing;
  mt?: MantineSpacing;
  mb?: MantineSpacing;
  ml?: MantineSpacing;
  mr?: MantineSpacing;
  p?: MantineSpacing;
  pt?: MantineSpacing;
  pb?: MantineSpacing;
  pl?: MantineSpacing;
  pr?: MantineSpacing;
}

interface FlyoutPanelContextType {
  isOpen: boolean;
  openPanel: (content: ReactNode, config?: PanelConfig) => void;
  closePanel: () => void;
  contentKey: number;
}

const FlyoutPanelContext = createContext<FlyoutPanelContextType>({
  isOpen: false,
  openPanel: () => {},
  closePanel: () => {},
  contentKey: 0,
});

export const useFlyoutPanel = () => {
  const context = useContext(FlyoutPanelContext);
  if (!context) {
    throw new Error('useFlyoutPanel must be used within a FlyoutPanelProvider');
  }
  return context;
};

interface FlyoutPanelProviderProps {
  children: ReactNode;
  defaultWidth?: number;
  defaultRadius?: MantineSpacing;
  defaultMargin?: MantineSpacing;
  defaultPadding?: MantineSpacing;
}

export const FlyoutPanelProvider: React.FC<FlyoutPanelProviderProps> = ({
  children,
  defaultWidth = 300,
  defaultRadius = 'lg',
  defaultMargin = 'md',
  defaultPadding = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [contentKey, setContentKey] = useState(0);
  const [currentConfig, setCurrentConfig] = useState<PanelConfig>({
    width: defaultWidth,
    radius: defaultRadius,
    m: defaultMargin,
    mt: 0,
  });

  const openPanel = (newContent: ReactNode, config?: PanelConfig) => {
    setContentKey((prev) => prev + 1);
    setContent(newContent);

    const baseMargin = config?.m ?? defaultMargin;
    const basePadding = config?.p ?? defaultPadding;

    setCurrentConfig({
      width: config?.width ?? defaultWidth,
      radius: config?.radius ?? defaultRadius,
      m: baseMargin,
      mt: config?.mt ?? baseMargin,
      mb: config?.mb ?? baseMargin,
      ml: config?.ml ?? baseMargin,
      mr: config?.mr ?? baseMargin,
      p: basePadding,
      pt: config?.pt ?? basePadding,
      pb: config?.pb ?? basePadding,
      pl: config?.pl ?? basePadding,
      pr: config?.pr ?? basePadding,
    });
    setIsOpen(true);
  };

  const closePanel = () => {
    setIsOpen(false);
    // Remove the timeout - content will be hidden by CSS when panel is closed
    setContent(null);
  };

  return (
    <FlyoutPanelContext.Provider
      value={{ isOpen, openPanel, closePanel, contentKey }}
    >
      {children}
      <FlyoutPanel
        width={currentConfig.width!}
        radius={currentConfig.radius}
        isOpen={isOpen}
        m={currentConfig.m}
        mt={currentConfig.mt}
        mb={currentConfig.mb}
        ml={currentConfig.ml}
        mr={currentConfig.mr}
        p={currentConfig.p}
        pt={currentConfig.pt}
        pb={currentConfig.pb}
        pl={currentConfig.pl}
        pr={currentConfig.pr}
      >
        {content}
      </FlyoutPanel>
    </FlyoutPanelContext.Provider>
  );
};

export default FlyoutPanelProvider;
