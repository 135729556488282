import { TaskConfig, WorkflowConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useWorkflowUpdater } from '@btrway/workflow-common-provider';
import { useCallback } from 'react';
import { TaskActions } from '../types/actions';

export const useTaskActions = (
  workflowUpdater: ReturnType<typeof useWorkflowUpdater>,
  workflowConfig: WorkflowConfig,
  setWorkflowConfig: React.Dispatch<React.SetStateAction<WorkflowConfig>>
): TaskActions => {
  const addTask = useCallback(
    async (newTask: TaskConfig) => {
      const taskWithKey = {
        ...newTask,
        taskKey: newTask.taskKey || uuid(),
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        tasks: [...(prev.tasks || []), taskWithKey],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { tasks: [taskWithKey] },
      });

      return taskWithKey;
    },
    [workflowUpdater]
  );

  const removeTask = useCallback(
    async (taskKey: string) => {
      setWorkflowConfig((prev) => ({
        ...prev,
        tasks: prev.tasks?.filter((task) => task.taskKey !== taskKey),
      }));

      await workflowUpdater.updateConfig({
        deleteConfigs: [taskKey],
      });
    },
    [workflowUpdater]
  );

  const updateTask = useCallback(
    async (taskKey: string, updatedTask: Partial<TaskConfig>) => {
      const tasks = workflowConfig.tasks || [];
      const existingIndex = tasks.findIndex((task) => task.taskKey === taskKey);

      if (existingIndex === -1) {
        throw new Error(`Task with key ${taskKey} not found`);
      }

      const updatedConfig = {
        ...tasks[existingIndex],
        ...updatedTask,
        taskKey,
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        tasks: prev.tasks?.map((task) =>
          task.taskKey === taskKey ? updatedConfig : task
        ),
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { tasks: [updatedConfig] },
      });

      return updatedConfig;
    },
    [workflowConfig.tasks, workflowUpdater]
  );

  return {
    addTask,
    removeTask,
    updateTask,
  };
};
