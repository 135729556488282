import { TaskConfig } from '@btrway/api-workflow';
import { StyledModal } from '@btrway/styled-modal';
import React from 'react';
import FormSubmission from '../FormSubmission/FormSubmission';

interface FormSubmissionModalProps {
  workflowDefinitionId: number;
  parentTaskListId?: number;
  taskConfig?: TaskConfig;
  isOpen: boolean;
  onClose: () => void;
}

const FormSubmissionModal: React.FC<FormSubmissionModalProps> = ({
  workflowDefinitionId,
  parentTaskListId,
  taskConfig,
  isOpen,
  onClose,
}) => {
  return (
    <StyledModal
      variant="xl"
      opened={isOpen}
      onClose={onClose}
      withCloseButton={false}
    >
      {workflowDefinitionId > 0 && (
        <FormSubmission
          workflowDefinitionId={workflowDefinitionId}
          parentTaskListId={parentTaskListId}
          // taskConfig={taskConfig}
          onClose={onClose}
        />
      )}
    </StyledModal>
  );
};

export default FormSubmissionModal;
