import {
  WorkflowConfig,
  WorkflowDefinitionRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import {
  FormSettingsEditor,
  FormSettingsEditorRef,
} from '@btrway/form-settings';
import { Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useRef, useState } from 'react';

interface AddWorkflowDefinitionModalProps {
  workflowType: WorkflowTypeEnum;
  workflowTypeDescription: string;
  opened: boolean;
  onClose: () => void;
  onSave: (
    partialWorkflowDefinition: Partial<WorkflowDefinitionRequest>
  ) => void;
}

const AddWorkflowDefinitionModal = ({
  workflowType,
  workflowTypeDescription,
  opened,
  onClose,
  onSave,
}: AddWorkflowDefinitionModalProps) => {
  const [workflowName, setWorkflowName] = useState('');
  const formSettingsRef = useRef<FormSettingsEditorRef>(null);

  const handleCreateWorkflow = () => {
    if (workflowName) {
      const formSettings = formSettingsRef.current?.getFormSettings();

      console.log('handleCreateWorkflow -> formSettings', formSettings);
      let workflowConfig: WorkflowConfig = {
        events: [],
        steps: [],
        transitions: [],
      };

      if (workflowType === WorkflowTypeEnum.form && formSettings) {
        workflowConfig = {
          ...workflowConfig,
          settings: formSettings,
        };
      }

      console.log('handleCreateWorkflow -> workflowConfig', workflowConfig);
      onSave({
        name: workflowName,
        workflowType: workflowType,
        workflowConfiguration: workflowConfig,
      });

      resetState();
      onClose();
    }
  };

  const resetState = () => {
    setWorkflowName('');
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetState();
        onClose();
      }}
      title={
        <Text fz="xl" fw={600}>
          Add {workflowTypeDescription}
        </Text>
      }
      size="80%"
    >
      <Modal.Body mih="calc(100vh *.6)">
        <Stack gap="sm">
          <TextInput
            label={`${workflowTypeDescription} Name`}
            placeholder={`Enter ${workflowTypeDescription} Name`}
            value={workflowName}
            onChange={(event) => setWorkflowName(event.currentTarget.value)}
            data-autofocus
            withAsterisk
          />

          {workflowType === WorkflowTypeEnum.form && (
            <FormSettingsEditor ref={formSettingsRef} />
          )}
        </Stack>
      </Modal.Body>

      <Group justify="flex-end">
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleCreateWorkflow}
          leftSection={<IconPlus size={16} />}
          disabled={!workflowName}
        >
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default AddWorkflowDefinitionModal;
