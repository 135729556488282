import { FieldConfig, RatingFieldProperties } from '@btrway/api-workflow';
import { NumberInput, Stack } from '@mantine/core';
import React from 'react';

interface RatingFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<RatingFieldProperties>) => void;
}

const defaultRatingFieldProperties: RatingFieldProperties = {
  count: 5,
};

export const RatingFieldSettings: React.FC<RatingFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const ratingProps = {
    ...defaultRatingFieldProperties,
    ...(field.fieldProperties as RatingFieldProperties),
  };

  const handleChange = (key: keyof RatingFieldProperties, value: any) => {
    onChange({
      ...ratingProps,
      [key]: value,
    });
  };

  return (
    <Stack gap="md">
      <NumberInput
        label="Number of Stars"
        description="The maximum rating value (number of stars)"
        value={ratingProps.count}
        min={1}
        max={10}
        onChange={(value) => handleChange('count', value)}
      />
    </Stack>
  );
};

export default RatingFieldSettings;
