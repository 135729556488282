import {
  FormSettings,
  PropertyReference,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useWorkflowService } from '@btrway/workflow-common-provider';
import { useStepTypes } from '@btrway/workflow-manager';
import { useMemo } from 'react';

export const usePropertyReferenceDescription = (
  propertyReference: PropertyReference | undefined
) => {
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();
  const { getStepType } = useStepTypes();

  return useMemo(() => {
    if (!propertyReference) return '';

    const workflowConfig = workflow?.workflowConfiguration;
    if (!workflowConfig?.steps) return propertyReference.label;

    // Handle About Entity properties
    if (
      propertyReference.propertyKey === 'aboutEntity' &&
      propertyReference.entityProperty
    ) {
      const formSettings =
        workflow?.workflowType === WorkflowTypeEnum.form
          ? (workflowConfig.settings as FormSettings)
          : undefined;

      const aboutEntityDescription =
        formSettings?.aboutSettings?.aboutEntityDescription;
      return `${aboutEntityDescription || 'About Entity'} - ${
        propertyReference.label
      }`;
    }

    // Find the step that this property belongs to
    const step = workflowConfig.steps.find(
      (s) => s.stepKey === propertyReference.stepKey
    );
    if (!step) return propertyReference.label;

    // Get step type information
    const stepTypeDTO = step.stepTypeCode
      ? getStepType(step.stepTypeCode)
      : undefined;
    if (!stepTypeDTO?.properties) return propertyReference.label;

    // Find the matching step type property
    const stepTypeProp = stepTypeDTO.properties.find(
      (prop) => prop.key === propertyReference.propertyKey
    );

    if (stepTypeProp) {
      if (propertyReference.entityProperty) {
        // If this is a sub-property (like email.workEmail), combine the parent and child labels
        return `${stepTypeProp.label} - ${propertyReference.label}`;
      }
      // For direct properties, use the step type's label
      return stepTypeProp.label;
    }

    // If no step type property match was found, use step title as context
    if (step.title && !propertyReference.label.includes(step.title)) {
      return `${step.title} - ${propertyReference.label}`;
    }

    return propertyReference.label;
  }, [propertyReference, workflow, getStepType]);
};

export default usePropertyReferenceDescription;
