import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return '';
  }

  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);

    // Check if the parsed number is valid
    if (!parsedNumber.isValid()) {
      return phoneNumber; // Return the original input if invalid
    }

    // Format US numbers using National format, others using International format
    if (parsedNumber.country === 'US') {
      return parsedNumber.formatNational();
    } else {
      return parsedNumber.formatInternational();
    }
  } catch (error) {
    // console.error('Error formatting phone number:', error);
    return phoneNumber; // Return original number if parsing fails
  }
};
