import { PropertyReference } from '@btrway/api-workflow';

export enum PropertyGroupType {
  FormField = 'FORM_FIELD',
  AboutEntity = 'ABOUT_ENTITY',
  StepType = 'STEP_TYPE',
  Submitter = 'SUBMITTER',
  StepOutput = 'STEP_OUTPUT',
}

export interface PropertyGroup {
  key: string;
  label: string;
  type: PropertyGroupType;
  stepKey?: string;
  properties: PropertyReference[];
}
