import {
  WorkflowConfig,
  WorkflowTemplateRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import {
  FormSettingsEditor,
  FormSettingsEditorRef,
} from '@btrway/form-settings';
import { uuid } from '@btrway/utils';
import { Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useRef, useState } from 'react';

interface AddWorkflowTemplateModalProps {
  workflowType: WorkflowTypeEnum;
  workflowTypeDescription: string;
  opened: boolean;
  onClose: () => void;
  onSave: (partialWorkflowTemplate: Partial<WorkflowTemplateRequest>) => void;
}

const AddWorkflowTemplateModal = ({
  workflowType,
  workflowTypeDescription,
  opened,
  onClose,
  onSave,
}: AddWorkflowTemplateModalProps) => {
  const [workflowName, setWorkflowName] = useState('');
  const formSettingsRef = useRef<FormSettingsEditorRef>(null);

  const handleCreateWorkflow = () => {
    if (workflowName) {
      const formSettings = formSettingsRef.current?.getFormSettings();

      let workflowConfig: WorkflowConfig | undefined;

      if (workflowType === WorkflowTypeEnum.form && formSettings) {
        workflowConfig = {
          settings: formSettings,
        };
      }

      onSave({
        name: workflowName,
        workflowType: workflowType,
        templateKey: uuid(),
        published: false,
        workflowConfiguration: workflowConfig,
      });

      resetState();
      onClose();
    }
  };

  const resetState = () => {
    setWorkflowName('');
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetState();
        onClose();
      }}
      title={
        <Text fz="xl" fw={600}>
          Add {workflowTypeDescription}
        </Text>
      }
      size="80%"
    >
      <Modal.Body mih="calc(100vh *.6)">
        <Stack gap="xl">
          <TextInput
            label={`${workflowTypeDescription} Name`}
            placeholder={`Enter ${workflowTypeDescription} Name`}
            value={workflowName}
            onChange={(event) => setWorkflowName(event.currentTarget.value)}
            data-autofocus
            withAsterisk
          />

          {workflowType === WorkflowTypeEnum.form && (
            <FormSettingsEditor ref={formSettingsRef} />
          )}
        </Stack>
      </Modal.Body>

      <Group justify="flex-end">
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleCreateWorkflow}
          leftSection={<IconPlus size={16} />}
          disabled={!workflowName}
        >
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default AddWorkflowTemplateModal;
