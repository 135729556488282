import { SliderFieldProperties } from '@btrway/api-workflow';
import { Slider } from '@mantine/core';
import React from 'react';
import { BaseFieldProps, DisplayMode, isReadOnly } from '../../types/baseField';

interface SliderFieldProps extends BaseFieldProps<number> {}

export const SliderField: React.FC<SliderFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const sliderFieldProperties = field.fieldProperties as SliderFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const minValue = sliderFieldProperties?.minValue ?? 0;
  const maxValue = sliderFieldProperties?.maxValue ?? 100;
  const step = sliderFieldProperties?.step ?? 1;

  const handleChange = (newValue: number) => {
    if (readonly) return;
    onChange?.(newValue);
  };

  // Always show the slider but make it readonly in display/readonly modes
  return (
    <Slider
      m="lg"
      value={value ?? minValue}
      onChange={handleChange}
      min={minValue}
      max={maxValue}
      step={step}
      disabled={readonly}
      marks={[
        { value: minValue, label: minValue.toString() },
        { value: maxValue, label: maxValue.toString() },
      ]}
      labelAlwaysOn
      label={(value) => value.toString()}
      styles={
        readonly
          ? {
              bar: {
                cursor: 'default',
              },
              thumb: {
                cursor: 'default',
                '&:hover': {
                  cursor: 'default',
                },
              },
              markLabel: {
                cursor: 'default',
              },
            }
          : undefined
      }
    />
  );
};

export default SliderField;
