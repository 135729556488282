import { Container, ScrollArea } from '@mantine/core';
import React from 'react';
import CertificationListView from '../CertificationListView/CertificationListView';

interface CertificationBuilderProps {
  workflowKey: string;
}

const CertificationBuilder: React.FC<CertificationBuilderProps> = ({
  workflowKey,
}) => {
  return (
    <ScrollArea style={{ height: '100%', width: '100%' }}>
      <Container size="md" px="md" style={{ maxWidth: 800 }}>
        <CertificationListView workflowKey={workflowKey} nestedList={false} />
      </Container>
    </ScrollArea>
  );
};

export default CertificationBuilder;
