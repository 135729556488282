// import { FormConfig } from '@btrway/api-workflow';
// import {
//   useFormCompletion,
//   useFormValidation,
//   ValidationErrors,
// } from '@btrway/form-completion-manager';
// import { Paper } from '@mantine/core';
// import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

// import { FieldRenderer } from '../FieldRenderer/FieldRenderer';

// interface FormRendererProps {
//   formConfig: FormConfig;
//   initialValues?: Record<string, any>;
//   onValuesChange?: (values: Record<string, any>) => void;
//   onValidationChange?: (errors: ValidationErrors) => void;
//   readOnly?: boolean;
// }

// export interface FormRendererRef {
//   validate: () => boolean;
// }

// const FormRendererContent = forwardRef<
//   FormRendererRef,
//   Omit<FormRendererProps, 'initialValues'>
// >(
//   (
//     { formConfig, onValuesChange, onValidationChange, readOnly = false },
//     ref
//   ) => {
//     const isFirstRender = useRef(true);
//     // const { values } = useFormValues();
//     const { validateForm, isValid } = useFormValidation(formConfig.fields);

//     // Expose validate method through ref
//     useImperativeHandle(
//       ref,
//       () => ({
//         validate: () => {
//           const errors = validateForm();
//           console.log('errors', errors);
//           onValidationChange?.(errors);
//           return isValid();
//         },
//       }),
//       [validateForm, isValid, onValidationChange]
//     );

//     return (
//       <Paper bg="white" shadow="none" style={{ width: '100%', height: '100%' }}>
//         <FieldRenderer fields={formConfig.fields} readOnly={readOnly} />
//       </Paper>
//     );
//   }
// );

// export const FormRenderer = forwardRef<FormRendererRef, FormRendererProps>(
//   (props, ref) => {
//     const { setFormValues } = useFormCompletion();

//     // Initialize form values if provided
//     useEffect(() => {
//       if (props.initialValues) {
//         setFormValues(props.initialValues);
//       }
//     }, [props.initialValues]);

//     return <FormRendererContent {...props} ref={ref} />;
//   }
// );

// FormRenderer.displayName = 'FormRenderer';

import { FormConfig } from '@btrway/api-workflow';
import {
  useFormCompletion,
  useFormValidation,
  ValidationErrors,
} from '@btrway/form-completion-manager';
import { Paper } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FieldRenderer } from '../FieldRenderer/FieldRenderer';

interface FormRendererProps {
  formConfig: FormConfig;
  stepKey: string; // Add stepKey to identify this form renderer
  initialValues?: Record<string, any>;
  onValuesChange?: (values: Record<string, any>) => void;
  onValidationChange?: (errors: ValidationErrors) => void;
  readOnly?: boolean;
}

export interface FormRendererRef {
  validate: () => boolean;
}

const FormRendererContent = forwardRef<
  FormRendererRef,
  Omit<FormRendererProps, 'initialValues'>
>(
  (
    {
      formConfig,
      stepKey,
      onValuesChange,
      onValidationChange,
      readOnly = false,
    },
    ref
  ) => {
    const isFirstRender = useRef(true);
    const { validateForm, isValid } = useFormValidation(formConfig.fields);
    const { addFields, removeFields } = useFormCompletion();

    // Register fields when mounted, unregister when unmounted
    useEffect(() => {
      console.log(
        'Registering fields for step:',
        stepKey,
        JSON.stringify(formConfig.fields)
      );
      addFields(stepKey, formConfig.fields);

      return () => {
        console.log('Unregistering fields for step:', stepKey);
        removeFields(stepKey);
      };
    }, [stepKey, formConfig.fields, addFields, removeFields]);

    // Expose validate method through ref
    useImperativeHandle(
      ref,
      () => ({
        validate: () => {
          const errors = validateForm();
          console.log('errors', errors);
          onValidationChange?.(errors);
          return isValid();
        },
      }),
      [validateForm, isValid, onValidationChange]
    );

    return (
      <Paper bg="white" shadow="none" style={{ width: '100%', height: '100%' }}>
        <FieldRenderer fields={formConfig.fields} readOnly={readOnly} />
      </Paper>
    );
  }
);

export const FormRenderer = forwardRef<FormRendererRef, FormRendererProps>(
  (props, ref) => {
    const { setFormValues } = useFormCompletion();

    // Initialize form values if provided
    useEffect(() => {
      if (props.initialValues) {
        setFormValues(props.initialValues);
      }
    }, [props.initialValues]);

    return <FormRendererContent {...props} ref={ref} />;
  }
);

FormRenderer.displayName = 'FormRenderer';
