import { TextInput, TextInputProps } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { useState } from 'react';

type EmailAddressInputProps = Omit<TextInputProps, 'error'> & {
  onEmailChange?: (email: string | null) => void;
};

export const EmailAddressInput = ({
  value,
  onChange,
  onEmailChange,
  description,
  ...props
}: EmailAddressInputProps) => {
  const [error, setError] = useState<string | null>(null);

  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmail = (email: string): boolean => {
    if (!email) return true;
    return EMAIL_REGEX.test(email);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();

    onChange?.(event);

    if (!newValue) {
      setError(null);
      onEmailChange?.(null);
      return;
    }

    onEmailChange?.(newValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();

    if (!newValue) {
      setError(null);
      onEmailChange?.(null);
      return;
    }

    if (validateEmail(newValue)) {
      setError(null);
      onEmailChange?.(newValue.toLowerCase());
    } else {
      setError('Please enter a valid email address');
      onEmailChange?.(newValue);
    }
  };

  return (
    <TextInput
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      type="email"
      autoComplete="email"
      description={description}
      leftSection={<IconMail size={16} />}
    />
  );
};
