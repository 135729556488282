import {
  DataTypeEnum,
  FieldConfig,
  FieldTypeEnum,
  PropertyReference,
  TextFieldDataType,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';

export const getDefaultFieldConfig = (
  propertyReference: PropertyReference
): FieldConfig => {
  const baseConfig: Partial<FieldConfig> = {
    fieldKey: uuid(),
    label: propertyReference.label,
    propertyReference: {
      ...propertyReference,
      dataType: DataTypeEnum.person,
    },
    required: true,
  };

  switch (propertyReference.dataType) {
    case DataTypeEnum.text:
      return {
        ...baseConfig,
        type: FieldTypeEnum.text,
      } as FieldConfig;

    case DataTypeEnum.email:
      return {
        ...baseConfig,
        type: FieldTypeEnum.text,
        fieldProperties: {
          dataType: TextFieldDataType.email,
        },
      } as FieldConfig;

    case DataTypeEnum.phone:
      return {
        ...baseConfig,
        type: FieldTypeEnum.text,
        fieldProperties: {
          dataType: TextFieldDataType.phone,
        },
      } as FieldConfig;

    case DataTypeEnum.date:
      return {
        ...baseConfig,
        type: FieldTypeEnum.date,
      } as FieldConfig;

    case DataTypeEnum.number:
      return {
        ...baseConfig,
        type: FieldTypeEnum.number,
      } as FieldConfig;

    case DataTypeEnum.location:
      return {
        ...baseConfig,
        type: FieldTypeEnum.address,
      } as FieldConfig;

    // Add other data type mappings as needed
    default:
      return {
        ...baseConfig,
        type: FieldTypeEnum.text,
      } as FieldConfig;
  }
};
