import { GlobalSearchResponse } from '@btrway/api-core';
import { DataTypeEnum, FormSettings } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useFormStart } from '@btrway/form-completion-manager';
import { Box, Button, Group, Stack, Text, Title } from '@mantine/core';
import { useState } from 'react';
import { StartStepOthers } from '../StartStepOthers/StartStepOthers';
import { StartStepSelf } from '../StartStepSelf/StartStepSelf';

interface StartStepOptionsProps {
  formSettings: FormSettings;
  onWorkflowStart?: (params: {
    workgroupId: number;
    aboutEntityId?: number;
    aboutEntityType?: DataTypeEnum;
  }) => void;
  onCancel: () => void;
}

export const StartStepOptions = ({
  formSettings,
  onWorkflowStart,
  onCancel,
}: StartStepOptionsProps) => {
  const { currentUser } = useAuthenticatedUser();
  const [mountKey] = useState(() => Date.now());

  const {
    canStartForSelf,
    canStartForOthers,
    workgroupsForSelf,
    workgroupsForOthers,
    userRolesForOthers,
    isLoading,
  } = useFormStart(currentUser, formSettings);

  const handleStartSelf = (workgroupId: number) => {
    if (onWorkflowStart) {
      onWorkflowStart({ workgroupId });
    }
  };

  const handleStartOthers = (
    workgroupId: number,
    entityData: GlobalSearchResponse
  ) => {
    console.log('handleStartOthers: ', workgroupId);
    if (onWorkflowStart) {
      onWorkflowStart({
        workgroupId,
        aboutEntityId: entityData.entityId,
        aboutEntityType: entityData.entityType,
      });
    }
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const renderHeader = () => {
    if (canStartForSelf && canStartForOthers) {
      return (
        <Title order={2} fw={600} ta="center" mb="xl">
          Who do you want to start this form for?
        </Title>
      );
    }
    if (canStartForOthers) {
      return (
        <Text size="xl" fw={600} ta="center" mb="xl">
          Select who you want to complete this form for
        </Text>
      );
    }
    if (canStartForSelf) {
      return (
        <Text size="xl" fw={600} ta="center" mb="xl">
          Click start button to proceed
        </Text>
      );
    }
    return null;
  };

  const renderContent = () => {
    const showHeader = canStartForSelf && canStartForOthers;

    if (showHeader) {
      return (
        <Group p="xl" gap="xl" grow align="stretch">
          <StartStepSelf
            workgroups={workgroupsForSelf}
            onStart={handleStartSelf}
            showHeader={true}
          />
          <StartStepOthers
            workgroups={workgroupsForOthers}
            userRoles={userRolesForOthers}
            mountKey={mountKey}
            onStart={handleStartOthers}
            showHeader={true}
          />
        </Group>
      );
    }

    // For single option, wrap in a container with fixed width and center it
    const singleOptionContent = canStartForOthers ? (
      <StartStepOthers
        workgroups={workgroupsForOthers}
        userRoles={userRolesForOthers}
        mountKey={mountKey}
        onStart={handleStartOthers}
      />
    ) : (
      <StartStepSelf workgroups={workgroupsForSelf} onStart={handleStartSelf} />
    );

    return (
      <Box mx="auto" w="600px">
        {singleOptionContent}
      </Box>
    );
  };

  return (
    <Stack style={{ width: '75%', minHeight: '100%' }} gap={0}>
      <Box mt="xl" style={{ flex: 1 }}>
        <Stack p="xl">
          {renderHeader()}
          {renderContent()}
        </Stack>
      </Box>
      <Box
        p="md"
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={onCancel} variant="outline" color="gray.6" size="md">
          Close
        </Button>
      </Box>
    </Stack>
  );
};

export default StartStepOptions;
