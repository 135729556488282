import { TextInput, Tree } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';
import { usePropertyTree } from '../../hooks/usePropertyTree';
import { PropertyTreeSelectorProps, RenderNodeProps } from '../../types/types';
import { TreeNode } from '../TreeNode/TreeNode';

export const PropertyTreeSelector: React.FC<PropertyTreeSelectorProps> = ({
  groupedBindingPropertyReferences,
  field,
  selectedProperty,
  onPropertySelect,
}) => {
  const { tree, filteredTreeData, searchQuery, setSearchQuery } =
    usePropertyTree({
      groupedBindingPropertyReferences,
      field,
      selectedProperty,
    });

  const renderNode = (props: RenderNodeProps) => (
    <TreeNode
      {...props}
      onSelect={onPropertySelect}
      selectedProperty={selectedProperty}
      tree={tree}
    />
  );

  return (
    <div>
      <TextInput
        placeholder="Search properties..."
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.currentTarget.value)}
        leftSection={<IconSearch size={16} />}
        mb="md"
      />
      {filteredTreeData.length > 0 ? (
        <Tree
          data={filteredTreeData}
          tree={tree}
          renderNode={renderNode}
          expandOnClick={false}
          selectOnClick={false}
        />
      ) : (
        <div
          style={{ textAlign: 'center', color: 'var(--mantine-color-gray-6)' }}
        >
          No properties available
        </div>
      )}
    </div>
  );
};
