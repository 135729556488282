import { Badge, Card, Group, Skeleton, Stack, Text } from '@mantine/core';
import React, { useEffect } from 'react';

import {
  useTaskFetcher,
  useTaskManager,
  useTaskModal,
} from '@btrway/task-manager';
import { TaskTypeIcon } from '@btrway/workflow-components';

import {
  EntityTaskResponse,
  TaskRetrievalRequestCompletionStatus,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { formatDateTime } from '@btrway/utils';

interface CertificationsViewProps {
  personId?: number; // Optional - if not provided, uses current user
}

const MIN_LOADING_DURATION = 400; // Minimum time to show loading state
const LOADING_DELAY = 200; // Delay before showing skeleton

const LoadingContent = () => (
  <div style={{ transition: 'opacity 0.2s ease-in-out' }}>
    <Stack gap="xs">
      {Array.from({ length: 10 }).map((_, i) => (
        <Card key={i} padding="sm" radius="sm" withBorder shadow="none">
          <Group justify="space-between" mb="xs">
            <Group gap="sm">
              <Skeleton circle height={20} />
              <Skeleton height={18} width={200} />
            </Group>
            <Skeleton height={18} width={100} />
          </Group>
          <Skeleton height={16} width="40%" />
        </Card>
      ))}
    </Stack>
  </div>
);

const CertificationsView: React.FC<CertificationsViewProps> = ({
  personId,
}) => {
  const { openTask } = useTaskModal();
  const { fetchTasksWithCriteria } = useTaskFetcher();
  const { currentPerson } = useAuthenticatedUser();
  const { tasks } = useTaskManager();
  const [isLoading, setIsLoading] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false); // Start as false
  const [error, setError] = React.useState<Error | null>(null);
  const loadingTimerRef = React.useRef<NodeJS.Timeout>();
  const hideLoadingTimerRef = React.useRef<NodeJS.Timeout>();

  const targetPersonId = personId || currentPerson.id;

  useEffect(() => {
    if (!targetPersonId) return;

    const startTime = Date.now();
    setIsLoading(true);

    // Clear any existing timers
    if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
    if (hideLoadingTimerRef.current) clearTimeout(hideLoadingTimerRef.current);

    // Set timer to show loading state after delay
    loadingTimerRef.current = setTimeout(() => {
      setShowLoading(true);
    }, LOADING_DELAY);

    fetchTasksWithCriteria(
      [targetPersonId],
      {
        workflowType: WorkflowTypeEnum.certification,
        completionStatus: TaskRetrievalRequestCompletionStatus.ALL,
      },
      {
        onSuccess: () => {
          const elapsedTime = Date.now() - startTime;
          const remainingTime = Math.max(0, MIN_LOADING_DURATION - elapsedTime);

          // If load finished before loading delay, clear the loading timer
          if (elapsedTime < LOADING_DELAY) {
            clearTimeout(loadingTimerRef.current);
            setShowLoading(false);
            setIsLoading(false);
            return;
          }

          // Ensure loading state shows for at least MIN_LOADING_DURATION
          hideLoadingTimerRef.current = setTimeout(() => {
            setIsLoading(false);
            // Add slight delay before hiding loading state for fade out
            setTimeout(() => setShowLoading(false), 200);
          }, remainingTime);
        },
        onError: (err) => {
          clearTimeout(loadingTimerRef.current);
          setError(err as Error);
          setIsLoading(false);
          setShowLoading(false);
        },
      }
    );

    // Cleanup timers on unmount or when dependencies change
    return () => {
      if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
      if (hideLoadingTimerRef.current)
        clearTimeout(hideLoadingTimerRef.current);
    };
  }, [targetPersonId, fetchTasksWithCriteria]);

  const certificationTasks = React.useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.rollupWorkflowType === WorkflowTypeEnum.certification &&
          task.taskType !== TaskTypeEnum.certification &&
          task.taskType !== TaskTypeEnum.completeTaskList &&
          task.parentWorkflowType !== WorkflowTypeEnum.taskOption
      ),
    [tasks, targetPersonId]
  );

  const handleTaskClick = (task: EntityTaskResponse) => {
    openTask({
      taskProperties: task.taskProperties || {},
      taskInstanceId: task.taskInstanceId,
      assignedEntityType: task.assignedEntityType,
      assignedEntityId: task.assignedEntityId,
      workgroupId: task.workgroupId,
      derivedWorkflowKey: task.parentWorkflowKey,
      taskKey: task.taskKey,
      taskType: task.taskType,
    });
  };

  const renderContent = () => {
    if (showLoading) {
      return (
        <div
          style={{
            opacity: isLoading ? 1 : 0,
            transition: 'opacity 0.2s ease-in-out',
          }}
        >
          <LoadingContent />
        </div>
      );
    }

    if (error) {
      return <Text c="red">Error loading tasks: {error.message}</Text>;
    }

    if (!certificationTasks.length) {
      return (
        <Text c="dimmed" ta="center">
          No pending certifications
        </Text>
      );
    }

    return (
      <div
        style={{
          opacity: 1,
          transition: 'opacity 0.2s ease-in-out',
        }}
      >
        <Stack gap="xs" style={{ width: '100%' }}>
          {certificationTasks
            .sort((a, b) => {
              // Handle cases where taskTitle might be undefined
              const titleA = a.taskTitle || '';
              const titleB = b.taskTitle || '';
              return titleA.localeCompare(titleB);
            })
            .map((task) => (
              <Card
                key={task.taskInstanceId || task.taskKey}
                padding="sm"
                radius="sm"
                withBorder
                shadow="none"
                onClick={() => handleTaskClick(task)}
                style={{ cursor: 'pointer' }}
              >
                <Group justify="space-between" mb="xs">
                  <Group gap="sm">
                    {task.taskType && (
                      <TaskTypeIcon iconName={task.taskType} size={20} />
                    )}
                    <Text fw={500}>{task.taskTitle}</Text>
                  </Group>
                </Group>
                {task.assignedAt && (
                  <Text size="sm" c="dimmed">
                    Assigned: {formatDateTime(task.assignedAt)}
                  </Text>
                )}
                {task.dueAt && (
                  <Text size="sm" c="dimmed">
                    Due: {formatDateTime(task.dueAt)}
                  </Text>
                )}
                {task.completed && (
                  <Badge color={task.completed ? 'green' : 'blue'}>
                    {task.completed ? 'Completed' : 'Open'}
                  </Badge>
                )}
              </Card>
            ))}
        </Stack>
      </div>
    );
  };

  return (
    <Card p="md" radius="lg" mih={800}>
      <Text fz="lg" fw={600} mb="sm">
        Certification Requirements
      </Text>
      {renderContent()}
    </Card>
  );
};

export default CertificationsView;
