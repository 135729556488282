import { WorkflowTypeEnum } from '@btrway/api-workflow';
import React, { lazy } from 'react';

const CertificationBuilder = lazy(() =>
  import('@btrway/task-list-builder').then((module) => ({
    default: module.CertificationBuilder,
  }))
);
const TaskListBuilder = lazy(() =>
  import('@btrway/task-list-builder').then((module) => ({
    default: module.TaskListBuilder,
  }))
);
const ProcessBuilder = lazy(() =>
  import('@btrway/process-builder').then((module) => ({
    default: module.ProcessBuilder,
  }))
);

interface WorkflowBuilderComponentProps {
  workflowKey: string;
  workflowType?: WorkflowTypeEnum;
}

export const WorkflowBuilderComponent: React.FC<
  WorkflowBuilderComponentProps
> = ({ workflowKey, workflowType }) => {
  if (!workflowKey || !workflowType) {
    return null;
  }

  const builderProps = { workflowKey };

  switch (workflowType) {
    case WorkflowTypeEnum.formPacket:
    case WorkflowTypeEnum.taskList:
    case WorkflowTypeEnum.curriculum:
      return <TaskListBuilder {...builderProps} />;
    case WorkflowTypeEnum.certification:
      return <CertificationBuilder {...builderProps} />;
    default:
      return <ProcessBuilder {...builderProps} />;
  }
};
