import { WorkflowTemplateSettings } from '@btrway/api-workflow';
import { useOrganizationTypes } from '@btrway/reference-data-manager';
import { useUserRoles } from '@btrway/security-manager';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-common-provider';
import { Button, Card, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import {
  formatRoleNames,
  shouldShowDefaultRoles,
} from '../../utils/defaultRolesUtils';
import { DeploymentButton } from '../DeploymentButton/DeploymentButton';
import { DeploymentFilterDrawer } from '../DeploymentFilterDrawer/DeploymentFilterDrawer';

interface DeploymentFilterViewProps {
  workflow?: CommonWorkflow | null;
}

export const DeploymentFilterView: React.FC<DeploymentFilterViewProps> = ({
  workflow,
}) => {
  const workflowService = useWorkflowService();
  const { getOrganizationTypeById } = useOrganizationTypes();
  const { userRoles } = useUserRoles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [templateSettings, setTemplateSettings] =
    useState<WorkflowTemplateSettings | null>(
      workflow?.templateSettings || null
    );

  const showRoles = shouldShowDefaultRoles(workflow?.workflowType);

  if (!workflow || workflow.sourceType !== 'template') {
    return null;
  }

  const handleClose = () => setIsDrawerOpen(false);

  const handleSave = async (newTemplateSettings: WorkflowTemplateSettings) => {
    const updatedWorkflow = {
      ...workflow,
      templateSettings: newTemplateSettings,
    };

    setTemplateSettings(newTemplateSettings || {});
    await workflowService.updateWorkflow(updatedWorkflow);
    handleClose();
  };

  const handleButtonClick = () => {
    setIsDrawerOpen(true);
  };

  const renderOrgTypesSummary = () => {
    const orgTypeIds =
      templateSettings?.organizationFilter?.organizationTypeIds || [];

    if (!orgTypeIds.length) {
      return <Text fz="sm">All Organization Types</Text>;
    }

    return (
      <Text fz="sm">
        {orgTypeIds
          .map((id) => getOrganizationTypeById(id)?.name)
          .filter(Boolean)
          .sort()
          .join(', ')}
      </Text>
    );
  };

  const renderStatesSummary = () => {
    const states = templateSettings?.organizationFilter?.stateCodes || [];

    if (!states.length) {
      return <Text fz="sm">All States</Text>;
    }

    return <Text fz="sm">{[...states].sort().join(', ')}</Text>;
  };

  const renderRoleSummary = () => {
    if (!showRoles) {
      return null;
    }

    const roles = templateSettings?.defaultUserRoles || [];

    if (!roles.length) {
      return (
        <Stack gap={4}>
          <Text fz="sm" fw={600}>
            Default Roles:
          </Text>
          <Text fz="sm">None</Text>
        </Stack>
      );
    }

    const roleNames = formatRoleNames(roles, userRoles);

    return (
      <Stack gap={4}>
        <Text fz="sm" fw={600}>
          Default Roles for Assignments:
        </Text>
        <Text fz="sm">{roleNames}</Text>
      </Stack>
    );
  };

  return (
    <Stack gap="md">
      <DeploymentButton workflow={workflow} />

      <Card
        padding="xs"
        radius="md"
        bg="red.1"
        withBorder
        style={{
          border: '1px solid var(--mantine-color-gray-5)',
          width: '300px',
        }}
      >
        <Stack gap="xs">
          <Group justify="space-between" align="center">
            <Text fw={600}>Deployment Settings</Text>
            <Button
              variant="subtle"
              size="compact-sm"
              onClick={handleButtonClick}
            >
              Edit
            </Button>
          </Group>

          <Stack gap={4}>
            <Text fz="sm" fw={600}>
              Organization Types:
            </Text>
            {renderOrgTypesSummary()}
          </Stack>

          <Stack gap={4}>
            <Text fz="sm" fw={600}>
              States:
            </Text>
            {renderStatesSummary()}
          </Stack>

          {renderRoleSummary()}
        </Stack>
      </Card>

      <DeploymentFilterDrawer
        opened={isDrawerOpen}
        onClose={handleClose}
        onSave={handleSave}
        templateSettings={templateSettings}
      />
    </Stack>
  );
};
