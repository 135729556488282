import { TaskConfig } from '@btrway/api-workflow';
import { SETTINGS_PANEL_LAYOUT } from '@btrway/workflow-builder-settings-panel';
import { useWorkflowUpdater } from '@btrway/workflow-common-provider';
import { Box, Button, Group, ScrollArea } from '@mantine/core';
import React, { useState } from 'react';

// Types for our editor components
export interface EditorChildProps {
  selectedTasks: TaskConfig[];
  onToggle: (taskConfig: TaskConfig) => void;
  onToggleBatch: (taskConfigs: TaskConfig[]) => void;
}

interface EditorWrapperProps {
  initialSelectedTasks: TaskConfig[];
  onSave: (addedTasks: TaskConfig[], removedTaskKeys: string[]) => void;
  onClose: () => void;
  children: (props: EditorChildProps) => React.ReactElement;
}

export const EditorWrapper: React.FC<EditorWrapperProps> = ({
  children,
  initialSelectedTasks,
  onSave,
  onClose,
}) => {
  const [localSelectedTasks, setLocalSelectedTasks] =
    useState<TaskConfig[]>(initialSelectedTasks);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { updateConfig } = useWorkflowUpdater();

  const handleToggle = (taskConfig: TaskConfig) => {
    const taskExists = localSelectedTasks.find(
      (task) => task.taskKey === taskConfig.taskKey
    );
    let newTasks: TaskConfig[];

    if (taskExists) {
      newTasks = localSelectedTasks.filter(
        (task) => task.taskKey !== taskConfig.taskKey
      );
    } else {
      newTasks = [...localSelectedTasks, taskConfig];
    }

    setLocalSelectedTasks(newTasks);
    setHasUnsavedChanges(true);
  };

  const handleToggleBatch = (taskConfigs: TaskConfig[]) => {
    const newTasks = [...localSelectedTasks];

    taskConfigs.forEach((taskConfig) => {
      const taskExists = newTasks.find(
        (task) => task.taskKey === taskConfig.taskKey
      );

      if (taskExists) {
        // Remove task if it exists
        const index = newTasks.findIndex(
          (t) => t.taskKey === taskConfig.taskKey
        );
        if (index !== -1) {
          newTasks.splice(index, 1);
        }
      } else {
        // Add task if it doesn't exist
        newTasks.push(taskConfig);
      }
    });

    setLocalSelectedTasks(newTasks);
    setHasUnsavedChanges(true);
  };

  const handleSave = async () => {
    const initialTaskKeys = new Set(
      initialSelectedTasks.map((task) => task.taskKey)
    );
    const selectedTaskKeys = new Set(
      localSelectedTasks.map((task) => task.taskKey)
    );

    const addedTasks = localSelectedTasks.filter(
      (task) => !initialTaskKeys.has(task.taskKey)
    );

    const removedTaskKeys = initialSelectedTasks
      .filter((task) => !selectedTaskKeys.has(task.taskKey))
      .map((task) => task.taskKey);

    try {
      // Update workflow configuration first
      await updateConfig({
        workflowConfig: {
          tasks: addedTasks,
        },
        deleteConfigs: removedTaskKeys,
      });

      // Then notify parent
      onSave(addedTasks, removedTaskKeys);
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error saving workflow changes:', error);
      // Handle error appropriately
    }
  };

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ScrollArea
        style={{
          flex: 1,
          marginBottom: `${SETTINGS_PANEL_LAYOUT.FOOTER_HEIGHT}px`,
        }}
      >
        {children({
          selectedTasks: localSelectedTasks,
          onToggle: handleToggle,
          onToggleBatch: handleToggleBatch,
        })}
      </ScrollArea>
      <Group
        justify="space-between"
        p="md"
        bg="gray.1"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: `${SETTINGS_PANEL_LAYOUT.FOOTER_HEIGHT}px`,
        }}
      >
        <Group gap="xs" justify="flex-end" style={{ marginLeft: 'auto' }}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Group>
    </Box>
  );
};
