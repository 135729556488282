import { DataTypeEnum } from '@btrway/api-core';
import { FieldConfig } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFieldDependencyGraph } from '../hooks/useFieldDependencyGraph';

interface FormCompletionContextType {
  workgroupId?: number;
  submittedBy: number;
  organizationId: number;
  aboutEntityType?: DataTypeEnum;
  aboutEntityId?: number;
  setFormContext: (values: {
    workgroupId?: number;
    aboutEntityType?: DataTypeEnum;
    aboutEntityId?: number;
  }) => void;
  formValues: Record<string, any>;
  setFormValue: (fieldKey: string, value: any) => void;
  setFormValues: (values: Record<string, any>) => void;
  clearFormValues: () => void;
  dependencyGraph?: ReturnType<typeof useFieldDependencyGraph>;
  addFields: (stepKey: string, fields: FieldConfig[]) => void;
  removeFields: (stepKey: string) => void;
}

interface StepFields {
  [stepKey: string]: FieldConfig[];
}

const FormCompletionContext = createContext<
  FormCompletionContextType | undefined
>(undefined);

interface FormCompletionProviderProps {
  children: React.ReactNode;
}

export const FormCompletionProvider: React.FC<FormCompletionProviderProps> = ({
  children,
}) => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const [workgroupId, setWorkgroupId] = useState<number>();
  const [aboutEntityType, setAboutEntityType] = useState<DataTypeEnum>();
  const [aboutEntityId, setAboutEntityId] = useState<number>();
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [stepFields, setStepFields] = useState<StepFields>({});

  // Combine all fields from all steps
  const allFields = useMemo(() => {
    return Object.values(stepFields).flat();
  }, [stepFields]);

  // Generate dependency graph from all fields
  const dependencyGraph = useFieldDependencyGraph(allFields, formValues);

  const addFields = useCallback((stepKey: string, fields: FieldConfig[]) => {
    setStepFields((prev) => ({
      ...prev,
      [stepKey]: fields,
    }));
  }, []);

  const removeFields = useCallback((stepKey: string) => {
    setStepFields((prev) => {
      const { [stepKey]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const setFormValue = useCallback((fieldKey: string, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldKey]: value,
    }));
  }, []);

  const clearFormValues = useCallback(() => {
    setFormValues({});
  }, []);

  const setFormContext = useCallback(
    (values: {
      workgroupId?: number;
      aboutEntityType?: DataTypeEnum;
      aboutEntityId?: number;
    }) => {
      setWorkgroupId(values.workgroupId);
      setAboutEntityType(values.aboutEntityType);
      setAboutEntityId(values.aboutEntityId);
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      workgroupId,
      submittedBy: currentPerson.id,
      organizationId: currentOrganization.id,
      aboutEntityType,
      aboutEntityId,
      setFormContext,
      formValues,
      setFormValue,
      setFormValues,
      clearFormValues,
      dependencyGraph,
      addFields,
      removeFields,
    }),
    [
      workgroupId,
      aboutEntityType,
      aboutEntityId,
      currentPerson.id,
      currentOrganization.id,
      setFormContext,
      formValues,
      clearFormValues,
      dependencyGraph,
      addFields,
      removeFields,
    ]
  );

  return (
    <FormCompletionContext.Provider value={contextValue}>
      {children}
    </FormCompletionContext.Provider>
  );
};

export const useFormCompletion = () => {
  const context = useContext(FormCompletionContext);
  if (!context) {
    throw new Error(
      'useFormCompletion must be used within a FormCompletionProvider'
    );
  }
  return context;
};
