import { GlobalSearchResponse } from '@btrway/api-core';
import { TaskAssignmentConfig } from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { Card, Group, Loader, Stack, Text } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconUserSearch,
} from '@tabler/icons-react';
import React from 'react';

interface PeopleSearchProps {
  searchValue: string;
  isSearching: boolean;
  searchResults: GlobalSearchResponse[];
  taskAssignmentConfig?: TaskAssignmentConfig;
  onPersonSelect: (person: GlobalSearchResponse) => void;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const PeopleSearch: React.FC<PeopleSearchProps> = ({
  searchValue,
  isSearching,
  searchResults,
  taskAssignmentConfig,
  onPersonSelect,
  isExpanded,
  onToggleExpand,
}) => {
  const hasSelectedPerson =
    taskAssignmentConfig?.assignmentType === 'person' &&
    taskAssignmentConfig.personId?.value !== undefined;

  const isSelectedPerson = (entityId: number) =>
    taskAssignmentConfig?.assignmentType === 'person' &&
    taskAssignmentConfig.personId?.value === entityId;

  const getSelectedCardStyle = (isSelected: boolean) => ({
    cursor: 'pointer',
    backgroundColor: isSelected ? 'var(--mantine-color-blue-light)' : undefined,
    borderColor: isSelected ? 'var(--mantine-color-blue-filled)' : undefined,
  });

  // Only show section if there are search results, selected person, or empty search
  const shouldShowSection =
    searchResults.length > 0 ||
    hasSelectedPerson ||
    !searchValue ||
    isSearching;

  if (!shouldShowSection) {
    return null;
  }

  return (
    <Stack gap="md">
      <Group
        justify="space-between"
        mb="xs"
        style={{ cursor: 'pointer' }}
        onClick={onToggleExpand}
      >
        <Text fw={600}>Specific People</Text>
        {isExpanded ? (
          <IconChevronDown size={16} />
        ) : (
          <IconChevronRight size={16} />
        )}
      </Group>

      {isExpanded && (
        <Stack gap="xs">
          {isSearching ? (
            <Stack align="center" py="md">
              <Loader size="sm" />
            </Stack>
          ) : (
            <>
              {hasSelectedPerson &&
                !searchValue &&
                taskAssignmentConfig.personId?.value && (
                  <Card
                    p="xs"
                    radius="md"
                    withBorder
                    style={getSelectedCardStyle(true)}
                  >
                    <Group gap="xs">
                      <IconUserSearch size={16} />
                      <EntityTag
                        dataType="person"
                        id={taskAssignmentConfig.personId.value}
                        allowClick={false}
                      />
                    </Group>
                  </Card>
                )}
              {searchValue &&
                searchResults.map((result) => (
                  <Card
                    key={result.entityId}
                    p="xs"
                    radius="md"
                    withBorder
                    style={getSelectedCardStyle(
                      isSelectedPerson(result.entityId)
                    )}
                    onClick={() => onPersonSelect(result)}
                  >
                    <Group gap="xs">
                      <IconUserSearch size={16} />
                      <Text>{result.displayName}</Text>
                    </Group>
                  </Card>
                ))}
              {!searchValue && !hasSelectedPerson && (
                <Text mb="xl" size="sm" c="dimmed">
                  Search above to select specific people
                </Text>
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};
