import { FieldConfig, WeightFieldProperties } from '@btrway/api-workflow';
import { Chip, Group, NumberInput, Stack } from '@mantine/core';
import React from 'react';

interface WeightFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<WeightFieldProperties>) => void;
}

const defaultWeightFieldProperties: WeightFieldProperties = {
  allowImperial: true,
  allowMetric: true,
  decimalPlaces: 2,
};

export const WeightFieldSettings: React.FC<WeightFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const weightProps = {
    ...defaultWeightFieldProperties,
    ...(field.fieldProperties as WeightFieldProperties),
  };

  const handleSingleChange = (key: keyof WeightFieldProperties, value: any) => {
    onChange({
      ...weightProps,
      [key]: value,
    });
  };

  const handleSystemChange = (values: string[]) => {
    onChange({
      ...weightProps,
      allowImperial: values.includes('imperial'),
      allowMetric: values.includes('metric'),
    });
  };

  const selectedSystems = [];
  if (weightProps.allowImperial) selectedSystems.push('imperial');
  if (weightProps.allowMetric) selectedSystems.push('metric');

  return (
    <Stack gap="md">
      <Group wrap="nowrap" gap="xs">
        <Chip.Group
          multiple
          value={selectedSystems}
          onChange={handleSystemChange}
        >
          <Chip value="imperial">Imperial</Chip>
          <Chip value="metric">Metric</Chip>
        </Chip.Group>
      </Group>

      <NumberInput
        label="Decimal Places"
        description="Number of decimal places for all measurements"
        value={weightProps.decimalPlaces}
        onChange={(value) => handleSingleChange('decimalPlaces', value)}
        min={0}
        max={4}
        step={1}
      />
    </Stack>
  );
};
