import { FieldConfig, SliderFieldProperties } from '@btrway/api-workflow';
import { Group, NumberInput } from '@mantine/core';
import React from 'react';

interface SliderFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<SliderFieldProperties>) => void;
}

const defaultSliderFieldProperties: SliderFieldProperties = {
  minValue: 0,
  maxValue: 100,
  step: 1,
};

export const SliderFieldSettings: React.FC<SliderFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const sliderProps = {
    ...defaultSliderFieldProperties,
    ...(field.fieldProperties as SliderFieldProperties),
  };

  const handleChange = (key: keyof SliderFieldProperties, value: any) => {
    onChange({
      ...sliderProps,
      [key]: value,
    });
  };

  return (
    <Group gap="xs" wrap="nowrap">
      <NumberInput
        label="Min"
        value={sliderProps.minValue}
        onChange={(value) => handleChange('minValue', value)}
      />
      <NumberInput
        label="Max"
        value={sliderProps.maxValue}
        onChange={(value) => handleChange('maxValue', value)}
      />
      <NumberInput
        label="Increment"
        value={sliderProps.step}
        min={1}
        step={1}
        onChange={(value) => handleChange('step', value)}
      />
    </Group>
  );
};

export default SliderFieldSettings;
