import { RatingFieldProperties } from '@btrway/api-workflow';
import { Rating, Stack } from '@mantine/core';
import React from 'react';
import { BaseFieldProps, DisplayMode, isReadOnly } from '../../types/baseField';

interface RatingFieldProps extends BaseFieldProps<number> {}

export const RatingField: React.FC<RatingFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const ratingFieldProperties = field.fieldProperties as RatingFieldProperties;
  const count = ratingFieldProperties?.count ?? 5;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const handleChange = (newValue: number) => {
    if (readonly) return;
    onChange?.(newValue || null);
  };

  return (
    <Stack gap="xs">
      <Rating
        value={value ?? 0}
        size="lg"
        onChange={handleChange}
        count={count}
        readOnly={readonly}
      />
    </Stack>
  );
};

export default RatingField;
