import { FieldConfig } from '@btrway/api-workflow';
import { Stack } from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BaseFieldProps, DisplayMode } from '../../types/baseField';
import styles from './HeadingField.module.css';

interface HeadingFieldProps
  extends BaseFieldProps<never, Partial<FieldConfig>> {}

export const HeadingField: React.FC<HeadingFieldProps> = ({
  field,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const headingSize = field.styles?.headingSize || 3;
  const isEditable = displayMode === DisplayMode.Builder;

  const [isEditingHeading, setIsEditingHeading] = useState(false);
  const [isEditingSubheading, setIsEditingSubheading] = useState(false);
  const headingRef = useRef<HTMLSpanElement>(null);
  const subheadingRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (headingRef.current && !isEditingHeading) {
      headingRef.current.textContent = field.label || '';
    }
    if (subheadingRef.current && !isEditingSubheading) {
      subheadingRef.current.textContent = field.description || '';
    }
  }, [field.label, field.description, isEditingHeading, isEditingSubheading]);

  const updateField = useCallback(
    (updates: Partial<FieldConfig>) => {
      onChange?.(updates);
    },
    [onChange]
  );

  const handleHeadingFocus = useCallback(
    (e: React.FocusEvent) => {
      e.stopPropagation();
      if (isEditable) {
        setIsEditingHeading(true);
        const selection = window.getSelection();
        const range = document.createRange();
        if (headingRef.current && selection) {
          range.selectNodeContents(headingRef.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    [isEditable]
  );

  const handleSubheadingFocus = useCallback(
    (e: React.FocusEvent) => {
      e.stopPropagation();
      if (isEditable) {
        setIsEditingSubheading(true);
        const selection = window.getSelection();
        const range = document.createRange();
        if (subheadingRef.current && selection) {
          range.selectNodeContents(subheadingRef.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    [isEditable]
  );

  const handleHeadingBlur = useCallback(
    (e: React.FocusEvent) => {
      e.stopPropagation();
      setIsEditingHeading(false);
      if (isEditable && headingRef.current?.textContent !== field.label) {
        updateField({
          label: headingRef.current?.textContent || '',
        });
      }
    },
    [field.label, updateField, isEditable]
  );

  const handleSubheadingBlur = useCallback(
    (e: React.FocusEvent) => {
      e.stopPropagation();
      setIsEditingSubheading(false);
      if (
        isEditable &&
        subheadingRef.current?.textContent !== field.description
      ) {
        updateField({
          description: subheadingRef.current?.textContent || '',
        });
      }
    },
    [field.description, updateField, isEditable]
  );

  const handleKeyDown = useCallback(
    (
        ref: React.RefObject<HTMLSpanElement>,
        currentText: string | undefined,
        isHeading: boolean
      ) =>
      (e: React.KeyboardEvent<HTMLSpanElement>) => {
        if (!isEditable) return;
        e.stopPropagation();
        if (e.key === 'Enter') {
          e.preventDefault();
          ref.current?.blur();
        } else if (e.key === 'Escape') {
          e.preventDefault();
          if (ref.current) {
            ref.current.textContent = currentText || '';
            ref.current.blur();
          }
        }
      },
    [isEditable]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      // Only stop propagation if we're editing to allow selection in the form builder
      if (isEditingHeading || isEditingSubheading) {
        e.stopPropagation();
      }
    },
    [isEditingHeading, isEditingSubheading]
  );

  const showSubHeading = field.description || isEditable;

  return (
    <Stack gap={0}>
      <div className={styles.fieldLabel} onClick={handleClick}>
        <span
          ref={headingRef}
          role="textbox"
          contentEditable={isEditable}
          spellCheck={false}
          className={`${styles.editor} ${
            isEditingHeading ? styles.editing : ''
          } ${!isEditable ? styles.readOnly : ''} ${styles.heading}`}
          style={{
            fontSize: `${2.5 - (headingSize - 1) * 0.35}rem`,
            fontWeight: 700,
            lineHeight: 1.2,
          }}
          onFocus={handleHeadingFocus}
          onBlur={handleHeadingBlur}
          onClick={handleClick}
          onKeyDown={handleKeyDown(headingRef, field.label, true)}
          aria-label="Heading text editor"
          aria-placeholder="Heading Text"
          suppressContentEditableWarning
        >
          {field.label}
        </span>
      </div>
      {showSubHeading && (
        <div className={styles.fieldLabel} onClick={handleClick}>
          <span
            ref={subheadingRef}
            role="textbox"
            contentEditable={isEditable}
            spellCheck={false}
            className={`${styles.editor} ${
              isEditingSubheading ? styles.editing : ''
            } ${!isEditable ? styles.readOnly : ''} ${styles.subheading}`}
            style={{
              fontSize: '1rem',
              color: 'var(--mantine-color-dimmed)',
            }}
            onFocus={handleSubheadingFocus}
            onBlur={handleSubheadingBlur}
            onClick={handleClick}
            onKeyDown={handleKeyDown(subheadingRef, field.description, false)}
            aria-label="Subheading text editor"
            aria-placeholder="Sub-heading (optional)"
            suppressContentEditableWarning
          >
            {field.description}
          </span>
        </div>
      )}
    </Stack>
  );
};

export default HeadingField;
