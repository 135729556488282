import { SpaceFieldProperties } from '@btrway/api-workflow';
import { Space } from '@mantine/core';
import React from 'react';
import { BaseFieldProps } from '../../types/baseField';

interface SpaceFieldProps extends BaseFieldProps<never> {}

export const SpaceField: React.FC<SpaceFieldProps> = ({ field }) => {
  const spaceFieldProperties = field.fieldProperties as SpaceFieldProperties;
  const height = spaceFieldProperties?.height ?? 20;

  return <Space h={height} />;
};

export default SpaceField;
