import { DataTypeEnum, RuleOperatorTypeEnum } from '@btrway/api-workflow';

export const compareValues = (
  fieldValue: any,
  ruleValue: any,
  dataType: DataTypeEnum,
  operator: RuleOperatorTypeEnum
): boolean => {
  // Handle undefined/null cases first
  if (fieldValue === undefined || fieldValue === null) {
    return operator === RuleOperatorTypeEnum.novalue;
  }

  // Convert values based on data type
  const convert = (value: any): any => {
    switch (dataType) {
      case DataTypeEnum.number:
      case DataTypeEnum.integer:
        return Number(value);
      case DataTypeEnum.boolean:
        return Boolean(value);
      case DataTypeEnum.date:
      case DataTypeEnum.dateTime:
      case DataTypeEnum.time:
        return new Date(value);
      default:
        return String(value);
    }
  };

  const typedFieldValue = convert(fieldValue);
  const typedRuleValue = convert(ruleValue);

  // Handle array operations
  if (
    operator === RuleOperatorTypeEnum.includes ||
    operator === RuleOperatorTypeEnum.excludes
  ) {
    const fieldArray = Array.isArray(fieldValue) ? fieldValue : [fieldValue];
    const ruleArray = Array.isArray(ruleValue) ? ruleValue : [ruleValue];

    const hasMatch = ruleArray.some((rule) => fieldArray.includes(rule));
    return operator === RuleOperatorTypeEnum.includes ? hasMatch : !hasMatch;
  }

  // Handle basic comparisons
  switch (operator) {
    case RuleOperatorTypeEnum.equal:
      return typedFieldValue === typedRuleValue;
    case RuleOperatorTypeEnum.notequal:
      return typedFieldValue !== typedRuleValue;
    case RuleOperatorTypeEnum.greater:
      return typedFieldValue > typedRuleValue;
    case RuleOperatorTypeEnum.greaterorequal:
      return typedFieldValue >= typedRuleValue;
    case RuleOperatorTypeEnum.less:
      return typedFieldValue < typedRuleValue;
    case RuleOperatorTypeEnum.lessorequal:
      return typedFieldValue <= typedRuleValue;
    case RuleOperatorTypeEnum.hasvalue:
      return (
        fieldValue !== undefined && fieldValue !== null && fieldValue !== ''
      );
    case RuleOperatorTypeEnum.novalue:
      return (
        fieldValue === undefined || fieldValue === null || fieldValue === ''
      );
    default:
      return false;
  }
};
