import { FieldConfig } from '@btrway/api-workflow';
import { labelNotApplicable, requiredNotApplicable } from '@btrway/form-fields';
import { Box, Card, Group, Text, ThemeIcon } from '@mantine/core';
import { IconAsterisk } from '@tabler/icons-react';
import FieldBinding from '../FieldBinding/FieldBinding';
import FieldComponent from '../FieldComponent/FieldComponent';
import FieldLabel from '../FieldLabel/FieldLabel';
import RequiredToggle from '../RequiredToggle/RequiredToggle';

interface FieldProps {
  field: FieldConfig;
  onUpdate: (fieldKey: string, updates: Partial<FieldConfig>) => void;
  readOnly?: boolean;
}

export const Field = ({ field, onUpdate, readOnly = false }: FieldProps) => {
  const handleLabelChange = (value: string) => {
    if (!readOnly) {
      onUpdate(field.fieldKey, { label: value });
    }
  };

  const handleRequiredChange = (required: boolean) => {
    if (!readOnly) {
      onUpdate(field.fieldKey, { required });
    }
  };

  return (
    <Card
      radius="md"
      p="xs"
      shadow="none"
      style={{
        width: '100%',
        background: 'white',
      }}
    >
      <div style={{ flex: 1 }}>
        <Group justify="space-between" wrap="nowrap">
          <Group justify="flex-start" gap={0} wrap="nowrap">
            {!labelNotApplicable(field.type) &&
              (readOnly ? (
                <div style={{ fontWeight: 500 }}>{field.label}</div>
              ) : (
                <FieldLabel
                  value={field.label}
                  onChange={handleLabelChange}
                  placeholder="Field Label"
                  readOnly={readOnly}
                  field={field}
                />
              ))}
            {!requiredNotApplicable(field.type) &&
              (readOnly ? (
                field.required && (
                  <ThemeIcon
                    variant="white"
                    color="red"
                    size="sm"
                    style={{ marginTop: '2px' }}
                  >
                    <IconAsterisk
                      style={{
                        width: '70%',
                        height: '70%',
                      }}
                    />
                  </ThemeIcon>
                )
              ) : (
                <RequiredToggle
                  required={field.required}
                  onChange={handleRequiredChange}
                />
              ))}
          </Group>
          {!readOnly && (
            <FieldBinding propertyReference={field.propertyReference} />
          )}
        </Group>
        {!labelNotApplicable(field.type) && (
          <Text fz="xs" c="dimmed">
            {field.description}
          </Text>
        )}
        <Box style={{ flexGrow: 1 }}>
          <FieldComponent
            field={field}
            readOnly={readOnly}
            onUpdate={(updates) => onUpdate(field.fieldKey, updates)}
          />
        </Box>
      </div>
    </Card>
  );
};

export default Field;
