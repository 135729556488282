import { useMemo } from 'react';
import { BaseHierarchicalItem } from '../types/hierarchicalItem';
import { createHierarchicalNodes } from '../utils/hierarchicalUtils';

export const useHierarchicalNodes = <T extends BaseHierarchicalItem>(
  items: T[] | undefined
) => {
  return useMemo(() => {
    if (!items) return [];
    return createHierarchicalNodes(items);
  }, [items]);
};
