import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';

export const DefaultFieldConfigs: Record<
  FieldTypeEnum,
  Partial<Omit<FieldConfig, 'fieldKey' | 'type'>>
> = {
  [FieldTypeEnum.text]: {
    // placeholder: 'Enter text...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.number]: {
    // placeholder: 'Enter number...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.select]: {
    // placeholder: 'Select an option...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.checkbox]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.switch]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.date]: {
    // placeholder: 'Select date...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.time]: {
    // placeholder: 'Select time...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.dateTime]: {
    // placeholder: 'Select date and time...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.stateProvince]: {
    label: 'State/Province',
    // placeholder: 'Select state/province...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.country]: {
    label: 'Country',
    // placeholder: 'Select country...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.postalcode]: {
    // placeholder: 'Enter postal code...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.address]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.organization]: {
    // placeholder: 'Select organization...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.workgroup]: {
    // placeholder: 'Select workgroup...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.person]: {
    // placeholder: 'Select person...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.facility]: {
    // placeholder: 'Select facility...',
    startWithNewLine: true,
  },
  [FieldTypeEnum.group]: {
    childFields: [],
    startWithNewLine: true,
  },
  [FieldTypeEnum.divider]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.heading]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.paragraph]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.signature]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.video]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.link]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.height]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.weight]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.slider]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.rating]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.calculation]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.boolean]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.file]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.image]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.space]: {
    startWithNewLine: true,
  },
  [FieldTypeEnum.multiple]: {
    startWithNewLine: true,
  },
};
