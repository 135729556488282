import { Box, MantineSpacing } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface MarginProps {
  m?: MantineSpacing;
  mt?: MantineSpacing;
  mb?: MantineSpacing;
  ml?: MantineSpacing;
  mr?: MantineSpacing;
}

interface PaddingProps {
  p?: MantineSpacing;
  pt?: MantineSpacing;
  pb?: MantineSpacing;
  pl?: MantineSpacing;
  pr?: MantineSpacing;
}

interface FlyoutPanelProps extends MarginProps, PaddingProps {
  children: ReactNode;
  width: number;
  isOpen: boolean;
  radius?: MantineSpacing;
}

const DEFAULT_MARGIN = 'lg';
const DEFAULT_PADDING = 0;
const DEFAULT_RADIUS = 'lg';

const FlyoutPanel: React.FC<FlyoutPanelProps> = ({
  children,
  width,
  isOpen,
  m = DEFAULT_MARGIN,
  mt,
  mb,
  ml,
  mr,
  p = DEFAULT_PADDING,
  pt,
  pb,
  pl,
  pr,
  radius = DEFAULT_RADIUS,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [margins, setMargins] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  useEffect(() => {
    // Function to measure actual computed margin
    const updateMargins = () => {
      if (!containerRef.current) return;
      const computedStyle = window.getComputedStyle(containerRef.current);
      setMargins({
        top: parseFloat(computedStyle.marginTop),
        bottom: parseFloat(computedStyle.marginBottom),
        left: parseFloat(computedStyle.marginLeft),
        right: parseFloat(computedStyle.marginRight),
      });
    };

    if (isOpen) {
      updateMargins();
    }
  }, [isOpen, m, mt, mb, ml, mr]);

  const menuVariants = {
    open: {
      width,
      height: `calc(100vh - ${margins.top}px - ${margins.bottom}px)`,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        duration: 0.2,
      },
    },
    closed: {
      width: 0,
      height: `calc(100vh - ${margins.top}px - ${margins.bottom}px)`,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 500,
        damping: 30,
        duration: 0.2,
      },
    },
  };

  // Convert margin value to CSS
  const getMarginValue = (
    value: MantineSpacing | undefined,
    fallback: MantineSpacing
  ): string => {
    if (value === undefined) return getMarginValue(fallback, DEFAULT_MARGIN);
    if (typeof value === 'number') return `${value}px`;
    return `var(--mantine-spacing-${value})`;
  };

  const getPaddingValue = (
    value: MantineSpacing | undefined,
    fallback: MantineSpacing
  ): string => {
    if (value === undefined) return getPaddingValue(fallback, DEFAULT_PADDING);
    if (typeof value === 'number') return `${value}px`;
    return `var(--mantine-spacing-${value})`;
  };

  // Calculate individual margins
  const marginStyles = {
    marginTop: getMarginValue(mt, m),
    marginBottom: getMarginValue(mb, m),
    marginLeft: getMarginValue(ml, m),
    marginRight: getMarginValue(mr, m),
  };

  const paddingStyles = {
    paddingTop: getPaddingValue(pt, p),
    paddingBottom: getPaddingValue(pb, p),
    paddingLeft: getPaddingValue(pl, p),
    paddingRight: getPaddingValue(pr, p),
  };

  return (
    <Box
      style={{
        position: 'relative',
        height: 0,
      }}
    >
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        ref={containerRef}
        style={{
          position: 'fixed',
          top: 0,
          right: '0',
          zIndex: 1000,
          backgroundColor: 'var(--mantine-color-white)',
          borderRadius:
            typeof radius === 'number'
              ? `${radius}px`
              : `var(--mantine-radius-${radius})`,
          boxShadow: 'var(--mantine-shadow-md)',
          overflow: 'hidden',
          ...marginStyles,
        }}
        variants={menuVariants}
      >
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: width,
                overflowY: 'auto',
                msOverflowStyle: 'auto',
                scrollbarWidth: 'thin',
                WebkitOverflowScrolling: 'touch',
                ...paddingStyles,
              }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

export default FlyoutPanel;
