export const INCH_TO_CM = 2.54;
export const LB_TO_KG = 0.45359237;
export const KG_TO_LB = 2.20462262;

export const FRACTIONS = [
  { value: '0', label: '0' },
  { value: '0.125', label: '1/8' },
  { value: '0.25', label: '1/4' },
  { value: '0.375', label: '3/8' },
  { value: '0.5', label: '1/2' },
  { value: '0.625', label: '5/8' },
  { value: '0.75', label: '3/4' },
  { value: '0.875', label: '7/8' },
] as const;
