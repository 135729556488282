import { FieldConfig } from '@btrway/api-workflow';
import {
  useFieldVisibility,
  useFormCompletion,
} from '@btrway/form-completion-manager';
import { Grid, Stack } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';
import FormField from '../FormField/FormField';

const MotionGrid = motion(Grid.Col);

interface FieldProps {
  field: FieldConfig;
  colSpan: number;
  getValue: (fieldKey: string) => any;
  handleFieldChange: (fieldKey: string, value: any) => void;
  readOnly?: boolean;
  parentFieldKey?: string;
  animate?: boolean;
}

const Field = React.memo(
  ({
    field,
    colSpan,
    getValue,
    handleFieldChange,
    readOnly,
    parentFieldKey,
    animate = true,
  }: FieldProps) => {
    const fullFieldKey = parentFieldKey
      ? `${parentFieldKey}.${field.fieldKey}`
      : field.fieldKey;

    if (!animate) {
      return (
        <Grid.Col
          key={fullFieldKey}
          span={colSpan}
          style={{ minWidth: '200px' }}
        >
          <FormField
            field={field}
            value={getValue(field.fieldKey)}
            onChange={(value) => handleFieldChange(field.fieldKey, value)}
            readOnly={readOnly}
          />
        </Grid.Col>
      );
    }

    return (
      <MotionGrid
        key={fullFieldKey}
        span={colSpan}
        style={{
          minWidth: '200px',
          position: 'relative',
        }}
        initial={{
          opacity: 0,
          scale: 0.8,
          marginBottom: 0,
          height: 0,
          y: -20,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          marginBottom: 'revert',
          height: 'auto',
          y: 0,
        }}
        exit={{
          opacity: 0,
          scale: 0.8,
          marginBottom: 0,
          height: 0,
          y: -20,
        }}
        transition={{
          duration: 0.2,
          ease: 'easeInOut',
        }}
        layout
      >
        <FormField
          field={field}
          value={getValue(field.fieldKey)}
          onChange={(value) => handleFieldChange(field.fieldKey, value)}
          readOnly={readOnly}
        />
      </MotionGrid>
    );
  }
);

interface FieldRendererProps {
  fields: FieldConfig[];
  readOnly?: boolean;
  parentFieldKey?: string;
  parentValue?: Record<string, any>;
  onFieldChange?: (fieldKey: string, value: any) => void;
  recordIndex?: number;
}

export const FieldRenderer: React.FC<FieldRendererProps> = ({
  fields,
  readOnly,
  parentFieldKey,
  parentValue,
  onFieldChange,
  recordIndex,
}) => {
  // Use constant for initial render check
  const isInitialRender = true;

  const { formValues, setFormValue } = useFormCompletion();
  const { isFieldVisible } = useFieldVisibility();

  const handleFieldChange = useCallback(
    (fieldKey: string, value: any) => {
      if (onFieldChange) {
        onFieldChange(fieldKey, value);
      } else {
        setFormValue(fieldKey, value);
      }
    },
    [onFieldChange, setFormValue]
  );

  const getValue = useCallback(
    (fieldKey: string) => {
      if (parentValue) {
        return parentValue[fieldKey];
      }
      return formValues[fieldKey];
    },
    [parentValue, formValues]
  );

  const rows = useMemo(() => {
    const result: FieldConfig[][] = [];
    let currentRow: FieldConfig[] = [];
    const visibleFields = fields.filter((field) =>
      isFieldVisible(field.fieldKey, recordIndex)
    );

    visibleFields.forEach((field) => {
      if (field.startWithNewLine !== false || currentRow.length === 0) {
        if (currentRow.length > 0) {
          result.push(currentRow);
        }
        currentRow = [field];
      } else {
        currentRow.push(field);
      }
    });

    if (currentRow.length > 0) {
      result.push(currentRow);
    }

    return result;
  }, [fields, isFieldVisible, recordIndex]);

  // Initial render without animations
  if (isInitialRender) {
    return (
      <Stack gap="md">
        {rows.map((row, rowIndex) => {
          const colSpan = 12 / row.length;
          return (
            <Grid key={rowIndex} gutter="md">
              {row.map((field) => (
                <Field
                  key={field.fieldKey}
                  field={field}
                  colSpan={colSpan}
                  getValue={getValue}
                  handleFieldChange={handleFieldChange}
                  readOnly={readOnly}
                  parentFieldKey={parentFieldKey}
                  animate={false}
                />
              ))}
            </Grid>
          );
        })}
      </Stack>
    );
  }

  // Subsequent renders with animations
  return (
    <Stack gap="md">
      {rows.map((row, rowIndex) => {
        const colSpan = 12 / row.length;
        return (
          <Grid key={rowIndex} gutter="md" style={{ position: 'relative' }}>
            <AnimatePresence mode="popLayout" initial={false}>
              {row.map((field) => (
                <Field
                  key={field.fieldKey}
                  field={field}
                  colSpan={colSpan}
                  getValue={getValue}
                  handleFieldChange={handleFieldChange}
                  readOnly={readOnly}
                  parentFieldKey={parentFieldKey}
                  animate={true}
                />
              ))}
            </AnimatePresence>
          </Grid>
        );
      })}
    </Stack>
  );
};

export default FieldRenderer;
