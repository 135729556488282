import { FieldConfig, TriggerSetConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { Button, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import { TriggerSetBuilder } from '../TriggerSetBuilder/TriggerSetBuilder';

interface TriggerSetsBuilderProps {
  initialTriggerSets?: TriggerSetConfig[];
  availableFields: FieldConfig[];
  description?: string;
  buttonLabel?: string;
  onChange?: (triggerSets: TriggerSetConfig[]) => void;
}

export const TriggerSetsBuilder: React.FC<TriggerSetsBuilderProps> = ({
  initialTriggerSets = [],
  availableFields,
  description,
  buttonLabel = 'Add Rule',
  onChange,
}) => {
  const handleTriggerSetUpdate = (updatedTriggerSet: TriggerSetConfig) => {
    const currentTriggerSets = [...initialTriggerSets];
    const existingIndex = currentTriggerSets.findIndex(
      (ts) => ts.triggerSetKey === updatedTriggerSet.triggerSetKey
    );

    let newTriggerSets;
    if (existingIndex >= 0) {
      newTriggerSets = [
        ...currentTriggerSets.slice(0, existingIndex),
        updatedTriggerSet,
        ...currentTriggerSets.slice(existingIndex + 1),
      ];
    } else {
      newTriggerSets = [...currentTriggerSets, updatedTriggerSet];
    }

    onChange?.(newTriggerSets);
  };

  const handleRemoveTriggerSet = (triggerSetToRemove: TriggerSetConfig) => {
    const newTriggerSets = initialTriggerSets.filter(
      (ts) => ts.triggerSetKey !== triggerSetToRemove.triggerSetKey
    );
    onChange?.(newTriggerSets);
  };

  const handleAddNew = () => {
    const newTriggerSet: TriggerSetConfig = {
      triggerSetKey: uuid(),
      triggers: [
        {
          triggerKey: uuid(),
          property: undefined,
          ruleOperator: undefined,
          ruleValues: [],
        },
      ],
    };

    onChange?.([...initialTriggerSets, newTriggerSet]);
  };

  return (
    <Stack>
      {description && <Text size="sm">{description}</Text>}

      {initialTriggerSets.length > 0 && (
        <Stack>
          {initialTriggerSets.map((triggerSet, index) => (
            <React.Fragment key={triggerSet.triggerSetKey}>
              <TriggerSetBuilder
                triggerSet={triggerSet}
                availableFields={availableFields}
                onUpdate={handleTriggerSetUpdate}
                onRemove={handleRemoveTriggerSet}
                initialEditMode={!triggerSet.triggers?.[0]?.property}
              />
              {index < initialTriggerSets.length - 1 && (
                <Text ta="center" fw={500}>
                  OR
                </Text>
              )}
            </React.Fragment>
          ))}
        </Stack>
      )}

      <Button
        variant="light"
        leftSection={<IconPlus size={16} />}
        onClick={handleAddNew}
        fullWidth
      >
        {buttonLabel}
      </Button>
    </Stack>
  );
};

TriggerSetsBuilder.displayName = 'TriggerSetsBuilder';

export default TriggerSetsBuilder;
