import {
  Button,
  Card,
  CloseButton,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useRef, useState } from 'react';
import { SignatureData } from '../../types/signatureTypes';
import { SignatureCanvas } from '../SignatureCanvas/SignatureCanvas';
import {
  TypedSignature,
  TypedSignatureRef,
} from '../TypedSignature/TypedSignature';

export interface SignatureInputProps {
  onSave: (data: SignatureData) => void;
  onClear?: () => void;
  initialName?: string;
  width?: number | string;
  signatureHeight?: number;
  enableTypedSignature?: boolean;
  className?: string;
}

export const SignatureInput: React.FC<SignatureInputProps> = ({
  onSave,
  onClear,
  initialName = '',
  width = 600,
  signatureHeight = 160,
  enableTypedSignature = true,
  className,
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const sigCanvasRef = useRef<React.ElementRef<typeof SignatureCanvas>>(null);
  const typedSignatureRef = useRef<TypedSignatureRef>(null);
  const [typedName, setTypedName] = useState(initialName);

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
      setIsEmpty(true);
    }
    setTypedName('');
    setIsCompleted(false);
    onClear?.();
  };

  const handleBegin = () => {
    if (!isCompleted) {
      setIsEmpty(false);
    }
  };

  const completeSignature = () => {
    if (typedName && typedSignatureRef.current) {
      const signatureData: SignatureData = {
        imageData: typedSignatureRef.current.toDataURL(),
        pointData: null,
        type: 'typed',
        typedName,
      };
      onSave(signatureData);
    } else if (sigCanvasRef.current && !isEmpty) {
      const signatureData: SignatureData = {
        imageData: sigCanvasRef.current
          .getTrimmedCanvas()
          .toDataURL('image/png'),
        pointData: sigCanvasRef.current.toData(),
        type: 'drawn',
      };
      onSave(signatureData);
    }
    setIsCompleted(true);
  };

  return (
    <Card
      withBorder
      radius="md"
      shadow="none"
      p="md"
      w={width}
      mx="auto"
      className={className}
      style={{
        border: '1px solid var(--mantine-color-gray-4)',
      }}
    >
      <Stack>
        {enableTypedSignature && (
          <Group align="center">
            <Text size="sm" style={{ whiteSpace: 'nowrap' }}>
              Draw Signature or Type Name Below
            </Text>
          </Group>
        )}

        <Paper withBorder shadow="xs" p="xs" bg="white">
          {enableTypedSignature && typedName ? (
            <TypedSignature
              ref={typedSignatureRef}
              name={typedName}
              height={signatureHeight}
            />
          ) : (
            <SignatureCanvas
              ref={sigCanvasRef}
              signatureHeight={signatureHeight}
              onBegin={handleBegin}
              disabled={isCompleted}
            />
          )}
        </Paper>

        {enableTypedSignature && (
          <TextInput
            placeholder="Type your name"
            value={typedName}
            onChange={(e) => !isCompleted && setTypedName(e.target.value)}
            style={{ flex: 1 }}
            disabled={isCompleted}
            rightSection={
              typedName && !isCompleted ? (
                <CloseButton onClick={() => setTypedName('')} />
              ) : null
            }
          />
        )}
        <Group grow>
          <Button variant="light" onClick={clearSignature}>
            Clear Signature
          </Button>
          <Button
            onClick={completeSignature}
            disabled={(!typedName && isEmpty) || isCompleted}
          >
            Complete Signature
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};
