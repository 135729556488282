import {
  EventConfig,
  EventTypeEnum,
  StepConfig,
  StepTypeEnum,
  TransitionConfig,
  WorkflowDefinitionRequest,
  WorkflowDefinitionResponse,
  WorkflowTypeEnum,
  getGetWorkflowDefinitionsByOrganizationQueryKey,
  useGetWorkflowDefinitionsByOrganization,
  useSaveWorkflowDefinition,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { uuid } from '@btrway/utils';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { workflowDefinitionsAtom } from '../atoms/workflowDefinitionAtoms';

export const useWorkflowDefinitions = () => {
  const [workflows, setWorkflows] = useAtom(workflowDefinitionsAtom);
  const { currentOrganization } = useAuthenticatedUser();

  const shouldFetch = useMemo(
    () => !!currentOrganization.id && workflows.length === 0,
    [currentOrganization.id, workflows.length]
  );

  const {
    data: fetchedWorkflows,
    refetch,
    isLoading,
    error,
  } = useGetWorkflowDefinitionsByOrganization(currentOrganization.id, {
    query: {
      enabled: shouldFetch,
      queryKey: getGetWorkflowDefinitionsByOrganizationQueryKey(
        currentOrganization.id
      ),
    },
  });

  const { mutateAsync: saveWorkflow } = useSaveWorkflowDefinition();

  const fetchWorkflows = useCallback(async () => {
    if (!currentOrganization) {
      console.error('Organization not found');
      return;
    }
    try {
      const result = await refetch();
      if (result.data) {
        setWorkflows(result.data);
      }
    } catch (err) {
      console.error('Failed to fetch workflows:', err);
    }
  }, [refetch, setWorkflows, currentOrganization]);

  useEffect(() => {
    if (fetchedWorkflows) {
      setWorkflows(fetchedWorkflows);
    }
  }, [fetchedWorkflows, setWorkflows]);

  const addWorkflow = useCallback(
    async (
      partialWorkflow: Partial<WorkflowDefinitionRequest>
    ): Promise<WorkflowDefinitionResponse | null> => {
      if (!currentOrganization) {
        console.error('Organization not found');
        return null;
      }
      try {
        let workflowConfiguration = partialWorkflow.workflowConfiguration;

        if (partialWorkflow.workflowType === WorkflowTypeEnum.form) {
          const startStep: StepConfig = {
            stepKey: uuid(),
            title: 'Start',
            stepTypeCode: StepTypeEnum.flowStart,
            formConfig: { fields: [] },
          };

          const formStep: StepConfig = {
            stepKey: uuid(),
            title: 'Form Submission',
            stepTypeCode: StepTypeEnum.formSubmission,
            formConfig: { fields: [] },
          };

          const transition: TransitionConfig = {
            transitionKey: uuid(),
            sourceStepKey: startStep.stepKey,
            targetStepKey: formStep.stepKey,
            index: 0,
          };

          workflowConfiguration = {
            ...partialWorkflow.workflowConfiguration,
            steps: [startStep, formStep],
            transitions: [transition],
          };
        } else if (
          partialWorkflow.workflowType === WorkflowTypeEnum.automation
        ) {
          const eventConfig: EventConfig = {
            eventKey: uuid(),
            title: 'Person Added',
            eventTypeCode: EventTypeEnum.personCreated,
          };

          workflowConfiguration = {
            events: [eventConfig],
            steps: [],
            transitions: [],
          };
        } else if (partialWorkflow.workflowType === WorkflowTypeEnum.file) {
          const fileUploadStep: StepConfig = {
            stepKey: uuid(),
            title: 'File Upload',
            stepTypeCode: StepTypeEnum.fileUpload,
            formConfig: { fields: [] },
          };

          console.log('fileUploadStep', fileUploadStep);

          workflowConfiguration = {
            steps: [fileUploadStep],
            transitions: [],
          };
        }

        console.log('workflowConfiguration', workflowConfiguration);
        const newWorkflow: WorkflowDefinitionRequest = {
          ...partialWorkflow,
          name: partialWorkflow.name || 'New Workflow',
          workflowKey: partialWorkflow.workflowKey || uuid(),
          workflowType: partialWorkflow.workflowType!,
          organizationId: currentOrganization.id,
          enabled: partialWorkflow.enabled ?? true,
          published: partialWorkflow.published || false,
          workflowConfiguration: workflowConfiguration || {},
        };
        console.log('newWorkflow', newWorkflow);
        const savedWorkflow = (await saveWorkflow({
          data: newWorkflow,
        })) as WorkflowDefinitionResponse;
        if (savedWorkflow) {
          setWorkflows((prev) => [...prev, savedWorkflow]);
        }
        return savedWorkflow;
      } catch (err) {
        console.error('Failed to add workflow:', err);
        return null;
      }
    },
    [saveWorkflow, setWorkflows, currentOrganization]
  );

  const getWorkflowDefinitionByKey = useCallback(
    (workflowKey: string) => {
      return (
        workflows.find((workflow) => workflow.workflowKey === workflowKey) ||
        null
      );
    },
    [workflows]
  );

  return {
    workflows,
    isLoading,
    error,
    fetchWorkflows,
    addWorkflow,
    getWorkflowDefinitionByKey,
  };
};
