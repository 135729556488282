import { WorkflowConfig } from '@btrway/api-workflow';
import { DisplayConfig } from '@btrway/workflow-builder-settings-panel';
import { WorkflowConfigProvider } from '@btrway/workflow-configuration-manager';
import React, { createContext, useContext, useRef, useState } from 'react';
import { Panel } from '../components/Panel/Panel';
import { ScreenRenderer } from '../components/ScreenRenderer/ScreenRenderer';
import { ScreenHandlers } from '../types/handlers';
import { Screen } from '../types/screen';
import {
  WorkflowBuilderContext,
  WorkflowBuilderContextType,
} from './WorkflowBuilderStateProvider';

interface PanelState {
  isOpen: boolean;
  screen: Screen | null;
  config: DisplayConfig;
  handlers: ScreenHandlers | null;
  originContext: WorkflowBuilderContextType | null;
  workflowConfig: WorkflowConfig | null;
}

interface PanelContextValue {
  openPanel: (
    screen: Screen,
    config: DisplayConfig,
    handlers: ScreenHandlers,
    originContext: WorkflowBuilderContextType,
    workflowConfig: WorkflowConfig
  ) => void;
  closePanel: () => void;
  panelState: PanelState;
}

const PanelContext = createContext<PanelContextValue | null>(null);

export const useWorkflowBuilderPanel = () => {
  const context = useContext(PanelContext);
  if (!context) {
    throw new Error(
      'useWorkflowBuilderPanel must be used within WorkflowBuilderPanelProvider'
    );
  }
  return context;
};

export const WorkflowBuilderPanelProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [panelState, setPanelState] = useState<PanelState>({
    isOpen: false,
    screen: null,
    config: { mode: 'panel', position: 'left' },
    handlers: null,
    originContext: null,
    workflowConfig: null,
  });

  // Keep track of whether this is the first render
  const isFirstRender = useRef(true);

  const openPanel = (
    screen: Screen,
    config: DisplayConfig,
    handlers: ScreenHandlers,
    originContext: WorkflowBuilderContextType,
    workflowConfig: WorkflowConfig
  ) => {
    // If panel is already open, just update the content without triggering new open animation
    if (panelState.isOpen) {
      setPanelState((prev) => ({
        ...prev,
        screen,
        config,
        handlers,
        originContext,
        workflowConfig,
      }));
    } else {
      // If panel is closed, do a full open with animation
      setPanelState({
        isOpen: true,
        screen,
        config,
        handlers,
        originContext,
        workflowConfig,
      });
    }
  };

  const closePanel = () => {
    setPanelState((prev) => ({ ...prev, isOpen: false }));
  };

  const contextValue: PanelContextValue = {
    openPanel,
    closePanel,
    panelState,
  };

  const PanelContent = () => {
    if (
      !panelState.isOpen ||
      !panelState.screen ||
      !panelState.handlers ||
      !panelState.originContext ||
      !panelState.workflowConfig
    ) {
      return null;
    }

    return (
      <Panel
        isOpen={panelState.isOpen}
        position={panelState.config.position}
        width={panelState.config.width}
        title={panelState.config.modalProps?.title as string}
        onClose={closePanel}
      >
        <WorkflowConfigProvider
          initialConfig={panelState.workflowConfig}
          onChange={(updatedConfig) => {
            if (panelState.originContext?.state.parentWorkflow) {
              const updatedWorkflow = {
                ...panelState.originContext.state.parentWorkflow,
                workflowConfiguration: updatedConfig,
              };
              panelState.originContext.dispatch({
                type: 'SET_PARENT_WORKFLOW',
                workflow: updatedWorkflow,
              });
            }
          }}
        >
          <WorkflowBuilderContext.Provider value={panelState.originContext}>
            <ScreenRenderer
              screen={panelState.screen}
              handlers={panelState.handlers}
            />
          </WorkflowBuilderContext.Provider>
        </WorkflowConfigProvider>
      </Panel>
    );
  };

  return (
    <PanelContext.Provider value={contextValue}>
      {children}
      <PanelContent />
    </PanelContext.Provider>
  );
};
