import { TaskConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { useTaskHelpResources } from '@btrway/help-manager';
import {
  useWorkflowBuilderState,
  WorkflowBuilderStateProvider,
} from '@btrway/workflow-builder-state';
import {
  CommonWorkflow,
  useWorkflowService,
  useWorkflowUpdater,
  WorkflowServiceContext,
  WorkflowServiceProvider,
} from '@btrway/workflow-common-provider';
import { WorkflowConfigProvider } from '@btrway/workflow-configuration-manager';
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Stack,
  Tooltip,
} from '@mantine/core';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { TaskSortType, useTaskGrouping } from '../../hooks/useTaskGrouping';
import EmptyTaskList from '../EmptyTaskList/EmptyTaskList';
import TaskGroup from '../TaskGroup/TaskGroup';

interface TaskListViewInnerProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  nestedTaskList: boolean;
}

const TaskListViewInner: React.FC<TaskListViewInnerProps> = ({
  workflowKey,
  workflow,
  nestedTaskList,
}) => {
  const { removeTask, updateConfig } = useWorkflowUpdater();
  const [hasStartedFromScratch, setHasStartedFromScratch] = useState(false);
  const [localTasks, setLocalTasks] = useState<TaskConfig[]>([]);
  const [currentSort, setCurrentSort] = useState<TaskSortType>('date');
  const workflowService = useWorkflowService();
  const { openScreen, openToolbox } = useWorkflowBuilderState();

  // Get unique task types for fetching help resources
  const uniqueTaskTypes = useMemo(
    () => Array.from(new Set(localTasks.map((task) => task.taskType))),
    [localTasks]
  );

  const { fetchHelpUsages } = useTaskHelpResources(
    workflowKey,
    uniqueTaskTypes
  );

  // Fetch help resources on mount and when tasks change
  useEffect(() => {
    fetchHelpUsages();
  }, [uniqueTaskTypes]);

  useEffect(() => {
    if (!workflow) return;

    // Set local tasks
    const workflowTasks = workflow.workflowConfiguration?.tasks ?? [];
    setLocalTasks(workflowTasks);
  }, [workflow]);

  const handleDelete = async (taskKey: string) => {
    try {
      await removeTask(taskKey);
      setLocalTasks((prev) => prev.filter((task) => task.taskKey !== taskKey));
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow?.workflowType) return;

    openToolbox(workflow, 'task');
  };

  const handleTaskClick = (task: TaskConfig) => {
    if (!workflow) return;

    openScreen({
      type: 'TASK_CONFIG',
      data: {
        config: task,
        workflow,
        isNew: false,
      },
    });
  };

  const handleEditWorkflow = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow) return;

    const isFormPacket = workflow.workflowType === WorkflowTypeEnum.formPacket;

    openScreen({
      type: isFormPacket ? 'FORM_PACKET_EDITOR' : 'CURRICULUM_EDITOR',
      data: {
        initialSelectedTasks: workflow.workflowConfiguration?.tasks || [],
        workflowKey,
        workflowName: workflow.name || (isFormPacket ? 'Form Packet' : ''),
        showTaskConfigAfterSave: false,
      },
    });
  };

  const handleNewTasks = async (tasks: TaskConfig[]) => {
    try {
      await updateConfig({
        workflowConfig: {
          tasks,
        },
      });
      setLocalTasks(tasks);
      setHasStartedFromScratch(true); // Set flag when user starts from scratch
    } catch (error) {
      console.error('Failed to update tasks:', error);
    }
  };

  const handleSkip = () => {
    setHasStartedFromScratch(true); // Set flag when user skips
    handleAddClick({ stopPropagation: () => {} } as React.MouseEvent);
  };

  const workflowType = workflow?.workflowType;
  const isFormPacket = workflowType === WorkflowTypeEnum.formPacket;
  const isCurriculum = workflowType === WorkflowTypeEnum.curriculum;
  const isTaskList = workflowType === WorkflowTypeEnum.taskList;
  const isTaskOption = workflowType === WorkflowTypeEnum.taskOption;
  const shouldUseGrouping = isTaskList;

  const { groupedTasks } = useTaskGrouping(
    localTasks,
    shouldUseGrouping ? (isCurriculum ? 'date' : currentSort) : 'none'
  );

  const handleSort = (sortType: TaskSortType) => {
    setCurrentSort(currentSort === sortType ? 'none' : sortType);
  };

  if (!workflow) {
    return <Center>No workflow found</Center>;
  }

  // Show EmptyTaskList when there are no tasks and this is a task list
  if (groupedTasks.length === 0 && isTaskList) {
    return (
      <EmptyTaskList
        onBuild={handleNewTasks}
        onSkip={handleSkip}
        style={{ padding: '20px' }}
      />
    );
  }

  return (
    <Center>
      <Stack gap="xs" align="stretch" p="md" w="100%">
        <Group justify="space-between" w="100%">
          <Group gap="xs" justify="flex-start">
            {isTaskList && (
              <>
                <Button.Group>
                  <Button
                    size="xs"
                    color="blue"
                    variant={currentSort === 'date' ? 'filled' : 'outline'}
                    onClick={() => handleSort('date')}
                  >
                    Date
                  </Button>
                  <Button
                    size="xs"
                    color="blue"
                    variant={currentSort === 'type' ? 'filled' : 'outline'}
                    onClick={() => handleSort('type')}
                  >
                    Type
                  </Button>
                  <Button
                    size="xs"
                    color="blue"
                    variant={currentSort === 'category' ? 'filled' : 'outline'}
                    onClick={() => handleSort('category')}
                  >
                    Category
                  </Button>
                </Button.Group>
              </>
            )}
          </Group>

          <Group justify="flex-end" gap="xs">
            {isFormPacket || isCurriculum ? (
              <Button
                size="xs"
                variant="light"
                color="blue"
                leftSection={<IconEdit size={16} />}
                onClick={handleEditWorkflow}
              >
                Select {isFormPacket ? 'Forms' : 'Courses'}
              </Button>
            ) : (
              <Group gap="xs">
                <Tooltip label={`Add New ${isTaskOption ? 'Option' : 'Task'}`}>
                  <ActionIcon
                    variant="light"
                    color="blue"
                    size="md"
                    radius="xl"
                    onClick={handleAddClick}
                  >
                    <IconPlus size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )}
          </Group>
        </Group>

        <Stack gap="xs">
          {localTasks.length === 0 && isTaskList && !hasStartedFromScratch ? (
            <EmptyTaskList
              onBuild={handleNewTasks}
              onSkip={handleSkip}
              style={{ padding: '20px' }}
            />
          ) : (
            groupedTasks.map(
              (group) =>
                workflowType && (
                  <TaskGroup
                    key={group.label}
                    label={group.label}
                    tasks={group.tasks}
                    workflow={workflow}
                    onTaskSelected={(taskKey) => {
                      const task = localTasks.find(
                        (t) => t.taskKey === taskKey
                      );
                      if (task) handleTaskClick(task);
                    }}
                    onTaskDeleted={handleDelete}
                  />
                )
            )
          )}
        </Stack>
      </Stack>
    </Center>
  );
};

// Outer wrapper component that provides context
interface TaskListViewProps {
  workflowKey: string;
  nestedTaskList?: boolean;
}

const TaskListView: React.FC<TaskListViewProps> = ({
  workflowKey,
  nestedTaskList = false,
}) => {
  return (
    <WorkflowServiceProvider workflowKey={workflowKey}>
      <WorkflowServiceContext.Consumer>
        {(service) => {
          if (!service) return null;
          const { data: workflow, isLoading } = service.getByKey();

          if (!workflow || isLoading) {
            return null;
          }
          return (
            <WorkflowConfigProvider
              initialConfig={workflow.workflowConfiguration}
            >
              <WorkflowBuilderStateProvider parentWorkflow={workflow}>
                <TaskListViewInner
                  workflowKey={workflowKey}
                  workflow={workflow}
                  nestedTaskList={nestedTaskList}
                />
              </WorkflowBuilderStateProvider>
            </WorkflowConfigProvider>
          );
        }}
      </WorkflowServiceContext.Consumer>
    </WorkflowServiceProvider>
  );
};

export default TaskListView;
