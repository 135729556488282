import { WorkflowSelection, WorkflowTypeEnum } from '@btrway/api-workflow';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-common-provider';
import { Card, Group, Text, UnstyledButton } from '@mantine/core';
import {
  IconAlertTriangleFilled,
  IconAlignBoxLeftTop,
  IconEdit,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { WorkflowSelector } from '../WorkflowSelector/WorkflowSelector';

interface WorkflowSelectorFieldProps {
  workflowType: WorkflowTypeEnum;
  workflowSelection?: WorkflowSelection;
  onWorkflowSelect: (
    workflowSelection: WorkflowSelection,
    name: string
  ) => void;
  title?: string;
}

export const WorkflowSelectorField: React.FC<WorkflowSelectorFieldProps> = ({
  workflowType,
  workflowSelection,
  onWorkflowSelect,
  title,
}) => {
  const workflowService = useWorkflowService();
  const [workflows, setWorkflows] = useState<CommonWorkflow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();

  useEffect(() => {
    const fetchWorkflows = async () => {
      const result = await workflowService.getWorkflows();
      setWorkflows(result.workflows);
      setIsLoading(result.isLoading);
    };

    fetchWorkflows();
  }, [workflowService]);

  const selectedWorkflow = workflows.find((workflow) => {
    const key =
      workflow.sourceType === 'definition'
        ? workflow.workflowKey
        : workflow.templateKey;
    return key === workflowSelection?.workflowDefinitionKey.value;
  });

  const openWorkflowSelector = () => {
    setRightSectionContent(
      <WorkflowSelector
        workflows={workflows}
        selectedWorkflowKey={workflowSelection?.workflowDefinitionKey.value}
        workflowType={workflowType}
        onWorkflowSelect={(workflowKey, name) => {
          console.log('onWorkflowSelect', workflowKey, name);
          onWorkflowSelect(
            { workflowDefinitionKey: { value: workflowKey } },
            name
          );
          clearRightSectionContent();
        }}
        onClose={clearRightSectionContent}
      />
    );
  };

  if (isLoading) {
    return <Text>Loading workflows...</Text>;
  }

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      onClick={openWorkflowSelector}
      style={{ cursor: 'pointer' }}
    >
      <Group justify="space-between" wrap="nowrap">
        <Group justify="flex-start" gap="xs" wrap="nowrap" align="flex-start">
          <IconAlignBoxLeftTop style={{ width: '20px', height: '20px' }} />
          <Text fz="md" fw={600}>
            {selectedWorkflow
              ? selectedWorkflow.name
              : title || `Select a ${workflowType}`}
          </Text>
        </Group>
        <UnstyledButton>
          {selectedWorkflow ? (
            <IconEdit size={20} color="blue" />
          ) : (
            <IconAlertTriangleFilled size={20} color="orange" />
          )}
        </UnstyledButton>
      </Group>
    </Card>
  );
};
