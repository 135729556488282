import { DataTypeEnum, FieldTypeEnum } from '@btrway/api-workflow';

/**
 * Maps FieldTypeEnum to corresponding DataTypeEnum
 * Returns null for field types that don't have a defined data type mapping
 */
export const getFieldDataType = (
  fieldType: FieldTypeEnum
): DataTypeEnum | null => {
  switch (fieldType) {
    // Text data type mappings
    case FieldTypeEnum.text:
    case FieldTypeEnum.select:
    case FieldTypeEnum.boolean:
      return DataTypeEnum.text;

    // Number data type mappings
    case FieldTypeEnum.number:
    case FieldTypeEnum.height:
    case FieldTypeEnum.weight:
    case FieldTypeEnum.rating:
    case FieldTypeEnum.slider:
    case FieldTypeEnum.calculation:
      return DataTypeEnum.number;

    // Boolean data type mappings
    case FieldTypeEnum.checkbox:
    case FieldTypeEnum.switch:
      return DataTypeEnum.boolean;

    // All other field types
    default:
      return null;
  }
};

/**
 * Type guard to check if a field type maps to text data
 */
export const isTextFieldType = (fieldType: FieldTypeEnum): boolean => {
  return getFieldDataType(fieldType) === DataTypeEnum.text;
};

/**
 * Type guard to check if a field type maps to number data
 */
export const isNumberFieldType = (fieldType: FieldTypeEnum): boolean => {
  return getFieldDataType(fieldType) === DataTypeEnum.number;
};

/**
 * Type guard to check if a field type maps to boolean data
 */
export const isBooleanFieldType = (fieldType: FieldTypeEnum): boolean => {
  return getFieldDataType(fieldType) === DataTypeEnum.boolean;
};

/**
 * Gets all field types that map to a specific data type
 */
export const getFieldTypesByDataType = (
  dataType: DataTypeEnum
): FieldTypeEnum[] => {
  return Object.values(FieldTypeEnum).filter(
    (fieldType) => getFieldDataType(fieldType) === dataType
  );
};
