import { Box } from '@mantine/core';
import React from 'react';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupTasksView: React.FC = () => {
  const { workgroup } = useWorkgroup();

  return <Box p="md">{/* <TaskStatisticsView workgroup={workgroup} /> */}</Box>;
};

export default WorkgroupTasksView;
