import { FieldConfig, PropertyReference } from '@btrway/api-workflow';
import { PropertyGroup } from '@btrway/property-reference-manager';
import { useTree } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { PropertyTreeNodeData } from '../types/types';
import {
  convertPropertyGroupToTreeData,
  getPropertyNodeValue,
} from '../utils/utils';

interface UsePropertyTreeProps {
  groupedBindingPropertyReferences: PropertyGroup[];
  field: FieldConfig;
  selectedProperty?: PropertyReference;
}

export const usePropertyTree = ({
  groupedBindingPropertyReferences,
  field,
  selectedProperty,
}: UsePropertyTreeProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [treeData, setTreeData] = useState<PropertyTreeNodeData[]>([]);

  const tree = useTree({
    initialSelectedState: selectedProperty
      ? [getPropertyNodeValue(selectedProperty)]
      : [],
  });

  // Filter tree data based on search query
  const filteredTreeData = useMemo(() => {
    if (!searchQuery) return treeData;

    const searchLower = searchQuery.toLowerCase();
    return treeData
      .map((group) => ({
        ...group,
        children: group.children?.filter(
          (node) =>
            typeof node.label === 'string' &&
            node.label.toLowerCase().includes(searchLower)
        ),
      }))
      .filter(
        (group) =>
          (typeof group.label === 'string' &&
            group.label.toLowerCase().includes(searchLower)) ||
          (group.children && group.children.length > 0)
      );
  }, [treeData, searchQuery]);

  useEffect(() => {
    if (groupedBindingPropertyReferences.length > 0) {
      const data = groupedBindingPropertyReferences.map((group) =>
        convertPropertyGroupToTreeData(group, field)
      );
      setTreeData(data);
      tree.initialize(data);

      // Expand all groups by default
      data.forEach((node) => tree.expand(node.value));

      // If there's a selected property, ensure its path is expanded
      if (selectedProperty) {
        const selectedNodeValue = getPropertyNodeValue(selectedProperty);
        data.forEach((node) => {
          if (
            node.children?.some((child) => child.value === selectedNodeValue)
          ) {
            tree.expand(node.value);
          }
        });
      }
    }
  }, [groupedBindingPropertyReferences, field, selectedProperty]);

  return {
    tree,
    treeData,
    filteredTreeData,
    searchQuery,
    setSearchQuery,
  };
};
