// import { useRightSection } from '@btrway/workflow-builder-rightsection';
// import {
//   Box,
//   CloseButton,
//   Group,
//   ScrollArea,
//   Stack,
//   Text,
// } from '@mantine/core';
// import clsx from 'clsx';
// import React from 'react';
// import { LAYOUT } from '../../constants/layout';
// import { PanelPosition } from '../../types/display';
// import moduleStyles from './Panel.module.css';

// interface PanelProps {
//   isOpen: boolean;
//   onClose: () => void;
//   children: React.ReactNode;
//   position?: PanelPosition;
//   width?: string | number;
//   title?: string;
// }

// export const Panel: React.FC<PanelProps> = ({
//   isOpen,
//   onClose,
//   children,
//   position = 'left',
//   width = LAYOUT.PANEL.DEFAULT_WIDTH,
//   title = 'Settings',
// }) => {
//   const { rightSectionContent, clearRightSectionContent } = useRightSection();
//   const rightSectionWidth = rightSectionContent?.width || '300px';

//   const getTransform = () => {
//     return position === 'left' ? 'translateX(-100%)' : 'translateX(100%)';
//   };

//   const getPanelClassName = () => {
//     return clsx(moduleStyles.panel, {
//       [moduleStyles.panelLeft]: position === 'left',
//       [moduleStyles.panelRight]: position === 'right',
//     });
//   };

//   return (
//     <Box
//       className={getPanelClassName()}
//       style={{
//         transform: isOpen ? 'translateX(0)' : getTransform(),
//         transition: `transform ${LAYOUT.TRANSITION.DURATION}ms ${LAYOUT.TRANSITION.TIMING}`,
//         display: 'flex',
//         flexDirection: position === 'left' ? 'row' : 'row-reverse',
//       }}
//     >
//       <Stack
//         style={{
//           width: typeof width === 'number' ? `${width}px` : width,
//           minWidth: typeof width === 'number' ? `${width}px` : width,
//           maxWidth: typeof width === 'number' ? `${width}px` : width,
//           height: '100%',
//           borderRight:
//             position === 'left' && rightSectionContent
//               ? '1px solid #e9ecef'
//               : undefined,
//           borderLeft:
//             position === 'right' && rightSectionContent
//               ? '1px solid #e9ecef'
//               : undefined,
//           flexShrink: 0,
//         }}
//         gap={0}
//       >
//         <Group
//           justify="space-between"
//           p="xs"
//           wrap="nowrap"
//           bg="gray.1"
//           style={{
//             borderBottom: '1px solid var(--mantine-color-gray-3)',
//             height: `${LAYOUT.PANEL.HEADER_HEIGHT}px`,
//             flexShrink: 0,
//           }}
//         >
//           <Text fz="md" fw={600}>
//             {title}
//           </Text>
//           <CloseButton onClick={onClose} aria-label="Close panel" />
//         </Group>
//         <Box
//           style={{
//             height: `calc(100% - ${LAYOUT.PANEL.HEADER_HEIGHT}px)`,
//             position: 'relative',
//           }}
//         >
//           {children}
//         </Box>
//       </Stack>

//       {rightSectionContent && (
//         <Stack
//           gap={0}
//           p={0}
//           bg="gray.1"
//           style={{
//             height: '100%',
//             width: rightSectionWidth,
//             minWidth: rightSectionWidth,
//             maxWidth: rightSectionWidth,
//             borderLeft: position === 'left' ? '1px solid #e9ecef' : undefined,
//             borderRight: position === 'right' ? '1px solid #e9ecef' : undefined,
//             flexShrink: 0,
//           }}
//         >
//           <Group
//             justify="flex-end"
//             p="sm"
//             style={{
//               height: `${LAYOUT.PANEL.HEADER_HEIGHT}px`,
//               flexShrink: 0,
//             }}
//           >
//             <CloseButton
//               onClick={clearRightSectionContent}
//               aria-label="Close right section"
//             />
//           </Group>
//           <ScrollArea style={{ flex: 1 }}>
//             <Box>{rightSectionContent.content}</Box>
//           </ScrollArea>
//         </Stack>
//       )}
//     </Box>
//   );
// };

import { useRightSection } from '@btrway/workflow-builder-rightsection';
import {
  Box,
  CloseButton,
  Group,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { LAYOUT } from '../../constants/layout';
import { PanelPosition } from '../../types/display';
import styles from './Panel.module.css';

interface PanelProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: PanelPosition;
  width?: string | number;
  title?: string;
}

export const Panel: React.FC<PanelProps> = ({
  isOpen,
  onClose,
  children,
  position = 'left',
  width = LAYOUT.PANEL.DEFAULT_WIDTH,
  title = 'Settings',
}) => {
  const { rightSectionContent, clearRightSectionContent } = useRightSection();
  const rightSectionWidth = rightSectionContent?.width || '300px';

  // Track panel visibility state
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  // Track content visibility state separately
  const [isContentVisible, setIsContentVisible] = useState(false);
  // Track previous children to handle transitions
  const [previousChildren, setPreviousChildren] =
    useState<React.ReactNode>(null);

  useEffect(() => {
    let panelTimer: number;
    let contentTimer: number;

    if (isOpen) {
      // Show panel first
      setIsPanelVisible(true);

      // Then fade in content after a short delay
      contentTimer = window.setTimeout(() => {
        setIsContentVisible(true);
      }, 50);
    } else {
      // Hide content first
      setIsContentVisible(false);

      // Then hide panel after content fade out
      panelTimer = window.setTimeout(() => {
        setIsPanelVisible(false);
      }, 150);
    }

    return () => {
      clearTimeout(panelTimer);
      clearTimeout(contentTimer);
    };
  }, [isOpen]);

  // Handle content transitions when children change
  useEffect(() => {
    if (children !== previousChildren && isOpen) {
      setIsContentVisible(false);

      const timer = setTimeout(() => {
        setPreviousChildren(children);
        setIsContentVisible(true);
      }, 150);

      return () => clearTimeout(timer);
    }
  }, [children, previousChildren, isOpen]);

  const handleClose = () => {
    setIsContentVisible(false);
    setTimeout(() => {
      onClose();
    }, 150);
  };

  if (!isOpen && !isPanelVisible) return null;

  return (
    <Box
      className={clsx(styles.panel, {
        [styles.panelLeft]: position === 'left',
        [styles.panelRight]: position === 'right',
        [styles.visible]: isPanelVisible,
      })}
      style={{
        width: typeof width === 'number' ? `${width}px` : width,
        minWidth: typeof width === 'number' ? `${width}px` : width,
        maxWidth: typeof width === 'number' ? `${width}px` : width,
      }}
    >
      <Stack
        style={{
          width: '100%',
          height: '100%',
          borderRight:
            position === 'left' && rightSectionContent
              ? '1px solid #e9ecef'
              : undefined,
          borderLeft:
            position === 'right' && rightSectionContent
              ? '1px solid #e9ecef'
              : undefined,
          flexShrink: 0,
        }}
        gap={0}
      >
        <Group
          justify="space-between"
          p="xs"
          wrap="nowrap"
          bg="gray.1"
          style={{
            borderBottom: '1px solid var(--mantine-color-gray-3)',
            height: `${LAYOUT.PANEL.HEADER_HEIGHT}px`,
            flexShrink: 0,
          }}
        >
          <Text fz="md" fw={600}>
            {title}
          </Text>
          <CloseButton onClick={handleClose} aria-label="Close panel" />
        </Group>

        <Box
          className={clsx(styles.content, {
            [styles.contentVisible]: isContentVisible,
          })}
          style={{
            height: `calc(100% - ${LAYOUT.PANEL.HEADER_HEIGHT}px)`,
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Stack>

      {rightSectionContent && (
        <Stack
          gap={0}
          p={0}
          bg="gray.1"
          style={{
            height: '100%',
            width: rightSectionWidth,
            minWidth: rightSectionWidth,
            maxWidth: rightSectionWidth,
            borderLeft: position === 'left' ? '1px solid #e9ecef' : undefined,
            borderRight: position === 'right' ? '1px solid #e9ecef' : undefined,
            flexShrink: 0,
          }}
        >
          <Group
            justify="flex-end"
            p="sm"
            style={{
              height: `${LAYOUT.PANEL.HEADER_HEIGHT}px`,
              flexShrink: 0,
            }}
          >
            <CloseButton
              onClick={clearRightSectionContent}
              aria-label="Close right section"
            />
          </Group>
          <ScrollArea style={{ flex: 1 }}>
            <Box>{rightSectionContent.content}</Box>
          </ScrollArea>
        </Stack>
      )}
    </Box>
  );
};
