import { StorageUsageEnum } from '@btrway/api-storage';
import { useImageUpload } from '@btrway/file-storage-components';
import { useUserRoles } from '@btrway/security-manager';
import { useWorkgroups } from '@btrway/workgroup-manager';
import { Anchor, Avatar, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePerson } from '../../providers/PersonProvider';

export const PersonProfileHeader: React.FC = () => {
  const { person } = usePerson();

  const { imageUrl: avatarUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.headshot,
    initialImageKey: person?.headshot,
  });
  const navigate = useNavigate();
  const { getUserRoleById } = useUserRoles();
  const { getWorkgroup } = useWorkgroups();

  const renderWorkgroupMemberInfo = () => {
    if (!person.workgroupMembers || person.workgroupMembers.length === 0) {
      return null;
    }

    return (
      <Stack gap="xs">
        {person.workgroupMembers.map((member) => {
          const userRole = getUserRoleById(member.userRoleId);
          const roleDisplay = userRole?.name || 'Unknown Role';
          const workgroup = getWorkgroup(member.workgroup.id);

          if (!workgroup) {
            return null;
          }

          return (
            <Text key={member.id} fz="sm">
              {roleDisplay} at{' '}
              <Anchor
                onClick={() => navigate(`/app/ops/${member.workgroup.id}`)}
                style={{ textDecoration: 'none' }}
              >
                <Text
                  component="span"
                  fz="sm"
                  className="hover:underline text-blue-600"
                >
                  {workgroup.displayName}
                </Text>
              </Anchor>
            </Text>
          );
        })}
      </Stack>
    );
  };

  return (
    <Group justify="flex-start" align="flex-start" wrap="nowrap">
      <Avatar src={avatarUrl} alt="Profile Picture" radius={45} size={90} />
      <Stack gap="xs" p={0}>
        <Text fz={30} fw={600}>
          {person.firstName} {person.lastName}
        </Text>
        {renderWorkgroupMemberInfo()}
      </Stack>
    </Group>
  );
};
