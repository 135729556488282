import { WeightFieldProperties } from '@btrway/api-workflow';
import { WeightData, WeightInput } from '@btrway/measurable-inputs';
import { Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface WeightFieldProps extends BaseFieldProps<WeightData> {}

export const WeightField: React.FC<WeightFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const weightFieldProperties = field.fieldProperties as WeightFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  // Format weight value for display
  const formatWeight = (data: WeightData | undefined): string => {
    if (!data) return 'No weight entered';

    const decimalPlaces = weightFieldProperties?.decimalPlaces ?? 2;

    if (data.system === 'imperial') {
      const { pounds } = data.imperial;
      return `${pounds.toFixed(decimalPlaces)} lbs`;
    } else {
      const { kilograms } = data.metric;
      return `${kilograms.toFixed(decimalPlaces)} kg`;
    }
  };

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value ? 'inherit' : 'dimmed'}>
          {value ? formatWeight(value) : 'No weight entered'}
        </Text>
      </Stack>
    );
  }

  const handleChange = (newValue: WeightData) => {
    if (readonly) return;
    onChange?.(newValue);
  };

  return (
    <WeightInput
      onChange={handleChange}
      imperialEnabled={weightFieldProperties?.allowImperial ?? true}
      metricEnabled={weightFieldProperties?.allowMetric ?? true}
      decimalPlaces={weightFieldProperties?.decimalPlaces ?? 2}
      value={value}
      readOnly={readonly}
    />
  );
};
