// DeploymentFilterEdit.tsx
import {
  WorkflowTemplateSettings,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import {
  useOrganizationTypes,
  useStateProvinces,
} from '@btrway/reference-data-manager';
import { useUserRoles } from '@btrway/security-manager';
import { Button, Chip, Group, Stack, TagsInput, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import {
  createRoleSuggestions,
  shouldShowDefaultRoles,
} from '../../utils/defaultRolesUtils';

interface DeploymentFilterEditProps {
  templateSettings?: WorkflowTemplateSettings | null;
  onSave: (templateSettings: WorkflowTemplateSettings) => void;
  onCancel?: () => void;
  workflowType?: WorkflowTypeEnum;
}

export const DeploymentFilterEdit: React.FC<DeploymentFilterEditProps> = ({
  templateSettings,
  onSave,
  onCancel,
  workflowType,
}) => {
  const organizationFilter = templateSettings?.organizationFilter;
  const { stateProvinces, isLoading: isLoadingStates } = useStateProvinces();
  const { organizationTypes, isLoading: isLoadingOrganizationTypes } =
    useOrganizationTypes();
  const { userRoles, getUserRolesBySlugs } = useUserRoles();

  const [selectedStates, setSelectedStates] = useState<string[]>(
    organizationFilter?.stateCodes || []
  );
  const [selectedOrgTypes, setSelectedOrgTypes] = useState<string[]>(
    organizationFilter?.organizationTypeIds?.map(String) || []
  );
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>(
    templateSettings?.defaultUserRoles || []
  );

  const showRoles = shouldShowDefaultRoles(workflowType);

  const userRoleSuggestions = useMemo(
    () => createRoleSuggestions(userRoles),
    [userRoles]
  );

  const selectedUserRoleNames = useMemo(() => {
    const rolesBySlug = getUserRolesBySlugs(selectedUserRoles);
    return rolesBySlug.map((role) => role.name);
  }, [selectedUserRoles, userRoles]);

  const handleSave = () => {
    const newTemplateSettings: WorkflowTemplateSettings = {
      ...templateSettings,
      defaultUserRoles: showRoles ? selectedUserRoles : [],
      organizationFilter: {
        ...organizationFilter,
        stateCodes: selectedStates,
        organizationTypeIds: selectedOrgTypes.map(Number),
      },
    };
    onSave(newTemplateSettings);
  };

  const handleUserRolesChange = (values: string[]) => {
    const newSlugs = values.map((value) => {
      const suggestion = userRoleSuggestions.find((s) => s.label === value);
      return suggestion ? suggestion.value : value;
    });
    setSelectedUserRoles(newSlugs);
  };

  const sortedStates =
    stateProvinces?.slice().sort((a, b) => a.code.localeCompare(b.code)) || [];
  const sortedOrgTypes =
    organizationTypes
      ?.filter((type) => !type.internal)
      .sort((a, b) => a.name.localeCompare(b.name)) || [];

  if (isLoadingStates || isLoadingOrganizationTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Stack style={{ height: '100%' }}>
      <Stack gap="md" p="md" style={{ flex: 1, overflow: 'auto' }}>
        {showRoles && (
          <div>
            <Text fz="md" fw={600} mb="md">
              Default User Roles
            </Text>
            <TagsInput
              value={selectedUserRoleNames}
              onChange={handleUserRolesChange}
              data={userRoleSuggestions.map((s) => s.label)}
              placeholder="Select user roles"
              clearable
            />
          </div>
        )}

        <div>
          <Text fz="md" fw={600} mb="md">
            Filter by Organization Type
          </Text>
          <Chip.Group
            multiple
            value={selectedOrgTypes}
            onChange={setSelectedOrgTypes}
          >
            <Group gap="xs" justify="flex-start">
              {sortedOrgTypes.map((orgType) => (
                <Chip key={orgType.id} value={String(orgType.id)}>
                  {orgType.name}
                </Chip>
              ))}
            </Group>
          </Chip.Group>
        </div>

        <div>
          <Text fz="md" fw={600} mb="md">
            Filter By State
          </Text>
          <Chip.Group
            multiple
            value={selectedStates}
            onChange={setSelectedStates}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr)',
                gap: '10px',
              }}
            >
              {sortedStates.map((state) => (
                <Chip
                  key={state.code}
                  value={state.code}
                  styles={{
                    label: {
                      width: '100%',
                      justifyContent: 'center',
                    },
                  }}
                >
                  {state.code}
                </Chip>
              ))}
            </div>
          </Chip.Group>
        </div>
      </Stack>

      <Group
        justify="flex-end"
        p="md"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          backgroundColor: 'var(--mantine-color-gray-1)',
        }}
      >
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </Stack>
  );
};
