import {
  FormSettings,
  TaskListSettings,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { FormSettingsEditor } from '@btrway/form-settings';
import { TaskListSettingsEditor } from '@btrway/task-list-settings';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-common-provider';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import { Box, Button, Group, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { WorkflowDescriptionEditor } from '../WorkflowDescriptionEditor/WorkflowDescriptionEditor';

interface WorkflowSettingsPanelProps {
  onSave?: () => void;
  onCancel?: () => void;
}

const TASK_LIST_WORKFLOW_TYPES = [
  WorkflowTypeEnum.taskList,
  WorkflowTypeEnum.certification,
  WorkflowTypeEnum.curriculum,
] as const;

type TaskListWorkflowType = (typeof TASK_LIST_WORKFLOW_TYPES)[number];

const isTaskListWorkflowType = (
  type: WorkflowTypeEnum
): type is TaskListWorkflowType => {
  return TASK_LIST_WORKFLOW_TYPES.includes(type as TaskListWorkflowType);
};

export const WorkflowSettingsPanel: React.FC<WorkflowSettingsPanelProps> = ({
  onSave,
  onCancel,
}) => {
  const { settingsActions } = useWorkflowConfig();
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();
  const [activeTab, setActiveTab] = useState<string | null>('description');
  const [localWorkflow, setLocalWorkflow] = useState<CommonWorkflow | null>(
    null
  );

  useEffect(() => {
    if (workflow) {
      setLocalWorkflow(workflow);
    }
  }, [workflow]);

  const handleWorkflowChange = (updatedWorkflow: CommonWorkflow) => {
    setLocalWorkflow(updatedWorkflow);
  };

  const handleFormSettingsChange = (formSettings: FormSettings) => {
    if (!localWorkflow) return;

    setLocalWorkflow((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        workflowConfiguration: {
          ...prev.workflowConfiguration,
          settings: formSettings,
        },
      };
    });
  };

  const handleTaskListSettingsChange = (taskListSettings: TaskListSettings) => {
    if (!localWorkflow) return;

    setLocalWorkflow((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        workflowConfiguration: {
          ...prev.workflowConfiguration,
          settings: taskListSettings,
        },
      };
    });
  };

  const handleSave = async () => {
    if (!localWorkflow) return;

    await workflowService.updateWorkflow(localWorkflow);

    if (localWorkflow.workflowConfiguration) {
      if (localWorkflow.workflowType === WorkflowTypeEnum.form) {
        await settingsActions.updateFormSettings(
          localWorkflow.workflowConfiguration.settings as FormSettings
        );
      } else if (isTaskListWorkflowType(localWorkflow.workflowType)) {
        await settingsActions.updateTaskListSettings(
          localWorkflow.workflowConfiguration.settings as TaskListSettings
        );
      }
    }
    onSave?.();
  };

  const handleCancel = () => {
    if (workflow) {
      setLocalWorkflow(workflow);
    }
    onCancel?.();
  };

  if (!localWorkflow) return null;

  const showTaskListSettings = isTaskListWorkflowType(
    localWorkflow.workflowType
  );

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Tabs value={activeTab} onChange={setActiveTab} style={{ flex: 1 }}>
        <Tabs.List>
          <Tabs.Tab value="description">Description</Tabs.Tab>
          {localWorkflow.workflowType === WorkflowTypeEnum.form && (
            <Tabs.Tab value="formSettings">Form Settings</Tabs.Tab>
          )}
          {showTaskListSettings && (
            <Tabs.Tab value="taskSettings">Task Assignment Settings</Tabs.Tab>
          )}
        </Tabs.List>

        <Box p="md" style={{ flex: 1, overflow: 'auto' }}>
          <Tabs.Panel value="description">
            <WorkflowDescriptionEditor
              workflow={localWorkflow}
              onChange={handleWorkflowChange}
            />
          </Tabs.Panel>

          {localWorkflow.workflowType === WorkflowTypeEnum.form && (
            <Tabs.Panel value="formSettings">
              <FormSettingsEditor
                initialFormSettings={
                  localWorkflow.workflowConfiguration?.settings as FormSettings
                }
                onChange={handleFormSettingsChange}
              />
            </Tabs.Panel>
          )}

          {showTaskListSettings && (
            <Tabs.Panel value="taskSettings">
              <TaskListSettingsEditor
                initialSettings={
                  localWorkflow.workflowConfiguration
                    ?.settings as TaskListSettings
                }
                onChange={handleTaskListSettingsChange}
              />
            </Tabs.Panel>
          )}
        </Box>
      </Tabs>

      <Box
        p="md"
        style={{ borderTop: '1px solid var(--mantine-color-gray-3)' }}
      >
        <Group justify="flex-end" gap="md">
          <Button variant="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Box>
    </Box>
  );
};
