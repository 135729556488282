import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import React, { useContext, useEffect } from 'react';
import { LAYOUT } from '../../constants/layout';
import { useScreen } from '../../hooks/useScreen';
import { useWorkflowBuilderState } from '../../hooks/useWorkflowBuilderState';
import { useWorkflowBuilderPanel } from '../../providers/WorkflowBuilderPanelProvider';
import { WorkflowBuilderContext } from '../../providers/WorkflowBuilderStateProvider';
import { Modal } from '../Modal/Modal';
import { ScreenRenderer } from '../ScreenRenderer/ScreenRenderer';

interface DisplayLayoutProps {
  children: React.ReactNode;
}

export const DisplayLayout: React.FC<DisplayLayoutProps> = ({ children }) => {
  const { state, closeScreen } = useWorkflowBuilderState();
  const { openPanel, closePanel } = useWorkflowBuilderPanel();
  const { displayState, currentScreen } = state;
  const screenContext = useScreen();
  const workflowBuilderContext = useContext(WorkflowBuilderContext);
  const { workflowConfig } = useWorkflowConfig();

  const handlers = {
    onClose: () => {
      // Add a small delay before actually closing to allow animation to complete
      const handleClose = () => {
        closeScreen();
        closePanel();
      };

      if (displayState.config.mode === 'panel') {
        setTimeout(handleClose, LAYOUT.TRANSITION.DURATION);
      } else {
        handleClose();
      }
    },
    onToolboxSelect: screenContext.screenHandlers.onToolboxSelect,
    onWorkflowSelect: screenContext.screenHandlers.onWorkflowSelect,
    onCourseSelect: screenContext.screenHandlers.onCourseSelect,
    onSave: screenContext.screenHandlers.onSave,
    onDelete: screenContext.screenHandlers.onDelete,
    onEditorSave: screenContext.screenHandlers.onEditorSave,
  };

  useEffect(() => {
    if (
      currentScreen &&
      displayState.isOpen &&
      displayState.config.mode === 'panel' &&
      workflowBuilderContext &&
      workflowConfig
    ) {
      openPanel(
        currentScreen,
        displayState.config,
        handlers,
        workflowBuilderContext,
        workflowConfig
      );
    } else {
      closePanel();
    }
  }, [
    currentScreen,
    displayState.isOpen,
    displayState.config,
    workflowBuilderContext,
    workflowConfig,
  ]);

  if (!currentScreen || !displayState.isOpen) {
    return <>{children}</>;
  }

  if (displayState.config.mode === 'panel') {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <Modal
        isOpen={displayState.isOpen}
        onClose={closeScreen}
        config={displayState.config.modalProps}
      >
        <ScreenRenderer screen={currentScreen} handlers={handlers} />
      </Modal>
    </>
  );
};
