import { Card, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';
import { EntityTypeConfig } from '../../config/entityTypes';

interface EntityTypeCardProps {
  config?: EntityTypeConfig;
  selected: boolean;
  onClick: () => void;
  isNothingCard?: boolean;
  variant?: 'sm' | 'lg';
}

export const EntityTypeCard: React.FC<EntityTypeCardProps> = ({
  config,
  selected,
  onClick,
  isNothingCard = false,
  variant = 'lg',
}) => {
  const theme = useMantineTheme();

  return (
    <Card
      py="sm"
      px="lg"
      radius="md"
      withBorder
      style={{
        cursor: 'pointer',
        borderColor: selected ? theme.colors[theme.primaryColor][6] : undefined,
        borderWidth: selected ? 2 : 1,
      }}
      onClick={onClick}
    >
      <Stack>
        {isNothingCard ? (
          <>
            <Text fw={500}>Nothing</Text>
            <Text size="sm" c="dimmed">
              Select this if the form is not about any specific entity type
            </Text>
          </>
        ) : (
          config && (
            <>
              <Group>
                <config.icon size={24} />
                <Text fw={500}>{config.label}</Text>
              </Group>
              {variant === 'lg' && (
                <Text size="sm" c="dimmed">
                  Examples:
                  <ul style={{ marginTop: 4, marginBottom: 0 }}>
                    {config.examples.map((example, i) => (
                      <li key={i}>{example}</li>
                    ))}
                  </ul>
                </Text>
              )}
            </>
          )
        )}
      </Stack>
    </Card>
  );
};
