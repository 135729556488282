import {
  DataTypeEnum,
  FieldConfig,
  PropertyReference,
  StepConfig,
  StepTypeEnum,
  WorkflowProperty,
} from '@btrway/api-workflow';

export const getAllFields = (fields: FieldConfig[]): FieldConfig[] => {
  return fields.reduce((allFields: FieldConfig[], field) => {
    allFields.push(field);
    if (field.childFields) {
      allFields.push(...getAllFields(field.childFields));
    }
    return allFields;
  }, []);
};

export const getFormFieldsAsProperties = (
  step: StepConfig
): PropertyReference[] => {
  if (!step.formConfig?.fields) return [];

  const allFields = getAllFields(step.formConfig.fields);

  return allFields.map((field) => ({
    stepKey: step.stepKey,
    propertyKey: field.fieldKey,
    label: field.label,
    dataType: DataTypeEnum.text, //TODO: get type by formfield.type
  }));
};

export const getStepTypeProperties = (
  step: StepConfig,
  getStepType: Function
): PropertyReference[] => {
  if (!step.stepTypeCode) return [];

  const stepTypeDTO = getStepType(step.stepTypeCode);
  if (!stepTypeDTO) return [];

  return (stepTypeDTO.properties || []).map((prop: WorkflowProperty) => ({
    stepKey: step.stepKey,
    propertyKey: prop.key,
    label: prop.label,
    dataType: prop.dataType,
  }));
};

export const addDataTypeProperties = (
  properties: PropertyReference[],
  getDataType: Function
): PropertyReference[] => {
  const allProperties: PropertyReference[] = [];

  properties.forEach((prop: PropertyReference) => {
    allProperties.push(prop);

    const dataTypeDetails = getDataType(prop.dataType);
    if (dataTypeDetails?.properties) {
      dataTypeDetails.properties.forEach(
        (subProp: {
          name: string;
          dataTypeCode: DataTypeEnum;
          key: string;
        }) => {
          allProperties.push({
            stepKey: prop.stepKey,
            propertyKey: `${prop.propertyKey}`,
            label: `${subProp.name}`,
            dataType: subProp.dataTypeCode,
            entityProperty: subProp.key,
          });
        }
      );
    }
  });

  return allProperties;
};

export const getPreviousSteps = (
  currentStepKey: string,
  workflowConfig: {
    transitions?: { sourceStepKey: string; targetStepKey: string }[];
    steps?: StepConfig[];
  }
): StepConfig[] => {
  const previousSteps: StepConfig[] = [];
  const visitedStepKeys = new Set<string>();

  const traverseSteps = (stepKey: string) => {
    if (visitedStepKeys.has(stepKey)) return;
    visitedStepKeys.add(stepKey);

    const incomingTransitions = workflowConfig.transitions?.filter(
      (transition) => transition.targetStepKey === stepKey
    );

    incomingTransitions?.forEach((transition) => {
      const sourceStep = workflowConfig.steps?.find(
        (step: StepConfig) => step.stepKey === transition.sourceStepKey
      );
      if (sourceStep && !visitedStepKeys.has(sourceStep.stepKey)) {
        previousSteps.push(sourceStep);
        traverseSteps(sourceStep.stepKey);
      }
    });
  };

  traverseSteps(currentStepKey);
  return previousSteps;
};

export const getOutputProperties = (
  step: StepConfig,
  getStepType: Function
): PropertyReference[] => {
  if (!step.stepTypeCode) return [];

  const stepTypeDTO = getStepType(step.stepTypeCode);
  if (!stepTypeDTO?.properties) return [];

  // For flowStart steps, handle submitter and about entity separately
  if (step.stepTypeCode === StepTypeEnum.flowStart) {
    return stepTypeDTO.properties
      .filter(
        (prop: WorkflowProperty) =>
          !prop.key.startsWith('submitter') &&
          !prop.key.startsWith('aboutEntity')
      )
      .map((prop: WorkflowProperty) => ({
        stepKey: step.stepKey,
        propertyKey: prop.key,
        label: prop.label,
        dataType: prop.dataType,
      }));
  }

  // For other steps, return all output properties
  return stepTypeDTO.properties.map((prop: WorkflowProperty) => ({
    stepKey: step.stepKey,
    propertyKey: prop.key,
    label: prop.label,
    dataType: prop.dataType,
  }));
};

export const getSubmitterProperties = (
  flowStartStep: StepConfig,
  getStepType: Function
): PropertyReference[] => {
  if (!flowStartStep || flowStartStep.stepTypeCode !== StepTypeEnum.flowStart)
    return [];

  const stepTypeDTO = getStepType(flowStartStep.stepTypeCode);
  if (!stepTypeDTO?.properties) return [];

  return stepTypeDTO.properties
    .filter((prop: WorkflowProperty) => prop.key.startsWith('submitter'))
    .map((prop: WorkflowProperty) => ({
      stepKey: flowStartStep.stepKey,
      propertyKey: prop.key,
      label: prop.label,
      dataType: prop.dataType,
    }));
};
