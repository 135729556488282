import {
  SubmitFormTaskDTO,
  useStartWorkflow,
  useSubmitFormTask,
} from '@btrway/api-workflow';
import { useCallback } from 'react';

export const useFormSubmission = () => {
  const { mutateAsync: submitFormStart } = useStartWorkflow();
  const { mutateAsync: submitFormTask } = useSubmitFormTask();

  const submitStepData = useCallback(
    async (
      data: SubmitFormTaskDTO,
      isFirstStep: boolean
    ): Promise<SubmitFormTaskDTO> => {
      try {
        if (isFirstStep) {
          return (await submitFormStart({ data })) as SubmitFormTaskDTO;
        } else {
          return (await submitFormTask({ data })) as SubmitFormTaskDTO;
        }
      } catch (error) {
        console.error('Error submitting form data:', error);
        throw error;
      }
    },
    [submitFormStart, submitFormTask]
  );

  return {
    submitStepData,
  };
};
