import { DateFieldProperties, DateFieldRenderType } from '@btrway/api-workflow';
import { Stack, Text } from '@mantine/core';
import { DateInput, DatePicker } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DateFieldProps extends BaseFieldProps<Date | string | null, Date> {}

export const DateField: React.FC<DateFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const dateProperties = field.fieldProperties as DateFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  // Parse incoming YYYY-MM-DD string to Date object
  const parseDate = (
    dateValue: Date | string | null | undefined
  ): Date | null => {
    if (!dateValue) return null;

    try {
      if (typeof dateValue === 'string') {
        const [year, month, day] = dateValue.split('-').map(Number);
        return new Date(year, month - 1, day, 12, 0, 0);
      }

      const d = dayjs(dateValue);
      if (d.isValid()) {
        return new Date(d.year(), d.month(), d.date(), 12, 0, 0);
      }

      return null;
    } catch (e) {
      console.error('Error parsing date:', e);
      return null;
    }
  };

  // Format date for display mode
  const formatDate = (date: Date | null): string => {
    if (!date) return '';
    return dayjs(date).format('MM/DD/YYYY');
  };

  const handleChange = (newDate: Date | null) => {
    if (readonly) return;

    if (onChange) {
      if (newDate) {
        const adjustedDate = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          12,
          0,
          0
        );
        onChange(adjustedDate);
      } else {
        onChange(null);
      }
    }
  };

  const { minDate, maxDate } = (() => {
    const today = new Date();
    let maxDate = undefined;
    let minDate = undefined;

    if (dateProperties?.minimumDaysAgo !== undefined) {
      maxDate = new Date(today);
      maxDate.setDate(today.getDate() - dateProperties.minimumDaysAgo);
    }

    if (dateProperties?.maximumDaysAgo !== undefined) {
      minDate = new Date(today);
      minDate.setDate(today.getDate() - dateProperties.maximumDaysAgo);
    }

    return { minDate, maxDate };
  })();

  const parsedValue = parseDate(value);

  // Display mode just shows formatted text
  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={parsedValue ? 'inherit' : 'dimmed'}>
          {parsedValue ? formatDate(parsedValue) : 'No date selected'}
        </Text>
      </Stack>
    );
  }

  // Common props for both input types
  const getDatePickerProps = () => ({
    required: field.required,
    readOnly: readonly,
    value: parsedValue,
    onChange: handleChange,
    minDate: minDate,
    maxDate: maxDate,
    popoverProps: readonly
      ? {
          opened: false,
          withinPortal: false,
        }
      : undefined,
  });

  const getDateInputProps = () => ({
    required: field.required,
    readOnly: readonly,
    value: parsedValue,
    onChange: handleChange,
    minDate: minDate,
    maxDate: maxDate,
    popoverProps: readonly
      ? {
          opened: false,
          withinPortal: false,
        }
      : undefined,
  });

  switch (dateProperties?.renderType) {
    case DateFieldRenderType.picker:
      const dateValue = parsedValue || undefined;
      const defaultDate = dateValue ? new Date(dateValue) : new Date();

      return (
        <Stack gap="xs">
          <DatePicker
            {...getDatePickerProps()}
            defaultDate={defaultDate}
            date={dateValue}
            onDateChange={() => {}}
          />
        </Stack>
      );

    case DateFieldRenderType.input:
    default:
      return (
        <Stack gap="xs">
          <DateInput
            {...getDateInputProps()}
            leftSection={<IconCalendar size={16} />}
            placeholder="Select date"
            valueFormat="MM/DD/YYYY"
            clearable={!readonly}
          />
        </Stack>
      );
  }
};

export default DateField;
