import { WorkflowConfig } from '@btrway/api-workflow';
import { useWorkflowUpdater } from '@btrway/workflow-common-provider';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useCertificationActions } from '../hooks/useCertificationActions';
import { useProcessActions } from '../hooks/useProcessActions';
import { useTaskActions } from '../hooks/useTaskActions';
import { useWorkflowSettingsActions } from '../hooks/useWorkflowSettingsActions';
import {
  CertificationActions,
  ProcessActions,
  SettingsActions,
  TaskActions,
} from '../types/actions';

export interface WorkflowConfigContextType {
  workflowConfig: WorkflowConfig;
  isLoading: boolean;
  selectedStepKey: string | null;
  selectStep: (stepKey: string | null) => void;
  certificationActions: CertificationActions;
  taskActions: TaskActions;
  processActions: ProcessActions;
  settingsActions: SettingsActions;
}

export interface WorkflowConfigProviderProps {
  children: React.ReactNode;
  initialConfig?: WorkflowConfig;
  onChange?: (config: WorkflowConfig) => void;
  isLoading?: boolean;
}

const WorkflowConfigContext = createContext<WorkflowConfigContextType | null>(
  null
);

export const WorkflowConfigProvider: React.FC<WorkflowConfigProviderProps> = ({
  children,
  initialConfig,
  onChange,
  isLoading = false,
}) => {
  const workflowUpdater = useWorkflowUpdater();
  const [workflowConfig, setWorkflowConfig] = useState<WorkflowConfig>(() => ({
    steps: [],
    transitions: [],
    events: [],
    certifications: [],
    tasks: [],
    ...initialConfig,
  }));

  const [selectedStepKey, setSelectedStepKey] = useState<string | null>(null);

  const certificationActions = useCertificationActions(
    workflowUpdater,
    workflowConfig,
    setWorkflowConfig
  );
  const taskActions = useTaskActions(
    workflowUpdater,
    workflowConfig,
    setWorkflowConfig
  );
  const processActions = useProcessActions(
    workflowUpdater,
    workflowConfig,
    setWorkflowConfig
  );
  const settingsActions = useWorkflowSettingsActions(
    workflowUpdater,
    setWorkflowConfig
  );

  const selectStep = useCallback(
    (stepKey: string | null) => {
      setSelectedStepKey(stepKey);
    },
    [setSelectedStepKey]
  );

  const value: WorkflowConfigContextType = {
    workflowConfig,
    isLoading,
    selectedStepKey,
    selectStep,
    certificationActions,
    taskActions,
    processActions,
    settingsActions,
  };

  return (
    <WorkflowConfigContext.Provider value={value}>
      {children}
    </WorkflowConfigContext.Provider>
  );
};

export const useWorkflowConfig = () => {
  const context = useContext(WorkflowConfigContext);
  if (!context) {
    throw new Error(
      'useWorkflowConfig must be used within a WorkflowConfigProvider'
    );
  }
  return context;
};
