import { Divider } from '@mantine/core';
import React from 'react';
import { BaseFieldProps } from '../../types/baseField';

interface DividerFieldProps extends BaseFieldProps<never> {}

const DividerField: React.FC<DividerFieldProps> = () => {
  return <Divider size="md" color="gray.5" variant="solid" />;
};

export default DividerField;
