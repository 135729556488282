import {
  FieldConfig,
  FieldProperties,
  FieldTypeEnum,
  FormConfig,
  SelectFieldRenderType,
  TextFieldDataType,
  TextFieldProperties,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { FormAIField, FormAIResponse } from '../types/formAIResponse';

const createFieldProperties = (
  field: FormAIField
): FieldProperties | undefined => {
  // Handle select fields
  if (field.type === 'select' && field.options) {
    return {
      allowsMultiple: false,
      allowsOther: false,
      options: field.options.map((opt, index) => ({
        label: opt,
        value: opt.toLowerCase().replace(/\s+/g, '_'),
        order: index,
      })),
      renderType: SelectFieldRenderType.picklist,
      sortAlpha: false,
    };
  }

  // Handle email fields
  if (field.type === 'email') {
    return {
      dataType: TextFieldDataType.email,
    } as TextFieldProperties;
  }

  // Handle phone fields
  if (field.type === 'phone') {
    return {
      dataType: TextFieldDataType.phone,
    } as TextFieldProperties;
  }

  if (field.type === 'textarea') {
    return {
      multiLine: true,
    } as TextFieldProperties;
  }

  return undefined;
};

const convertField = (field: FormAIField): FieldConfig => {
  const baseConfig: FieldConfig = {
    fieldKey: uuid(),
    label: field.title,
    // Convert email and phone types to text type
    type:
      field.type === 'email' ||
      field.type === 'phone' ||
      field.type === 'textarea'
        ? FieldTypeEnum.text
        : (field.type as FieldTypeEnum),
    required: field.required ?? false,
    startWithNewLine: true,
  };

  const fieldProperties = createFieldProperties(field);
  if (fieldProperties) {
    baseConfig.fieldProperties = fieldProperties;
  }

  return baseConfig;
};

export const convertToFormConfig = (
  formAIResponse: FormAIResponse
): FormConfig => {
  console.log('formAIResponse', formAIResponse);
  return {
    fields: formAIResponse.fields.map(convertField),
  };
};
