import {
  EventConfig,
  StepConfig,
  TransitionConfig,
  WorkflowConfig,
} from '@btrway/api-workflow';
import { useCallback, useMemo } from 'react';

export interface WorkflowConfigUtilities {
  getStep: (stepKey: string) => StepConfig | undefined;
  getEvent: (eventKey: string) => EventConfig | undefined;
  getOrderedSteps: () => StepConfig[];
  getOutgoingTransitions: (step: StepConfig) => TransitionConfig[];
  getNextStep: (currentStepKey: string) => StepConfig | null;
  getPreviousStep: (currentStepKey: string) => StepConfig | null;
}

export const useWorkflowConfigUtilities = (
  workflowConfig: WorkflowConfig
): WorkflowConfigUtilities => {
  const getStep = (stepKey: string): StepConfig | undefined =>
    workflowConfig.steps?.find((s) => s.stepKey === stepKey);

  const getEvent = (eventKey: string): EventConfig | undefined =>
    workflowConfig.events?.find((e) => e.eventKey === eventKey);

  const getOutgoingTransitions = (step: StepConfig): TransitionConfig[] =>
    workflowConfig.transitions?.filter(
      (transition) => transition.sourceStepKey === step.stepKey
    ) || [];

  const orderedSteps = useMemo((): StepConfig[] => {
    if (!workflowConfig.steps || !workflowConfig.transitions) return [];

    const steps = [...workflowConfig.steps];
    const transitions = [...workflowConfig.transitions];

    // Find the start step (one with no incoming transitions)
    const startStep = steps.find(
      (step: StepConfig) =>
        !transitions.some(
          (transition) => transition.targetStepKey === step.stepKey
        )
    );

    if (!startStep) return [];

    const orderedSteps: StepConfig[] = [startStep];
    let currentStep = startStep;

    while (orderedSteps.length < steps.length) {
      const outgoingTransition = transitions.find(
        (transition) => transition.sourceStepKey === currentStep.stepKey
      );

      if (!outgoingTransition) break;

      const nextStep = steps.find(
        (step: StepConfig) => step.stepKey === outgoingTransition.targetStepKey
      );

      if (nextStep) {
        orderedSteps.push(nextStep);
        currentStep = nextStep;
      } else {
        break;
      }
    }

    return orderedSteps;
  }, [workflowConfig.steps, workflowConfig.transitions]);

  const getOrderedSteps = useCallback((): StepConfig[] => {
    return orderedSteps;
  }, [orderedSteps]);

  const getNextStep = (currentStepKey: string): StepConfig | null => {
    const currentIndex = orderedSteps.findIndex(
      (step) => step.stepKey === currentStepKey
    );

    return currentIndex === -1 || currentIndex === orderedSteps.length - 1
      ? null
      : orderedSteps[currentIndex + 1];
  };

  const getPreviousStep = (currentStepKey: string): StepConfig | null => {
    const currentIndex = orderedSteps.findIndex(
      (step) => step.stepKey === currentStepKey
    );

    return currentIndex <= 0 ? null : orderedSteps[currentIndex - 1];
  };

  return {
    getStep,
    getEvent,
    getOrderedSteps,
    getOutgoingTransitions,
    getNextStep,
    getPreviousStep,
  };
};
