// types/metricContext.ts

import { WorkflowTypeEnum } from '@btrway/api-workflow';

export interface MetricContext {
  taskListLabel: string;
  taskLabel: string;
  showTaskLists: boolean;
  showTasks: boolean;
}

export const getMetricContext = (
  workflowType?: WorkflowTypeEnum,
  taskListType?: string
): MetricContext => {
  if (!workflowType) {
    return {
      taskListLabel: 'Lists',
      taskLabel: 'Tasks',
      showTaskLists: true,
      showTasks: true,
    };
  }

  switch (workflowType) {
    case WorkflowTypeEnum.certification:
      if (taskListType === 'Coach Certification') {
        return {
          taskListLabel: 'Coaches Certified',
          taskLabel: 'Requirements',
          showTaskLists: true,
          showTasks: false,
        };
      } else if (taskListType === 'Student-Athlete Eligibility') {
        return {
          taskListLabel: 'Student-Athletes Eligible',
          taskLabel: 'Requirements',
          showTaskLists: true,
          showTasks: false,
        };
      }
      return {
        taskListLabel: 'Certifications',
        taskLabel: 'Requirements',
        showTaskLists: true,
        showTasks: false,
      };

    case WorkflowTypeEnum.taskList:
      return {
        taskListLabel: 'To-Do Lists',
        taskLabel: 'To-Dos',
        showTaskLists: false,
        showTasks: true,
      };

    case WorkflowTypeEnum.curriculum:
      return {
        taskListLabel: 'Curriculums',
        taskLabel: 'Courses',
        showTaskLists: true,
        showTasks: true,
      };

    default:
      return {
        taskListLabel: 'Lists',
        taskLabel: 'Tasks',
        showTaskLists: true,
        showTasks: true,
      };
  }
};
