import { FieldTypeEnum } from '@btrway/api-workflow';
import { Box, CloseButton, Stack, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { fieldGroups, getFieldLabel } from '../../utils/toolbox';
import { FormFieldSelectItem } from '../FormFieldSelectItem/FormFieldSelectItem';

interface FormFieldSelectOptionsProps {
  currentFieldType: FieldTypeEnum;
  onFieldTypeSelect: (fieldType: FieldTypeEnum) => void;
}

export const FormFieldSelectOptions: React.FC<FormFieldSelectOptionsProps> = ({
  currentFieldType,
  onFieldTypeSelect,
}) => {
  const [searchText, setSearchText] = useState('');

  // Flatten and sort all field types
  const allFieldTypes = useMemo(() => {
    const types = fieldGroups.flatMap((group) => group.fields);
    return [...types].sort((a, b) =>
      getFieldLabel(a).localeCompare(getFieldLabel(b))
    );
  }, []);

  // Filter field types based on search
  const filteredFieldTypes = useMemo(() => {
    const searchLower = searchText.toLowerCase();
    return searchLower
      ? allFieldTypes.filter((fieldType) =>
          getFieldLabel(fieldType).toLowerCase().includes(searchLower)
        )
      : allFieldTypes;
  }, [allFieldTypes, searchText]);

  return (
    <Stack gap="md">
      <Box px="xs" pt="xs">
        <TextInput
          placeholder="Search field types..."
          value={searchText}
          onChange={(event) => setSearchText(event.currentTarget.value)}
          leftSection={<IconSearch size={16} />}
          rightSection={
            searchText ? (
              <CloseButton onClick={() => setSearchText('')} size="sm" />
            ) : null
          }
        />
      </Box>

      <Box
        style={{
          maxHeight: 'calc(80vh - 120px)',
          overflowY: 'auto',
        }}
      >
        <Stack gap="xs" px="xs" pb="xs">
          {filteredFieldTypes.map((fieldType) => (
            <FormFieldSelectItem
              key={fieldType}
              type={fieldType}
              isSelected={fieldType === currentFieldType}
              onSelect={() => onFieldTypeSelect(fieldType)}
            />
          ))}
          {searchText && filteredFieldTypes.length === 0 && (
            <Box py="xl" ta="center" c="dimmed">
              No matching fields found
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
