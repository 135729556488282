// utils/configCreators.ts
import {
  CertificationConfig,
  CertificationRequiredTypeEnum,
  CertificationTypeEnum,
  CertificationValidTypeEnum,
  EventConfig,
  EventTypeEnum,
  StepConfig,
  StepTypeEnum,
  TaskConfig,
  TaskMetadata,
  TaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';

export const createBaseTaskConfig = (
  taskType: TaskTypeEnum,
  title: string = ''
): TaskConfig => ({
  taskKey: uuid(),
  taskType,
  metadata: {
    title,
    description: '',
  } as TaskMetadata,
  taskProperties: {} as TaskProperties,
});

export const createBaseCertificationConfig = (
  certType: CertificationTypeEnum,
  title: string = ''
): CertificationConfig => ({
  certificationKey: uuid(),
  certificationType: certType,
  title,
  triggerSets: [],
  taskProperties: {},
  certificationValidConfig: {
    validType: CertificationValidTypeEnum.thisYear,
    withinDays: 0,
  },
  certificationRequiredConfig: {
    requiredType: CertificationRequiredTypeEnum.yes,
    timeValue: 0,
  },
});

export const createBaseEventConfig = (
  eventType: EventTypeEnum,
  title: string = ''
): EventConfig => ({
  eventKey: uuid(),
  eventTypeCode: eventType,
  title,
  triggerSets: [],
  basePropertyConfig: {},
});

export const createBaseStepConfig = (
  stepTypeCode: StepTypeEnum,
  title: string,
  taskConfig?: TaskConfig
): StepConfig => ({
  stepKey: uuid(),
  stepTypeCode,
  title,
  ...(taskConfig && { taskConfig }),
});

export const createBaseWorkflowTaskConfig = (
  workflowKey: string,
  workflowName: string,
  taskType: TaskTypeEnum
): TaskConfig => ({
  ...createBaseTaskConfig(taskType, workflowName),
  taskProperties: {
    workflowSelection: {
      workflowDefinitionKey: { value: workflowKey },
    },
  } as TaskProperties,
});

export const createBaseWorkflowCertificationConfig = (
  workflowKey: string,
  workflowName: string,
  certType: CertificationTypeEnum
): CertificationConfig => ({
  ...createBaseCertificationConfig(certType, workflowName),
  taskProperties: {
    workflowSelection: {
      workflowDefinitionKey: { value: workflowKey },
    },
  },
});

export const createBaseCourseTaskConfig = (
  courseUid: string,
  courseName: string
): TaskConfig => ({
  ...createBaseTaskConfig(TaskTypeEnum.completeCourse, courseName),
  taskProperties: {
    courseSelection: {
      courseUid: { value: courseUid },
    },
  } as TaskProperties,
});

export const createBaseCourseCertificationConfig = (
  courseUid: string,
  courseName: string
): CertificationConfig => ({
  ...createBaseCertificationConfig(
    CertificationTypeEnum.completeCourse,
    courseName
  ),
  taskProperties: {
    courseSelection: {
      courseUid: { value: courseUid },
    },
  },
});
