import {
  FieldOption,
  SelectFieldProperties,
  SelectFieldRenderType,
} from '@btrway/api-workflow';
import {
  Checkbox,
  Chip,
  ChipGroup,
  Group,
  MultiSelect,
  Radio,
  SegmentedControl,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface SelectFieldProps
  extends BaseFieldProps<string | string[], string | string[]> {}

const sortOptions = (options: FieldOption[] = []): FieldOption[] => {
  return [...options].sort((a, b) => {
    if (a.order !== undefined && b.order !== undefined)
      return a.order - b.order;
    if (a.order !== undefined) return -1;
    if (b.order !== undefined) return 1;
    return a.label.localeCompare(b.label);
  });
};

const getOptionsData = (options: FieldOption[] = []) => {
  return sortOptions(options).map((opt) => ({
    label: opt.label,
    value: String(opt.value ?? opt.label),
  }));
};

export const SelectField: React.FC<SelectFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const selectProperties = field.fieldProperties as
    | SelectFieldProperties
    | undefined;
  const options = getOptionsData(selectProperties?.options);
  const readonly = isReadOnly(displayMode) || field.readOnly;

  if (!options.length) {
    return (
      <Text size="sm" c="dimmed" style={{ fontStyle: 'italic' }}>
        No options available
      </Text>
    );
  }

  // For display mode, show selected values as text
  if (isDisplayMode(displayMode)) {
    const selectedLabels = Array.isArray(value)
      ? options
          .filter((opt) => value.includes(opt.value))
          .map((opt) => opt.label)
      : options.find((opt) => opt.value === value)?.label;

    return (
      <Stack gap="xs">
        <Text size="sm" c={value ? 'inherit' : 'dimmed'}>
          {Array.isArray(selectedLabels)
            ? selectedLabels.length > 0
              ? selectedLabels.join(', ')
              : 'No selection'
            : selectedLabels || 'No selection'}
        </Text>
      </Stack>
    );
  }

  const handleChange = (newValue: string | string[] | null) => {
    if (!readonly) {
      onChange?.(newValue);
    }
  };

  const renderType =
    selectProperties?.renderType || SelectFieldRenderType.picklist;
  const allowsMultiple = selectProperties?.allowsMultiple ?? false;

  if (allowsMultiple) {
    switch (renderType) {
      case SelectFieldRenderType.picklist:
        return (
          <MultiSelect
            data={options}
            value={Array.isArray(value) ? value : []}
            onChange={handleChange}
            required={field.required}
            searchable
            clearable
          />
        );

      case SelectFieldRenderType.chip:
        return (
          <Stack gap="xs">
            <ChipGroup
              multiple
              value={Array.isArray(value) ? value : []}
              onChange={handleChange}
            >
              <Group gap="xs">
                {options.map((option) => (
                  <Chip key={option.value} value={option.value}>
                    {option.label}
                  </Chip>
                ))}
              </Group>
            </ChipGroup>
          </Stack>
        );

      case SelectFieldRenderType.radioCheckbox:
        return (
          <Checkbox.Group
            value={Array.isArray(value) ? value : []}
            onChange={handleChange}
            required={field.required}
          >
            <Stack gap="xs">
              {options.map((option) => (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </Stack>
          </Checkbox.Group>
        );

      default:
        return (
          <MultiSelect
            data={options}
            value={Array.isArray(value) ? value : []}
            onChange={handleChange}
            required={field.required}
            searchable
            clearable
          />
        );
    }
  } else {
    switch (renderType) {
      case SelectFieldRenderType.picklist:
        return (
          <Select
            data={options}
            value={value as string}
            onChange={(newValue) => handleChange(newValue)}
            required={field.required}
            searchable
            clearable
          />
        );

      case SelectFieldRenderType.chip:
        return (
          <Stack gap="xs">
            <ChipGroup
              value={value as string}
              onChange={(newValue) => handleChange(newValue)}
            >
              <Group gap="xs">
                {options.map((option) => (
                  <Chip key={option.value} value={option.value}>
                    {option.label}
                  </Chip>
                ))}
              </Group>
            </ChipGroup>
          </Stack>
        );

      case SelectFieldRenderType.radioCheckbox:
        return (
          <Radio.Group
            value={value as string}
            onChange={(newValue) => handleChange(newValue)}
            required={field.required}
          >
            <Stack gap="xs">
              {options.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </Stack>
          </Radio.Group>
        );

      case SelectFieldRenderType.segmented:
        return (
          <Stack gap="xs">
            <SegmentedControl
              data={options}
              value={value as string}
              onChange={(newValue) => handleChange(newValue)}
            />
          </Stack>
        );

      default:
        return (
          <Select
            data={options}
            value={value as string}
            onChange={(newValue) => handleChange(newValue)}
            required={field.required}
            searchable
            clearable
          />
        );
    }
  }
};

export default SelectField;
