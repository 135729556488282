import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import {
  CommonWorkflow,
  useWorkflowCommon,
  useWorkflowService,
} from '@btrway/workflow-common-provider';
import {
  Box,
  Button,
  Card,
  Group,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
  IconChevronRight,
  IconPlus,
  IconSearch,
  IconX,
} from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';

// Helper function to format workflow type names
const formatWorkflowType = (type: keyof typeof WorkflowTypeEnum): string => {
  const formatMap: Record<keyof typeof WorkflowTypeEnum, string> = {
    automation: 'Automation',
    curriculum: 'Curriculum',
    form: 'Form',
    formPacket: 'Form Packet',
    taskList: 'Task List',
    taskOption: 'Task Option',
    file: 'File',
    certification: 'Certification',
  };
  return formatMap[type] || type;
};

interface WorkflowSelectorProps {
  workflows: CommonWorkflow[];
  selectedWorkflowKey: string | undefined;
  workflowType: WorkflowTypeEnum;
  onWorkflowSelect: (workflowKey: string, name: string, isNew: boolean) => void;
  onClose: () => void;
}

const WorkflowSelectionCard: React.FC<{
  workflow: CommonWorkflow;
  onSelect: (key: string, name: string) => void;
  isSelected: boolean;
}> = ({ workflow, onSelect, isSelected }) => {
  const workflowKey =
    workflow.sourceType === 'definition'
      ? workflow.workflowKey
      : workflow.templateKey;

  return (
    <Card
      shadow="none"
      padding="xs"
      radius="md"
      withBorder
      onClick={() => onSelect(workflowKey!, workflow.name || '')}
      style={{
        cursor: 'pointer',
        backgroundColor: isSelected
          ? 'var(--mantine-color-blue-light)'
          : undefined,
      }}
    >
      <Group justify="space-between" wrap="nowrap">
        <Text fz="sm" fw={isSelected ? 600 : 400}>
          {workflow.name}
        </Text>
        <IconChevronRight
          size={18}
          color={isSelected ? 'var(--mantine-color-blue-6)' : undefined}
        />
      </Group>
    </Card>
  );
};

const NewWorkflowCard: React.FC<{
  isEditing: boolean;
  onStartNew: () => void;
  onSave: (name: string) => Promise<void>;
  onCancel: () => void;
}> = ({ isEditing, onStartNew, onSave, onCancel }) => {
  const [newWorkflowName, setNewWorkflowName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  if (!isEditing) {
    return (
      <Card
        shadow="none"
        padding="xs"
        radius="md"
        withBorder
        onClick={onStartNew}
        style={{
          cursor: 'pointer',
          backgroundColor: 'var(--mantine-color-red-0)',
          borderColor: 'var(--mantine-color-red-3)',
        }}
      >
        <Group wrap="nowrap">
          <IconPlus size={16} color="var(--mantine-color-red-6)" />
          <Text fz="sm" c="red.6">
            Create New
          </Text>
        </Group>
      </Card>
    );
  }

  return (
    <Card
      shadow="none"
      padding="xs"
      radius="md"
      withBorder
      style={{
        backgroundColor: 'var(--mantine-color-red-0)',
        borderColor: 'var(--mantine-color-red-3)',
      }}
    >
      <Stack gap="xs">
        <TextInput
          placeholder="Enter workflow name"
          value={newWorkflowName}
          onChange={(event) => setNewWorkflowName(event.currentTarget.value)}
          autoFocus
          size="xs"
          disabled={isSaving}
        />
        <Group justify="flex-end" gap="xs">
          <Button
            variant="subtle"
            size="xs"
            color="red"
            onClick={() => {
              setNewWorkflowName('');
              onCancel();
            }}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            size="xs"
            color="red"
            onClick={async () => {
              if (newWorkflowName.trim()) {
                setIsSaving(true);
                try {
                  await onSave(newWorkflowName);
                } finally {
                  setIsSaving(false);
                  setNewWorkflowName('');
                }
              }
            }}
            loading={isSaving}
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

export const WorkflowSelector: React.FC<WorkflowSelectorProps> = ({
  workflows,
  selectedWorkflowKey,
  workflowType,
  onWorkflowSelect,
  onClose,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const workflowService = useWorkflowService();
  const { sourceType } = useWorkflowCommon();

  const formattedType = formatWorkflowType(
    workflowType as keyof typeof WorkflowTypeEnum
  );

  const filteredWorkflows = useMemo(() => {
    let filtered = workflows.filter(
      (workflow) => workflow.workflowType === workflowType
    );

    if (debouncedSearchTerm) {
      const lowerSearchTerm = debouncedSearchTerm.toLowerCase();
      filtered = filtered.filter((workflow) =>
        workflow.name?.toLowerCase().includes(lowerSearchTerm)
      );
    }

    return filtered.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflows, workflowType, debouncedSearchTerm]);

  const handleWorkflowSelect = (workflowKey: string, name: string) => {
    onWorkflowSelect(workflowKey, name, false);
  };

  const handleCreateNewWorkflow = async (name: string) => {
    const newWorkflowKey = uuid();
    const newWorkflow: CommonWorkflow = {
      sourceType,
      workflowType,
      published: false,
      name: name.trim(),
      workflowKey: newWorkflowKey,
      templateKey: sourceType === 'template' ? newWorkflowKey : undefined,
    };

    try {
      await workflowService.updateWorkflow(newWorkflow);
      const workflowsQuery = workflowService.getWorkflows();

      if (workflowsQuery.refetch) {
        await workflowsQuery.refetch();
      }

      onWorkflowSelect(
        sourceType === 'definition'
          ? newWorkflow.workflowKey!
          : newWorkflow.templateKey!,
        newWorkflow.name || '',
        true
      );

      // Only clear the creation state after everything is successful
      setIsCreatingNew(false);
    } catch (error) {
      console.error('Failed to create workflow:', error);
      throw error; // Re-throw to trigger the finally block in the card component
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack p="md" gap="lg">
        <Box>
          <Text fz="md" fw={500} mb="xs">
            Create a new {formattedType}
          </Text>
          <NewWorkflowCard
            isEditing={isCreatingNew}
            onStartNew={() => setIsCreatingNew(true)}
            onSave={handleCreateNewWorkflow}
            onCancel={() => setIsCreatingNew(false)}
          />
        </Box>

        <Box>
          <Text fz="md" fw={500} mb="xs">
            Or select from existing {formattedType}s
          </Text>
          <TextInput
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.currentTarget.value)}
            leftSection={<IconSearch size="1.1rem" stroke={1.5} />}
            rightSection={
              searchTerm && (
                <IconX
                  size="1rem"
                  style={{ cursor: 'pointer' }}
                  onClick={handleClearSearch}
                />
              )
            }
            styles={(theme) => ({
              input: {
                height: 'auto',
                paddingTop: '0.3rem',
                paddingBottom: '0.3rem',
                paddingLeft: '2.5rem',
                paddingRight: searchTerm ? '2.5rem' : theme.spacing.xl,
              },
              rightSection: {
                pointerEvents: 'auto',
                paddingRight: theme.spacing.xs,
              },
              leftSection: {
                width: '2.5rem',
                pointerEvents: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            })}
          />
        </Box>
      </Stack>

      <ScrollArea style={{ flex: 1 }}>
        <Stack gap="xs" p="md" pt={0}>
          {filteredWorkflows.map((workflow) => (
            <WorkflowSelectionCard
              key={workflow.workflowKey}
              workflow={workflow}
              onSelect={handleWorkflowSelect}
              isSelected={workflow.workflowKey === selectedWorkflowKey}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Box>
  );
};
