import { FieldTypeEnum } from '@btrway/api-workflow';

// Does not support required
const requiredNotApplicableFields = new Set<FieldTypeEnum>([
  FieldTypeEnum.heading,
  FieldTypeEnum.paragraph,
  FieldTypeEnum.divider,
  FieldTypeEnum.space,
]);

export const requiredNotApplicable = (type: FieldTypeEnum): boolean =>
  requiredNotApplicableFields.has(type);

const labelNotApplicableFields = new Set<FieldTypeEnum>([
  FieldTypeEnum.heading,
  FieldTypeEnum.paragraph,
  FieldTypeEnum.divider,
  FieldTypeEnum.space,
]);

export const labelNotApplicable = (type: FieldTypeEnum): boolean =>
  labelNotApplicableFields.has(type);
