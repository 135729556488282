import {
  EntityTaskResponse,
  TaskRetrievalRequest,
  TaskRetrievalRequestCompletionStatus,
  TaskTypeEnum,
  useGetPersonTasks,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useCallback } from 'react';

export interface TaskQueryResponse {
  content: EntityTaskResponse[];
}

export const useTaskQueries = () => {
  const { mutateAsync: retrievePersonTasks } = useGetPersonTasks();

  const fetchTasks = useCallback(
    (request: TaskRetrievalRequest): Promise<TaskQueryResponse> => {
      console.log('fetchTasks called with request', request);
      return new Promise((resolve, reject) => {
        retrievePersonTasks(
          { data: request },
          {
            onSuccess: (data: EntityTaskResponse[]) =>
              resolve({ content: data }),
            onError: (error: unknown) => reject(error),
          }
        );
      });
    },
    [retrievePersonTasks]
  );

  const getTaskAssignmentsRequest = useCallback(
    (personId: number, organizationId: number): TaskRetrievalRequest => ({
      organizationId,
      personIds: [personId],
      completionStatus: TaskRetrievalRequestCompletionStatus.NOT_COMPLETED,
      includeRoleBasedTasks: true,
      // Optional filters that can be added if needed:
      // taskTypes: undefined, // Filter by specific task types
      // workflowKey: undefined, // Filter by specific workflow
      // workflowType: undefined // Filter by workflow type
    }),
    []
  );

  const getTaskListTasksRequest = useCallback(
    (personId: number, organizationId: number): TaskRetrievalRequest => ({
      organizationId,
      personIds: [personId],
      completionStatus: TaskRetrievalRequestCompletionStatus.ALL,
      includeRoleBasedTasks: false,
      // If we want to filter for only task list tasks, we can add:
      workflowType: WorkflowTypeEnum.taskList,
    }),
    []
  );

  const getMultiPersonTaskListRequest = useCallback(
    (personIds: number[], organizationId: number): TaskRetrievalRequest => ({
      organizationId,
      personIds,
      completionStatus: TaskRetrievalRequestCompletionStatus.ALL,
      includeRoleBasedTasks: false,
      // workflowType: WorkflowTypeEnum.taskList,
    }),
    []
  );

  // Helper to create a custom task retrieval request
  const createTaskRequest = useCallback(
    (
      organizationId: number,
      personIds: number[],
      options?: {
        completionStatus?: TaskRetrievalRequestCompletionStatus;
        includeRoleBasedTasks?: boolean;
        taskTypes?: TaskTypeEnum[];
        workflowKey?: string;
        workflowType?: WorkflowTypeEnum;
      }
    ): TaskRetrievalRequest => ({
      organizationId,
      personIds,
      completionStatus: options?.completionStatus || 'ALL',
      includeRoleBasedTasks: options?.includeRoleBasedTasks ?? true,
      taskTypes: options?.taskTypes,
      workflowKey: options?.workflowKey,
      workflowType: options?.workflowType,
    }),
    []
  );

  return {
    fetchTasks,
    getTaskAssignmentsRequest,
    getTaskListTasksRequest,
    getMultiPersonTaskListRequest,
    createTaskRequest,
  };
};
