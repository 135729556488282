import { Group } from '@mantine/core';
import { SettingsDisplayState } from '../../types/settingsDisplay';
import { SettingsPanel } from '../SettingsPanel/SettingsPanel';

interface SettingsDisplayLayoutProps {
  children: React.ReactNode;
  leftPanel: SettingsDisplayState;
  rightPanel: SettingsDisplayState;
  onLeftPanelClose: () => void;
  onRightPanelClose: () => void;
}

export const SettingsDisplayLayout: React.FC<SettingsDisplayLayoutProps> = ({
  children,
  leftPanel,
  rightPanel,
  onLeftPanelClose,
  onRightPanelClose,
}) => {
  return (
    <Group
      justify="flex-start"
      align="flex-start"
      wrap="nowrap"
      style={{ height: '100%', position: 'relative' }}
    >
      <SettingsPanel
        isOpen={leftPanel.isOpen}
        onClose={onLeftPanelClose}
        position="left"
        width={leftPanel.width}
      >
        {leftPanel.content}
      </SettingsPanel>

      {children}

      <SettingsPanel
        isOpen={rightPanel.isOpen}
        onClose={onRightPanelClose}
        position="right"
        width={rightPanel.width}
      >
        {rightPanel.content}
      </SettingsPanel>
    </Group>
  );
};
