import { PropertyReference } from '@btrway/api-workflow';
import {
  getDefaultFieldConfig,
  useFormConfig,
} from '@btrway/form-configuration-manager';
import { ActionIcon, Group, Paper } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface ConnectedToolboxItemProps {
  property: PropertyReference;
}

export const ConnectedToolboxItem: React.FC<ConnectedToolboxItemProps> = ({
  property,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { formConfig, updateFormConfig } = useFormConfig();

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'connected-field',
      item: () => {
        return {
          type: 'connected-field',
          propertyReference: property,
          isNew: true,
        };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const handleAddField = () => {
    const newField = getDefaultFieldConfig(property);
    const updatedConfig = {
      ...formConfig,
      fields: [...formConfig.fields, newField],
      modified: new Date().toISOString(),
    };
    updateFormConfig(updatedConfig);
  };

  return (
    <div
      ref={drag}
      style={{
        width: '100%',
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Paper
        withBorder
        p="xs"
        component={motion.div}
        whileHover={{
          scale: 1.03,
          boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
        }}
        whileTap={{
          scale: 0.97,
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        }}
      >
        <Group justify="space-between" wrap="nowrap">
          <div style={{ flex: 1 }}>{property.label}</div>
          {isHovered && (
            <ActionIcon
              onClick={(e) => {
                e.stopPropagation();
                handleAddField();
              }}
              radius="xl"
              variant="light"
              color="blue"
              size="sm"
            >
              <IconPlus size={16} />
            </ActionIcon>
          )}
        </Group>
      </Paper>
    </div>
  );
};

export default ConnectedToolboxItem;
