import { UnstyledButton } from '@mantine/core';
import { MeasurementSystem } from '../../types/types';

interface SystemSwitcherProps {
  currentSystem: MeasurementSystem;
  onSystemChange: () => void;
  disabled?: boolean;
}

export const SystemSwitcher = ({
  currentSystem,
  onSystemChange,
  disabled,
}: SystemSwitcherProps) => {
  if (disabled) return null;

  return (
    <UnstyledButton
      onClick={onSystemChange}
      style={{
        color: 'var(--mantine-color-blue-filled)',
        fontSize: 'var(--mantine-font-size-xs)',
        width: 'fit-content',
      }}
    >
      Change to {currentSystem === 'imperial' ? 'metric' : 'imperial'}
    </UnstyledButton>
  );
};
