import { Box, ScrollArea } from '@mantine/core';
import React from 'react';

interface ProcessBuilderLayoutProps {
  children: React.ReactNode;
}

export const ProcessBuilderLayout: React.FC<ProcessBuilderLayoutProps> = ({
  children,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box style={{ flex: 1, width: '100%' }}>
        <ScrollArea style={{ height: '100%' }}>{children}</ScrollArea>
      </Box>
    </Box>
  );
};
