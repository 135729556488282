import { WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  WorkflowCommonProvider,
  WorkflowServiceProvider,
  WorkflowSourceType,
} from '@btrway/workflow-common-provider';
import { WorkflowConfigProvider } from '@btrway/workflow-configuration-manager';
import { Button } from '@mantine/core';
import React, { useState } from 'react';
import { ProcessBuilderProvider } from '../../providers/ProcessBuilderProvider';
import { ProcessBuilderWizard } from '../ProcessBuilderWizard/ProcessBuilderWizard';

interface ProcessBuilderWizardButtonProps {
  sourceType: WorkflowSourceType;
  workflowType?: WorkflowTypeEnum;
  onComplete?: (workflowKey: string) => void;
}

export const ProcessBuilderWizardButton: React.FC<
  ProcessBuilderWizardButtonProps
> = ({ sourceType, workflowType = WorkflowTypeEnum.form, onComplete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleComplete = (workflowKey: string) => {
    setIsOpen(false);
    onComplete?.(workflowKey);
  };
  return (
    <>
      <Button onClick={() => setIsOpen(true)}>New Form</Button>
      <WorkflowCommonProvider sourceType={sourceType}>
        <WorkflowServiceProvider>
          <WorkflowConfigProvider>
            <ProcessBuilderProvider
              workflowType={workflowType}
              initialWorkflow={{}}
            >
              <ProcessBuilderWizard
                opened={isOpen}
                onClose={() => setIsOpen(false)}
                title="Build Process"
                onSubmit={handleComplete}
              />
            </ProcessBuilderProvider>
          </WorkflowConfigProvider>
        </WorkflowServiceProvider>
      </WorkflowCommonProvider>
    </>
  );
};
