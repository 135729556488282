import { BooleanFieldProperties, FieldConfig } from '@btrway/api-workflow';
import { Group, TextInput } from '@mantine/core';
import React from 'react';

interface BooleanFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<BooleanFieldProperties>) => void;
}

const defaultBooleanFieldProperties: BooleanFieldProperties = {
  yesLabel: 'Yes',
  noLabel: 'No',
};

export const BooleanFieldSettings: React.FC<BooleanFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const booleanProps = {
    ...defaultBooleanFieldProperties,
    ...(field.fieldProperties as BooleanFieldProperties),
  };

  const handleChange = (key: keyof BooleanFieldProperties, value: string) => {
    onChange({
      ...booleanProps,
      [key]: value,
    });
  };

  return (
    <Group gap="xs" wrap="nowrap" grow>
      <TextInput
        label="Yes Label"
        description="Label for the 'true' option"
        value={booleanProps.yesLabel}
        onChange={(event) =>
          handleChange('yesLabel', event.currentTarget.value)
        }
        placeholder="Yes"
      />

      <TextInput
        label="No Label"
        description="Label for the 'false' option"
        value={booleanProps.noLabel}
        onChange={(event) => handleChange('noLabel', event.currentTarget.value)}
        placeholder="No"
      />
    </Group>
  );
};

export default BooleanFieldSettings;
