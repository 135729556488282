import { WorkgroupResponse } from '@btrway/api-core';
import {
  Button,
  Card,
  Group,
  Select,
  Stack,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconUserCheck } from '@tabler/icons-react';
import { useState } from 'react';

const workgroupsData = (workgroups: WorkgroupResponse[]) => {
  return workgroups.map((wg) => ({
    value: wg.id.toString(),
    label: wg.displayName,
  }));
};

interface StartStepSelfProps {
  workgroups: WorkgroupResponse[];
  onStart: (workgroupId: number) => void;
  showHeader?: boolean;
}

export const StartStepSelf = ({
  workgroups,
  onStart,
  showHeader = false,
}: StartStepSelfProps) => {
  const [selectedWorkgroup, setSelectedWorkgroup] = useState<string | null>(
    null
  );

  const content = (
    <Stack style={{ height: '100%' }} justify="space-between">
      <div>
        {workgroups.length > 1 && (
          <Select
            placeholder="Choose a workgroup"
            data={workgroupsData(workgroups)}
            value={selectedWorkgroup}
            onChange={(value) => {
              setSelectedWorkgroup(value);
              if (value) onStart(parseInt(value, 10));
            }}
          />
        )}
      </div>
      {workgroups.length === 1 ? (
        <Button size="lg" onClick={() => onStart(workgroups[0].id)} fullWidth>
          Start Form
        </Button>
      ) : (
        <Button
          size="lg"
          onClick={() =>
            selectedWorkgroup && onStart(parseInt(selectedWorkgroup, 10))
          }
          fullWidth
          disabled={!selectedWorkgroup}
        >
          Start Form
        </Button>
      )}
    </Stack>
  );

  if (!showHeader) {
    return content;
  }

  return (
    <Card
      withBorder
      padding="xl"
      radius="lg"
      style={{ borderColor: 'var(--mantine-color-gray-5)' }}
    >
      <Group mb="md">
        <ThemeIcon size="xl" radius="xl">
          <IconUserCheck size={24} />
        </ThemeIcon>
        <Title order={3} fw={600}>
          Myself
        </Title>
      </Group>
      {content}
    </Card>
  );
};
