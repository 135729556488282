import {
  StepCompletionDTO,
  StepConfig,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { useFormCompletion } from '@btrway/form-completion-manager';
import { FormRenderer, FormRendererRef } from '@btrway/form-renderer';
import { useStyledModal } from '@btrway/styled-modal';
import { Box, Button, Card, Group, Stack, Text } from '@mantine/core';
import { IconClick } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';

interface FormStepProps {
  workflowDefinition: WorkflowDefinitionResponse;
  currentStep: StepConfig;
  prefilledData: any;
  onSubmit: (stepData: StepCompletionDTO) => void;
  onCancel: () => void;
  onSaveForLater: () => void;
}

const FormStep: React.FC<FormStepProps> = ({
  workflowDefinition,
  currentStep,
  prefilledData,
  onSubmit,
  onCancel,
  onSaveForLater,
}) => {
  const { setModalTitle } = useStyledModal();
  const { formValues } = useFormCompletion();

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const formRef = useRef<FormRendererRef>(null);

  useEffect(() => {
    setModalTitle(
      <Text fz="xl" fw={700}>
        {workflowDefinition.name}
      </Text>
    );

    return () => {
      setModalTitle(undefined);
    };
  }, [workflowDefinition.name, setModalTitle]);

  const handleSubmit = () => {
    setHasAttemptedSubmit(true);

    if (formRef.current?.validate()) {
      const stepData: StepCompletionDTO = {
        stepKey: currentStep.stepKey,
        values: formValues,
      };
      onSubmit(stepData);
    } else {
      const firstErrorElement = document.querySelector(
        '[aria-invalid="true"]'
      ) as HTMLElement;
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  return (
    <Stack bg="gray.1" style={{ width: '100%', height: '100%' }} gap={0}>
      <Box
        p="xl"
        style={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <Card maw={900} mx="auto" radius="lg" p="md" bg="white">
          <FormRenderer
            ref={formRef}
            formConfig={currentStep.formConfig!}
            initialValues={prefilledData}
            onValidationChange={setValidationErrors}
          />
          {hasAttemptedSubmit && Object.keys(validationErrors).length > 0 && (
            <Text c="red" size="sm" mt="xs">
              Please fill in all required fields before submitting.
            </Text>
          )}
        </Card>
      </Box>
      <Group justify="space-between" bg="white" p="md" gap="md">
        <Button onClick={onCancel} size="md" variant="outline">
          Cancel
        </Button>
        <Group justify="flex-end" gap="md">
          <Button onClick={onSaveForLater} size="md" variant="outline" disabled>
            Save for Later
          </Button>
          <Button
            onClick={handleSubmit}
            color="green.8"
            size="md"
            leftSection={<IconClick size={24} />}
          >
            Submit Form
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};

export default FormStep;
