import { FieldConfig, TriggerConfig } from '@btrway/api-workflow';
import { Text } from '@mantine/core';
import React from 'react';
import { getOperatorMetadata } from '../../utils/operatorUtils';

interface TriggerViewProps {
  trigger: TriggerConfig;
  availableFields: FieldConfig[];
  isFirst: boolean;
}

const getValueDisplay = (value: unknown): string => {
  if (!value) return '';
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return String(value);
};

export const TriggerView: React.FC<TriggerViewProps> = ({
  trigger,
  availableFields,
  isFirst,
}) => {
  const selectedField = availableFields.find(
    (field) => field.fieldKey === trigger.property?.propertyKey
  );

  return (
    <Text fz="sm">
      {isFirst ? 'IF' : 'AND'}{' '}
      <Text component="span" fz="sm" fw={600}>
        {selectedField?.label || 'Unknown field'}
      </Text>
      {trigger.ruleOperator && (
        <>{' ' + getOperatorMetadata(trigger.ruleOperator).label + ' '}</>
      )}
      {trigger.ruleOperator &&
        trigger.ruleOperator !== 'hasvalue' &&
        trigger.ruleOperator !== 'novalue' && (
          <Text component="span" fz="sm" fw={600} c="blue.7">
            {getValueDisplay(trigger.ruleValues?.[0]?.value)}
          </Text>
        )}
    </Text>
  );
};

export default TriggerView;
