import {
  CertificationTypeEnum,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { DisplayConfig } from '../types/display';
import { Screen } from '../types/screen';

export function getWorkflowTypeForTask(
  taskType: TaskTypeEnum
): WorkflowTypeEnum | undefined {
  switch (taskType) {
    case TaskTypeEnum.completeTaskList:
      return WorkflowTypeEnum.taskList;
    case TaskTypeEnum.completeCurriculum:
      return WorkflowTypeEnum.curriculum;
    case TaskTypeEnum.completeFormPacket:
      return WorkflowTypeEnum.formPacket;
    case TaskTypeEnum.certification:
      return WorkflowTypeEnum.certification;
    case TaskTypeEnum.completeTaskOption:
      return WorkflowTypeEnum.taskOption;
    case TaskTypeEnum.submitForm:
      return WorkflowTypeEnum.form;
    default:
      return undefined;
  }
}

export function getWorkflowTypeForCertification(
  certType: CertificationTypeEnum
): WorkflowTypeEnum | undefined {
  switch (certType) {
    case CertificationTypeEnum.completeTaskList:
      return WorkflowTypeEnum.taskList;
    case CertificationTypeEnum.completeCurriculum:
      return WorkflowTypeEnum.curriculum;
    case CertificationTypeEnum.completeFormPacket:
      return WorkflowTypeEnum.formPacket;
    case CertificationTypeEnum.certification:
      return WorkflowTypeEnum.certification;
    case CertificationTypeEnum.completeTaskOption:
      return WorkflowTypeEnum.taskOption;
    case CertificationTypeEnum.submitForm:
      return WorkflowTypeEnum.form;
    default:
      return undefined;
  }
}

export function getDefaultDisplayConfig(screen: Screen): DisplayConfig {
  const baseConfig = {
    mode: 'panel' as const,
    position: 'left' as const,
    width: 400,
  };

  switch (screen.type) {
    case 'TASK_CONFIG':
    case 'STEP_CONFIG':
    case 'EVENT_CONFIG':
    case 'CERTIFICATION_CONFIG':
      return {
        ...baseConfig,
        // width: 600,
      };
    case 'CURRICULUM_EDITOR':
    case 'FORM_PACKET_EDITOR':
      return {
        ...baseConfig,
        // width: 800,
      };
    default:
      return baseConfig;
  }
}
