import { NumberFieldProperties, NumberFieldType } from '@btrway/api-workflow';
import { NumberInput, Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface NumberFieldProps extends BaseFieldProps<number> {}

export const NumberField: React.FC<NumberFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const numberProperties = field.fieldProperties as NumberFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const decimalScale =
    numberProperties?.numberType === NumberFieldType.integer
      ? 0
      : numberProperties?.decimalPlaces ?? 2;

  // Format number for display based on field type
  const formatNumber = (num: number | null): string => {
    if (num === null || isNaN(num)) return '';

    const formatter = new Intl.NumberFormat('en-US', {
      style:
        numberProperties?.numberType === NumberFieldType.currency
          ? 'currency'
          : 'decimal',
      currency: 'USD',
      minimumFractionDigits: decimalScale,
      maximumFractionDigits: decimalScale,
    });

    return formatter.format(num);
  };

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value !== null ? 'inherit' : 'dimmed'}>
          {value !== null ? formatNumber(value || null) : 'No value'}
        </Text>
      </Stack>
    );
  }

  const commonProps = {
    required: field.required,
    readOnly: readonly,
    value: value ?? '',
    min: numberProperties?.minValue,
    max: numberProperties?.maxValue,
    allowNegative: numberProperties?.allowNegative ?? true,
    decimalScale: decimalScale,
    fixedDecimalScale: true,
    allowDecimal: numberProperties?.numberType !== NumberFieldType.integer,
    thousandSeparator: true,
    hideControls: true,
    clampBehavior: 'strict' as const,
    prefix:
      numberProperties?.numberType === NumberFieldType.currency
        ? '$'
        : undefined,
  };

  const handleChange = (newValue: string | number) => {
    if (readonly) return;

    if (newValue === '') {
      onChange?.(null);
      return;
    }

    const numericValue =
      typeof newValue === 'string' ? parseFloat(newValue) : newValue;
    if (!isNaN(numericValue)) {
      onChange?.(numericValue);
    }
  };

  return (
    <Stack gap="xs">
      <NumberInput {...commonProps} onChange={handleChange} />
    </Stack>
  );
};

export default NumberField;
