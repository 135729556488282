// src/hooks/useAddressValidation.ts
import { AddressRequest } from '@btrway/api-core';
import { useEffect, useState } from 'react';
import {
  countryConfigurations,
  defaultCountryConfig,
} from '../config/countries';
import { AddressValidationState } from '../types/types';

interface TouchedFields {
  street1?: boolean;
  street2?: boolean;
  city?: boolean;
  stateProvinceCode?: boolean;
  postalCode?: boolean;
  countryCode?: boolean;
}

interface UseAddressValidationProps {
  address: AddressRequest;
  onValidationChange?: (validation: AddressValidationState) => void;
}

interface UseAddressValidationReturn {
  validation: AddressValidationState;
  touchedFields: TouchedFields;
  handleBlur: (field: keyof TouchedFields) => void;
  getFieldError: (
    field: keyof AddressValidationState['errors']
  ) => string | undefined;
  resetTouchedFields: () => void;
}

const validateAddress = (address: AddressRequest): AddressValidationState => {
  const errors: AddressValidationState['errors'] = {};
  const countryConfig = address.countryCode
    ? countryConfigurations[address.countryCode] || defaultCountryConfig
    : defaultCountryConfig;

  // Required field validations
  if (!address.street1?.trim()) {
    errors.street1 = 'Address Line 1 is required';
  }

  if (!address.city?.trim()) {
    errors.city = 'City is required';
  }

  if (!address.countryCode) {
    errors.countryCode = 'Country is required';
  }

  // Postal code validation
  if (countryConfig.showPostalCode && countryConfig.postalCodeRequired) {
    if (!address.postalCode) {
      errors.postalCode = 'Postal code is required';
    } else if (
      !String(address.postalCode).match(countryConfig.postalCodeFormat)
    ) {
      errors.postalCode = 'Invalid postal code format';
    }
  }

  // State/Province validation
  if (countryConfig.showStateProvince && countryConfig.stateProvinceRequired) {
    if (!address.stateProvinceCode) {
      errors.stateProvinceCode = `${countryConfig.labels.stateProvince} is required`;
    }
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const useAddressValidation = ({
  address,
  onValidationChange,
}: UseAddressValidationProps): UseAddressValidationReturn => {
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validation, setValidation] = useState<AddressValidationState>({
    isValid: true,
    errors: {},
  });

  // Validate address whenever it changes
  useEffect(() => {
    const newValidation = validateAddress(address);
    setValidation(newValidation);
    onValidationChange?.(newValidation);
  }, [address, onValidationChange]);

  const handleBlur = (field: keyof TouchedFields) => {
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const getFieldError = (field: keyof AddressValidationState['errors']) => {
    return touchedFields[field] ? validation.errors[field] : undefined;
  };

  const resetTouchedFields = () => {
    setTouchedFields({});
  };

  return {
    validation,
    touchedFields,
    handleBlur,
    getFieldError,
    resetTouchedFields,
  };
};
