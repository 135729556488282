import { FieldConfig, WorkgroupFieldProperties } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useWorkgroupTypes } from '@btrway/workgroup-manager';
import { Chip, Group, Stack, Switch, Text } from '@mantine/core';
import React from 'react';

interface WorkgroupFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<WorkgroupFieldProperties>) => void;
}

const defaultWorkgroupProperties: WorkgroupFieldProperties = {
  limitToWorkflowWorkgroup: false,
  workgroupTypeSlugs: [],
};

export const WorkgroupFieldSettings: React.FC<WorkgroupFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { workgroupTypes } = useWorkgroupTypes(currentOrganization.id);

  const workgroupProps = {
    ...defaultWorkgroupProperties,
    ...(field.fieldProperties as WorkgroupFieldProperties),
  };

  const handleChange = (key: keyof WorkgroupFieldProperties, value: any) => {
    onChange({
      ...workgroupProps,
      [key]: value,
    });
  };

  return (
    <Stack gap="md">
      <Text fz="sm" fw={600}>
        Workgroup Types
      </Text>
      <Chip.Group
        multiple
        value={workgroupProps.workgroupTypeSlugs || []}
        onChange={(value) => handleChange('workgroupTypeSlugs', value)}
      >
        <Group gap="xs">
          {workgroupTypes?.map((type) => (
            <Chip key={type.slug} value={type.slug}>
              {type.name}
            </Chip>
          ))}
        </Group>
      </Chip.Group>

      <Switch
        label="Limit to workflow workgroup"
        description="Only allow selection of workgroups within the current workflow"
        checked={workgroupProps.limitToWorkflowWorkgroup}
        onChange={(event) =>
          handleChange('limitToWorkflowWorkgroup', event.currentTarget.checked)
        }
      />
    </Stack>
  );
};

export default WorkgroupFieldSettings;
