import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { useWorkgroup } from '@btrway/workgroup-components';
import { Container, Stack, Transition } from '@mantine/core';
import React from 'react';
import { useWorkgroupStats } from '../../providers/WorkgroupStatsProvider';
import { getMetricContext } from '../../types/metricContext';
import { PersonDetail } from '../PersonDetail/PersonDetail';
import { TaskStatisticsCard } from '../TaskStatisticsCard/TaskStatisticsCard';
import { TaskStatisticsSkeleton } from '../TaskStatisticsSkeleton/TaskStatisticsSkeleton';

interface WorkgroupTaskStatsViewProps {
  workflowType?: WorkflowTypeEnum;
  taskListType?: string;
}

export const WorkgroupTaskStatsView: React.FC<WorkgroupTaskStatsViewProps> = ({
  workflowType,
  taskListType,
}) => {
  const { workgroup } = useWorkgroup();

  const parentWorkgroupId = workgroup.parentId;
  const isSchool = workgroup?.workgroupType.name === 'School';
  const isTeam = workgroup?.workgroupType.name === 'Team';
  const isCoachCertification = taskListType === 'Coach Certification';
  const isStudentAthlete = taskListType === 'Student-Athlete Eligibility';

  const { getStatsByFilter, isLoading } = useWorkgroupStats();
  const [expandedWorkgroups, setExpandedWorkgroups] = React.useState<
    Set<number>
  >(new Set());

  const metricContext = getMetricContext(workflowType, taskListType);

  const filteredStats =
    getStatsByFilter(
      workflowType || taskListType
        ? {
            workflowType,
            taskListType,
          }
        : undefined
    )?.filter((workgroup) => workgroup.workflows.length > 0) ?? [];

  const toggleExpand = (workgroupId: number) => {
    setExpandedWorkgroups((prev) => {
      const next = new Set(prev);
      if (next.has(workgroupId)) {
        next.delete(workgroupId);
      } else {
        next.add(workgroupId);
      }
      return next;
    });
  };

  const customTransition = {
    in: { opacity: 1, transform: 'translateY(0)' },
    out: { opacity: 0, transform: 'translateY(-20px)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

  if (isLoading) {
    return (
      <Container size="xl" p={0}>
        <Stack>
          <TaskStatisticsSkeleton />
        </Stack>
      </Container>
    );
  }

  const renderContent = () => {
    if (isSchool && isCoachCertification) {
      return (
        <PersonDetail
          workgroupId={workgroup.id}
          workflowType={workflowType}
          taskListType={taskListType}
          metricContext={metricContext}
        />
      );
    }

    if (isSchool && isStudentAthlete) {
      return (
        <Stack gap="md">
          {filteredStats.map((workgroup) => (
            <TaskStatisticsCard
              key={workgroup.workgroupId}
              statistics={workgroup}
              isExpanded={expandedWorkgroups.has(workgroup.workgroupId!)}
              onToggleExpand={() =>
                workgroup.workgroupId && toggleExpand(workgroup.workgroupId)
              }
              metricContext={metricContext}
              workflowType={workflowType}
              taskListType={taskListType}
            />
          ))}
        </Stack>
      );
    }

    if ((isTeam && isCoachCertification) || (isTeam && isStudentAthlete)) {
      return (
        <PersonDetail
          workgroupId={parentWorkgroupId || workgroup.id}
          filterWorkgroupId={workgroup.id}
          workflowType={workflowType}
          taskListType={taskListType}
          metricContext={metricContext}
        />
      );
    }

    // Default fallback case
    return (
      <Stack gap="md">
        {filteredStats.map((workgroup) => (
          <TaskStatisticsCard
            key={workgroup.workgroupId}
            statistics={workgroup}
            isExpanded={expandedWorkgroups.has(workgroup.workgroupId!)}
            onToggleExpand={() =>
              workgroup.workgroupId && toggleExpand(workgroup.workgroupId)
            }
            metricContext={metricContext}
            workflowType={workflowType}
            taskListType={taskListType}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Container size="xl" w="100%" p={0}>
      <Transition
        mounted={true}
        transition={customTransition}
        duration={400}
        timingFunction="ease"
      >
        {(styles) => <div style={styles}>{renderContent()}</div>}
      </Transition>
    </Container>
  );
};
