import { BooleanFieldProperties } from '@btrway/api-workflow';
import { SegmentedControl, Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface BooleanFieldProps extends BaseFieldProps<boolean> {}

export const BooleanField: React.FC<BooleanFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const booleanFieldProperties =
    field.fieldProperties as BooleanFieldProperties;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const yesLabel = booleanFieldProperties?.yesLabel ?? 'Yes';
  const noLabel = booleanFieldProperties?.noLabel ?? 'No';

  // In display mode, show the appropriate label as text
  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value === undefined ? 'dimmed' : 'inherit'}>
          {value === undefined ? 'No selection' : value ? yesLabel : noLabel}
        </Text>
      </Stack>
    );
  }

  const handleChange = (newValue: string) => {
    if (readonly) return;
    onChange?.(newValue === 'true');
  };

  return (
    <Stack gap="xs">
      <SegmentedControl
        value={value?.toString()}
        onChange={handleChange}
        radius="xl"
        data={[
          { label: yesLabel, value: 'true' },
          { label: noLabel, value: 'false' },
        ]}
        disabled={readonly}
      />
      {field.required && value === undefined && (
        <Text size="xs" c="red">
          This field is required
        </Text>
      )}
    </Stack>
  );
};

export default BooleanField;
