/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type VideoFieldSourceType = typeof VideoFieldSourceType[keyof typeof VideoFieldSourceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoFieldSourceType = {
  url: 'url',
  upload: 'upload',
} as const;
