import { AddressFieldProperties, FieldConfig } from '@btrway/api-workflow';
import { useCountries } from '@btrway/reference-data-manager';
import { Select, Stack } from '@mantine/core';
import React from 'react';

interface AddressFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<AddressFieldProperties>) => void;
}

const defaultAddressFieldProperties: AddressFieldProperties = {
  defaultCountryCode: undefined,
};

export const AddressFieldSettings: React.FC<AddressFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const { countries, isLoading } = useCountries();

  const addressProps = {
    ...defaultAddressFieldProperties,
    ...(field.fieldProperties as AddressFieldProperties),
  };

  const handleDefaultCountryChange = (value: string | null) => {
    onChange({
      ...addressProps,
      defaultCountryCode: value || undefined,
    });
  };

  const countryOptions =
    countries
      ?.sort((a, b) => {
        // Put US first
        if (a.code === 'US') return -1;
        if (b.code === 'US') return 1;
        // Then CA second
        if (a.code === 'CA') return -1;
        if (b.code === 'CA') return 1;
        // Rest alphabetically by name
        return a.name.localeCompare(b.name);
      })
      .map((country) => ({
        value: country.code,
        label: country.name,
      })) || [];

  return (
    <Stack gap="md">
      <Select
        label="Default Country"
        description="Pre-selected country when creating new addresses"
        data={countryOptions}
        value={addressProps.defaultCountryCode || null}
        onChange={handleDefaultCountryChange}
        searchable
        clearable
        disabled={isLoading}
        styles={{ dropdown: { zIndex: 1001 } }}
      />
    </Stack>
  );
};

export default AddressFieldSettings;
