import { FieldConfig, FieldStyles } from '@btrway/api-workflow';
import { Select, Stack } from '@mantine/core';
import React from 'react';
import { supportsFieldStyles } from '../../../utils/properties';

const sizeOptions = [
  { value: '1', label: 'Biggest', description: 'Page Title / Main Heading' },
  { value: '2', label: 'Big', description: 'Section Header' },
  { value: '3', label: 'Medium', description: 'Subsection Header' },
  { value: '4', label: 'Small', description: 'Group Title' },
];

interface FieldStylesEditorProps {
  field: FieldConfig;
  onChange: (styles: FieldStyles) => void;
}

export const FieldStylesEditor: React.FC<FieldStylesEditorProps> = ({
  field,
  onChange,
}) => {
  if (!supportsFieldStyles(field.type)) {
    return null;
  }

  const handleChange = (value: string | null) => {
    onChange({
      ...field.styles,
      headingSize: value ? parseInt(value, 10) : undefined,
    });
  };

  return (
    <Stack gap="md">
      <Select
        label="Heading Size"
        description="Choose the size and importance level of the heading"
        data={sizeOptions}
        value={String(field.styles?.headingSize || '2')}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default FieldStylesEditor;
