import { FacilityResponse } from '@btrway/api-calendar';
import {
  EntityGroupResponse,
  PersonResponse,
  WorkgroupResponse,
} from '@btrway/api-core';
import { AssetResponse } from '@btrway/api-courseware';
import { UserRoleResponse } from '@btrway/api-security';
import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { EntityDataType } from '../types/EntityDataType';

export const renderEntityContent = (dataType: EntityDataType, data: any) => {
  switch (dataType) {
    case 'person':
      return renderPerson(data as PersonResponse);
    case 'workgroup':
      return renderWorkgroup(data as WorkgroupResponse);
    case 'course':
      return renderCourse(data as AssetResponse);
    case 'workflowDefinition':
      return renderWorkflowDefinition(data as WorkflowDefinitionResponse);
    case 'entityGroup':
      return renderEntityGroup(data as EntityGroupResponse);
    case 'facility':
      return renderFacility(data as FacilityResponse);
    case 'userRole':
      return renderUserRole(data as UserRoleResponse);
    default:
      return 'Unknown entity';
  }
};

const renderPerson = (person: PersonResponse) => {
  return `${person.firstName} ${person.lastName}`;
};

const renderWorkgroup = (workgroup: WorkgroupResponse) => {
  return workgroup.name;
};

const renderCourse = (course: AssetResponse) => {
  return course.title;
};

const renderWorkflowDefinition = (
  workflowDefinition: WorkflowDefinitionResponse
) => {
  return workflowDefinition.name;
};

const renderEntityGroup = (entityGroup: EntityGroupResponse) => {
  return entityGroup.name;
};

const renderFacility = (facility: FacilityResponse) => {
  return facility.name;
};

const renderUserRole = (userRole: UserRoleResponse) => {
  return userRole.name;
};
