import { StorageUsageEnum } from '@btrway/api-storage';
import { EditableImage, useImageUpload } from '@btrway/file-storage-components';
import { Image, Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface ImageUploadFieldProps extends BaseFieldProps<string> {
  width?: number;
  height?: number;
  aspectRatio?: {
    width: number;
    height: number;
  };
}

export const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
  width = 300,
  height,
  aspectRatio,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const { imageUrl, handleImageUpload: handleUpload } = useImageUpload({
    storageUsage: StorageUsageEnum.form,
    initialImageKey: value,
    onImageUpload: (imageKey: string) => onChange?.(imageKey),
  });

  // Display mode shows a simple image
  if (isDisplayMode(displayMode)) {
    if (!imageUrl) {
      return (
        <Stack gap="xs">
          <Text size="sm" c="dimmed">
            No image uploaded
          </Text>
        </Stack>
      );
    }

    return (
      <Stack gap="xs">
        <div style={{ maxHeight: 200 }}>
          <Image
            src={imageUrl}
            width="auto"
            height="100%"
            fit="contain"
            style={{ maxHeight: '100%', width: 'auto' }}
          />
        </div>
      </Stack>
    );
  }

  // Other modes use EditableImage with appropriate readonly state
  return (
    <Stack gap="xs">
      <EditableImage
        storageUsage={StorageUsageEnum.form}
        initialImageKey={value}
        onImageUpload={onChange}
        width={width}
        height={height}
        editable={!readonly}
        editButtonLabel="Change Image"
        modalTitle={field.label || 'Upload Image'}
        aspectRatio={aspectRatio}
      />

      {field.required && !value && (
        <Text size="xs" c="red">
          An image is required
        </Text>
      )}
    </Stack>
  );
};

export default ImageUploadField;
