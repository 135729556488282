import { FieldConfig, MultipleFieldProperties } from '@btrway/api-workflow';
import { useFormConfig } from '@btrway/form-configuration-manager';
import { Button, Card, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import FormBuilderContent from '../../builder/FormBuilderContent/FormBuilderContent';

interface MultipleFieldProps {
  field: FieldConfig;
  readOnly?: boolean;
}

const MultipleField: React.FC<MultipleFieldProps> = ({
  field,
  readOnly = false,
}) => {
  const { updateField } = useFormConfig();
  const isReadOnly = false;
  const multipleFieldProperties =
    field.fieldProperties as MultipleFieldProperties;
  const itemDescription = multipleFieldProperties?.itemDescription || 'Item';

  const handleUpdate = (fieldKey: string, updates: Partial<FieldConfig>) => {
    const updatedChildFields =
      field.childFields?.map((childField) =>
        childField.fieldKey === fieldKey
          ? { ...childField, ...updates }
          : childField
      ) || [];

    updateField(field.fieldKey, { childFields: updatedChildFields });
  };

  const handleRemove = (fieldKey: string) => {
    const updatedChildFields =
      field.childFields?.filter(
        (childField) => childField.fieldKey !== fieldKey
      ) || [];

    updateField(field.fieldKey, { childFields: updatedChildFields });
  };

  const handleFieldsReorder = (newFields: FieldConfig[]) => {
    updateField(field.fieldKey, { childFields: newFields });
  };

  const handleAddField = (
    type: any,
    options?: Partial<FieldConfig>,
    index?: number
  ) => {
    const currentFields = [...(field.childFields || [])];
    if (typeof index === 'number') {
      currentFields.splice(index, 0, options as FieldConfig);
    } else {
      currentFields.push(options as FieldConfig);
    }
    updateField(field.fieldKey, { childFields: currentFields });
  };

  return (
    <Stack gap="md">
      <Card
        withBorder
        radius="md"
        padding="sm"
        style={{
          minHeight: '200px',
          // background: '#f8f9fa',
          border: '1px solid var(--mantine-color-gray-5)',
        }}
      >
        <Text fz="lg" fw={600}>
          {itemDescription} Fields
        </Text>

        <Stack gap="md" mih={200} h="100%">
          {(field.childFields?.length ?? 0) === 0 && (
            <Text
              c="dimmed"
              ta="center"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            >
              Drop fields here
            </Text>
          )}
          <FormBuilderContent
            fields={field.childFields || []}
            onUpdate={handleUpdate}
            onRemove={handleRemove}
            onFieldsReorder={handleFieldsReorder}
            onAddField={handleAddField}
            readOnly={isReadOnly}
            parentFieldKey={field.fieldKey}
          />
        </Stack>
      </Card>
      <Button leftSection={<IconPlus size={16} />} variant="light">
        Add {itemDescription}
      </Button>
    </Stack>
  );
};

export default MultipleField;
