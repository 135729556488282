import { FieldTypeEnum } from '@btrway/api-workflow';
import AddressFieldSettings from '../field-type-settings/AddressFieldSettings';
import BooleanFieldSettings from '../field-type-settings/BooleanFieldSettings';
import DateFieldSettings from '../field-type-settings/DateFieldSettings';
import { HeightFieldSettings } from '../field-type-settings/HeightFieldSettings';
import MultipleFieldSettings from '../field-type-settings/MultipleFieldSettings';
import { NumberFieldSettings } from '../field-type-settings/NumberFieldSettings';
import PersonFieldSettings from '../field-type-settings/PersonFieldSettings';
import RatingFieldSettings from '../field-type-settings/RatingFieldSettings';
import SelectFieldSettings from '../field-type-settings/SelectFieldSettings';
import SignatureFieldSettings from '../field-type-settings/SignatureFieldSettings';
import SliderFieldSettings from '../field-type-settings/SliderFieldSettings';
import SpaceFieldSettings from '../field-type-settings/SpaceFieldSettings';
import TextFieldSettings from '../field-type-settings/TextFieldSettings';
import { VideoFieldSettings } from '../field-type-settings/VideoFieldSettings';
import { WeightFieldSettings } from '../field-type-settings/WeightFieldSettings';
import WorkgroupFieldSettings from '../field-type-settings/WorkgroupFieldSettings';

export interface FieldSettingsItem {
  type: FieldTypeEnum;
  component: React.ComponentType<any>;
}

class FieldSettingsRegistry {
  private registry: Map<FieldTypeEnum, FieldSettingsItem> = new Map();

  constructor() {
    this.registerDefaultSettings();
  }

  private registerDefaultSettings() {
    this.register({
      type: FieldTypeEnum.boolean,
      component: BooleanFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.text,
      component: TextFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.date,
      component: DateFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.height,
      component: HeightFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.number,
      component: NumberFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.rating,
      component: RatingFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.select,
      component: SelectFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.signature,
      component: SignatureFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.slider,
      component: SliderFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.weight,
      component: WeightFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.video,
      component: VideoFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.address,
      component: AddressFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.person,
      component: PersonFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.workgroup,
      component: WorkgroupFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.space,
      component: SpaceFieldSettings,
    });

    this.register({
      type: FieldTypeEnum.multiple,
      component: MultipleFieldSettings,
    });
  }

  register(item: FieldSettingsItem) {
    this.registry.set(item.type, item);
  }

  get(type: FieldTypeEnum): FieldSettingsItem | undefined {
    return this.registry.get(type);
  }

  getAll(): FieldSettingsItem[] {
    return Array.from(this.registry.values());
  }

  getComponent(type: FieldTypeEnum): React.ComponentType<any> | undefined {
    return this.registry.get(type)?.component;
  }
}

// Create and export a single instance
export const fieldSettingsRegistry = new FieldSettingsRegistry();

export default fieldSettingsRegistry;
