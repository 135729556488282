import {
  getPresignedUrl,
  StorageUploadRequest,
  StorageUsageEnum,
} from '@btrway/api-storage';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useStorageUpload } from '@btrway/file-storage-components';
import { FileDownload } from '@btrway/shared-components';
import {
  ActionIcon,
  Box,
  Button,
  FileButton,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { IconDownload, IconPaperclip, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface FileUploadFieldProps extends BaseFieldProps<string[]> {}

export const FileUploadField: React.FC<FileUploadFieldProps> = ({
  field,
  value = [],
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;
  const [uploading, setUploading] = useState(false);
  const [keysAndUrls, setKeysAndUrls] = useState<{ [key: string]: string }>({});
  const [storageKeys, setStorageKeys] = useState<string[]>(value);
  const { storageUpload } = useStorageUpload();
  const { currentUser, currentOrganization } = useAuthenticatedUser();

  useEffect(() => {
    if (value.length > 0) {
      setStorageKeys(value);
      getPresignedUrls(value);
    }
  }, [value]);

  const handleFileUpload = async (files: File[]) => {
    setUploading(true);
    try {
      const storageUploadRequest: StorageUploadRequest = {
        organizationId: currentOrganization.id,
        userId: currentUser.user.id,
        fileNames: files.map((file) => file.name),
        storageUsageEnum: StorageUsageEnum.form,
      };

      const res = await storageUpload(
        storageUploadRequest,
        files.map((file) => ({
          file,
          contentType: file.type,
          fileName: file.name,
        }))
      );

      const updatedKeys = [...storageKeys, ...res.storageKeys];
      setStorageKeys(updatedKeys);
      getPresignedUrls(updatedKeys);
      onChange?.(updatedKeys);
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveFile = (storageKeyToRemove: string) => {
    const updatedKeys = storageKeys.filter((key) => key !== storageKeyToRemove);
    const updatedUrls = { ...keysAndUrls };
    delete updatedUrls[storageKeyToRemove];

    setStorageKeys(updatedKeys);
    setKeysAndUrls(updatedUrls);
    onChange?.(updatedKeys);
  };

  const getPresignedUrls = async (keys: string[]) => {
    const urlMap: { [key: string]: string } = {};
    for (const key of keys) {
      const presignedUrl = (await getPresignedUrl(StorageUsageEnum.form, {
        storageKey: key,
      })) as string;
      urlMap[key] = presignedUrl;
    }
    setKeysAndUrls(urlMap);
  };

  // Display mode shows simple download links
  if (isDisplayMode(displayMode)) {
    if (storageKeys.length === 0) {
      return (
        <Stack gap="xs">
          <Text size="sm" c="dimmed">
            No files uploaded
          </Text>
        </Stack>
      );
    }

    return (
      <Stack gap="xs">
        {storageKeys.map((key) => {
          const filename = key.split('/').pop() || key;
          return (
            <Group key={key} gap="xs">
              <FileDownload url={keysAndUrls[key]} filename={filename}>
                <Group gap="xs" style={{ cursor: 'pointer' }}>
                  <IconDownload size={16} />
                  <Text size="sm" c="blue">
                    {filename}
                  </Text>
                </Group>
              </FileDownload>
            </Group>
          );
        })}
      </Stack>
    );
  }

  return (
    <Box>
      <Stack gap="xs">
        {!readonly && (
          <Group>
            <FileButton
              onChange={handleFileUpload}
              accept="*/*"
              multiple
              disabled={readonly}
            >
              {(props) => (
                <Button
                  {...props}
                  leftSection={<IconPaperclip size={16} />}
                  loading={uploading}
                >
                  Attach Files
                </Button>
              )}
            </FileButton>
          </Group>
        )}

        {storageKeys.length > 0 && (
          <Paper p="xs" withBorder>
            <Stack gap="xs">
              {storageKeys.map((key) => {
                const filename = key.split('/').pop() || key;
                return (
                  <Group key={key} justify="space-between">
                    <FileDownload url={keysAndUrls[key]} filename={filename}>
                      <Text size="sm" c="blue" style={{ cursor: 'pointer' }}>
                        {filename}
                      </Text>
                    </FileDownload>
                    {!readonly && (
                      <ActionIcon
                        color="red"
                        onClick={() => handleRemoveFile(key)}
                        variant="subtle"
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    )}
                  </Group>
                );
              })}
            </Stack>
          </Paper>
        )}

        {field.required && storageKeys.length === 0 && (
          <Text size="xs" c="red">
            At least one file is required
          </Text>
        )}
      </Stack>
    </Box>
  );
};

export default FileUploadField;
