import { FieldConfig, MultipleFieldProperties } from '@btrway/api-workflow';
import { useFormCompletion } from '@btrway/form-completion-manager';
import { ActionIcon, Button, Card, Group, Stack, Text } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useCallback, useMemo } from 'react';
import { FieldRenderer } from '../FieldRenderer/FieldRenderer';

interface MultipleFieldRendererProps {
  field: FieldConfig;
  readOnly?: boolean;
}

const defaultMultipleFieldProperties: MultipleFieldProperties = {
  itemDescription: '',
  minimumItems: 0,
  maximumItems: 10,
  renderType: 'form',
};

export const MultipleFieldRenderer: React.FC<MultipleFieldRendererProps> = ({
  field,
  readOnly = false,
}) => {
  const { formValues, setFormValue } = useFormCompletion();

  const multipleProps = {
    ...defaultMultipleFieldProperties,
    ...(field.fieldProperties as MultipleFieldProperties),
  };

  const emptyRecordTemplate = useMemo(() => {
    const record: Record<string, any> = {};
    field.childFields?.forEach((childField) => {
      record[childField.fieldKey] = '';
    });
    return record;
  }, [field.childFields]);

  // Memoize the current value and ensure minimum items
  const value = useMemo(() => {
    const currentValue = formValues[field.fieldKey];
    const initialValue = Array.isArray(currentValue) ? currentValue : [];

    // If we don't have enough items and not in readonly mode, add up to minimum
    if (initialValue.length < multipleProps.minimumItems && !readOnly) {
      const newValue = [...initialValue];
      while (newValue.length < multipleProps.minimumItems) {
        newValue.push({ ...emptyRecordTemplate });
      }
      return newValue;
    }

    return initialValue;
  }, [
    formValues,
    field.fieldKey,
    multipleProps.minimumItems,
    readOnly,
    emptyRecordTemplate,
  ]);

  // Set initial value with minimum items
  React.useEffect(() => {
    if (value !== formValues[field.fieldKey]) {
      setFormValue(field.fieldKey, value);
    }
  }, [value, field.fieldKey, setFormValue, formValues]);

  const handleAddRecord = useCallback(() => {
    if (
      multipleProps.maximumItems &&
      value.length >= multipleProps.maximumItems
    ) {
      return;
    }
    const newRecords = [...value, { ...emptyRecordTemplate }];
    setFormValue(field.fieldKey, newRecords);
  }, [
    value,
    emptyRecordTemplate,
    field.fieldKey,
    setFormValue,
    multipleProps.maximumItems,
  ]);

  const handleRemoveRecord = useCallback(
    (index: number) => {
      if (value.length <= multipleProps.minimumItems) {
        return;
      }
      const newRecords = [...value];
      newRecords.splice(index, 1);
      setFormValue(field.fieldKey, newRecords);
    },
    [value, field.fieldKey, setFormValue, multipleProps.minimumItems]
  );

  const handleFieldChange = useCallback(
    (index: number, fieldKey: string, fieldValue: any) => {
      const newRecords = value.map((record, i) => {
        if (i === index) {
          const newRecord = {
            ...record,
            [fieldKey]: fieldValue,
          };
          return newRecord;
        }
        return record;
      });

      setFormValue(field.fieldKey, newRecords);
    },
    [value, field.fieldKey, setFormValue]
  );

  const showAddButton =
    !readOnly &&
    (!multipleProps.maximumItems || value.length < multipleProps.maximumItems);
  const showRemoveButton =
    !readOnly && value.length > multipleProps.minimumItems;

  // Memoize the record components
  const recordComponents = useMemo(() => {
    return value.map((record, index) => (
      <Card
        key={`${field.fieldKey}-record-${index}`}
        withBorder
        radius="md"
        p="md"
      >
        <Group justify="space-between" mb="xs">
          <Group gap="xs">
            <Text fz="lg" fw={600}>
              {multipleProps.itemDescription} {index + 1}
            </Text>
          </Group>
          {showRemoveButton && (
            <ActionIcon
              color="red"
              variant="subtle"
              onClick={() => handleRemoveRecord(index)}
            >
              <IconTrash size={16} />
            </ActionIcon>
          )}
        </Group>
        <FieldRenderer
          fields={field.childFields || []}
          readOnly={readOnly}
          parentFieldKey={`${field.fieldKey}.${index}`}
          parentValue={record}
          onFieldChange={(fieldKey, fieldValue) =>
            handleFieldChange(index, fieldKey, fieldValue)
          }
          recordIndex={index}
        />
      </Card>
    ));
  }, [
    value,
    field.fieldKey,
    field.childFields,
    readOnly,
    handleFieldChange,
    multipleProps.itemDescription,
    showRemoveButton,
  ]);

  return (
    <Stack gap="md">
      {recordComponents}
      {showAddButton && (
        <Button
          leftSection={<IconPlus size={16} />}
          variant="light"
          onClick={handleAddRecord}
        >
          Add Another {multipleProps.itemDescription}
        </Button>
      )}
    </Stack>
  );
};
