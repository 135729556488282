import { DataTypeEnum, FieldTypeEnum } from '@btrway/api-workflow';

// Identify which fields should be read-only by default
export const defaultReadOnlyTypes = new Set([
  DataTypeEnum.systemId,
  DataTypeEnum.systemDateTime,
  DataTypeEnum.sentEmail,
  DataTypeEnum.importJob,
  DataTypeEnum.registration,
  DataTypeEnum.taskInstance,
  DataTypeEnum.workflowInstance,
]);

// Identify which fields should support multiple selection
export const supportsMultipleSelection = new Set([
  DataTypeEnum.workgroup,
  DataTypeEnum.workgroupMember,
  DataTypeEnum.userRole,
  DataTypeEnum.entityGroup,
]);

// Only specify fields that must start new line
const mustStartNewLineFields = new Set<FieldTypeEnum>([
  FieldTypeEnum.address,
  FieldTypeEnum.divider,
  FieldTypeEnum.space,
  FieldTypeEnum.group,
  FieldTypeEnum.heading,
  FieldTypeEnum.paragraph,
  FieldTypeEnum.divider,
  FieldTypeEnum.space,
]);

export const mustStartNewLine = (type: FieldTypeEnum): boolean =>
  mustStartNewLineFields.has(type);

const configurationNotApplicableFields = new Set<FieldTypeEnum>([
  FieldTypeEnum.divider,
]);

export const configurationNotApplicable = (type: FieldTypeEnum): boolean =>
  configurationNotApplicableFields.has(type);
