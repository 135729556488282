import {
  AssignTaskInputProperties,
  FormConfig,
  StepConfig,
  TaskAssignmentConfig,
} from '@btrway/api-workflow';
import { FormBuilder, FormBuilderRef } from '@btrway/form-builder';
import { ApproverSelect } from '@btrway/process-builder-approver';
import {
  ActionIcon,
  Button,
  Card,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React, { useRef, useState } from 'react';
import { StepConfigCardIcon } from '../../components/StepConfigCardIcon/StepConfigCardIcon';
import { StepConfigConnector } from '../../components/StepConfigConnector/StepConfigConnector';
import { StepConfigContent } from '../../components/StepConfigContent/StepConfigContent';
import styles from './ApprovalCard.module.css';

interface ApprovalCardProps {
  step: StepConfig;
  workflowKey: string;
  workflowTitle: string;
  isLast?: boolean;
  isSelected?: boolean;
  isAutomatedStep?: boolean;
  stepType: string;
  onSelect: (stepKey: string) => void;
  onSave?: (stepConfig: StepConfig) => void;
  onCancel?: () => void;
  onDelete?: (stepKey: string) => void;
}

const ApprovalCard: React.FC<ApprovalCardProps> = ({
  step,
  workflowKey,
  workflowTitle,
  isLast = false,
  isSelected = false,
  isAutomatedStep = false,
  stepType,
  onSelect,
  onSave,
  onCancel,
  onDelete,
}) => {
  const taskAssignmentProperties =
    step.inputProperties as AssignTaskInputProperties;

  const formBuilderRef = useRef<FormBuilderRef>(null);
  const [stepTitle, setStepTitle] = useState(step.title);
  const [taskAssignmentConfig, setTaskAssignmentConfig] =
    useState<TaskAssignmentConfig>(
      taskAssignmentProperties?.taskAssignmentConfig || {
        assignmentType: undefined,
      }
    );

  const handleClick = () => {
    onSelect(step.stepKey);
  };

  const handleSave = () => {
    formBuilderRef.current?.handleSave();
  };

  const handleCancel = () => {
    formBuilderRef.current?.handleCancel();
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(step.stepKey);
    }
  };

  const handleFormSave = (formConfig: FormConfig) => {
    if (onSave) {
      const updatedStepConfig: StepConfig = {
        ...step,
        title: stepTitle,
        formConfig,
        inputProperties: {
          taskAssignmentConfig,
        } as AssignTaskInputProperties,
      };
      onSave(updatedStepConfig);
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStepTitle(event.currentTarget.value);
  };

  const handleApproverChange = (
    newTaskAssignmentConfig: TaskAssignmentConfig
  ) => {
    setTaskAssignmentConfig(newTaskAssignmentConfig);
  };

  return (
    <>
      <Card
        p="md"
        radius="md"
        withBorder
        shadow="xs"
        className={styles.card}
        onClick={!isSelected ? handleClick : undefined}
      >
        <Stack gap={0}>
          {isSelected ? (
            <div onClick={(e) => e.stopPropagation()}>
              <Group justify="space-between">
                <Group justify="flex-start" p={0}>
                  <Text fz="xl" fw={600}>
                    Approval Step
                  </Text>
                  <ActionIcon
                    size="lg"
                    variant="outline"
                    color="red"
                    onClick={handleDelete}
                  >
                    <IconTrash size={16} />
                  </ActionIcon>
                </Group>
                <Group justify="flex-end" p={0}>
                  <Button color="gray" variant="outline" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button onClick={handleSave} variant="filled" color="blue">
                    Save Form
                  </Button>
                </Group>
              </Group>
              <Divider my="lg" />
              <Stack gap="xl">
                <Stack gap="md">
                  <Text fz="md" fw={600}>
                    Approval Step Settings
                  </Text>
                  <Group align="center" gap="xl">
                    <Text fz="sm" style={{ width: '80px' }}>
                      Step Title
                    </Text>
                    <TextInput
                      value={stepTitle}
                      onChange={handleTitleChange}
                      w={300}
                      size="sm"
                      variant="default"
                    />
                  </Group>
                  <Group align="center" gap="xl">
                    <Text fz="md" style={{ width: '80px' }}>
                      Assign To
                    </Text>
                    <div style={{ flex: 1 }}>
                      <ApproverSelect
                        taskAssignmentConfig={taskAssignmentConfig}
                        onConfigChange={handleApproverChange}
                        primaryEntityDescription="Primary Workflow Person"
                      />
                    </div>
                  </Group>
                  <Group align="center" gap="xl">
                    <Text fz="md" style={{ width: '80px' }}>
                      Conditions
                    </Text>
                    <div style={{ flex: 1 }}>
                      <Text c="gray">
                        Conditions for a step to occur is not yet implemented.
                      </Text>
                    </div>
                  </Group>
                </Stack>
                <Stack gap="md">
                  <Text fz="md" fw={600}>
                    Approval Questions
                  </Text>
                  <Card radius="lg" withBorder>
                    <FormBuilder
                      ref={formBuilderRef}
                      stepKey={step.stepKey}
                      initialConfig={step.formConfig}
                      formTitle={workflowTitle}
                      onSave={handleFormSave}
                      onCancel={onCancel}
                      readOnly={false}
                      emptyFormMessage="Questions are not required for an approval step but you can add them here!"
                    />
                  </Card>
                </Stack>
              </Stack>
            </div>
          ) : (
            <Group
              gap="xs"
              wrap="nowrap"
              justify="flex-start"
              align="flex-start"
            >
              <StepConfigCardIcon stepKey={step.stepKey} />
              <StepConfigContent
                step={step}
                workflowKey={workflowKey}
                isAutomatedStep={isAutomatedStep}
                stepType={stepType}
              />
            </Group>
          )}
        </Stack>
      </Card>
      {!isLast && !isSelected && <StepConfigConnector />}
    </>
  );
};

export default ApprovalCard;
