/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type FieldTypeEnum = typeof FieldTypeEnum[keyof typeof FieldTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldTypeEnum = {
  heading: 'heading',
  paragraph: 'paragraph',
  video: 'video',
  link: 'link',
  text: 'text',
  number: 'number',
  height: 'height',
  weight: 'weight',
  select: 'select',
  checkbox: 'checkbox',
  switch: 'switch',
  boolean: 'boolean',
  rating: 'rating',
  slider: 'slider',
  date: 'date',
  time: 'time',
  dateTime: 'dateTime',
  stateProvince: 'stateProvince',
  country: 'country',
  postalcode: 'postalcode',
  address: 'address',
  organization: 'organization',
  workgroup: 'workgroup',
  person: 'person',
  facility: 'facility',
  multiple: 'multiple',
  group: 'group',
  divider: 'divider',
  space: 'space',
  signature: 'signature',
  calculation: 'calculation',
  file: 'file',
  image: 'image',
} as const;
