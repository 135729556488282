import { DataTypeEnum } from '@btrway/api-core';
import {
  IconBuilding,
  IconCalendarEvent,
  IconUser,
  IconUsers,
} from '@tabler/icons-react';

export interface EntityTypeConfig {
  type: DataTypeEnum;
  label: string;
  icon: typeof IconUser;
  examples: string[];
}

export const ENTITY_TYPE_CONFIGS: EntityTypeConfig[] = [
  {
    type: DataTypeEnum.person,
    label: 'Person',
    icon: IconUser,
    examples: [
      'Employee onboarding forms',
      'Performance reviews',
      'Personal development plans',
    ],
  },
  {
    type: DataTypeEnum.calendarEvent,
    label: 'Event',
    icon: IconCalendarEvent,
    examples: [
      'Event registration forms',
      'Event feedback surveys',
      'Session evaluations',
    ],
  },
  {
    type: DataTypeEnum.workgroup,
    label: 'Workgroup',
    icon: IconUsers,
    examples: [
      'Team assessments',
      'Group progress reports',
      'Department reviews',
    ],
  },
  {
    type: DataTypeEnum.facility,
    label: 'Facility',
    icon: IconBuilding,
    examples: [
      'Facility inspection forms',
      'Maintenance requests',
      'Safety assessments',
    ],
  },
];
