import {
  FieldConfig,
  FieldOption,
  SelectFieldProperties,
  SelectFieldRenderType,
} from '@btrway/api-workflow';
import { Group, Stack } from '@mantine/core';
import React from 'react';
import FieldOptions from '../components/FieldOptions/FieldOptions';
import RenderStyleSelector from '../components/RenderStyleSelector/RenderStyleSelector';

interface SelectFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<SelectFieldProperties>) => void;
}

const defaultSelectProperties: SelectFieldProperties = {
  allowsMultiple: false,
  allowsOther: false,
  renderType: SelectFieldRenderType.picklist,
  sortAlpha: false,
  options: [],
};

export const SelectFieldSettings: React.FC<SelectFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const selectProps = {
    ...defaultSelectProperties,
    ...(field.fieldProperties as SelectFieldProperties),
  };

  const handleChange = (key: keyof SelectFieldProperties, value: any) => {
    // If trying to enable multiple selection while in segmented mode,
    // switch to picklist first
    if (
      key === 'allowsMultiple' &&
      value === true &&
      selectProps.renderType === SelectFieldRenderType.segmented
    ) {
      onChange({
        ...selectProps,
        allowsMultiple: true,
        renderType: SelectFieldRenderType.picklist,
      });
      return;
    }

    // If switching to segmented mode while multiple selection is enabled,
    // disable multiple selection
    if (
      key === 'renderType' &&
      value === SelectFieldRenderType.segmented &&
      selectProps.allowsMultiple
    ) {
      onChange({
        ...selectProps,
        renderType: value,
        allowsMultiple: false,
      });
      return;
    }

    onChange({
      ...selectProps,
      [key]: value,
    });
  };

  const handleOptionsChange = (newOptions: FieldOption[]) => {
    handleChange('options', newOptions);
  };

  return (
    <Stack gap="xl">
      <Group gap="md" align="flex-start">
        <Stack style={{ flex: 1 }}>
          <RenderStyleSelector
            value={selectProps.renderType}
            onChange={(value) => handleChange('renderType', value)}
            allowsMultiple={selectProps.allowsMultiple}
            onAllowMultipleChange={(value) =>
              handleChange('allowsMultiple', value)
            }
            allowsOther={selectProps.allowsOther}
            onAllowOtherChange={(value) => handleChange('allowsOther', value)}
            sortAlpha={selectProps.sortAlpha}
            onSortAlphaChange={(value) => handleChange('sortAlpha', value)}
          />
        </Stack>
      </Group>

      <FieldOptions
        options={selectProps.options || []}
        onChange={handleOptionsChange}
        sortAlpha={selectProps.sortAlpha}
      />
    </Stack>
  );
};

export default SelectFieldSettings;
