import { useCallback, useState } from 'react';
import { WIZARD_STEPS } from '../config/steps';
import { WizardStep } from '../types/wizardStep';

interface WizardStepsState {
  activeStep: number;
  maxReachedStep: number;
  isLastStep: boolean;
  currentStep: WizardStep;
  steps: WizardStep[];
}

interface WizardStepsActions {
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (stepIndex: number) => void;
}

export const useWizardSteps = (): WizardStepsState & WizardStepsActions => {
  const [activeStep, setActiveStep] = useState(0);
  const [maxReachedStep, setMaxReachedStep] = useState(0);

  const nextStep = useCallback(() => {
    if (activeStep < WIZARD_STEPS.length - 1) {
      setActiveStep((prev) => prev + 1);
      setMaxReachedStep((prev) => Math.max(prev, activeStep + 1));
    }
  }, [activeStep]);

  const prevStep = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  }, [activeStep]);

  const goToStep = useCallback(
    (stepIndex: number) => {
      if (stepIndex <= maxReachedStep) {
        setActiveStep(stepIndex);
      }
    },
    [maxReachedStep]
  );

  const isLastStep = activeStep === WIZARD_STEPS.length - 1;
  const currentStep = WIZARD_STEPS[activeStep];

  return {
    activeStep,
    maxReachedStep,
    nextStep,
    prevStep,
    goToStep,
    isLastStep,
    currentStep,
    steps: WIZARD_STEPS,
  };
};
