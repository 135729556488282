// import {
//   DataTypeEnum,
//   FieldTypeEnum,
//   RuleOperatorTypeEnum,
// } from '@btrway/api-workflow';
// import {
//   Card,
//   Group,
//   Popover,
//   Stack,
//   Text,
//   UnstyledButton,
// } from '@mantine/core';
// import { useDisclosure } from '@mantine/hooks';
// import React, { useEffect, useMemo } from 'react';
// import styles from './OperatorPicker.module.css';

// interface OperatorOption {
//   value: RuleOperatorTypeEnum;
//   label: string;
//   symbol?: string;
// }

// interface OperatorPickerProps {
//   selectedOperator?: RuleOperatorTypeEnum;
//   dataType?: DataTypeEnum;
//   fieldType?: FieldTypeEnum;
//   onOperatorSelect: (operator: RuleOperatorTypeEnum) => void;
// }

// const operatorSymbols: Partial<Record<RuleOperatorTypeEnum, string>> = {
//   [RuleOperatorTypeEnum.equal]: 'is',
//   [RuleOperatorTypeEnum.notequal]: 'not',
//   [RuleOperatorTypeEnum.greater]: 'greater',
//   [RuleOperatorTypeEnum.greaterorequal]: 'is or greater than',
//   [RuleOperatorTypeEnum.less]: 'less than',
//   [RuleOperatorTypeEnum.lessorequal]: 'is or less than',
//   [RuleOperatorTypeEnum.includes]: 'includes',
//   [RuleOperatorTypeEnum.excludes]: 'excludes',
// };

// const getOperatorDisplay = (
//   operator: RuleOperatorTypeEnum,
//   label: string
// ): string => {
//   return operatorSymbols[operator] ?? label;
// };

// export const OperatorPicker: React.FC<OperatorPickerProps> = ({
//   selectedOperator,
//   dataType,
//   fieldType,
//   onOperatorSelect,
// }) => {
//   const [opened, { open, close }] = useDisclosure(false);

//   const operatorOptions = useMemo(() => {
//     if (!dataType) return [];

//     const operators: OperatorOption[] = [];

//     // Special case for Select fields
//     if (fieldType === FieldTypeEnum.select) {
//       return [
//         { value: RuleOperatorTypeEnum.includes, label: 'includes' },
//         { value: RuleOperatorTypeEnum.excludes, label: 'excludes' },
//         { value: RuleOperatorTypeEnum.hasvalue, label: 'has any value' },
//         { value: RuleOperatorTypeEnum.novalue, label: 'has no value' },
//       ];
//     }

//     // Standard operators based on data type
//     switch (dataType) {
//       case DataTypeEnum.text:
//         operators.push(
//           { value: RuleOperatorTypeEnum.equal, label: 'equals' },
//           { value: RuleOperatorTypeEnum.notequal, label: 'not equals' },
//           { value: RuleOperatorTypeEnum.contains, label: 'contains' },
//           { value: RuleOperatorTypeEnum.startswith, label: 'starts with' },
//           { value: RuleOperatorTypeEnum.hasvalue, label: 'has any value' },
//           { value: RuleOperatorTypeEnum.novalue, label: 'has no value' }
//         );
//         break;
//       case DataTypeEnum.number:
//       case DataTypeEnum.integer:
//         operators.push(
//           { value: RuleOperatorTypeEnum.equal, label: 'equals' },
//           { value: RuleOperatorTypeEnum.notequal, label: 'not equals' },
//           { value: RuleOperatorTypeEnum.greater, label: 'greater than' },
//           {
//             value: RuleOperatorTypeEnum.greaterorequal,
//             label: 'greater than or equal',
//           },
//           { value: RuleOperatorTypeEnum.less, label: 'less than' },
//           {
//             value: RuleOperatorTypeEnum.lessorequal,
//             label: 'less than or equal',
//           }
//         );
//         break;
//       default:
//         operators.push(
//           { value: RuleOperatorTypeEnum.equal, label: 'equals' },
//           { value: RuleOperatorTypeEnum.notequal, label: 'not equals' }
//         );
//     }
//     return operators;
//   }, [dataType, fieldType]);

//   useEffect(() => {
//     if (operatorOptions.length > 0 && !selectedOperator) {
//       onOperatorSelect(operatorOptions[0].value);
//     }
//   }, [operatorOptions, selectedOperator, onOperatorSelect]);

//   const selectedOperatorOption = operatorOptions.find(
//     (op) => op.value === selectedOperator
//   );

//   const handleOperatorSelect = (operator: RuleOperatorTypeEnum) => {
//     onOperatorSelect(operator);
//     close();
//   };

//   return (
//     <Popover
//       position="bottom"
//       width={200}
//       opened={opened}
//       onChange={close}
//       withinPortal={true}
//       zIndex={1100}
//     >
//       <Popover.Target>
//         <Card
//           radius="xl"
//           padding="xs"
//           withBorder
//           className={styles.card}
//           onClick={open}
//           miw={50}
//         >
//           <Group justify="center" gap="xs">
//             <Text size="sm" fw={500}>
//               {selectedOperatorOption
//                 ? getOperatorDisplay(
//                     selectedOperatorOption.value,
//                     selectedOperatorOption.label
//                   )
//                 : '='}
//             </Text>
//           </Group>
//         </Card>
//       </Popover.Target>

//       <Popover.Dropdown>
//         <Stack gap="xs">
//           {operatorOptions.map((operator) => (
//             <UnstyledButton
//               key={operator.value}
//               onClick={() => handleOperatorSelect(operator.value)}
//               className={styles.operatorButton}
//             >
//               <Group gap="xs">
//                 <Text size="sm">{operator.label}</Text>
//               </Group>
//             </UnstyledButton>
//           ))}
//         </Stack>
//       </Popover.Dropdown>
//     </Popover>
//   );
// };

// src/components/OperatorPicker/OperatorPicker.tsx

import {
  Card,
  Group,
  Popover,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import React from 'react';
import { useOperatorPicker } from '../../hooks/useOperatorPicker';
import type { OperatorPickerProps } from '../../types/operator';
import styles from './OperatorPicker.module.css';

export const OperatorPicker: React.FC<OperatorPickerProps> = ({
  selectedOperator,
  dataType,
  fieldType,
  onOperatorSelect,
}) => {
  const {
    opened,
    open,
    close,
    operatorOptions,
    selectedOperatorOption,
    handleOperatorSelect,
  } = useOperatorPicker({
    selectedOperator,
    dataType,
    fieldType,
    onOperatorSelect,
  });

  return (
    <Popover
      position="bottom"
      width={200}
      opened={opened}
      onChange={close}
      withinPortal={true}
      zIndex={1100}
    >
      <Popover.Target>
        <Card
          radius="xl"
          padding="xs"
          withBorder
          className={styles.card}
          onClick={open}
          miw={50}
        >
          <Group justify="center" gap="xs">
            <Text size="sm" fw={500}>
              {selectedOperatorOption?.label || '='}
            </Text>
          </Group>
        </Card>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack gap="xs">
          {operatorOptions.map((operator) => (
            <UnstyledButton
              key={operator.value}
              onClick={() => handleOperatorSelect(operator.value)}
              className={styles.operatorButton}
            >
              <Group gap="xs">
                <Text size="sm">{operator.label}</Text>
              </Group>
            </UnstyledButton>
          ))}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
