import {
  DataTypeEnum,
  FormSettings,
  StepCompletionDTO,
  StepConfig,
  StepTypeEnum,
  TaskTypeEnum,
  WorkflowConfig,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useFormCompletion } from '@btrway/form-completion-manager';
import { Group } from '@mantine/core';
import React, { useState } from 'react';
import FormDescription from '../FormDescription/FormDescription';
import StartStepOptions from '../StartStepOptions/StartStepOptions';

interface StartStepProps {
  workflowDefinition: WorkflowDefinitionResponse;
  currentStep: StepConfig;
  onStartClick: (
    stepData: StepCompletionDTO,
    workgroupId: number,
    aboutEntityId: number,
    aboutEntityType: DataTypeEnum
  ) => void;
  onCancel: () => void;
  workflowConfiguration: WorkflowConfig;
  isSubmitting?: boolean;
}

const StartStep: React.FC<StartStepProps> = ({
  workflowDefinition,
  currentStep,
  onStartClick,
  onCancel,
  workflowConfiguration,
}) => {
  const { currentPerson } = useAuthenticatedUser();
  const { setFormContext } = useFormCompletion();
  const [mountKey, setMountKey] = useState(0);

  React.useEffect(() => {
    return () => {
      setMountKey((prev) => prev + 1);
    };
  }, []);

  const handleWorkflowOptionsSubmit = ({
    workgroupId,
    aboutEntityId,
    aboutEntityType,
  }: {
    workgroupId: number;
    aboutEntityId?: number;
    aboutEntityType?: DataTypeEnum;
  }) => {
    setFormContext({
      workgroupId,
      aboutEntityId,
      aboutEntityType,
    });

    const effectiveAboutEntityId = aboutEntityId || currentPerson.id;
    const effectiveAboutEntityType = aboutEntityType || DataTypeEnum.person;

    const stepData: StepCompletionDTO = {
      stepKey: currentStep.stepKey,
      values: {
        submittedBy: currentPerson.id as any,
        aboutEntity: effectiveAboutEntityId as any,
      },
    };

    onStartClick(
      stepData,
      workgroupId,
      effectiveAboutEntityId,
      effectiveAboutEntityType
    );
  };

  const getReviewSteps = () => {
    const reviewSteps: StepConfig[] = [];
    const visitedSteps = new Set<string>();

    const traverseSteps = (stepKey: string) => {
      if (visitedSteps.has(stepKey)) return;
      visitedSteps.add(stepKey);

      const step = workflowConfiguration.steps?.find(
        (s) => s.stepKey === stepKey
      );
      if (!step) return;

      if (
        step.stepTypeCode === StepTypeEnum.assignTask &&
        step.taskConfig &&
        step.taskConfig.taskType === TaskTypeEnum.completeWorkflowStep
      ) {
        reviewSteps.push(step);
      }

      const outgoingTransitions =
        workflowConfiguration.transitions?.filter(
          (t) => t.sourceStepKey === stepKey
        ) || [];

      outgoingTransitions.forEach((transition) => {
        traverseSteps(transition.targetStepKey);
      });
    };

    const startStep = workflowConfiguration.steps?.find(
      (s) => s.stepTypeCode === StepTypeEnum.flowStart
    );
    if (startStep) {
      traverseSteps(startStep.stepKey);
    }

    return reviewSteps;
  };

  const reviewSteps = getReviewSteps();

  return (
    <Group
      w="100%"
      align="stretch"
      style={{ minHeight: '100%' }}
      gap={0}
      wrap="nowrap"
    >
      <FormDescription
        workflowDefinition={workflowDefinition}
        reviewSteps={reviewSteps}
      />
      {workflowConfiguration?.settings &&
        'launchSettings' in workflowConfiguration.settings && (
          <StartStepOptions
            key={mountKey}
            formSettings={workflowConfiguration.settings as FormSettings}
            onWorkflowStart={handleWorkflowOptionsSubmit}
            onCancel={onCancel}
          />
        )}
    </Group>
  );
};

export default StartStep;
