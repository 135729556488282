import { FieldConfig } from '@btrway/api-workflow';
import { Card, Stack } from '@mantine/core';
import React from 'react';
import { FieldRenderer } from '../FieldRenderer/FieldRenderer';

interface FormFieldGroupProps {
  field: FieldConfig;
  value: Record<string, any>;
  onChange: (value: Record<string, any>) => void;
  readOnly?: boolean;
}

export const FormFieldGroup: React.FC<FormFieldGroupProps> = ({
  field,
  value = {},
  onChange,
  readOnly = false,
}) => {
  return (
    <Card withBorder p="md" radius="md">
      <Stack gap="xs">
        <FieldRenderer
          fields={field.childFields || []}
          readOnly={readOnly}
          parentFieldKey={field.fieldKey}
        />
      </Stack>
    </Card>
  );
};

export default FormFieldGroup;
