import { UserRoleResponse } from '@btrway/api-security';
import { WorkflowTypeEnum } from '@btrway/api-workflow';

export const shouldShowDefaultRoles = (
  workflowType?: WorkflowTypeEnum
): boolean => {
  return (
    workflowType === WorkflowTypeEnum.taskList ||
    workflowType === WorkflowTypeEnum.certification
  );
};

export const createRoleSuggestions = (
  userRoles: UserRoleResponse[] = []
): Array<{ value: string; label: string }> => {
  return userRoles.map((role) => ({
    value: role.slug,
    label: role.name,
  }));
};

export const formatRoleNames = (
  roles: string[] = [],
  userRoles: UserRoleResponse[]
): string => {
  const slugToRole = new Map(userRoles.map((role) => [role.slug, role]));
  return roles
    .map((slug) => slugToRole.get(slug)?.name)
    .filter((name): name is string => !!name)
    .sort()
    .join(', ');
};
