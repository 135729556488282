import { Card } from '@mantine/core';
import React from 'react';
import { useDragLayer } from 'react-dnd';

const getDisplayText = (item: any) => {
  if (item.field) {
    // Existing field being dragged
    return item.field.label;
  }
  if (item.type) {
    // New field from toolbox
    // Convert camelCase to Title Case with spaces
    return item.type
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str: string) => str.toUpperCase())
      .trim();
  }
  return 'Field';
};

const DragPreview = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || !currentOffset || !item) {
    return null;
  }

  const getItemStyles = (): React.CSSProperties => {
    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      position: 'fixed',
      left: 0,
      top: 0,
      width: 'auto',
      height: 'auto',
      zIndex: 100,
      pointerEvents: 'none' as const,
    };
  };

  return (
    <div style={getItemStyles()}>
      <Card
        shadow="sm"
        p="xs"
        withBorder
        radius="xl"
        bg="blue.1"
        style={{
          width: 'auto',
          minWidth: '200px',
        }}
      >
        {getDisplayText(item)}
      </Card>
    </div>
  );
};

export default DragPreview;
