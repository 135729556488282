/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type SystemEventTypeEnum = typeof SystemEventTypeEnum[keyof typeof SystemEventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SystemEventTypeEnum = {
  availability: 'availability',
} as const;
