import { Card, ScrollArea } from '@mantine/core';
import React from 'react';
import CertificationListView from '../CertificationListView/CertificationListView';
import TaskListView from '../TaskListView/TaskListView';

interface NestedWorkflowViewProps {
  workflowKey: string;
  parentWorkflowKey: string;
  type: 'task' | 'certification';
}

export const NestedWorkflowView: React.FC<NestedWorkflowViewProps> = ({
  workflowKey,
  parentWorkflowKey,
  type,
}) => {
  if (!workflowKey || workflowKey === parentWorkflowKey) return null;

  return (
    <Card bg="gray.3" withBorder p={0} radius="md">
      <ScrollArea h={300} offsetScrollbars type="auto" pl="xs" pt={0} pb={0}>
        {type === 'task' ? (
          <TaskListView workflowKey={workflowKey} nestedTaskList={true} />
        ) : (
          <CertificationListView workflowKey={workflowKey} nestedList={true} />
        )}
      </ScrollArea>
    </Card>
  );
};
