import { FieldConfig, HeightFieldProperties } from '@btrway/api-workflow';
import { Chip, Group, NumberInput, Stack, Switch } from '@mantine/core';
import React from 'react';

interface HeightFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<HeightFieldProperties>) => void;
}

const defaultHeightFieldProperties: HeightFieldProperties = {
  allowFractionalInches: true,
  allowImperial: true,
  allowMetric: true,
  metricDecimalPlaces: 2,
};

export const HeightFieldSettings: React.FC<HeightFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const heightProps = {
    ...defaultHeightFieldProperties,
    ...(field.fieldProperties as HeightFieldProperties),
  };

  const handleSingleChange = (key: keyof HeightFieldProperties, value: any) => {
    onChange({
      ...heightProps,
      [key]: value,
    });
  };

  const handleSystemChange = (values: string[]) => {
    onChange({
      ...heightProps,
      allowImperial: values.includes('imperial'),
      allowMetric: values.includes('metric'),
    });
  };

  const selectedSystems = [];
  if (heightProps.allowImperial) selectedSystems.push('imperial');
  if (heightProps.allowMetric) selectedSystems.push('metric');

  return (
    <Stack gap="md">
      <Group wrap="nowrap" gap="xs">
        <Chip.Group
          multiple
          value={selectedSystems}
          onChange={handleSystemChange}
        >
          <Chip value="imperial">Imperial</Chip>
          <Chip value="metric">Metric</Chip>
        </Chip.Group>
      </Group>

      {heightProps.allowImperial && (
        <Switch
          label="Allow Fractional Inches"
          description="Enable 1/8 inch increments"
          checked={heightProps.allowFractionalInches}
          onChange={(event) =>
            handleSingleChange(
              'allowFractionalInches',
              event.currentTarget.checked
            )
          }
        />
      )}

      {heightProps.allowMetric && (
        <NumberInput
          label="Metric Decimal Places"
          // description="Number of decimal places for centimeter values"
          value={heightProps.metricDecimalPlaces}
          onChange={(value) => handleSingleChange('metricDecimalPlaces', value)}
          min={0}
          max={4}
          step={1}
        />
      )}
    </Stack>
  );
};
