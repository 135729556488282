import { FormReview } from '@btrway/form-review';
import React from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

const FormReviewTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  taskKey,
  // onComplete,
  onClose,
}) => {
  const { completeTask } = useTaskActionContext();

  // Cast taskProperties to get workflowDefinitionKey
  // const taskProperties =
  //   taskConfig.taskProperties as WorkflowStepTaskProperties;
  // const workflowDefinitionKey = taskProperties.workflowDefinitionKey.value;

  // Get current organization
  // const { currentOrganization } = useAuthenticatedUser();

  // Render FormSubmission component with fetched workflow definition
  return (
    <FormReview
      taskInstanceId={taskInstanceId || 0}
      isOpen={true}
      onTaskComplete={async (taskInstanceId) => {
        console.log('FormReviewTask onTaskComplete');
        await completeTask('Form submitted successfully');
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
    />
  );
};

export default FormReviewTask;
