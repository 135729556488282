import { FieldConfig } from '@btrway/api-workflow';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { supportsFieldStyles } from '../../../utils/properties';
import styles from './FieldLabel.module.css';

interface FieldLabelProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  field: FieldConfig;
}

const FieldLabel: React.FC<FieldLabelProps> = ({
  value,
  onChange,
  placeholder = 'Field Label',
  readOnly = false,
  field,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const editorRef = useRef<HTMLSpanElement>(null);
  const supportsStyles = supportsFieldStyles(field.type);
  const headingSize = field.styles?.headingSize || 3;

  useEffect(() => {
    if (editorRef.current && !isEditing) {
      editorRef.current.textContent = value;
    }
  }, [value, isEditing]);

  const handleFocus = useCallback(
    (e: React.FocusEvent) => {
      e.stopPropagation();
      if (!readOnly) {
        setIsEditing(true);
        const selection = window.getSelection();
        const range = document.createRange();
        if (editorRef.current && selection) {
          range.selectNodeContents(editorRef.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    [readOnly]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent) => {
      e.stopPropagation();
      setIsEditing(false);
      if (editorRef.current && editorRef.current.textContent !== value) {
        onChange(editorRef.current.textContent || '');
      }
    },
    [value, onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        e.preventDefault();
        editorRef.current?.blur();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        if (editorRef.current) {
          editorRef.current.textContent = value;
          editorRef.current.blur();
        }
      }
    },
    [value]
  );

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const getStyles = () => {
    if (supportsStyles) {
      return {
        fontSize: `${2.5 - (headingSize - 1) * 0.35}rem`,
        lineHeight: 1.2,
        fontWeight: 700,
      };
    }

    return {
      fontSize: '16px',
      lineHeight: 1.2,
      fontWeight: 500,
    };
  };

  return (
    <div className={styles.fieldLabel} onClick={handleClick}>
      <span
        ref={editorRef}
        role="textbox"
        contentEditable={!readOnly}
        spellCheck={false}
        className={`${styles.editor} ${isEditing ? styles.editing : ''} ${
          readOnly ? styles.readOnly : ''
        } ${styles.label}`}
        style={getStyles()}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label="Field label editor"
        aria-placeholder={placeholder}
        suppressContentEditableWarning
      >
        {value}
      </span>
    </div>
  );
};

export default FieldLabel;
