import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { useWorkgroup } from '@btrway/workgroup-components';
import { Group } from '@mantine/core';
import {
  IconAward,
  IconBulb,
  IconListCheck,
  IconPlayFootball,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router';
import { useWorkgroupStats } from '../../providers/WorkgroupStatsProvider';
import { TaskStatusCard } from '../TaskStatusCard/TaskStatusCard';

export const WorkgroupDashboardCards: React.FC = () => {
  const navigate = useNavigate();
  const { workgroup } = useWorkgroup();
  const { getAggregatedStats } = useWorkgroupStats();

  const getTypeStats = (filter: {
    workflowType: WorkflowTypeEnum;
    taskListType?: string;
  }) => {
    const stats = getAggregatedStats(filter);
    if (!stats) {
      return {
        completedTasks: 0,
        completedTaskLists: 0,
        totalTasks: 0,
        totalTaskLists: 0,
        progress: 0,
      };
    }

    return {
      completedTasks: stats.completedTasks,
      completedTaskLists: stats.completedTaskLists,
      totalTasks: stats.totalTasks,
      totalTaskLists: stats.totalTaskLists,
      progress: stats.taskCompletionPercentage,
    };
  };

  const coachCertificationStats = getTypeStats({
    workflowType: WorkflowTypeEnum.certification,
    taskListType: 'Coach Certification',
  });

  const studentAthleteStats = getTypeStats({
    workflowType: WorkflowTypeEnum.certification,
    taskListType: 'Student-Athlete Eligibility',
  });

  const todoStats = getTypeStats({
    workflowType: WorkflowTypeEnum.taskList,
  });

  const curriculumStats = getTypeStats({
    workflowType: WorkflowTypeEnum.curriculum,
  });

  return (
    <Group gap="md" w="100%" justify="center">
      <TaskStatusCard
        title="Coach Certification"
        backgroundColor="#b5e5cf"
        icon={IconAward}
        details={[
          `${coachCertificationStats.completedTaskLists} of ${coachCertificationStats.totalTaskLists} Lists Complete`,
          `${coachCertificationStats.completedTasks} of ${coachCertificationStats.totalTasks} Tasks Complete`,
          `${
            coachCertificationStats.totalTasks -
            coachCertificationStats.completedTasks
          } Tasks Left`,
        ]}
        progress={coachCertificationStats.progress}
        onClick={() =>
          navigate(`/app/oversight/${workgroup.id}/certifications`)
        }
      />
      <TaskStatusCard
        title="Student-Athlete Eligibility"
        backgroundColor="yellow.1"
        icon={IconPlayFootball}
        details={[
          `${studentAthleteStats.completedTaskLists} of ${studentAthleteStats.totalTaskLists} Eligible`,
          `${studentAthleteStats.completedTasks} of ${studentAthleteStats.totalTasks} Requirements Complete`,
          `${
            studentAthleteStats.totalTasks - studentAthleteStats.completedTasks
          } Tasks Left`,
        ]}
        progress={studentAthleteStats.progress}
        onClick={() =>
          navigate(`/app/oversight/${workgroup.id}/certifications`)
        }
      />
      <TaskStatusCard
        title="To-Dos"
        backgroundColor="#c3e0e5"
        icon={IconListCheck}
        details={[
          `${todoStats.completedTaskLists} of ${todoStats.totalTaskLists} Lists Complete`,
          `${todoStats.completedTasks} of ${todoStats.totalTasks} Tasks Complete`,
          `${todoStats.totalTasks - todoStats.completedTasks} Tasks Left`,
        ]}
        progress={todoStats.progress}
        onClick={() => navigate(`/app/oversight/${workgroup.id}/todos`)}
      />
      <TaskStatusCard
        title="Curriculums"
        backgroundColor="#ece3f0"
        icon={IconBulb}
        details={[
          `${curriculumStats.completedTaskLists} of ${curriculumStats.totalTaskLists} Courses Complete`,
          `${curriculumStats.completedTasks} of ${curriculumStats.totalTasks} Tasks Complete`,
          `${
            curriculumStats.totalTasks - curriculumStats.completedTasks
          } Tasks Left`,
        ]}
        progress={curriculumStats.progress}
        onClick={() => navigate(`/app/oversight/${workgroup.id}/curriculums`)}
      />
    </Group>
  );
};
