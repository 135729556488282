// src/components/AddressInput/AddressInput.tsx
import { AddressRequest, AddressResponse } from '@btrway/api-core';
import { Card, Group, Select, Stack, TextInput } from '@mantine/core';
import React from 'react';
import {
  countryConfigurations,
  defaultCountryConfig,
} from '../../config/countries';
import { useAddressInput } from '../../hooks/useAddressInput';
import { useAddressValidation } from '../../hooks/useAddressValidation';
import { AddressValidationState } from '../../types/types';

interface AddressInputProps {
  value?: AddressResponse;
  onChange?: (value: AddressRequest) => void;
  onValidationChange?: (validation: AddressValidationState) => void;
  readOnly?: boolean;
  required?: boolean;
  defaultCountryCode?: string;
}

export const AddressInput: React.FC<AddressInputProps> = ({
  value,
  onChange,
  onValidationChange,
  readOnly = false,
  required = false,
  defaultCountryCode = 'US',
}) => {
  const { address, handleFieldChange, countryOptions, filteredStateProvinces } =
    useAddressInput({
      value,
      onChange,
      defaultCountryCode,
    });

  const { getFieldError, handleBlur } = useAddressValidation({
    address,
    onValidationChange,
  });

  // Get country configuration
  const countryConfig = address.countryCode
    ? countryConfigurations[address.countryCode] || defaultCountryConfig
    : defaultCountryConfig;

  const commonProps = {
    required,
    disabled: readOnly,
  };

  return (
    <Card
      withBorder
      radius="md"
      p="md"
      style={{
        border: '1px solid var(--mantine-color-gray-4)',
      }}
    >
      <Stack gap="md">
        <TextInput
          {...commonProps}
          label={countryConfig.labels?.addressLine1}
          value={address.street1 || ''}
          onChange={(e) => handleFieldChange('street1', e.currentTarget.value)}
          onBlur={() => handleBlur('street1')}
          error={getFieldError('street1')}
        />

        <TextInput
          label={countryConfig.labels?.addressLine2}
          value={address.street2 || ''}
          onChange={(e) => handleFieldChange('street2', e.currentTarget.value)}
          onBlur={() => handleBlur('street2')}
          disabled={readOnly}
        />

        <Group align="flex-start" grow>
          <TextInput
            {...commonProps}
            label={countryConfig.labels?.city}
            value={address.city || ''}
            onChange={(e) => handleFieldChange('city', e.currentTarget.value)}
            onBlur={() => handleBlur('city')}
            error={getFieldError('city')}
          />

          {countryConfig.showStateProvince &&
            (filteredStateProvinces.length > 0 ? (
              <Select
                key={`state-select-${address.countryCode}`}
                {...commonProps}
                label={countryConfig.labels.stateProvince}
                data={filteredStateProvinces}
                value={address.stateProvinceCode || null}
                onChange={(value) =>
                  handleFieldChange('stateProvinceCode', value)
                }
                onBlur={() => handleBlur('stateProvinceCode')}
                error={getFieldError('stateProvinceCode')}
                searchable
                clearable
                styles={{ dropdown: { zIndex: 1001 } }}
              />
            ) : (
              <TextInput
                {...commonProps}
                label={countryConfig.labels.stateProvince}
                value={address.stateProvinceCode || ''}
                onChange={(e) =>
                  handleFieldChange('stateProvinceCode', e.currentTarget.value)
                }
                onBlur={() => handleBlur('stateProvinceCode')}
                error={getFieldError('stateProvinceCode')}
              />
            ))}

          {countryConfig.showPostalCode && (
            <TextInput
              {...commonProps}
              label={countryConfig.labels?.postalCode}
              value={address.postalCode || ''}
              onChange={(e) =>
                handleFieldChange('postalCode', e.currentTarget.value)
              }
              onBlur={() => handleBlur('postalCode')}
              error={getFieldError('postalCode')}
              required={countryConfig.postalCodeRequired}
            />
          )}
        </Group>

        <Select
          {...commonProps}
          label="Country"
          data={countryOptions}
          value={address.countryCode || ''}
          onChange={(value) => handleFieldChange('countryCode', value)}
          onBlur={() => handleBlur('countryCode')}
          error={getFieldError('countryCode')}
          searchable
          clearable
        />
      </Stack>
    </Card>
  );
};

export default AddressInput;
