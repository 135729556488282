import {
  FieldConfig,
  FieldTypeEnum,
  PropertyReference,
  RuleOperatorTypeEnum,
  SelectFieldProperties,
  TextFieldProperties,
  TriggerConfig,
  TriggerConfigRuleValuesItem,
} from '@btrway/api-workflow';
import { getFieldDataType } from '@btrway/form-fields';
import { FormFieldInput } from '@btrway/form-renderer';
import { ActionIcon, Group, Stack, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React, { useMemo } from 'react';
import { FieldPicker } from '../FieldPicker/FieldPicker';
import { OperatorPicker } from '../OperatorPicker/OperatorPicker';
import classes from './TriggerConditionEditor.module.css';

interface TriggerConditionEditorProps {
  trigger: TriggerConfig;
  availableFields: FieldConfig[];
  onUpdate: (trigger: TriggerConfig) => void;
  onRemove: () => void;
  isFirst: boolean;
}

export const TriggerConditionEditor: React.FC<TriggerConditionEditorProps> = ({
  trigger,
  availableFields,
  onUpdate,
  onRemove,
  isFirst,
}) => {
  const selectedField = useMemo(() => {
    if (!trigger.property?.propertyKey) return undefined;

    return availableFields.find(
      (field) => field.fieldKey === trigger.property?.propertyKey
    );
  }, [availableFields, trigger.property?.propertyKey]);

  const showValueInput = useMemo(() => {
    return Boolean(
      trigger.property &&
        trigger.ruleOperator &&
        trigger.ruleOperator !== RuleOperatorTypeEnum.hasvalue &&
        trigger.ruleOperator !== RuleOperatorTypeEnum.novalue
    );
  }, [trigger.property, trigger.ruleOperator]);

  const modifiedField = useMemo(() => {
    if (!selectedField) return undefined;

    let modifiedFieldConfig = { ...selectedField };

    // Handle Select fields - force allowsMultiple for includes/excludes
    if (selectedField.type === FieldTypeEnum.select) {
      const shouldAllowMultiple =
        trigger.ruleOperator === RuleOperatorTypeEnum.includes ||
        trigger.ruleOperator === RuleOperatorTypeEnum.excludes;

      modifiedFieldConfig = {
        ...modifiedFieldConfig,
        fieldProperties: {
          ...modifiedFieldConfig.fieldProperties,
          allowsMultiple: shouldAllowMultiple,
        } as SelectFieldProperties,
      };
    }

    // Handle Text fields - remove email/phone dataType to render standard TextField
    if (selectedField.type === FieldTypeEnum.text) {
      const textProperties =
        modifiedFieldConfig.fieldProperties as TextFieldProperties;
      if (
        textProperties?.dataType === 'email' ||
        textProperties?.dataType === 'phone'
      ) {
        modifiedFieldConfig = {
          ...modifiedFieldConfig,
          fieldProperties: {
            ...textProperties,
            dataType: undefined,
          } as TextFieldProperties,
        };
      }
    }

    return modifiedFieldConfig;
  }, [selectedField, trigger.ruleOperator]);

  const handleFieldChange = (fieldKey: string) => {
    const field = availableFields.find((f) => f.fieldKey === fieldKey);

    if (field) {
      const dataType = getFieldDataType(field.type);

      if (dataType) {
        const propertyReference: PropertyReference = {
          propertyKey: field.fieldKey,
          dataType: dataType,
          label: field.label,
        };

        onUpdate({
          ...trigger,
          property: propertyReference,
          ruleOperator: undefined,
          ruleValues: [],
        });
      }
    }
  };

  const handleOperatorChange = (operatorValue: RuleOperatorTypeEnum) => {
    onUpdate({
      ...trigger,
      ruleOperator: operatorValue,
      ruleValues:
        operatorValue === RuleOperatorTypeEnum.hasvalue ||
        operatorValue === RuleOperatorTypeEnum.novalue
          ? [{ value: true }]
          : [],
    });
  };

  const handleValueChange = (value: any) => {
    onUpdate({
      ...trigger,
      ruleValues: [{ value }] as TriggerConfigRuleValuesItem[],
    });
  };

  return (
    <Stack w="100%">
      <Group justify="space-between" wrap="nowrap">
        <Group gap="xs" justify="flex-start" align="center" w="100%">
          <Text size="sm" fw={500}>
            {isFirst ? 'IF' : 'AND'}
          </Text>

          <FieldPicker
            selectedField={selectedField}
            availableFields={availableFields}
            onFieldSelect={handleFieldChange}
          />

          {trigger.property && (
            <OperatorPicker
              selectedOperator={trigger.ruleOperator}
              dataType={trigger.property.dataType}
              fieldType={selectedField?.type}
              onOperatorSelect={handleOperatorChange}
            />
          )}
        </Group>
        <ActionIcon variant="subtle" onClick={onRemove}>
          <IconTrash size={16} />
        </ActionIcon>
      </Group>
      {showValueInput && modifiedField && (
        <div className={classes.valueContainer}>
          <FormFieldInput
            field={modifiedField}
            value={trigger.ruleValues?.[0]?.value}
            onChange={handleValueChange}
            readOnly={false}
          />
        </div>
      )}
    </Stack>
  );
};
