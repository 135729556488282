import { StorageUploadRequest, StorageUsageEnum } from '@btrway/api-storage';
import { SignatureFieldProperties } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  useImageUpload,
  useStorageUpload,
} from '@btrway/file-storage-components';
import { SignatureData, SignatureInput } from '@btrway/signature-input';
import { Image, LoadingOverlay, Paper, Text } from '@mantine/core';
import React, { useState } from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface SignatureFieldProps extends BaseFieldProps<string> {}

export const SignatureField: React.FC<SignatureFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const [uploading, setUploading] = useState(false);
  const [previewData, setPreviewData] = useState<SignatureData | null>(null);
  const { storageUpload } = useStorageUpload();
  const { currentUser, currentOrganization } = useAuthenticatedUser();
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const signatureFieldProperties =
    field.fieldProperties as SignatureFieldProperties;

  // Use imageUpload hook to get the signature image URL
  const { imageUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.form,
    initialImageKey: value,
    onImageUpload: () => {}, // We handle upload separately
  });

  const handleSave = async (signatureData: SignatureData) => {
    setUploading(true);
    try {
      if (!signatureData.imageData) {
        throw new Error('Invalid signature data: No image data provided');
      }

      const response = await fetch(signatureData.imageData);
      const blob = await response.blob();
      const file = new File([blob], `signature-${Date.now()}.png`, {
        type: 'image/png',
      });

      const storageUploadRequest: StorageUploadRequest = {
        organizationId: currentOrganization.id,
        userId: currentUser.user.id,
        fileNames: [file.name],
        storageUsageEnum: StorageUsageEnum.form,
      };

      const res = await storageUpload(storageUploadRequest, [
        {
          file,
          contentType: file.type,
          fileName: file.name,
        },
      ]);

      setPreviewData(signatureData);
      onChange?.(res.storageKeys[0]);
    } catch (error) {
      console.error('Error uploading signature:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleClear = () => {
    setPreviewData(null);
    onChange?.(null);
  };

  // Display mode shows the signature image
  if (isDisplayMode(displayMode)) {
    return (
      <Paper p="md">
        {imageUrl ? (
          <Image
            src={imageUrl}
            alt="Signature"
            fit="contain"
            style={{ maxWidth: '100%', maxHeight: '160px' }}
          />
        ) : (
          <Text size="sm" c="dimmed" ta="center">
            No signature provided
          </Text>
        )}
      </Paper>
    );
  }

  // Readonly mode (builder-readonly)
  if (readonly) {
    return (
      <Paper p="md">
        {imageUrl ? (
          <Image
            src={imageUrl}
            alt="Signature"
            fit="contain"
            style={{ maxWidth: '100%', maxHeight: '160px' }}
          />
        ) : (
          <Text size="sm" c="dimmed" ta="center">
            No signature provided
          </Text>
        )}
      </Paper>
    );
  }

  // Input/Builder mode
  return (
    <Paper pos="relative">
      <LoadingOverlay visible={uploading} overlayProps={{ blur: 2 }} />
      <SignatureInput
        onSave={handleSave}
        onClear={handleClear}
        enableTypedSignature={signatureFieldProperties?.allowTextEntry ?? true}
        width="100%"
        initialName={previewData?.type === 'typed' ? previewData.typedName : ''}
      />
    </Paper>
  );
};

export default SignatureField;
