import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';
import { useCallback, useMemo } from 'react';
import { useFormCompletion } from '../providers/FormCompletionProvider';
import { ValidationErrors } from '../types/validation';
import { useFieldVisibility } from './useFieldVisibility';

interface ValidationPath {
  fieldKey: string;
  parentPath?: string;
  index?: number;
}

export const useFormValidation = (fields: FieldConfig[]) => {
  const { formValues } = useFormCompletion();
  const { isFieldVisible } = useFieldVisibility();

  // Get all required fields that are currently visible
  const requiredVisibleFields = useMemo(() => {
    const getRequiredVisibleFields = (
      fields: FieldConfig[],
      parentPath?: string
    ): ValidationPath[] => {
      return fields.reduce<ValidationPath[]>((acc, field) => {
        const fieldVisible = isFieldVisible(field.fieldKey);

        if (fieldVisible) {
          if (field.required) {
            acc.push({
              fieldKey: field.fieldKey,
              parentPath,
            });
          }

          // For multiple fields, we need to check each record's child fields
          if (
            field.type === FieldTypeEnum.multiple &&
            field.childFields?.length
          ) {
            const records = (formValues[field.fieldKey] || []) as Record<
              string,
              any
            >[];
            records.forEach((_, index) => {
              const recordPath = `${field.fieldKey}.${index}`;
              const childFields = getRequiredVisibleFields(
                field.childFields!,
                recordPath
              );
              acc.push(...childFields);
            });
          }
          // For regular nested fields
          else if (field.childFields?.length) {
            const nestedPath = parentPath
              ? `${parentPath}.${field.fieldKey}`
              : field.fieldKey;
            acc.push(
              ...getRequiredVisibleFields(field.childFields, nestedPath)
            );
          }
        }

        return acc;
      }, []);
    };

    return getRequiredVisibleFields(fields);
  }, [fields, isFieldVisible, formValues]);

  // Check if a value is effectively empty
  const isEmptyValue = useCallback((value: any): boolean => {
    if (value === undefined || value === null || value === '') {
      return true;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) return true;
      // For arrays of objects (multiple fields), check if all objects are empty
      return value.every(
        (item) => typeof item === 'object' && Object.keys(item).length === 0
      );
    }

    if (typeof value === 'object' && Object.keys(value).length === 0) {
      return true;
    }

    return false;
  }, []);

  // Get value from nested path
  const getNestedValue = useCallback((obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => {
      return acc?.[part];
    }, obj);
  }, []);

  // Validate a single field
  const validateField = useCallback(
    (field: FieldConfig, parentPath?: string): string | null => {
      if (!field.required || !isFieldVisible(field.fieldKey)) {
        return null;
      }

      // For multiple fields, validate the structure and each record's fields
      if (field.type === FieldTypeEnum.multiple) {
        const records = formValues[field.fieldKey] as
          | Record<string, any>[]
          | undefined;

        // Check if the multiple field itself has required records
        if (!records || records.length === 0) {
          return `At least one ${field.label} record is required`;
        }

        // Validate each record's fields
        for (let i = 0; i < records.length; i++) {
          const record = records[i];
          if (!record || typeof record !== 'object') continue;

          for (const childField of field.childFields || []) {
            const childValue = record[childField.fieldKey];
            if (childField.required && isEmptyValue(childValue)) {
              return `${childField.label} is required in ${
                field.label
              } record ${i + 1}`;
            }
          }
        }

        return null;
      }

      // For regular fields
      let value;
      if (parentPath) {
        value = getNestedValue(formValues, `${parentPath}.${field.fieldKey}`);
      } else {
        value = formValues[field.fieldKey];
      }

      // For address fields, check DOM for validation state
      if (field.type === FieldTypeEnum.address) {
        const fullFieldKey = parentPath
          ? `${parentPath}.${field.fieldKey}`
          : field.fieldKey;
        const element = document.querySelector(
          `[data-field-key="${fullFieldKey}"]`
        );
        if (element?.getAttribute('aria-invalid') === 'true') {
          return `${field.label} is incomplete`;
        }
        return null;
      }

      if (isEmptyValue(value)) {
        return `${field.label} is required`;
      }

      return null;
    },
    [formValues, isFieldVisible, isEmptyValue, getNestedValue]
  );

  // Validate all fields and return errors
  const validateForm = useCallback((): ValidationErrors => {
    const errors: ValidationErrors = {};

    const validateFieldsRecursive = (
      fields: FieldConfig[],
      parentPath?: string
    ) => {
      fields.forEach((field) => {
        if (isFieldVisible(field.fieldKey)) {
          const error = validateField(field, parentPath);
          if (error) {
            const fullFieldKey = parentPath
              ? `${parentPath}.${field.fieldKey}`
              : field.fieldKey;
            errors[fullFieldKey] = error;
          }

          // Continue validation for child fields
          if (
            field.childFields?.length &&
            field.type !== FieldTypeEnum.multiple
          ) {
            const nestedPath = parentPath
              ? `${parentPath}.${field.fieldKey}`
              : field.fieldKey;
            validateFieldsRecursive(field.childFields, nestedPath);
          }
        }
      });
    };

    validateFieldsRecursive(fields);
    return errors;
  }, [fields, isFieldVisible, validateField]);

  // Check if the form is valid
  const isValid = useCallback((): boolean => {
    const errors = validateForm();
    return Object.keys(errors).length === 0;
  }, [validateForm]);

  // Get missing required field labels
  const getMissingRequiredFieldLabels = useCallback((): string[] => {
    const errors = validateForm();
    return requiredVisibleFields
      .map((field) => {
        const fullFieldKey = field.parentPath
          ? `${field.parentPath}.${field.fieldKey}`
          : field.fieldKey;
        if (errors[fullFieldKey]) {
          const foundField = fields.find((f) => f.fieldKey === field.fieldKey);
          return foundField?.label || '';
        }
        return '';
      })
      .filter(Boolean);
  }, [validateForm, requiredVisibleFields, fields]);

  return {
    isValid,
    validateForm,
    requiredVisibleFields,
    getMissingRequiredFieldLabels,
  };
};
