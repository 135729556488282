import { FieldTypeEnum } from '@btrway/api-workflow';
import { ActionIcon, Group, Paper, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  getFieldDescription,
  getFieldIcon,
  getFieldLabel,
} from '../../utils/toolbox';

interface FormToolboxItemProps {
  type: FieldTypeEnum;
  onSelect: () => void;
}

export const FormToolboxItem: React.FC<FormToolboxItemProps> = ({
  type,
  onSelect,
}) => {
  // Set up drag functionality
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'new-field',
      item: () => {
        return {
          type: 'new-field',
          fieldType: type,
          isNew: true,
        };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [type]
  );

  // Use empty image as drag preview
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div
      ref={drag}
      style={{
        width: '100%',
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Paper
        withBorder
        p="xs"
        onClick={onSelect}
        component={motion.div}
        whileHover={{
          scale: 1.03,
          boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
          transition: {
            type: 'spring',
            stiffness: 400,
            damping: 10,
          },
        }}
        whileTap={{
          scale: 0.97,
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          transition: {
            type: 'spring',
            stiffness: 400,
            damping: 10,
          },
        }}
      >
        <Group gap="sm" wrap="nowrap">
          <ActionIcon size="sm" variant="light" color="blue">
            {getFieldIcon(type)}
          </ActionIcon>
          <div style={{ flex: 1 }}>
            <Text fz="sm" fw={500}>
              {getFieldLabel(type)}
            </Text>
            <Text fz="xs" c="dimmed">
              {getFieldDescription(type)}
            </Text>
          </div>
        </Group>
      </Paper>
    </div>
  );
};

export default FormToolboxItem;
