import { StepConfig, WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { Box, List, Text } from '@mantine/core';

interface FormDescriptionProps {
  workflowDefinition: WorkflowDefinitionResponse;
  reviewSteps: StepConfig[];
}

const FormDescription: React.FC<FormDescriptionProps> = ({
  workflowDefinition,
  reviewSteps,
}) => {
  return (
    <Box
      style={{
        width: '25%',
        minWidth: '25%',
        overflow: 'auto',
        padding: 'var(--mantine-spacing-xl)',
        backgroundColor: 'var(--mantine-color-gray-1)',
        borderRadius: 'var(--mantine-radius-md)',
      }}
    >
      <Text fz={36} fw={700} mb="md">
        {workflowDefinition.name}
      </Text>
      <Text fz="lg" fw={700} mb="md">
        {workflowDefinition.workflowMetadata?.shortDescription}
      </Text>
      <Text fz="lg" mb="xl">
        {workflowDefinition.workflowMetadata?.longDescription}
      </Text>
      {reviewSteps.length > 0 && (
        <>
          <Text fz="xl" fw={600} mb="md">
            What Happens after You Submit?
          </Text>
          <List type="ordered" spacing="xs">
            {reviewSteps.map((step, index) => (
              <List.Item key={step.stepKey} fz="lg">
                <Text fz="lg">{step.title || `Review Step ${index + 1}`}</Text>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default FormDescription;
