import { TaskConfig, TaskTypeEnum } from '@btrway/api-workflow';
import { useWorkflowService } from '@btrway/workflow-common-provider';
import { useCallback } from 'react';
import { useWorkflowBuilderState } from './useWorkflowBuilderState';

export const useScreen = () => {
  const { state, closeScreen, screenHandlers, dispatch } =
    useWorkflowBuilderState();
  const defaultWorkflowService = useWorkflowService();
  const { workflows } = defaultWorkflowService.getWorkflows();

  const handleEditorSave = useCallback(
    async (
      addedTasks: TaskConfig[],
      removedTaskKeys: string[],
      workflowName: string,
      workflowKey: string,
      showTaskConfigAfterSave: boolean
    ) => {
      try {
        const taskType =
          state.currentScreen?.type === 'CURRICULUM_EDITOR'
            ? TaskTypeEnum.completeCurriculum
            : TaskTypeEnum.completeFormPacket;

        if (showTaskConfigAfterSave) {
          dispatch({
            type: 'SELECT_WORKFLOW',
            workflowKey,
            name: workflowName,
            isNew: true,
            // taskType,
          });
        } else {
          closeScreen();
        }
      } catch (error) {
        console.error('Error in handleEditorSave:', error);
      }
    },
    [state.currentScreen, closeScreen, dispatch]
  );

  return {
    state,
    closeScreen,
    screenHandlers: {
      ...screenHandlers,
      onEditorSave: handleEditorSave,
    },
  };
};
