/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  EventAttendeeRequest,
  EventAttendeeResponse,
  EventChangeHistoryRequest,
  EventChangeHistoryResponse,
  EventDateUpdateRequest,
  EventFilterRequest,
  EventRecurrenceDeleteRequest,
  EventReminderRequest,
  EventReminderResponse,
  EventRequest,
  EventResponse,
  EventTargetRequest,
  EventTargetResponse,
  EventTypeRequest,
  EventTypeResponse,
  FacilityRequest,
  FacilityResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const saveFacility = (
    facilityRequest: FacilityRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/facilities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: facilityRequest
    },
      options);
    }
  


export const getSaveFacilityMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveFacility>>, TError,{data: FacilityRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveFacility>>, TError,{data: FacilityRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveFacility>>, {data: FacilityRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveFacility(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveFacilityMutationResult = NonNullable<Awaited<ReturnType<typeof saveFacility>>>
    export type SaveFacilityMutationBody = FacilityRequest
    export type SaveFacilityMutationError = unknown

    export const useSaveFacility = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveFacility>>, TError,{data: FacilityRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveFacility>>,
        TError,
        {data: FacilityRequest},
        TContext
      > => {

      const mutationOptions = getSaveFacilityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEvent = (
    eventRequest: EventRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventRequest
    },
      options);
    }
  


export const getSaveEventMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEvent>>, TError,{data: EventRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEvent>>, TError,{data: EventRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEvent>>, {data: EventRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEvent(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEventMutationResult = NonNullable<Awaited<ReturnType<typeof saveEvent>>>
    export type SaveEventMutationBody = EventRequest
    export type SaveEventMutationError = unknown

    export const useSaveEvent = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEvent>>, TError,{data: EventRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEvent>>,
        TError,
        {data: EventRequest},
        TContext
      > => {

      const mutationOptions = getSaveEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getFilteredEvents = (
    eventFilterRequest: EventFilterRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<EventResponse[]>(
      {url: `/api/calendar/events/filter`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventFilterRequest
    },
      options);
    }
  


export const getGetFilteredEventsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFilteredEvents>>, TError,{data: EventFilterRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getFilteredEvents>>, TError,{data: EventFilterRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getFilteredEvents>>, {data: EventFilterRequest}> = (props) => {
          const {data} = props ?? {};

          return  getFilteredEvents(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetFilteredEventsMutationResult = NonNullable<Awaited<ReturnType<typeof getFilteredEvents>>>
    export type GetFilteredEventsMutationBody = EventFilterRequest
    export type GetFilteredEventsMutationError = unknown

    export const useGetFilteredEvents = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFilteredEvents>>, TError,{data: EventFilterRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getFilteredEvents>>,
        TError,
        {data: EventFilterRequest},
        TContext
      > => {

      const mutationOptions = getGetFilteredEventsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEventType = (
    eventTypeRequest: EventTypeRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/event-types`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventTypeRequest
    },
      options);
    }
  


export const getSaveEventTypeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventType>>, TError,{data: EventTypeRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEventType>>, TError,{data: EventTypeRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEventType>>, {data: EventTypeRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEventType(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEventTypeMutationResult = NonNullable<Awaited<ReturnType<typeof saveEventType>>>
    export type SaveEventTypeMutationBody = EventTypeRequest
    export type SaveEventTypeMutationError = unknown

    export const useSaveEventType = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventType>>, TError,{data: EventTypeRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEventType>>,
        TError,
        {data: EventTypeRequest},
        TContext
      > => {

      const mutationOptions = getSaveEventTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEventTarget = (
    eventTargetRequest: EventTargetRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/event-targets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventTargetRequest
    },
      options);
    }
  


export const getSaveEventTargetMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventTarget>>, TError,{data: EventTargetRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEventTarget>>, TError,{data: EventTargetRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEventTarget>>, {data: EventTargetRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEventTarget(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEventTargetMutationResult = NonNullable<Awaited<ReturnType<typeof saveEventTarget>>>
    export type SaveEventTargetMutationBody = EventTargetRequest
    export type SaveEventTargetMutationError = unknown

    export const useSaveEventTarget = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventTarget>>, TError,{data: EventTargetRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEventTarget>>,
        TError,
        {data: EventTargetRequest},
        TContext
      > => {

      const mutationOptions = getSaveEventTargetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEventReminder = (
    eventReminderRequest: EventReminderRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/event-reminders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventReminderRequest
    },
      options);
    }
  


export const getSaveEventReminderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventReminder>>, TError,{data: EventReminderRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEventReminder>>, TError,{data: EventReminderRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEventReminder>>, {data: EventReminderRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEventReminder(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEventReminderMutationResult = NonNullable<Awaited<ReturnType<typeof saveEventReminder>>>
    export type SaveEventReminderMutationBody = EventReminderRequest
    export type SaveEventReminderMutationError = unknown

    export const useSaveEventReminder = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventReminder>>, TError,{data: EventReminderRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEventReminder>>,
        TError,
        {data: EventReminderRequest},
        TContext
      > => {

      const mutationOptions = getSaveEventReminderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEventChangeHistory = (
    eventChangeHistoryRequest: EventChangeHistoryRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/event-change-histories`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventChangeHistoryRequest
    },
      options);
    }
  


export const getSaveEventChangeHistoryMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventChangeHistory>>, TError,{data: EventChangeHistoryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEventChangeHistory>>, TError,{data: EventChangeHistoryRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEventChangeHistory>>, {data: EventChangeHistoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEventChangeHistory(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEventChangeHistoryMutationResult = NonNullable<Awaited<ReturnType<typeof saveEventChangeHistory>>>
    export type SaveEventChangeHistoryMutationBody = EventChangeHistoryRequest
    export type SaveEventChangeHistoryMutationError = unknown

    export const useSaveEventChangeHistory = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventChangeHistory>>, TError,{data: EventChangeHistoryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEventChangeHistory>>,
        TError,
        {data: EventChangeHistoryRequest},
        TContext
      > => {

      const mutationOptions = getSaveEventChangeHistoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEventAttendee = (
    eventAttendeeRequest: EventAttendeeRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/event-attendees`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: eventAttendeeRequest
    },
      options);
    }
  


export const getSaveEventAttendeeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventAttendee>>, TError,{data: EventAttendeeRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEventAttendee>>, TError,{data: EventAttendeeRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEventAttendee>>, {data: EventAttendeeRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEventAttendee(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEventAttendeeMutationResult = NonNullable<Awaited<ReturnType<typeof saveEventAttendee>>>
    export type SaveEventAttendeeMutationBody = EventAttendeeRequest
    export type SaveEventAttendeeMutationError = unknown

    export const useSaveEventAttendee = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEventAttendee>>, TError,{data: EventAttendeeRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEventAttendee>>,
        TError,
        {data: EventAttendeeRequest},
        TContext
      > => {

      const mutationOptions = getSaveEventAttendeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateEventDates = (
    id: number,
    eventDateUpdateRequest: EventDateUpdateRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/events/${id}/dates`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: eventDateUpdateRequest
    },
      options);
    }
  


export const getUpdateEventDatesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventDates>>, TError,{id: number;data: EventDateUpdateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateEventDates>>, TError,{id: number;data: EventDateUpdateRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEventDates>>, {id: number;data: EventDateUpdateRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  updateEventDates(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateEventDatesMutationResult = NonNullable<Awaited<ReturnType<typeof updateEventDates>>>
    export type UpdateEventDatesMutationBody = EventDateUpdateRequest
    export type UpdateEventDatesMutationError = unknown

    export const useUpdateEventDates = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventDates>>, TError,{id: number;data: EventDateUpdateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateEventDates>>,
        TError,
        {id: number;data: EventDateUpdateRequest},
        TContext
      > => {

      const mutationOptions = getUpdateEventDatesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkgroupCalendarFeed = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/calendar/feed/workgroup/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupCalendarFeedQueryKey = (workgroupId: number,) => {
    return [`/api/calendar/feed/workgroup/${workgroupId}`] as const;
    }

    
export const getGetWorkgroupCalendarFeedQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupCalendarFeedQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>> = ({ signal }) => getWorkgroupCalendarFeed(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupCalendarFeedQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>>
export type GetWorkgroupCalendarFeedQueryError = unknown



export function useGetWorkgroupCalendarFeed<TData = Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupCalendarFeed>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupCalendarFeedQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getFacilityById = (
    facilityId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<FacilityResponse>(
      {url: `/api/calendar/facilities/${facilityId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFacilityByIdQueryKey = (facilityId: number,) => {
    return [`/api/calendar/facilities/${facilityId}`] as const;
    }

    
export const getGetFacilityByIdQueryOptions = <TData = Awaited<ReturnType<typeof getFacilityById>>, TError = unknown>(facilityId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFacilityById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFacilityByIdQueryKey(facilityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFacilityById>>> = ({ signal }) => getFacilityById(facilityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(facilityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFacilityById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFacilityByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFacilityById>>>
export type GetFacilityByIdQueryError = unknown



export function useGetFacilityById<TData = Awaited<ReturnType<typeof getFacilityById>>, TError = unknown>(
 facilityId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFacilityById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFacilityByIdQueryOptions(facilityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteFacility = (
    facilityId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/facilities/${facilityId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFacilityMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFacility>>, TError,{facilityId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFacility>>, TError,{facilityId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFacility>>, {facilityId: number}> = (props) => {
          const {facilityId} = props ?? {};

          return  deleteFacility(facilityId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFacilityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFacility>>>
    
    export type DeleteFacilityMutationError = unknown

    export const useDeleteFacility = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFacility>>, TError,{facilityId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFacility>>,
        TError,
        {facilityId: number},
        TContext
      > => {

      const mutationOptions = getDeleteFacilityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getFacilitiesByWorkgroup = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<FacilityResponse[]>(
      {url: `/api/calendar/facilities/workgroup/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFacilitiesByWorkgroupQueryKey = (workgroupId: number,) => {
    return [`/api/calendar/facilities/workgroup/${workgroupId}`] as const;
    }

    
export const getGetFacilitiesByWorkgroupQueryOptions = <TData = Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFacilitiesByWorkgroupQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>> = ({ signal }) => getFacilitiesByWorkgroup(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFacilitiesByWorkgroupQueryResult = NonNullable<Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>>
export type GetFacilitiesByWorkgroupQueryError = unknown



export function useGetFacilitiesByWorkgroup<TData = Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFacilitiesByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFacilitiesByWorkgroupQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getFacilitiesByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<FacilityResponse[]>(
      {url: `/api/calendar/facilities/organization/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFacilitiesByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/calendar/facilities/organization/${organizationId}`] as const;
    }

    
export const getGetFacilitiesByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getFacilitiesByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFacilitiesByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFacilitiesByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFacilitiesByOrganization>>> = ({ signal }) => getFacilitiesByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFacilitiesByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFacilitiesByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getFacilitiesByOrganization>>>
export type GetFacilitiesByOrganizationQueryError = unknown



export function useGetFacilitiesByOrganization<TData = Awaited<ReturnType<typeof getFacilitiesByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFacilitiesByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFacilitiesByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getEventById = (
    eventId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventResponse>(
      {url: `/api/calendar/events/${eventId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventByIdQueryKey = (eventId: number,) => {
    return [`/api/calendar/events/${eventId}`] as const;
    }

    
export const getGetEventByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventById>>, TError = unknown>(eventId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventByIdQueryKey(eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventById>>> = ({ signal }) => getEventById(eventId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventById>>>
export type GetEventByIdQueryError = unknown



export function useGetEventById<TData = Awaited<ReturnType<typeof getEventById>>, TError = unknown>(
 eventId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventByIdQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEvent = (
    eventId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/events/${eventId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEventMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEvent>>, TError,{eventId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEvent>>, TError,{eventId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEvent>>, {eventId: number}> = (props) => {
          const {eventId} = props ?? {};

          return  deleteEvent(eventId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEventMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEvent>>>
    
    export type DeleteEventMutationError = unknown

    export const useDeleteEvent = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEvent>>, TError,{eventId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEvent>>,
        TError,
        {eventId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getEventTypeById = (
    eventTypeId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventTypeResponse>(
      {url: `/api/calendar/event-types/${eventTypeId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventTypeByIdQueryKey = (eventTypeId: number,) => {
    return [`/api/calendar/event-types/${eventTypeId}`] as const;
    }

    
export const getGetEventTypeByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventTypeById>>, TError = unknown>(eventTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypeById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventTypeByIdQueryKey(eventTypeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTypeById>>> = ({ signal }) => getEventTypeById(eventTypeId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(eventTypeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventTypeById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventTypeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTypeById>>>
export type GetEventTypeByIdQueryError = unknown



export function useGetEventTypeById<TData = Awaited<ReturnType<typeof getEventTypeById>>, TError = unknown>(
 eventTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypeById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventTypeByIdQueryOptions(eventTypeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEventType = (
    eventTypeId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/event-types/${eventTypeId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEventTypeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventType>>, TError,{eventTypeId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEventType>>, TError,{eventTypeId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventType>>, {eventTypeId: number}> = (props) => {
          const {eventTypeId} = props ?? {};

          return  deleteEventType(eventTypeId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEventTypeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventType>>>
    
    export type DeleteEventTypeMutationError = unknown

    export const useDeleteEventType = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventType>>, TError,{eventTypeId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEventType>>,
        TError,
        {eventTypeId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEventTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getEventTypesByOrganizationId = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventTypeResponse[]>(
      {url: `/api/calendar/event-types/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventTypesByOrganizationIdQueryKey = (organizationId: number,) => {
    return [`/api/calendar/event-types/organizations/${organizationId}`] as const;
    }

    
export const getGetEventTypesByOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventTypesByOrganizationId>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventTypesByOrganizationIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTypesByOrganizationId>>> = ({ signal }) => getEventTypesByOrganizationId(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventTypesByOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventTypesByOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTypesByOrganizationId>>>
export type GetEventTypesByOrganizationIdQueryError = unknown



export function useGetEventTypesByOrganizationId<TData = Awaited<ReturnType<typeof getEventTypesByOrganizationId>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventTypesByOrganizationIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getEventTargetById = (
    eventTargetId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventTargetResponse>(
      {url: `/api/calendar/event-targets/${eventTargetId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventTargetByIdQueryKey = (eventTargetId: number,) => {
    return [`/api/calendar/event-targets/${eventTargetId}`] as const;
    }

    
export const getGetEventTargetByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventTargetById>>, TError = unknown>(eventTargetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTargetById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventTargetByIdQueryKey(eventTargetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTargetById>>> = ({ signal }) => getEventTargetById(eventTargetId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(eventTargetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventTargetById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventTargetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTargetById>>>
export type GetEventTargetByIdQueryError = unknown



export function useGetEventTargetById<TData = Awaited<ReturnType<typeof getEventTargetById>>, TError = unknown>(
 eventTargetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTargetById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventTargetByIdQueryOptions(eventTargetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEventTarget = (
    eventTargetId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/event-targets/${eventTargetId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEventTargetMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventTarget>>, TError,{eventTargetId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEventTarget>>, TError,{eventTargetId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventTarget>>, {eventTargetId: number}> = (props) => {
          const {eventTargetId} = props ?? {};

          return  deleteEventTarget(eventTargetId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEventTargetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventTarget>>>
    
    export type DeleteEventTargetMutationError = unknown

    export const useDeleteEventTarget = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventTarget>>, TError,{eventTargetId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEventTarget>>,
        TError,
        {eventTargetId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEventTargetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getEventReminderById = (
    eventReminderId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventReminderResponse>(
      {url: `/api/calendar/event-reminders/${eventReminderId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventReminderByIdQueryKey = (eventReminderId: number,) => {
    return [`/api/calendar/event-reminders/${eventReminderId}`] as const;
    }

    
export const getGetEventReminderByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventReminderById>>, TError = unknown>(eventReminderId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventReminderById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventReminderByIdQueryKey(eventReminderId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventReminderById>>> = ({ signal }) => getEventReminderById(eventReminderId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(eventReminderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventReminderById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventReminderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventReminderById>>>
export type GetEventReminderByIdQueryError = unknown



export function useGetEventReminderById<TData = Awaited<ReturnType<typeof getEventReminderById>>, TError = unknown>(
 eventReminderId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventReminderById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventReminderByIdQueryOptions(eventReminderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEventReminder = (
    eventReminderId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/event-reminders/${eventReminderId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEventReminderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventReminder>>, TError,{eventReminderId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEventReminder>>, TError,{eventReminderId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventReminder>>, {eventReminderId: number}> = (props) => {
          const {eventReminderId} = props ?? {};

          return  deleteEventReminder(eventReminderId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEventReminderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventReminder>>>
    
    export type DeleteEventReminderMutationError = unknown

    export const useDeleteEventReminder = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventReminder>>, TError,{eventReminderId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEventReminder>>,
        TError,
        {eventReminderId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEventReminderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getEventChangeHistoryById = (
    eventChangeHistoryId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventChangeHistoryResponse>(
      {url: `/api/calendar/event-change-histories/${eventChangeHistoryId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventChangeHistoryByIdQueryKey = (eventChangeHistoryId: number,) => {
    return [`/api/calendar/event-change-histories/${eventChangeHistoryId}`] as const;
    }

    
export const getGetEventChangeHistoryByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventChangeHistoryById>>, TError = unknown>(eventChangeHistoryId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventChangeHistoryById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventChangeHistoryByIdQueryKey(eventChangeHistoryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventChangeHistoryById>>> = ({ signal }) => getEventChangeHistoryById(eventChangeHistoryId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(eventChangeHistoryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventChangeHistoryById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventChangeHistoryByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventChangeHistoryById>>>
export type GetEventChangeHistoryByIdQueryError = unknown



export function useGetEventChangeHistoryById<TData = Awaited<ReturnType<typeof getEventChangeHistoryById>>, TError = unknown>(
 eventChangeHistoryId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventChangeHistoryById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventChangeHistoryByIdQueryOptions(eventChangeHistoryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getEventAttendeeById = (
    eventAttendeeId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventAttendeeResponse>(
      {url: `/api/calendar/event-attendees/${eventAttendeeId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventAttendeeByIdQueryKey = (eventAttendeeId: number,) => {
    return [`/api/calendar/event-attendees/${eventAttendeeId}`] as const;
    }

    
export const getGetEventAttendeeByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEventAttendeeById>>, TError = unknown>(eventAttendeeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventAttendeeById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventAttendeeByIdQueryKey(eventAttendeeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventAttendeeById>>> = ({ signal }) => getEventAttendeeById(eventAttendeeId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(eventAttendeeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventAttendeeById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventAttendeeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventAttendeeById>>>
export type GetEventAttendeeByIdQueryError = unknown



export function useGetEventAttendeeById<TData = Awaited<ReturnType<typeof getEventAttendeeById>>, TError = unknown>(
 eventAttendeeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventAttendeeById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventAttendeeByIdQueryOptions(eventAttendeeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEventAttendee = (
    eventAttendeeId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/event-attendees/${eventAttendeeId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEventAttendeeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventAttendee>>, TError,{eventAttendeeId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEventAttendee>>, TError,{eventAttendeeId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventAttendee>>, {eventAttendeeId: number}> = (props) => {
          const {eventAttendeeId} = props ?? {};

          return  deleteEventAttendee(eventAttendeeId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEventAttendeeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventAttendee>>>
    
    export type DeleteEventAttendeeMutationError = unknown

    export const useDeleteEventAttendee = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventAttendee>>, TError,{eventAttendeeId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEventAttendee>>,
        TError,
        {eventAttendeeId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEventAttendeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteRecurringEvent = (
    id: number,
    eventRecurrenceDeleteRequest: EventRecurrenceDeleteRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/calendar/events/${id}/recurrence`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: eventRecurrenceDeleteRequest
    },
      options);
    }
  


export const getDeleteRecurringEventMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRecurringEvent>>, TError,{id: number;data: EventRecurrenceDeleteRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRecurringEvent>>, TError,{id: number;data: EventRecurrenceDeleteRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRecurringEvent>>, {id: number;data: EventRecurrenceDeleteRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteRecurringEvent(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRecurringEventMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRecurringEvent>>>
    export type DeleteRecurringEventMutationBody = EventRecurrenceDeleteRequest
    export type DeleteRecurringEventMutationError = unknown

    export const useDeleteRecurringEvent = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRecurringEvent>>, TError,{id: number;data: EventRecurrenceDeleteRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRecurringEvent>>,
        TError,
        {id: number;data: EventRecurrenceDeleteRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRecurringEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
