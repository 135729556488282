import {
  CertificationTypeEnum,
  EventTypeEnum,
  StepConfig,
  StepTypeEnum,
  TaskConfig,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { ToolboxItem, ToolboxMode } from '@btrway/workflow-builder-toolbox';
import { CommonWorkflow } from '@btrway/workflow-common-provider';
import {
  useConfigFactory,
  useWorkflowConfig,
} from '@btrway/workflow-configuration-manager';
import { useCallback, useContext } from 'react';
import { WorkflowBuilderContext } from '../providers/WorkflowBuilderStateProvider';
import { DisplayConfig } from '../types/display';
import { Screen } from '../types/screen';
import { WorkflowItemConfig } from '../types/state';

export const useWorkflowBuilderState = () => {
  const context = useContext(WorkflowBuilderContext);
  if (!context) {
    throw new Error(
      'useWorkflowBuilderState must be used within WorkflowBuilderStateProvider'
    );
  }

  const { state, dispatch } = context;
  const configFactory = useConfigFactory();
  const { taskActions, processActions, certificationActions } =
    useWorkflowConfig();

  const openScreen = useCallback(
    (
      screen: Screen,
      config: DisplayConfig = { mode: 'panel', position: 'left' }
    ) => {
      dispatch({ type: 'OPEN_SCREEN', screen, config });
    },
    [dispatch]
  );

  // Helper functions for specific screen types
  const openStepConfigScreen = useCallback(
    (config: StepConfig, isNew: boolean = true, width?: number) => {
      openScreen(
        {
          type: 'STEP_CONFIG',
          data: {
            config,
            workflow: state.parentWorkflow!,
            isNew,
          },
        },
        {
          mode: 'panel',
          position: 'left',
          ...(width && { width }),
        }
      );
    },
    [openScreen, state.parentWorkflow]
  );

  const openTaskConfigScreen = useCallback(
    (config: TaskConfig, isNew: boolean = true, width?: number) => {
      const isAutomationWorkflow =
        state.parentWorkflow?.workflowType === WorkflowTypeEnum.automation;

      if (isAutomationWorkflow) {
        const newStep = configFactory.createStepConfig('task', config.taskType);
        if (newStep.taskConfig) {
          newStep.taskConfig = {
            ...newStep.taskConfig,
            taskKey: config.taskKey,
            taskType: config.taskType,
            taskProperties: config.taskProperties,
            metadata: config.metadata,
          };
        }
        openStepConfigScreen(newStep, isNew, width);
      } else {
        openScreen(
          {
            type: 'TASK_CONFIG',
            data: {
              config,
              workflow: state.parentWorkflow!,
              isNew,
            },
          },
          {
            mode: 'panel',
            position: 'left',
            ...(width && { width }),
          }
        );
      }
    },
    [
      openScreen,
      state.parentWorkflow,
      configFactory.createStepConfig,
      openStepConfigScreen,
    ]
  );

  const openToolbox = useCallback(
    (targetWorkflow: CommonWorkflow, mode: ToolboxMode) => {
      openScreen(
        {
          type: 'TOOLBOX',
          data: { mode, workflowType: targetWorkflow?.workflowType },
        },
        {
          mode: 'panel',
          position: 'left',
          width: 400,
          modalProps: {
            title: `Add ${mode.charAt(0).toUpperCase() + mode.slice(1)}`,
          },
        }
      );
    },
    [openScreen]
  );

  const closeScreen = useCallback(() => {
    dispatch({ type: 'CLOSE_SCREEN' });
  }, [dispatch]);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, [dispatch]);

  const handleStepTypeSelect = useCallback(
    (stepType: StepTypeEnum) => {
      reset();
      dispatch({ type: 'SELECT_STEP_TYPE', stepType });
    },
    [dispatch, reset]
  );

  const handleTaskTypeSelect = useCallback(
    (taskType: TaskTypeEnum) => {
      reset();
      dispatch({ type: 'SELECT_TASK_TYPE', taskType });
    },
    [dispatch, reset]
  );

  const handleCertificationTypeSelect = useCallback(
    (certType: CertificationTypeEnum) => {
      reset();
      dispatch({ type: 'SELECT_CERTIFICATION_TYPE', certType });
    },
    [dispatch, reset]
  );

  const handleEventTypeSelect = useCallback(
    (eventType: EventTypeEnum) => {
      reset();
      dispatch({ type: 'SELECT_EVENT_TYPE', eventType });
    },
    [dispatch, reset]
  );

  const handleWorkflowSelect = useCallback(
    (workflowKey: string, name: string, isNew: boolean) => {
      dispatch({ type: 'SELECT_WORKFLOW', workflowKey, name, isNew });
    },
    [dispatch]
  );

  const handleCourseSelect = useCallback(
    (courseUid: string, courseName: string) => {
      dispatch({ type: 'SELECT_COURSE', courseUid, courseName });
    },
    [dispatch]
  );

  const handleToolboxSelect = useCallback(
    (item: ToolboxItem) => {
      if (item.type === 'stepType') {
        handleStepTypeSelect(item.data.code as StepTypeEnum);
      } else if (item.type === 'taskType') {
        handleTaskTypeSelect(item.data.code as TaskTypeEnum);
      } else if (item.type === 'certificationType') {
        handleCertificationTypeSelect(item.data.code as CertificationTypeEnum);
      } else if (item.type === 'eventType') {
        handleEventTypeSelect(item.data.code as EventTypeEnum);
      }
    },
    [handleStepTypeSelect, handleTaskTypeSelect, handleCertificationTypeSelect]
  );

  const handleSave = useCallback(
    async (itemConfig: WorkflowItemConfig) => {
      try {
        switch (itemConfig.type) {
          case 'step': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'STEP_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await processActions.appendStep(config);
            } else {
              await processActions.updateStep(config.stepKey, config);
            }
            break;
          }
          case 'task': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'TASK_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await taskActions.addTask(config);
            } else {
              await taskActions.updateTask(config.taskKey, config);
            }
            break;
          }
          case 'certification': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'CERTIFICATION_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await certificationActions.addCertification(config);
            } else {
              await certificationActions.updateCertification(
                config.certificationKey,
                config
              );
            }
            break;
          }
          case 'event': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'EVENT_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await processActions.addEvent(config);
            } else {
              await processActions.updateEvent(config.eventKey, config);
            }
            break;
          }
        }
        closeScreen();
      } catch (error) {
        console.error('Error saving configuration:', error);
      }
    },
    [
      state.currentScreen,
      closeScreen,
      processActions,
      taskActions,
      certificationActions,
    ]
  );

  const handleDelete = useCallback(
    async (key: string) => {
      try {
        const currentScreen = state.currentScreen;
        if (!currentScreen) return;

        if (currentScreen.type === 'TASK_CONFIG') {
          await taskActions.removeTask(key);
        } else if (currentScreen.type === 'CERTIFICATION_CONFIG') {
          await certificationActions.removeCertification(key);
        } else if (currentScreen.type === 'STEP_CONFIG') {
          await processActions.removeStep(key);
        } else if (currentScreen.type === 'EVENT_CONFIG') {
          await processActions.removeEvent(key);
        }
        closeScreen();
      } catch (error) {
        console.error('Error deleting configuration:', error);
      }
    },
    [
      state.currentScreen,
      taskActions,
      certificationActions,
      processActions,
      closeScreen,
    ]
  );

  return {
    state,
    dispatch,
    openScreen,
    openStepConfigScreen,
    openTaskConfigScreen,
    openToolbox,
    closeScreen,
    reset,
    screenHandlers: {
      onToolboxSelect: handleToolboxSelect,
      onWorkflowSelect: handleWorkflowSelect,
      onCourseSelect: handleCourseSelect,
      onSave: handleSave,
      onDelete: handleDelete,
      onClose: closeScreen,
    },
  };
};
