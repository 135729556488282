import { FieldConfig, TriggerSetConfig } from '@btrway/api-workflow';
import { Card, Stack } from '@mantine/core';
import React from 'react';
import { TriggerView } from '../TriggerView/TriggerView';
import styles from './TriggerSetView.module.css';

interface TriggerSetViewProps {
  triggerSet: TriggerSetConfig;
  availableFields: FieldConfig[];
  actions?: React.ReactNode;
}

export const TriggerSetView: React.FC<TriggerSetViewProps> = ({
  triggerSet,
  availableFields,
  actions,
}) => {
  return (
    <Card
      withBorder
      shadow="xs"
      padding="sm"
      radius="md"
      className={styles.viewCard}
    >
      <Stack gap="xs">
        {triggerSet.triggers?.map((trigger, index) => (
          <TriggerView
            key={trigger.triggerKey}
            trigger={trigger}
            availableFields={availableFields}
            isFirst={index === 0}
          />
        ))}
        {actions}
      </Stack>
    </Card>
  );
};
