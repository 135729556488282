import { WorkflowBuilderStateProvider } from '@btrway/workflow-builder-state';
import { useWorkflowService } from '@btrway/workflow-common-provider';
import { Box } from '@mantine/core';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ProcessBuilderLayout } from '../ProcessBuilderLayout/ProcessBuilderLayout';
import { ProcessPanelDragDrop } from '../ProcessPanelDragDrop/ProcessPanelDragDrop';
import { ProcessView } from '../ProcessView/ProcessView';

interface ProcessBuilderProps {
  workflowKey: string;
}

export const ProcessBuilder: React.FC<ProcessBuilderProps> = ({
  workflowKey,
}) => {
  const workflowService = useWorkflowService();
  const { data: workflow, isLoading } = workflowService.getByKey();

  if (isLoading || !workflow || !workflow.workflowConfiguration) {
    return null;
  }

  return (
    <Box
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <ProcessPanelDragDrop>
        <DndProvider backend={HTML5Backend}>
          <WorkflowBuilderStateProvider parentWorkflow={workflow}>
            <Box
              style={{
                flexGrow: 1,
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <ProcessBuilderLayout>
                <ProcessView workflow={workflow} />
              </ProcessBuilderLayout>
            </Box>
          </WorkflowBuilderStateProvider>
        </DndProvider>
      </ProcessPanelDragDrop>
    </Box>
  );
};
