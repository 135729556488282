import { TextFieldDataType, TextFieldProperties } from '@btrway/api-workflow';
import { EmailAddressInput, PhoneNumberInput } from '@btrway/shared-components';
import { Stack, Text, TextInput, Textarea } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface TextFieldProps extends BaseFieldProps<string> {}

export const TextField: React.FC<TextFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const textProperties = field.fieldProperties as TextFieldProperties;
  const useTextArea = textProperties?.multiLine ?? false;
  const readonly = isReadOnly(displayMode) || field.readOnly;

  // Local state for immediate UI updates
  const [localValue, setLocalValue] = useState(value || '');

  // Update local state when prop value changes
  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  // Debounced handler for updating parent state
  const debouncedOnChange = useDebouncedCallback((newValue: string | null) => {
    onChange?.(newValue);
  }, 300);

  // Handle immediate UI updates
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.currentTarget.value;
    setLocalValue(newValue); // Update local state immediately
    debouncedOnChange(newValue || null); // Debounce update to parent
  };

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value ? 'inherit' : 'dimmed'}>
          {value || textProperties?.placeholder || 'No value'}
        </Text>
      </Stack>
    );
  }

  const commonProps = {
    title: field.label,
    required: field.required,
    placeholder: textProperties?.placeholder,
    value: localValue, // Use local state for the input value
    readOnly: readonly,
  };

  if (useTextArea) {
    return (
      <Stack gap="xs">
        <Textarea
          {...commonProps}
          minRows={textProperties?.rows ?? 3}
          autosize
          maxRows={12}
          onChange={readonly ? undefined : handleChange}
        />
      </Stack>
    );
  }

  switch (textProperties?.dataType) {
    case TextFieldDataType.email:
      return (
        <Stack gap="xs">
          <EmailAddressInput
            {...commonProps}
            onEmailChange={(newValue) => {
              if (!readonly) {
                setLocalValue(newValue || '');
                debouncedOnChange(newValue);
              }
            }}
          />
        </Stack>
      );

    case TextFieldDataType.phone:
      return (
        <Stack gap="xs">
          <PhoneNumberInput
            {...commonProps}
            onPhoneChange={(newValue) => {
              if (!readonly) {
                setLocalValue(newValue || '');
                debouncedOnChange(newValue);
              }
            }}
          />
        </Stack>
      );

    default:
      return (
        <Stack gap="xs">
          <TextInput
            {...commonProps}
            onChange={readonly ? undefined : handleChange}
          />
        </Stack>
      );
  }
};

export default TextField;
