import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import { CertificationsTopSection } from '../../components/CertificationsTopSection/CertificationsTopSection';
import { WorkgroupTaskStatsView } from '../../components/WorkgroupTaskStatsView/WorkgroupTaskStatsView';
import styles from './StudentEligibilityPage.module.css';

const StudentEligibilityPage: React.FC = () => {
  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        <CertificationsTopSection
          workflowType={WorkflowTypeEnum.certification}
          taskListType="Student-Athlete Eligibility"
        />
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <WorkgroupTaskStatsView
                workflowType={WorkflowTypeEnum.certification}
                taskListType="Student-Athlete Eligibility"
              />
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </Stack>
  );
};

export default StudentEligibilityPage;
