// AddressFieldDisplay.tsx
import { AddressResponse } from '@btrway/api-core';
import {
  useCountries,
  useStateProvinces,
} from '@btrway/reference-data-manager';
import { Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';

interface AddressFieldDisplayProps {
  value?: AddressResponse;
}

export const AddressFieldDisplay: React.FC<AddressFieldDisplayProps> = ({
  value,
}) => {
  const { countries } = useCountries();
  const { stateProvinces } = useStateProvinces();

  // Lookup functions for state and country names
  const getCountryName = (code?: string) => {
    if (!code) return undefined;
    return countries.find((country) => country.code === code)?.name;
  };

  const getStateName = (code?: string) => {
    if (!code) return undefined;
    return stateProvinces.find((state) => state.code === code)?.name;
  };

  // Format address lines for display
  const formattedAddress = useMemo(() => {
    if (!value) return [];

    const lines: string[] = [];

    // Add street address lines
    if (value.street1) {
      lines.push(value.street1);
    }
    if (value.street2) {
      lines.push(value.street2);
    }

    // Build city, state, postal code line
    const cityLine: string[] = [];
    if (value.city) {
      cityLine.push(value.city);
    }

    const stateName = getStateName(value.stateProvinceCode);
    if (stateName) {
      cityLine.push(value.stateProvinceCode || ''); // Use code rather than name per requirements
    }

    if (value.postalCode) {
      cityLine.push(value.postalCode);
    }

    if (cityLine.length > 0) {
      lines.push(cityLine.join(', '));
    }

    // Add country name
    const countryName = getCountryName(value.countryCode);
    if (countryName) {
      lines.push(countryName);
    }

    return lines;
  }, [value, countries, stateProvinces]);

  if (!value || formattedAddress.length === 0) {
    return (
      <Stack gap="xs">
        <Text size="sm" c="dimmed">
          No address provided
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap={0}>
      {formattedAddress.map((line, index) => (
        <Text key={index} size="sm">
          {line}
        </Text>
      ))}
    </Stack>
  );
};
