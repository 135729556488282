import { useGetPersonApprovalTasks } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { taskInstancesAtom } from '../atoms/taskInstancesAtoms';
import { reconcileTaskInstances } from '../utils/reconcileTaskInstances';

interface UseApprovalTasksOptions {
  personId?: number;
}

export const useApprovalTasks = ({
  personId,
}: UseApprovalTasksOptions = {}) => {
  const setTasks = useSetAtom(taskInstancesAtom);
  const { currentPerson } = useAuthenticatedUser();

  const targetPersonId = personId || currentPerson.id;

  const query = useGetPersonApprovalTasks(targetPersonId);

  // Update the atom when we get new data
  useEffect(() => {
    if (query.data) {
      setTasks((prevTasks) => reconcileTaskInstances(prevTasks, query.data));
    }
  }, [query.data, setTasks]);

  return {
    approvalTasks: query.data ?? [],
    isLoading: query.isLoading,
    error: query.error as Error | null,
  };
};
