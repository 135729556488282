import { useEffect, useState } from 'react';
import { MeasurementSystem } from '../types/types';

interface UseMeasurementSystemProps {
  initialSystem: MeasurementSystem;
  imperialEnabled: boolean;
  metricEnabled: boolean;
  readOnly: boolean;
}

export const useMeasurementSystem = ({
  initialSystem,
  imperialEnabled,
  metricEnabled,
  readOnly,
}: UseMeasurementSystemProps) => {
  const [system, setSystem] = useState<MeasurementSystem>(initialSystem);

  useEffect(() => {
    if (!imperialEnabled && system === 'imperial' && metricEnabled) {
      setSystem('metric');
    } else if (!metricEnabled && system === 'metric' && imperialEnabled) {
      setSystem('imperial');
    }
  }, [imperialEnabled, metricEnabled]);

  const handleSystemChange = () => {
    if (readOnly) return;
    setSystem(system === 'imperial' ? 'metric' : 'imperial');
  };

  return {
    system,
    handleSystemChange,
  };
};
