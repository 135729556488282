import {
  TaskAssignmentConfig,
  TaskAssignmentTypeEnum,
} from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { useUserRoles } from '@btrway/security-manager';
import { Badge, Card, Group, Popover, Text } from '@mantine/core';
import {
  IconChevronDown,
  IconUserCircle,
  IconUsers,
  IconUserSearch,
  IconUserSquareRounded,
} from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { ApproverEditor } from '../ApproverEditor/ApproverEditor';

interface ApproverSelectProps {
  taskAssignmentConfig?: TaskAssignmentConfig;
  onConfigChange: (config: TaskAssignmentConfig, description: string) => void;
  primaryEntityDescription?: string;
}

export const ApproverSelect: React.FC<ApproverSelectProps> = ({
  taskAssignmentConfig,
  onConfigChange,
  primaryEntityDescription,
}) => {
  const [opened, setOpened] = useState(false);
  const { getUserRoleBySlug } = useUserRoles();

  const getAssignmentDescription = useCallback(
    (config?: TaskAssignmentConfig): React.ReactNode => {
      if (!config?.assignmentType) return 'Select Approver';

      switch (config.assignmentType) {
        case TaskAssignmentTypeEnum.primaryWorkflowPerson:
          return (
            <Badge size="lg" color="blue">
              {primaryEntityDescription || 'Primary Entity'}
            </Badge>
          );
        case TaskAssignmentTypeEnum.userRole:
          const roleSlug = config.userRoleSlug?.value;
          const role = roleSlug ? getUserRoleBySlug(roleSlug) : undefined;
          return role ? `${role.name}` : 'Unknown Role';
        case TaskAssignmentTypeEnum.person:
          if (config.personId?.reference) {
            return `${
              config.personId.reference.label ||
              config.personId.reference.propertyKey
            }`;
          } else if (config.personId?.value) {
            return (
              <Group gap="xs" wrap="nowrap">
                <Text>Person:</Text>
                <EntityTag
                  dataType="person"
                  id={config.personId.value}
                  allowClick={false}
                />
              </Group>
            );
          }
          return 'Select Person';
        default:
          return 'Select Approver';
      }
    },
    [getUserRoleBySlug, primaryEntityDescription]
  );

  const getAssignmentIcon = (config?: TaskAssignmentConfig) => {
    if (!config?.assignmentType) return <IconUserSquareRounded size={20} />;

    switch (config.assignmentType) {
      case TaskAssignmentTypeEnum.primaryWorkflowPerson:
        return <IconUserCircle size={16} />;
      case TaskAssignmentTypeEnum.userRole:
        return <IconUsers size={16} />;
      case TaskAssignmentTypeEnum.person:
        return <IconUserSearch size={16} />;
      default:
        return <IconUserSquareRounded size={16} />;
    }
  };

  const handleClick = () => {
    setOpened(true);
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      width={400}
      shadow="md"
    >
      <Popover.Target>
        <Card
          shadow="none"
          miw={300}
          px="xs"
          py={5}
          radius="sm"
          withBorder
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: 'fit-content',
            borderColor: 'var(--mantine-color-gray-4)',
          }}
        >
          <Group gap="xs" justify="space-between" wrap="nowrap">
            <Group gap="xs" wrap="nowrap">
              {getAssignmentIcon(taskAssignmentConfig)}
              <Text size="sm">
                {getAssignmentDescription(taskAssignmentConfig)}
              </Text>
            </Group>
            <IconChevronDown size={16} />
          </Group>
        </Card>
      </Popover.Target>

      <Popover.Dropdown>
        <ApproverEditor
          taskAssignmentConfig={taskAssignmentConfig}
          onConfigChange={(config, description) => {
            onConfigChange(config, description);
            setOpened(false);
          }}
          onClose={() => setOpened(false)}
          primaryEntityDescription={primaryEntityDescription}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
