import {
  CertificationTaskProperties,
  FormConfig,
  StepConfig,
  StepTypeEnum,
  StepTypeTypeEnum,
  TaskListTaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { FormBuilder } from '@btrway/form-builder';
import { useStepTypes } from '@btrway/workflow-manager';
import { Draggable } from '@hello-pangea/dnd';
import { Button, Card, Group } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconEdit,
  IconWand,
} from '@tabler/icons-react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { CertificationDetail } from '../CertificationDetail/CertificationDetail';
import { StepConfigCardIcon } from '../StepConfigCardIcon/StepConfigCardIcon';
import { StepConfigConnector } from '../StepConfigConnector/StepConfigConnector';
import { StepConfigContent } from '../StepConfigContent/StepConfigContent';
import { TaskListDetail } from '../TaskListDetail/TaskListDetail';

interface StepConfigCardProps {
  workflowKey: string;
  step: StepConfig;
  index: number;
  isLast: boolean;
  isSelected: boolean;
  onSelect: (stepKey: string) => void;
  onSave?: (stepKey: string, formConfig: FormConfig) => void;
}

const PREVIEW_HEIGHT = 350;
const BUTTON_HEIGHT = 50;
const CONTENT_HEIGHT = PREVIEW_HEIGHT - BUTTON_HEIGHT;

const StepConfigCard: React.FC<StepConfigCardProps> = ({
  workflowKey,
  step,
  index,
  isLast,
  isSelected,
  onSelect,
  onSave,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [nestedWorkflowKey, setNestedWorkflowKey] = useState('');
  const [showFormBuilder, setShowFormBuilder] = useState(false);

  const { getStepType } = useStepTypes();

  const stepType = getStepType(step?.stepTypeCode as StepTypeEnum);
  const isAutomatedStep = stepType?.type === StepTypeTypeEnum.action;

  const showTaskListEditor =
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType === TaskTypeEnum.completeTaskList;
  const showCertificationEditor =
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType === TaskTypeEnum.certification;
  const isWorkflowStepForm =
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType === TaskTypeEnum.completeWorkflowStep;

  useEffect(() => {
    if (showTaskListEditor && step?.taskConfig?.taskProperties) {
      const taskListProperties = step.taskConfig
        .taskProperties as TaskListTaskProperties;
      setNestedWorkflowKey(
        taskListProperties.workflowSelection.workflowDefinitionKey?.value || ''
      );
    } else if (showCertificationEditor && step?.taskConfig?.taskProperties) {
      const certificationProperties = step.taskConfig
        .taskProperties as CertificationTaskProperties;
      setNestedWorkflowKey(
        certificationProperties.workflowSelection.workflowDefinitionKey
          ?.value || ''
      );
    }
  }, [step, showTaskListEditor, showCertificationEditor]);

  const hasNestedContent =
    showTaskListEditor || showCertificationEditor || isWorkflowStepForm;

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
    if (isWorkflowStepForm) {
      setShowFormBuilder(!isExpanded);
    }
  };

  const handleClick = () => {
    // setSelectedItem({ type: 'step', key: step.stepKey });
    onSelect(step.stepKey);
  };

  const handleFormChange = (config: FormConfig) => {
    // Handle form changes if needed
  };

  const handleFormSave = (config: FormConfig) => {
    if (onSave) {
      onSave(step.stepKey, config);
    }
  };

  const renderFormBuilder = () => {
    if (!isWorkflowStepForm || !isExpanded) return null;

    if (isSelected && showFormBuilder) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <FormBuilder
            initialConfig={step.formConfig}
            formTitle={step.title}
            onChange={handleFormChange}
            onSave={handleFormSave}
            readOnly={false}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          position: 'relative',
          height: PREVIEW_HEIGHT,
          overflow: 'hidden',
        }}
      >
        <div style={{ height: CONTENT_HEIGHT, overflow: 'hidden' }}>
          <FormBuilder
            initialConfig={step.formConfig}
            formTitle={step.title}
            readOnly={true}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: BUTTON_HEIGHT,
            left: 0,
            right: 0,
            height: BUTTON_HEIGHT,
            background:
              'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 100%)',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: BUTTON_HEIGHT,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <Button
            variant="outline"
            size="lg"
            leftSection={<IconEdit size={16} />}
            onClick={() => setShowFormBuilder(true)}
          >
            Edit Form Fields
          </Button>
        </div>
      </div>
    );
  };

  const renderNestedContent = () => {
    if (!isExpanded) return null;

    // if (isWorkflowStepForm) {
    //   return renderFormBuilder();
    // }

    return (
      <>
        {showTaskListEditor && (
          <TaskListDetail workflowKey={nestedWorkflowKey} />
        )}
        {showCertificationEditor && (
          <CertificationDetail workflowKey={nestedWorkflowKey} />
        )}
      </>
    );
  };

  return (
    <Draggable draggableId={step.stepKey} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            width: '100%',
            ...provided.draggableProps.style,
          }}
          onClick={handleClick}
        >
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <Card
              p="md"
              radius="md"
              withBorder
              shadow="xs"
              style={{
                width: '100%',
                marginBottom: 0,
                opacity: snapshot.isDragging ? 0.5 : 1,
                borderColor: isSelected
                  ? 'var(--mantine-color-blue-5)'
                  : undefined,
                borderWidth: isSelected ? 2 : 1,
                backgroundColor: isAutomatedStep ? '#F2E9FE' : undefined,
                cursor: 'pointer',
              }}
            >
              <Group justify="space-between" gap="xs" wrap="nowrap">
                <Group
                  gap="xs"
                  wrap="nowrap"
                  justify="flex-start"
                  align="flex-start"
                >
                  <StepConfigCardIcon stepKey={step.stepKey} />
                  <StepConfigContent
                    step={step}
                    workflowKey={workflowKey}
                    isAutomatedStep={isAutomatedStep}
                    stepType={stepType}
                  />
                  {hasNestedContent && (
                    <div
                      onClick={handleExpandClick}
                      style={{ cursor: 'pointer', display: 'flex' }}
                    >
                      {isExpanded ? (
                        <IconChevronDown size={20} />
                      ) : (
                        <IconChevronRight size={20} />
                      )}
                    </div>
                  )}
                </Group>
                {isAutomatedStep && <IconWand size={24} color="#8C65D3" />}
              </Group>

              {renderNestedContent()}
            </Card>
            {!isLast && !snapshot.isDragging && !isSelected && (
              <StepConfigConnector />
            )}
          </motion.div>
        </div>
      )}
    </Draggable>
  );
};

export default StepConfigCard;
