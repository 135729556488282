import { FieldTypeEnum } from '@btrway/api-workflow';
import { usePropertyReferences } from '@btrway/property-reference-manager';
import { FlyoutAnimationContext } from '@btrway/shared-components';
import {
  Box,
  CloseButton,
  Collapse,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconSearch,
} from '@tabler/icons-react';
import { motion } from 'framer-motion';
import React, { useContext, useMemo, useState } from 'react';
import { fieldGroups } from '../../utils/toolbox';
import { ConnectedToolboxItem } from '../ConnectedToolboxItem/ConnectedToolboxItem';
import FormToolboxItem from '../FormToolboxItem/FormToolboxItem';

interface FormToolboxProps {
  stepKey: string;
  onFieldSelect: (type: keyof typeof FieldTypeEnum) => void;
}

export const FormToolbox: React.FC<FormToolboxProps> = ({
  stepKey,
  onFieldSelect,
}) => {
  const { isOpen } = useContext(FlyoutAnimationContext);
  const { aboutEntityProperties } = usePropertyReferences(stepKey || '');
  const [searchText, setSearchText] = useState('');
  const [isConnectedExpanded, setIsConnectedExpanded] = useState(false);

  const filteredData = useMemo(() => {
    const searchLower = searchText.toLowerCase();
    const filteredConnected = aboutEntityProperties.filter((prop) =>
      prop.label.toLowerCase().includes(searchLower)
    );

    const filteredGroups = fieldGroups
      .map((group) => ({
        ...group,
        fields: group.fields.filter((fieldType) => {
          const label =
            fieldType.charAt(0).toUpperCase() +
            fieldType.slice(1).replace(/([A-Z])/g, ' $1');
          return label.toLowerCase().includes(searchLower);
        }),
      }))
      .filter((group) => group.fields.length > 0);

    return {
      connectedFields: filteredConnected,
      regularGroups: filteredGroups,
    };
  }, [searchText, aboutEntityProperties]);

  React.useEffect(() => {
    if (filteredData.connectedFields.length > 0 && searchText) {
      setIsConnectedExpanded(true);
    }
  }, [searchText, filteredData.connectedFields.length]);

  const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Search input - now outside of animations */}
      <Box px="md" py={0}>
        <TextInput
          placeholder="Search for fields to add..."
          value={searchText}
          onChange={(event) => setSearchText(event.currentTarget.value)}
          leftSection={<IconSearch size={16} />}
          rightSection={
            searchText ? (
              <CloseButton onClick={() => setSearchText('')} size="sm" />
            ) : null
          }
          autoFocus
        />
      </Box>

      {/* Scrollable animated content */}
      <Box style={{ flex: 1, overflowY: 'auto' }}>
        <motion.div
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
          transition={{
            staggerChildren: 0.03,
            delayChildren: 0.2,
          }}
        >
          <Stack gap="xl" p="md">
            {/* Connected Fields Section */}
            {aboutEntityProperties.length > 0 && (
              <Stack gap="xs">
                <Box
                  onClick={() => setIsConnectedExpanded(!isConnectedExpanded)}
                  style={{ cursor: 'pointer' }}
                >
                  <Group wrap="nowrap">
                    <Text fw={700}>Connected Fields</Text>
                    {isConnectedExpanded ? (
                      <IconChevronDown size={20} />
                    ) : (
                      <IconChevronRight size={20} />
                    )}
                  </Group>
                </Box>
                <Collapse in={isConnectedExpanded}>
                  <Stack gap="xs">
                    {filteredData.connectedFields.map((property) => (
                      <Box
                        key={`${property.propertyKey}-${property.entityProperty}`}
                      >
                        <ConnectedToolboxItem property={property} />
                      </Box>
                    ))}
                  </Stack>
                </Collapse>
              </Stack>
            )}

            {/* Regular Field Groups */}
            {filteredData.regularGroups.map((group) => (
              <Stack key={group.title} gap="xs">
                <motion.div variants={itemVariants}>
                  <Text fw={700}>{group.title}</Text>
                </motion.div>
                <Stack gap="xs">
                  {group.fields.map((fieldType) => (
                    <motion.div key={fieldType} variants={itemVariants}>
                      <FormToolboxItem
                        type={fieldType}
                        onSelect={() => onFieldSelect(fieldType)}
                      />
                    </motion.div>
                  ))}
                </Stack>
              </Stack>
            ))}

            {/* No Results Message */}
            {searchText &&
              filteredData.regularGroups.length === 0 &&
              filteredData.connectedFields.length === 0 && (
                <motion.div variants={itemVariants}>
                  <Text c="dimmed" ta="center" py="xl">
                    No matching fields found
                  </Text>
                </motion.div>
              )}
          </Stack>
        </motion.div>
      </Box>
    </Box>
  );
};

export default FormToolbox;
