// src/lib/components/EntityFilterSelector.tsx
import { useGetEventTypesByOrganizationId } from '@btrway/api-calendar';
import { DataTypeEnum } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/security-manager';
import { useWorkgroupTypes } from '@btrway/workgroup-manager';
import { Chip, Group, Stack } from '@mantine/core';
import React from 'react';

interface EntityFilterSelectorProps {
  entityType: DataTypeEnum;
  selectedFilters?: string[];
  onChange: (values: string[]) => void;
}

export const EntityFilterSelector: React.FC<EntityFilterSelectorProps> = ({
  entityType,
  selectedFilters = [],
  onChange,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { userRoles } = useUserRoles();
  const { data: eventTypes } = useGetEventTypesByOrganizationId(
    currentOrganization.id
  );
  const { workgroupTypes } = useWorkgroupTypes(currentOrganization.id);

  const renderChips = () => {
    switch (entityType) {
      case DataTypeEnum.person:
        return userRoles?.map((role) => (
          <Chip key={role.slug} value={role.slug}>
            {role.name}
          </Chip>
        ));

      case DataTypeEnum.calendarEvent:
        return eventTypes
          ?.filter((et) => et.systemType !== 'availability')
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((eventType) => (
            <Chip
              key={eventType.slug}
              value={eventType.slug}
              style={
                eventType.primaryColor
                  ? {
                      backgroundColor: `${eventType.primaryColor}20`,
                      borderColor: eventType.primaryColor,
                    }
                  : undefined
              }
            >
              {eventType.name}
            </Chip>
          ));

      case DataTypeEnum.workgroup:
        return workgroupTypes?.map((type) => (
          <Chip key={type.slug} value={type.slug}>
            {type.name}
          </Chip>
        ));

      default:
        return null;
    }
  };

  return (
    <Stack>
      <Chip.Group multiple value={selectedFilters} onChange={onChange}>
        <Group>{renderChips()}</Group>
      </Chip.Group>
    </Stack>
  );
};
