import {
  DataTypeEnum,
  FieldTypeEnum,
  RuleOperatorTypeEnum,
} from '@btrway/api-workflow';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useMemo } from 'react';
import type { OperatorOption } from '../types/operator';
import {
  getAvailableOperators,
  getOperatorMetadata,
} from '../utils/operatorUtils';

interface UseOperatorPickerProps {
  selectedOperator?: RuleOperatorTypeEnum;
  dataType?: DataTypeEnum;
  fieldType?: FieldTypeEnum;
  onOperatorSelect: (operator: RuleOperatorTypeEnum) => void;
}

interface UseOperatorPickerReturn {
  opened: boolean;
  open: () => void;
  close: () => void;
  operatorOptions: OperatorOption[];
  selectedOperatorOption?: OperatorOption;
  handleOperatorSelect: (operator: RuleOperatorTypeEnum) => void;
}

export const useOperatorPicker = ({
  selectedOperator,
  dataType,
  fieldType,
  onOperatorSelect,
}: UseOperatorPickerProps): UseOperatorPickerReturn => {
  const [opened, { open, close }] = useDisclosure(false);

  // Get available operators and convert to options
  const operatorOptions = useMemo(() => {
    const operators = getAvailableOperators(dataType, fieldType);
    return operators.map((operator) => ({
      value: operator,
      label: getOperatorMetadata(operator).label,
    }));
  }, [dataType, fieldType]);

  // Find the currently selected operator option
  const selectedOperatorOption = useMemo(
    () => operatorOptions.find((op) => op.value === selectedOperator),
    [operatorOptions, selectedOperator]
  );

  // Auto-select first operator if none selected and options available
  useEffect(() => {
    if (operatorOptions.length > 0 && !selectedOperator) {
      onOperatorSelect(operatorOptions[0].value);
    }
  }, [operatorOptions, selectedOperator, onOperatorSelect]);

  const handleOperatorSelect = (operator: RuleOperatorTypeEnum) => {
    onOperatorSelect(operator);
    close();
  };

  return {
    opened,
    open,
    close,
    operatorOptions,
    selectedOperatorOption,
    handleOperatorSelect,
  };
};
