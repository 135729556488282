import {
  FormCompletionProvider,
  useFormCompletion,
} from '@btrway/form-completion-manager';
import { FormRenderer, FormRendererRef } from '@btrway/form-renderer';
import { useStyledModal } from '@btrway/styled-modal';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useFormReview } from '../../hooks/useFormReview';
import WorkflowInstanceView from '../WorkflowInstanceView/WorkflowInstanceView';
import styles from './FormReview.module.css';

interface FormReviewProps {
  taskInstanceId: number;
  isOpen: boolean;
  onClose: () => void;
  onTaskComplete?: (taskInstanceId: number) => void;
}

const FormReviewInner: React.FC<FormReviewProps> = ({
  taskInstanceId,
  isOpen,
  onClose,
  onTaskComplete,
}) => {
  const { setModalTitle } = useStyledModal();
  const { taskInstance, currentStep, isLoading, submitReview } =
    useFormReview(taskInstanceId);
  const { formValues } = useFormCompletion();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const formRef = useRef<FormRendererRef>(null);

  useEffect(() => {
    setModalTitle(
      <Text fz="xl" fw={700}>
        {taskInstance?.workflowInstance?.workflowDefinition?.name || 'Review'}
      </Text>
    );

    return () => {
      setModalTitle(undefined);
    };
  }, [taskInstance?.workflowInstance?.workflowDefinition?.name, setModalTitle]);

  const handleCancel = () => {
    onClose();
  };

  const validateForm = () => {
    if (!currentStep?.formConfig?.fields) return true;
    return formRef.current?.validate() ?? false;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setError('Please fill out all required fields before submitting.');
      const firstErrorElement = document.querySelector(
        '[aria-invalid="true"]'
      ) as HTMLElement;
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const result = await submitReview(formValues);
      if (result) {
        if (onTaskComplete) {
          onTaskComplete(taskInstanceId);
        } else {
          onClose();
        }
      } else {
        setError('Failed to submit review. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      setError('Failed to submit review. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading || !taskInstance || !currentStep) {
    return null;
  }

  const hasForm = !!currentStep.formConfig;

  return (
    <Stack style={{ width: '100%', height: '100%' }} gap={0}>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        {hasForm && currentStep.formConfig ? (
          <div
            className={styles.split}
            style={{ display: 'flex', width: '100%' }}
          >
            <div style={{ flex: 1, height: '100%', overflow: 'auto' }}>
              {taskInstance.workflowInstance && (
                <WorkflowInstanceView taskInstance={taskInstance} />
              )}
            </div>
            <div style={{ flex: 1, height: '100%', overflow: 'auto' }}>
              <Box p="xl">
                <FormRenderer
                  ref={formRef}
                  formConfig={currentStep.formConfig}
                />
              </Box>
            </div>
          </div>
        ) : (
          <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            {taskInstance.workflowInstance && (
              <WorkflowInstanceView taskInstance={taskInstance} />
            )}
          </div>
        )}
      </Box>
      {error && (
        <Text color="red" style={{ textAlign: 'center', padding: '10px' }}>
          {error}
        </Text>
      )}
      <Group justify="flex-end" gap="md" p="md">
        <Button onClick={handleCancel} size="lg" variant="outline">
          <Text fz={24} fw={600}>
            Cancel
          </Text>
        </Button>
        <Button
          onClick={handleSubmit}
          color="green.8"
          size="lg"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          <Text fz={24} fw={600}>
            Submit
          </Text>
        </Button>
      </Group>
    </Stack>
  );
};

const FormReview: React.FC<FormReviewProps> = (props) => {
  return (
    <FormCompletionProvider>
      <FormReviewInner {...props} />
    </FormCompletionProvider>
  );
};

export default FormReview;
