import { useEffect, useState } from 'react';
import { KG_TO_LB, LB_TO_KG } from '../types/constants';
import { MeasurementSystem, WeightData } from '../types/types';

interface UseWeightProps {
  value?: WeightData;
  system: MeasurementSystem;
  onChange?: (data: WeightData) => void;
  decimalPlaces: number;
}

export const useWeight = ({
  value,
  system,
  onChange,
  decimalPlaces,
}: UseWeightProps) => {
  const [pounds, setPounds] = useState<number | undefined>(
    value?.imperial.pounds
  );
  const [kilograms, setKilograms] = useState<number | undefined>(
    value?.metric.kilograms
  );

  useEffect(() => {
    if (value) {
      setPounds(value.imperial.pounds);
      setKilograms(value.metric.kilograms);
    }
  }, [value]);

  const calculateValues = (
    value: number | undefined,
    newSystem?: MeasurementSystem
  ) => {
    const currentSystem = newSystem || system;

    if (value === undefined) {
      setKilograms(undefined);
      setPounds(undefined);

      if (onChange) {
        onChange({
          system: currentSystem,
          imperial: { pounds: 0 },
          metric: { kilograms: 0 },
        });
      }
      return;
    }

    if (currentSystem === 'imperial') {
      const calculatedKg = +(value * LB_TO_KG).toFixed(decimalPlaces);
      setKilograms(calculatedKg);
      setPounds(+value.toFixed(decimalPlaces));

      if (onChange) {
        onChange({
          system: currentSystem,
          imperial: {
            pounds: +value.toFixed(decimalPlaces),
          },
          metric: {
            kilograms: calculatedKg,
          },
        });
      }
    } else {
      const calculatedLb = +(value * KG_TO_LB).toFixed(decimalPlaces);
      setKilograms(+value.toFixed(decimalPlaces));
      setPounds(calculatedLb);

      if (onChange) {
        onChange({
          system: currentSystem,
          imperial: {
            pounds: calculatedLb,
          },
          metric: {
            kilograms: +value.toFixed(decimalPlaces),
          },
        });
      }
    }
  };

  return {
    pounds,
    setPounds,
    kilograms,
    setKilograms,
    calculateValues,
  };
};
