import {
  DataTypeEnum,
  GlobalSearchResponse,
  useSearchGlobal,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  Combobox,
  Input,
  Pill,
  PillsInput,
  Stack,
  useCombobox,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { filterTopResults } from '../../utils/similarity';
import { SearchResultOption } from '../SearchResultOption/SearchResultOption';

interface GlobalSearchTagInputProps {
  onSelect: (result: GlobalSearchResponse) => void;
  entityTypes?: DataTypeEnum[];
  filterByWorkgroupIds?: number[];
  filterByUserRoleIds?: number[];
  includeChildWorkgroups?: boolean;
  includeChildUserRoles?: boolean;
  clearOnSelect?: boolean;
  debounceMs?: number;
  minimumScore?: number;
  limit?: number;
  onlyTopResults?: boolean;
  label?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const GlobalSearchTagInput: React.FC<GlobalSearchTagInputProps> = ({
  onSelect,
  entityTypes,
  filterByWorkgroupIds,
  filterByUserRoleIds,
  includeChildWorkgroups = true,
  includeChildUserRoles = true,
  clearOnSelect = true,
  debounceMs = 300,
  minimumScore,
  limit,
  onlyTopResults = false,
  label,
  description,
  placeholder = 'Search...',
  required,
  size = 'md',
}) => {
  const [selectedItems, setSelectedItems] = useState<GlobalSearchResponse[]>(
    []
  );
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, debounceMs);
  const [searchResults, setSearchResults] = useState<GlobalSearchResponse[]>(
    []
  );
  const lastRequestRef = useRef<string>('');

  const { currentOrganization, rootWorkgroupId } = useAuthenticatedUser();
  const { mutate: search } = useSearchGlobal();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  // Search effect
  useEffect(() => {
    if (!debouncedSearchValue || !currentOrganization) {
      setSearchResults([]);
      return;
    }

    const searchRequest = {
      organizationId: currentOrganization.id,
      searchValue: debouncedSearchValue,
      filterByWorkgroupIds: filterByWorkgroupIds ?? [rootWorkgroupId],
      ...(filterByUserRoleIds && { filterByUserRoleIds }),
      ...(entityTypes && { filterByEntityTypes: entityTypes }),
      ...(includeChildWorkgroups !== undefined && { includeChildWorkgroups }),
      ...(includeChildUserRoles !== undefined && { includeChildUserRoles }),
      ...(minimumScore !== undefined && { minimumScore }),
      ...(limit !== undefined && { limit }),
    };

    const requestSignature = JSON.stringify(searchRequest);
    if (requestSignature === lastRequestRef.current) {
      return;
    }

    lastRequestRef.current = requestSignature;

    search(
      { data: searchRequest },
      {
        onSuccess: (data) => {
          const results = data as GlobalSearchResponse[];
          const finalResults = onlyTopResults
            ? filterTopResults(results)
            : results;
          setSearchResults(finalResults);
        },
        onError: (error) => {
          console.error('Search failed:', error);
          lastRequestRef.current = '';
          setSearchResults([]);
        },
      }
    );
  }, [
    debouncedSearchValue,
    currentOrganization,
    search,
    entityTypes,
    filterByWorkgroupIds,
    filterByUserRoleIds,
    includeChildWorkgroups,
    includeChildUserRoles,
    rootWorkgroupId,
    minimumScore,
    limit,
    onlyTopResults,
  ]);

  // Effect to manage dropdown visibility
  useEffect(() => {
    if (searchResults.length > 0 && searchValue) {
      combobox.openDropdown();
    } else {
      combobox.closeDropdown();
    }
  }, [searchResults.length, searchValue, combobox]);

  const handleValueRemove = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const handleOptionSubmit = useCallback(
    (value: string) => {
      const [entityType, entityId] = value.split(':');
      const selected = searchResults.find(
        (item) =>
          item.entityType === entityType &&
          item.entityId === parseInt(entityId, 10)
      );

      if (selected) {
        setSelectedItems([selected]);
        onSelect(selected);

        if (clearOnSelect) {
          setSearchValue('');
          combobox.closeDropdown();
        }
      }
    },
    [searchResults, onSelect, clearOnSelect, combobox]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      combobox.updateSelectedOptionIndex();
      setSearchValue(event.currentTarget.value);
    },
    [combobox]
  );

  const values = selectedItems.map((item) => (
    <Pill
      key={`${item.entityType}:${item.entityId}`}
      withRemoveButton
      onRemove={handleValueRemove}
      size={size}
    >
      {item.displayName}
    </Pill>
  ));

  const options = searchResults.map((result) => (
    <Combobox.Option
      key={`${result.entityType}:${result.entityId}`}
      value={`${result.entityType}:${result.entityId}`}
    >
      <SearchResultOption result={result} showBadge showSimilarity={false} />
    </Combobox.Option>
  ));

  return (
    <Stack gap="xs">
      {label && <Input.Label required={required}>{label}</Input.Label>}
      {description && <Input.Description>{description}</Input.Description>}
      <Combobox store={combobox} onOptionSubmit={handleOptionSubmit}>
        <Combobox.DropdownTarget>
          <PillsInput
            rightSection={
              <IconSearch
                size={
                  size === 'sm'
                    ? 14
                    : size === 'md'
                    ? 16
                    : size === 'lg'
                    ? 18
                    : 20
                }
              />
            }
            size={size}
            radius={size === 'sm' ? 'sm' : size === 'md' ? 'md' : 'lg'}
          >
            <Pill.Group>
              {values}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  placeholder={selectedItems.length === 0 ? placeholder : ''}
                  value={searchValue}
                  onChange={handleSearchChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && searchValue.length === 0) {
                      event.preventDefault();
                      handleValueRemove();
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>
            {options.length > 0 ? (
              options
            ) : searchValue ? (
              <Combobox.Empty>No results found</Combobox.Empty>
            ) : null}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Stack>
  );
};

export default GlobalSearchTagInput;
