import {
  FieldConfig,
  VideoFieldProperties,
  VideoFieldSourceType,
} from '@btrway/api-workflow';
import { UrlTextInput } from '@btrway/shared-components';
import { MuxVideo } from '@btrway/video-components';
import { NumberInput, SegmentedControl, Stack } from '@mantine/core';
import React from 'react';

interface VideoFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<VideoFieldProperties>) => void;
}

const defaultVideoProperties: VideoFieldProperties = {
  sourceType: VideoFieldSourceType.url,
  videoUrl: '',
  playbackIdentifier: undefined,
  playerHeight: undefined,
};

export const VideoFieldSettings: React.FC<VideoFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const videoProps = {
    ...defaultVideoProperties,
    ...(field.fieldProperties as VideoFieldProperties),
  };

  const handleSourceTypeChange = (value: string) => {
    // Convert string value to VideoFieldSourceType
    const sourceType = value as VideoFieldSourceType;
    onChange({
      ...videoProps,
      sourceType,
      // Clear the other field type when switching
      ...(sourceType === VideoFieldSourceType.url
        ? { playbackIdentifier: undefined }
        : { videoUrl: undefined }),
    });
  };

  const handleUrlChange = (url: string | null) => {
    onChange({
      ...videoProps,
      videoUrl: url || undefined,
    });
  };

  const handleVideoUpdate = (
    newPlaybackIdentifier: string,
    duration: number
  ) => {
    onChange({
      ...videoProps,
      playbackIdentifier: newPlaybackIdentifier,
    });
  };

  const handleHeightChange = (value: string | number) => {
    // Convert empty string to undefined, keep numbers as is
    const height = value === '' ? undefined : Number(value);
    onChange({
      ...videoProps,
      playerHeight: height,
    });
  };

  return (
    <Stack gap="md">
      <SegmentedControl
        value={videoProps.sourceType || VideoFieldSourceType.url}
        onChange={handleSourceTypeChange}
        data={[
          { label: 'URL', value: VideoFieldSourceType.url },
          { label: 'Upload', value: VideoFieldSourceType.upload },
        ]}
      />

      {videoProps.sourceType === VideoFieldSourceType.url ? (
        <UrlTextInput
          label="Video URL"
          placeholder="Enter video URL"
          value={videoProps.videoUrl || ''}
          onUrlChange={handleUrlChange}
        />
      ) : (
        <MuxVideo
          playbackIdentifier={videoProps.playbackIdentifier}
          duration={undefined}
          onVideoUpdate={handleVideoUpdate}
        />
      )}

      <NumberInput
        label="Player Height"
        description="Height in pixels (leave empty for default)"
        placeholder="e.g., 360"
        value={videoProps.playerHeight || ''}
        onChange={handleHeightChange}
        min={100}
        max={1080}
        step={10}
        hideControls={false}
      />
    </Stack>
  );
};

// Type guard to check if field is a video field
export const isVideoFieldSettings = (field: FieldConfig): boolean => {
  return field.type === 'video';
};
