import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const ToolboxPortal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    // Create or find the portal container
    let container = document.getElementById('toolbox-portal-container');
    if (!container) {
      container = document.createElement('div');
      container.id = 'toolbox-portal-container';
      // Position it absolutely in relation to the viewport
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.zIndex = '1000';
      document.body.appendChild(container);
    }
    setPortalContainer(container);

    // Cleanup on unmount
    return () => {
      if (container && container.parentElement) {
        container.parentElement.removeChild(container);
      }
    };
  }, []);

  return portalContainer ? createPortal(children, portalContainer) : null;
};
