import { Stack, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface TimeFieldProps extends BaseFieldProps<string> {}

export const TimeField: React.FC<TimeFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;

  // Format time for display
  const formatTime = (timeString: string | null): string => {
    if (!timeString) return '';
    return dayjs(`2000-01-01 ${timeString}`).format('hh:mm A');
  };

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={value ? 'inherit' : 'dimmed'}>
          {value ? formatTime(value) : 'No time selected'}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap="xs">
      <TimeInput
        required={field.required}
        disabled={readonly}
        value={value || ''}
        onChange={(event) =>
          !readonly && onChange?.(event.currentTarget.value || null)
        }
      />
    </Stack>
  );
};

export default TimeField;
