import {
  Card,
  Checkbox,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

interface LaunchCheckboxCardProps {
  title: string;
  description: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  variant?: 'sm' | 'lg';
}

export const LaunchCheckboxCard: React.FC<LaunchCheckboxCardProps> = ({
  title,
  description,
  checked,
  onChange,
  variant = 'lg',
}) => {
  const theme = useMantineTheme();

  const handleClick = () => {
    onChange(!checked);
  };

  return (
    <Card
      py="sm"
      px="lg"
      radius="md"
      withBorder
      style={{
        cursor: 'pointer',
        borderColor: checked ? theme.colors[theme.primaryColor][6] : undefined,
        borderWidth: checked ? 2 : 1,
      }}
      onClick={handleClick}
    >
      <Stack>
        <Group align="flex-start" wrap="nowrap">
          <Checkbox
            checked={checked}
            onChange={(event) => {
              event.stopPropagation();
              onChange(event.currentTarget.checked);
            }}
          />
          <Stack gap={4}>
            <Text fw={500}>{title}</Text>
            {variant === 'lg' && (
              <Text size="sm" c="dimmed">
                {description}
              </Text>
            )}
          </Stack>
        </Group>
      </Stack>
    </Card>
  );
};
