import { UserRoleResponse } from '@btrway/api-security';
import {
  HierarchicalNode,
  HierarchicalSelector,
} from '@btrway/hierarchical-selector';
import { useUserRoles } from '@btrway/security-manager';

export interface UserRoleSelectionData {
  roleIds: number[];
  roleSlugs: string[];
  description?: string;
  additionalData?: Map<number, boolean>;
}

export interface UserRoleHierarchicalSelectorProps {
  selectedRoleSlugs?: string[];
  selectedRoleIds?: number[];
  initialAdditionalData?: Map<number, boolean>;
  onChange: (selection: UserRoleSelectionData) => void;
  renderAdditionalContent?: (props: {
    node: HierarchicalNode<UserRoleResponse>;
    isSelected: boolean;
    onAdditionalDataChange: (data: boolean) => void;
  }) => React.ReactNode;
  allowMultiple?: boolean;
}

export const UserRoleHierarchicalSelector = ({
  selectedRoleSlugs,
  selectedRoleIds,
  initialAdditionalData,
  onChange,
  renderAdditionalContent,
  allowMultiple = true,
}: UserRoleHierarchicalSelectorProps) => {
  const { userRoles } = useUserRoles();

  // Function to get additional IDs from slugs if needed
  const getAdditionalIdsFromSlugs = (items: UserRoleResponse[]) => {
    if (!selectedRoleSlugs) return [];
    return items
      .filter((role) => selectedRoleSlugs.includes(role.slug))
      .map((role) => role.id);
  };

  // Handle changes from the generic selector
  const handleChange = (selection: {
    ids: number[];
    additionalData?: Map<number, boolean>;
  }) => {
    const selectedRoles =
      userRoles?.filter((role) => selection.ids.includes(role.id)) || [];

    // Get description if only one role is selected
    const description =
      selectedRoles.length === 1 ? selectedRoles[0].name : undefined;

    onChange({
      roleIds: selection.ids,
      roleSlugs: selectedRoles.map((role) => role.slug),
      description,
      additionalData: selection.additionalData,
    });
  };

  return (
    <HierarchicalSelector<UserRoleResponse, boolean>
      items={userRoles || []}
      selectedIds={selectedRoleIds}
      getAdditionalIds={
        selectedRoleSlugs ? getAdditionalIdsFromSlugs : undefined
      }
      onChange={handleChange}
      renderAdditionalContent={renderAdditionalContent}
      initialAdditionalData={initialAdditionalData}
      allowMultiple={allowMultiple}
    />
  );
};
