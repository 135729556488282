import { useAuthenticatedUser } from '@btrway/current-user';
import { Provider as JotaiProvider } from 'jotai';
import { ReactNode } from 'react';

interface JotaiCurrentPersonWrapperProps {
  children: ReactNode;
}

const JotaiCurrentPersonWrapper = ({
  children,
}: JotaiCurrentPersonWrapperProps) => {
  const { currentPerson } = useAuthenticatedUser();

  return <JotaiProvider key={currentPerson.id}>{children}</JotaiProvider>;
};

export default JotaiCurrentPersonWrapper;
