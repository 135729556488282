import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Point } from '../../types/signatureTypes';
import styles from './SignatureCanvas.module.css';

interface SignatureCanvasProps {
  signatureHeight: number;
  onBegin?: () => void;
  disabled?: boolean;
}

interface SignatureCanvasRef {
  clear: () => void;
  isEmpty: () => boolean;
  getTrimmedCanvas: () => HTMLCanvasElement;
  toData: () => Point[][];
}

interface SignatureCanvasComponentProps {
  canvasProps?: React.CanvasHTMLAttributes<HTMLCanvasElement>;
  backgroundColor?: string;
}

const SignatureCanvasComponent =
  ReactSignatureCanvas as unknown as React.ComponentType<
    SignatureCanvasComponentProps & { ref: React.RefObject<SignatureCanvasRef> }
  >;

export const SignatureCanvas = forwardRef<
  SignatureCanvasRef,
  SignatureCanvasProps
>(({ signatureHeight, onBegin, disabled }, ref) => {
  const sigPadRef = useRef<SignatureCanvasRef>(null);

  useImperativeHandle(ref, () => ({
    clear: () => sigPadRef.current?.clear(),
    isEmpty: () => sigPadRef.current?.isEmpty() ?? true,
    getTrimmedCanvas: () =>
      sigPadRef.current?.getTrimmedCanvas() as HTMLCanvasElement,
    toData: () => sigPadRef.current?.toData() as Point[][],
  }));

  const handleBegin = (event: any) => {
    if (!disabled && onBegin) {
      onBegin();
    }
    if (disabled) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <SignatureCanvasComponent
      ref={sigPadRef}
      canvasProps={{
        className: `${styles.signatureCanvas} ${
          disabled ? styles.disabled : ''
        }`,
        style: { height: signatureHeight },
        onMouseDown: handleBegin,
        onTouchStart: handleBegin,
      }}
      backgroundColor="white"
    />
  );
});

SignatureCanvas.displayName = 'SignatureCanvas';
