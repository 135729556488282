import { Group, SimpleGrid, Stack, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import { ENTITY_TYPE_CONFIGS } from '../../config/entityTypes';
import { EntityType } from '../../types/state';
import { EntityTypeCard } from '../EntityTypeCard/EntityTypeCard';

interface EntityTypeSelectorProps {
  selectedType?: EntityType;
  onSelect: (type: EntityType) => void;
  hideUnselected?: boolean;
  variant?: 'sm' | 'lg';
  heading?: string | React.ReactNode;
}

export const EntityTypeSelector: React.FC<EntityTypeSelectorProps> = ({
  selectedType,
  onSelect,
  hideUnselected = false,
  variant = 'lg',
  heading,
}) => {
  const renderCards = () => {
    if (hideUnselected && selectedType) {
      const selectedConfig = ENTITY_TYPE_CONFIGS.find(
        (config) => config.type === selectedType
      );
      if (selectedType === 'nothing') {
        return (
          <EntityTypeCard
            isNothingCard
            selected={true}
            onClick={() => {}}
            variant={variant}
          />
        );
      } else if (selectedConfig) {
        return (
          <EntityTypeCard
            config={selectedConfig}
            selected={true}
            onClick={() => {}}
            variant={variant}
          />
        );
      }
      return null;
    }

    const cards = (
      <>
        {ENTITY_TYPE_CONFIGS.map((config) => (
          <EntityTypeCard
            key={config.type}
            config={config}
            selected={selectedType === config.type}
            onClick={() => onSelect(config.type)}
            variant={variant}
          />
        ))}
        <EntityTypeCard
          isNothingCard
          selected={selectedType === 'nothing'}
          onClick={() => onSelect('nothing')}
          variant={variant}
        />
      </>
    );

    return variant === 'sm' ? (
      <Stack gap="md">{cards}</Stack>
    ) : (
      <SimpleGrid cols={3} spacing="md">
        {cards}
      </SimpleGrid>
    );
  };

  return (
    <Stack gap="md">
      {(heading || selectedType) && (
        <Group justify="flex-start" gap="lg" align="center">
          {heading && (
            <Text size="lg" fw={500}>
              {heading}
            </Text>
          )}
          {selectedType && (
            <UnstyledButton onClick={() => onSelect(null)} c="blue" fz="sm">
              Change type
            </UnstyledButton>
          )}
        </Group>
      )}
      {renderCards()}
    </Stack>
  );
};
