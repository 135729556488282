import { EntityTaskResponse, TaskInstanceResponse } from '@btrway/api-workflow';
import { TaskIdentifierProps } from '../types/TaskIdentifierProps';

type TaskResponse = TaskInstanceResponse | EntityTaskResponse;

export const getStableTaskIdentifier = (
  taskOrProps: Partial<TaskResponse> | TaskIdentifierProps
): string => {
  // Type guard to check if the object has taskInstanceId
  const hasTaskInstanceId = (
    obj: typeof taskOrProps
  ): obj is { taskInstanceId: number } =>
    'taskInstanceId' in obj &&
    typeof obj.taskInstanceId === 'number' &&
    obj.taskInstanceId > 0;

  // If we have a taskInstanceId, use that as the most stable identifier
  if (hasTaskInstanceId(taskOrProps)) {
    return `instance.${taskOrProps.taskInstanceId}`;
  }

  // Type guard to check if object has all required properties for a derived key
  const hasRequiredProps = (
    obj: typeof taskOrProps
  ): obj is {
    assignedEntityType: string;
    assignedEntityId: number;
    taskKey: string;
  } =>
    'assignedEntityType' in obj &&
    'assignedEntityId' in obj &&
    'taskKey' in obj &&
    typeof obj.assignedEntityType === 'string' &&
    typeof obj.assignedEntityId === 'number' &&
    typeof obj.taskKey === 'string';

  if (!hasRequiredProps(taskOrProps)) {
    throw new Error(
      'Either taskInstanceId or all of (assignedEntityType, assignedEntityId, taskKey) must be provided'
    );
  }

  return `derived.${taskOrProps.assignedEntityType}.${taskOrProps.assignedEntityId}.${taskOrProps.taskKey}`;
};

/**
 * Type guard to check if a task response is a partial EntityTaskResponse
 */
export const isEntityTaskResponse = (
  task: Partial<TaskResponse>
): task is Partial<EntityTaskResponse> => {
  return 'derivedWorkflowKey' in task;
};

/**
 * Type guard to check if a task response is a partial TaskInstanceResponse
 */
export const isTaskInstanceResponse = (
  task: Partial<TaskResponse>
): task is Partial<TaskInstanceResponse> => {
  return 'id' in task;
};

/**
 * Helper to create a task identifier from individual parts
 */
export const createTaskIdentifier = (
  entityType: string,
  entityId: number,
  taskKey: string
): string => {
  return `derived.${entityType}.${entityId}.${taskKey}`;
};

/**
 * Helper to extract identifier props from a task response
 */
export const getTaskIdentifierProps = (
  task: Partial<TaskResponse>
): TaskIdentifierProps => {
  if (isTaskInstanceResponse(task)) {
    return {
      taskInstanceId: task.id,
      assignedEntityType: task.assignedEntityType,
      assignedEntityId: task.assignedEntityId,
      taskKey: task.taskKey,
    };
  }
  return {
    taskInstanceId: task.taskInstanceId,
    assignedEntityType: task.assignedEntityType,
    assignedEntityId: task.assignedEntityId,
    taskKey: task.taskKey,
  };
};

/**
 * Helper to check if two tasks represent the same logical task
 */
export const isSameTask = (
  task1: Partial<TaskResponse>,
  task2: Partial<TaskResponse>
): boolean => {
  try {
    const id1 = getStableTaskIdentifier(task1);
    const id2 = getStableTaskIdentifier(task2);
    return id1 === id2;
  } catch {
    return false;
  }
};
