/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TextFieldDataType = typeof TextFieldDataType[keyof typeof TextFieldDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextFieldDataType = {
  email: 'email',
  phone: 'phone',
} as const;
