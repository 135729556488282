import {
  CertificationTypeEnum,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { ConfigFactory } from '@btrway/workflow-configuration-manager';
import { Action } from '../types/actions';
import { DisplayConfig } from '../types/display';
import { State } from '../types/state';
import {
  getDefaultDisplayConfig,
  getWorkflowTypeForCertification,
  getWorkflowTypeForTask,
} from '../utils/screenUtils';

const defaultDisplayConfig: DisplayConfig = {
  mode: 'panel',
  position: 'left',
  width: 400,
};

export const initialState: State = {
  currentScreen: null,
  displayState: {
    isOpen: false,
    content: null,
    config: defaultDisplayConfig,
  },
  parentWorkflow: null,
};

export interface ReducerDependencies {
  configFactory: ConfigFactory;
}

export function createWorkflowBuilderReducer(deps: ReducerDependencies) {
  return function workflowBuilderReducer(state: State, action: Action): State {
    const { configFactory } = deps;

    switch (action.type) {
      case 'OPEN_SCREEN':
        return {
          ...state,
          currentScreen: action.screen,
          displayState: {
            isOpen: true,
            content: null,
            config: action.config || getDefaultDisplayConfig(action.screen),
          },
        };

      case 'CLOSE_SCREEN':
        return {
          ...state,
          currentScreen: null,
          displayState: {
            ...state.displayState,
            isOpen: false,
          },
        };

      case 'SET_PARENT_WORKFLOW':
        return {
          ...state,
          parentWorkflow: action.workflow,
        };

      case 'RESET':
        return {
          ...initialState,
          parentWorkflow: state.parentWorkflow,
        };

      case 'SELECT_STEP_TYPE': {
        const newStep = configFactory.createStepConfig('step', action.stepType);
        newStep.stepKey = uuid();

        return {
          ...state,
          currentScreen: {
            type: 'STEP_CONFIG',
            data: {
              config: newStep,
              workflow: state.parentWorkflow!,
              isNew: true,
            },
          },
          displayState: {
            isOpen: true,
            content: null,
            config: {
              mode: 'panel',
              position: 'left',
              width: 400,
            },
          },
        };
      }

      case 'SELECT_TASK_TYPE': {
        const isFormOrAutomation =
          state.parentWorkflow?.workflowType === WorkflowTypeEnum.form ||
          state.parentWorkflow?.workflowType === WorkflowTypeEnum.automation;

        if (isFormOrAutomation) {
          const stepConfig = configFactory.createStepConfig(
            'task',
            action.taskType
          );
          return {
            ...state,
            currentScreen: {
              type: 'STEP_CONFIG',
              data: {
                config: stepConfig,
                workflow: state.parentWorkflow!,
                isNew: true,
              },
            },
            displayState: {
              isOpen: true,
              content: null,
              config: { mode: 'panel', position: 'left', width: 400 },
            },
          };
        }

        switch (action.taskType) {
          case TaskTypeEnum.thirdParty:
          case TaskTypeEnum.sendEmail: {
            const config = configFactory.createTaskConfig(action.taskType);
            config.taskKey = uuid();
            return {
              ...state,
              currentScreen: {
                type: 'TASK_CONFIG',
                data: {
                  config,
                  workflow: state.parentWorkflow!,
                  isNew: true,
                },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left', width: 400 },
              },
            };
          }

          case TaskTypeEnum.completeCourse:
            return {
              ...state,
              currentScreen: {
                type: 'COURSE_SELECTOR',
                data: { taskType: action.taskType },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left' },
              },
            };

          default: {
            const workflowType = getWorkflowTypeForTask(action.taskType);
            if (workflowType) {
              return {
                ...state,
                currentScreen: {
                  type: 'WORKFLOW_SELECTOR',
                  data: {
                    taskType: action.taskType,
                    workflowType,
                    workflows: [], // Note: workflows should be managed separately
                    selectedWorkflowKey: undefined,
                  },
                },
                displayState: {
                  isOpen: true,
                  content: null,
                  config: { mode: 'panel', position: 'left' },
                },
              };
            } else {
              const config = configFactory.createTaskConfig(action.taskType);
              config.taskKey = uuid();
              return {
                ...state,
                currentScreen: {
                  type: 'TASK_CONFIG',
                  data: {
                    config,
                    workflow: state.parentWorkflow!,
                    isNew: true,
                  },
                },
                displayState: {
                  isOpen: true,
                  content: null,
                  config: { mode: 'panel', position: 'left', width: 400 },
                },
              };
            }
          }
        }
      }

      case 'SELECT_CERTIFICATION_TYPE': {
        switch (action.certType) {
          case CertificationTypeEnum.completeCurriculum:
          case CertificationTypeEnum.submitForm:
          case CertificationTypeEnum.completeTaskOption:
          case CertificationTypeEnum.completeTaskList:
          case CertificationTypeEnum.certification: {
            const workflowType = getWorkflowTypeForCertification(
              action.certType
            );
            return {
              ...state,
              currentScreen: {
                type: 'WORKFLOW_SELECTOR',
                data: {
                  taskType: action.certType as unknown as TaskTypeEnum,
                  workflowType: workflowType!,
                  workflows: [], // Note: workflows should be managed separately
                  selectedWorkflowKey: undefined,
                },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left' },
              },
            };
          }

          case CertificationTypeEnum.completeCourse:
            return {
              ...state,
              currentScreen: {
                type: 'COURSE_SELECTOR',
                data: { taskType: TaskTypeEnum.completeCourse },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left' },
              },
            };

          default: {
            const newConfig = configFactory.createCertificationConfig(
              action.certType
            );
            return {
              ...state,
              currentScreen: {
                type: 'CERTIFICATION_CONFIG',
                data: {
                  config: newConfig,
                  workflow: state.parentWorkflow!,
                  isNew: true,
                },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left', width: 400 },
              },
            };
          }
        }
      }

      case 'SELECT_EVENT_TYPE': {
        const eventConfig = configFactory.createEventConfig(action.eventType);
        eventConfig.eventKey = uuid();

        return {
          ...state,
          currentScreen: {
            type: 'EVENT_CONFIG',
            data: {
              config: eventConfig,
              workflow: state.parentWorkflow!,
              isNew: true,
            },
          },
          displayState: {
            isOpen: true,
            content: null,
            config: { mode: 'panel', position: 'left', width: 400 },
          },
        };
      }

      case 'SELECT_WORKFLOW': {
        const currentScreen = state.currentScreen;
        if (!currentScreen || currentScreen.type !== 'WORKFLOW_SELECTOR') {
          return state;
        }

        const { taskType } = currentScreen.data;

        // Handle special cases for curriculum and form packet
        if (action.isNew) {
          if (taskType === TaskTypeEnum.completeCurriculum) {
            return {
              ...state,
              currentScreen: {
                type: 'CURRICULUM_EDITOR',
                data: {
                  initialSelectedTasks: [],
                  workflowKey: action.workflowKey,
                  workflowName: action.name,
                  showTaskConfigAfterSave: true,
                },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left', width: 400 },
              },
            };
          }

          if (taskType === TaskTypeEnum.completeFormPacket) {
            return {
              ...state,
              currentScreen: {
                type: 'FORM_PACKET_EDITOR',
                data: {
                  initialSelectedTasks: [],
                  workflowKey: action.workflowKey,
                  workflowName: action.name,
                  showTaskConfigAfterSave: true,
                },
              },
              displayState: {
                isOpen: true,
                content: null,
                config: { mode: 'panel', position: 'left', width: 400 },
              },
            };
          }
        }

        const isCertificationWorkflow =
          state.parentWorkflow?.workflowType === WorkflowTypeEnum.certification;

        if (isCertificationWorkflow) {
          const certType = taskType as unknown as CertificationTypeEnum;
          const newConfig = configFactory.createWorkflowCertificationConfig(
            action.workflowKey,
            action.name,
            certType
          );
          return {
            ...state,
            currentScreen: {
              type: 'CERTIFICATION_CONFIG',
              data: {
                config: newConfig,
                workflow: state.parentWorkflow!,
                isNew: true,
              },
            },
            displayState: {
              isOpen: true,
              content: null,
              config: { mode: 'panel', position: 'left', width: 400 },
            },
          };
        }

        const newConfig = configFactory.createWorkflowTaskConfig(
          action.workflowKey,
          action.name,
          taskType
        );
        return {
          ...state,
          currentScreen: {
            type: 'TASK_CONFIG',
            data: {
              config: newConfig,
              workflow: state.parentWorkflow!,
              isNew: true,
            },
          },
          displayState: {
            isOpen: true,
            content: null,
            config: { mode: 'panel', position: 'left', width: 400 },
          },
        };
      }

      case 'SELECT_COURSE': {
        const currentScreen = state.currentScreen;
        if (!currentScreen || currentScreen.type !== 'COURSE_SELECTOR') {
          return state;
        }

        const { taskType } = currentScreen.data;
        const isCertificationWorkflow =
          state.parentWorkflow?.workflowType === WorkflowTypeEnum.certification;

        if (isCertificationWorkflow) {
          const newConfig = configFactory.createCourseCertificationConfig(
            action.courseUid,
            action.courseName
          );
          return {
            ...state,
            currentScreen: {
              type: 'CERTIFICATION_CONFIG',
              data: {
                config: newConfig,
                workflow: state.parentWorkflow!,
                isNew: true,
              },
            },
            displayState: {
              isOpen: true,
              content: null,
              config: { mode: 'panel', position: 'left', width: 400 },
            },
          };
        }

        const newConfig = configFactory.createCourseTaskConfig(
          action.courseUid,
          action.courseName
        );
        return {
          ...state,
          currentScreen: {
            type: 'TASK_CONFIG',
            data: {
              config: newConfig,
              workflow: state.parentWorkflow!,
              isNew: true,
            },
          },
          displayState: {
            isOpen: true,
            content: null,
            config: { mode: 'panel', position: 'left', width: 400 },
          },
        };
      }

      default:
        return state;
    }
  };
}
