import {
  getGetEventTypesByOrganizationIdQueryKey,
  useGetEventTypesByOrganizationId,
} from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback, useMemo } from 'react';

export const useCalendarEventTypes = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const organizationId = currentOrganization.id;

  const {
    data: eventTypes,
    isLoading,
    error,
    refetch,
  } = useGetEventTypesByOrganizationId(organizationId, {
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetEventTypesByOrganizationIdQueryKey(organizationId),
    },
  });

  const getEventTypeById = useCallback(
    (eventTypeId: number) => {
      return eventTypes?.find((eventType) => eventType.id === eventTypeId);
    },
    [eventTypes]
  );

  const getEventTypeBySlug = useCallback(
    (eventTypeSlug: string) => {
      return eventTypes?.find((eventType) => eventType.slug === eventTypeSlug);
    },
    [eventTypes]
  );

  const sortedEventTypes = useMemo(() => {
    return [...(eventTypes || [])].sort((a, b) => a.name.localeCompare(b.name));
  }, [eventTypes]);

  return {
    eventTypes: sortedEventTypes,
    isLoading,
    error,
    getEventTypeById,
    getEventTypeBySlug,
    refetchEventTypes: refetch,
  };
};
