import { Container, ScrollArea } from '@mantine/core';
import React from 'react';
import TaskListView from '../TaskListView/TaskListView';

interface TaskListBuilderProps {
  workflowKey: string;
}

const TaskListBuilder: React.FC<TaskListBuilderProps> = ({ workflowKey }) => {
  return (
    <ScrollArea style={{ height: '100%', width: '100%' }}>
      <Container size="md" px="md" style={{ maxWidth: 800 }}>
        <TaskListView workflowKey={workflowKey} nestedTaskList={false} />
      </Container>
    </ScrollArea>
  );
};

export default TaskListBuilder;
