import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import {
  CommonWorkflow,
  useWorkflowService,
  WorkflowCommonProvider,
  WorkflowServiceProvider,
  WorkflowSourceType,
} from '@btrway/workflow-common-provider';
import { WorkflowConfigProvider } from '@btrway/workflow-configuration-manager';
import { useDisclosure } from '@mantine/hooks';
import React, { useCallback } from 'react';
import { WorkflowBuilderComponent } from '../../components/WorkflowBuilderComponent/WorkflowBuilderComponent';
import { WorkflowBuilderLayout } from '../../components/WorkflowBuilderLayout/WorkflowBuilderLayout';

interface WorkflowBuilderPageProps {
  sourceType: WorkflowSourceType;
  workflowKey: string;
}

const WorkflowBuilderContent: React.FC<{ workflowKey: string }> = ({
  workflowKey,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const workflowService = useWorkflowService();
  const { data: workflow, isLoading } = workflowService.getByKey();

  const handleMetadataSave = useCallback(
    async (updatedWorkflow: CommonWorkflow) => {
      if (!workflow) return;
      await workflowService.updateWorkflow(updatedWorkflow);
      close();
    },
    [workflow, workflowService, close]
  );

  if (isLoading) {
    return <div>Loading workflow...</div>;
  }

  if (!workflow) {
    return <div>No workflow found</div>;
  }

  return (
    <RightSectionProvider>
      <SettingsDisplayProvider>
        <WorkflowConfigProvider initialConfig={workflow.workflowConfiguration}>
          <WorkflowBuilderLayout
            workflow={workflow}
            workflowKey={workflowKey}
            isOpen={opened}
            onClose={close}
            onSave={handleMetadataSave}
            onEditClick={open}
          >
            <WorkflowBuilderComponent
              workflowKey={workflowKey}
              workflowType={workflow.workflowType}
            />
          </WorkflowBuilderLayout>
        </WorkflowConfigProvider>
      </SettingsDisplayProvider>
    </RightSectionProvider>
  );
};

const WorkflowBuilderPage: React.FC<WorkflowBuilderPageProps> = ({
  sourceType,
  workflowKey,
}) => {
  return (
    <WorkflowCommonProvider sourceType={sourceType}>
      <WorkflowServiceProvider workflowKey={workflowKey}>
        <WorkflowBuilderContent workflowKey={workflowKey} />
      </WorkflowServiceProvider>
    </WorkflowCommonProvider>
  );
};

export default WorkflowBuilderPage;
