import { FieldConfig, ParagraphFieldProperties } from '@btrway/api-workflow';
import {
  ContentEditor,
  ContentRenderer,
  EditorContent,
} from '@btrway/content-editor';
import { Stack, Text } from '@mantine/core';
import React, { MouseEvent, useCallback } from 'react';
import { BaseFieldProps, DisplayMode } from '../../types/baseField';

interface ParagraphFieldProps
  extends BaseFieldProps<never, Partial<FieldConfig>> {}

export const ParagraphField: React.FC<ParagraphFieldProps> = ({
  field,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const typedFieldProperties =
    field.fieldProperties as ParagraphFieldProperties;
  const paragraphContent = typedFieldProperties?.value || {};
  const isEmpty = Object.keys(paragraphContent).length === 0;

  const handleContentChange = useCallback(
    (content: EditorContent, html: string) => {
      if (displayMode === DisplayMode.Builder && onChange) {
        onChange({
          fieldProperties: {
            value: content,
          } as ParagraphFieldProperties,
        });
      }
    },
    [onChange, displayMode]
  );

  const handleEditorClick = useCallback(
    (e: MouseEvent) => {
      // Only stop propagation in builder mode
      if (displayMode === DisplayMode.Builder) {
        e.stopPropagation();
      }
    },
    [displayMode]
  );

  // Show content editor only in Builder mode
  if (displayMode === DisplayMode.Builder) {
    return (
      <Stack gap="xs">
        <div onClick={handleEditorClick}>
          <ContentEditor
            initialContent={paragraphContent}
            onChange={handleContentChange}
            editor={undefined}
          />
        </div>
      </Stack>
    );
  }

  // For all other modes, use ContentRenderer
  return (
    <Stack gap="xs">
      {isEmpty ? (
        <Text size="sm" c="dimmed">
          No content added
        </Text>
      ) : (
        <div onClick={handleEditorClick}>
          <ContentRenderer content={JSON.stringify(paragraphContent)} />
        </div>
      )}
    </Stack>
  );
};

export default ParagraphField;
