import { Box, Text } from '@mantine/core';
import React, { forwardRef, useImperativeHandle } from 'react';
import styles from './TypedSignature.module.css';

interface TypedSignatureProps {
  name: string;
  height: number;
  fontSize?: string;
}

export interface TypedSignatureRef {
  toDataURL: () => string;
}

export const TypedSignature = forwardRef<
  TypedSignatureRef,
  TypedSignatureProps
>(({ name, height, fontSize }, ref) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const convertToImage = (): string => {
    if (!containerRef.current) return '';

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return '';

    // Set canvas size to match container
    const containerRect = containerRef.current.getBoundingClientRect();
    canvas.width = containerRect.width;
    canvas.height = containerRect.height;

    // Set white background
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Configure text style
    context.fillStyle = 'black';
    context.font = `${fontSize || '4rem'} "Dancing Script"`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // Draw text centered
    context.fillText(name, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL('image/png');
  };

  useImperativeHandle(ref, () => ({
    toDataURL: convertToImage,
  }));

  return (
    <Box
      ref={containerRef}
      h={height}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text className={styles.signatureText} style={{ userSelect: 'none' }}>
        {name}
      </Text>
    </Box>
  );
});

TypedSignature.displayName = 'TypedSignature';
