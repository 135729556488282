// FieldLabel.tsx
import { Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconAsterisk } from '@tabler/icons-react';
import React from 'react';

interface FieldLabelProps {
  label: string;
  description?: string;
  required?: boolean;
  readOnly?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({
  label,
  description,
  required = false,
  readOnly = false,
}) => {
  return (
    <Stack gap={0}>
      <Group gap={4} wrap="nowrap">
        <Text size="md" fw={600} style={{ wordBreak: 'break-word' }}>
          {label}
        </Text>
        {required && !readOnly && (
          <Tooltip label="This is required" position="top" withArrow>
            <ThemeIcon
              variant="white"
              color="red"
              size="sm"
              style={{ marginTop: '2px' }}
            >
              <IconAsterisk
                style={{
                  width: '70%',
                  height: '70%',
                }}
              />
            </ThemeIcon>
          </Tooltip>
        )}
      </Group>
      {description && (
        <Text size="sm" fw={500} c="dimmed">
          {description}
        </Text>
      )}
    </Stack>
  );
};

export default FieldLabel;
