import { CertificationConfig } from '@btrway/api-workflow';
import {
  useWorkflowBuilderState,
  WorkflowBuilderStateProvider,
} from '@btrway/workflow-builder-state';
import {
  CommonWorkflow,
  useWorkflowService,
  WorkflowServiceContext,
  WorkflowServiceProvider,
} from '@btrway/workflow-common-provider';
import { WorkflowConfigProvider } from '@btrway/workflow-configuration-manager';
import { ActionIcon, Center, Group, Stack, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import CertificationCard from '../CertificationCard/CertificationCard';
import CertificationEvaluationButton from '../CertificationEvaluationButton/CertificationEvaluationButton';

interface CertificationListViewInnerProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  nestedList: boolean;
}

const CertificationListViewInner: React.FC<CertificationListViewInnerProps> = ({
  workflowKey,
  workflow,
  nestedList = false,
}) => {
  const [localCertifications, setLocalCertifications] = useState<
    CertificationConfig[]
  >([]);
  const { openScreen, openToolbox } = useWorkflowBuilderState();
  const workflowService = useWorkflowService();

  useEffect(() => {
    if (!workflow) return;

    const workflowCertifications =
      workflow.workflowConfiguration?.certifications ?? [];
    setLocalCertifications(workflowCertifications);
  }, [workflow]);

  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow?.workflowType) return;

    openToolbox(workflow, 'certification');
  };

  const handleCertificationClick = (certification: CertificationConfig) => {
    if (!workflow) return;

    console.log('handleCertificationClick', nestedList);

    openScreen({
      type: 'CERTIFICATION_CONFIG',
      data: {
        config: certification,
        workflow,
        isNew: false,
      },
    });
  };

  if (!workflow) {
    return <Center>No workflow found</Center>;
  }

  return (
    <Center>
      <Stack gap="xs" align="stretch" p="md" w="100%">
        <Group justify="flex-end" gap="xs">
          <CertificationEvaluationButton workflowKey={workflowKey} />
          <Tooltip label="Add New Requirement">
            <ActionIcon
              variant="light"
              color="blue"
              size="md"
              radius="xl"
              onClick={handleAddClick}
            >
              <IconPlus size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>

        <Stack gap="lg">
          {localCertifications.map((certification, index) => (
            <CertificationCard
              key={certification.certificationKey}
              workflowKey={workflowKey}
              certificationConfig={certification}
              index={index}
              isLast={index === localCertifications.length - 1}
              onCertificationSelected={(certificationKey) => {
                const cert = localCertifications.find(
                  (c) => c.certificationKey === certificationKey
                );
                if (cert) handleCertificationClick(cert);
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Center>
  );
};

interface CertificationListViewProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  nestedList?: boolean;
}

const CertificationListView: React.FC<CertificationListViewProps> = ({
  workflowKey,
  workflow,
  nestedList = false,
}) => {
  return (
    <WorkflowServiceProvider workflowKey={workflowKey}>
      <WorkflowServiceContext.Consumer>
        {(service) => {
          if (!service) return null;
          const { data: workflow, isLoading } = service.getByKey();

          if (!workflow || isLoading) {
            return null;
          }
          return (
            <WorkflowConfigProvider
              initialConfig={workflow.workflowConfiguration}
            >
              <WorkflowBuilderStateProvider parentWorkflow={workflow}>
                <CertificationListViewInner
                  workflowKey={workflowKey}
                  workflow={workflow}
                  nestedList={nestedList}
                />
              </WorkflowBuilderStateProvider>
            </WorkflowConfigProvider>
          );
        }}
      </WorkflowServiceContext.Consumer>
    </WorkflowServiceProvider>
  );
};

export default CertificationListView;
