import { UpdatePersonRequest, savePerson } from '@btrway/api-core'; // Import these from your API module
import { StorageUsageEnum } from '@btrway/api-storage';
import { useAuth } from '@btrway/auth-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { EditableImage } from '@btrway/file-storage-components';
import { HeaderLayout } from '@btrway/layout-components';
import { useUserRoles } from '@btrway/security-manager';
import { Button, Card, Space, Stack, Text } from '@mantine/core';
// import InternalPasswordResetModal from '@web/auth/pages/PasswordReset/InternalPasswordResetModal';
import React, { useState } from 'react';

const MyProfilePage: React.FC = () => {
  const {
    currentPerson,
    currentUser,
    currentOrganization,
    workgroupMembers,
    refetchCurrentUser,
  } = useAuthenticatedUser();
  const { setPassword } = useAuth();
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);
  const { getUserRoleById } = useUserRoles();

  const handleImageUpload = async (imageKey: string) => {
    if (currentPerson && currentOrganization) {
      const personRequest: UpdatePersonRequest = {
        id: currentPerson.id,
        organizationId: currentOrganization.id,
        email: currentPerson.email,
        headshot: imageKey,
        birthDate: currentPerson.birthDate,
        externalUserId: currentPerson.externalUser?.id,
        firstName: currentPerson.firstName,
        homePhone: currentPerson.homePhone,
        lastName: currentPerson.lastName,
        mobilePhone: currentPerson.mobilePhone,
        workPhone: currentPerson.workPhone,
      };

      try {
        // Save the updated person data
        await savePerson(personRequest);

        // Refetch the user data to get the updated information
        await refetchCurrentUser();
      } catch (error) {
        console.error('Error saving person data:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handleButtonClick = () => {
    setIsPasswordResetModalOpen((o) => !o);
  };

  const handlePasswordReset = async (newPassword: string) => {
    const setPasswordBody = {
      token: '',
      password: newPassword,
    };

    try {
      const response = await setPassword(setPasswordBody);
      if (response.success) {
        console.log('Password successfully changed');
      } else {
        console.error('Unable to change password right now');
      }
    } catch (error) {
      console.error('Attempt to change password failed: ', error);
    }
  };

  return (
    <HeaderLayout
      header={`${currentUser?.user.firstName} ${currentUser?.user.lastName}`}
      subtitleComponent={
        <Text fz={14} c="gray">
          Member of {currentOrganization.name}
        </Text>
      }
    >
      <Space h={20} />
      <Card shadow="sm" padding="lg" radius="md" w={250}>
        <EditableImage
          storageUsage={StorageUsageEnum.headshot}
          initialImageKey={currentPerson.headshot}
          onImageUpload={handleImageUpload}
          height={150}
          editable={true}
          circular={true}
          editButtonLabel="Update Photo"
          modalTitle="Upload Profile Photo"
          aspectRatio={{ width: 1, height: 1 }} // This enforces square cropping
        />
      </Card>
      <Space h={20} />
      {workgroupMembers && workgroupMembers.length > 0 && (
        <Card shadow="sm" padding="lg" radius="md" w={250}>
          <Text fz={20} mb="xs">
            Workgroup Membership
          </Text>
          <Stack gap="xs">
            {workgroupMembers.map((member) => (
              <Text key={member.id}>
                {member.workgroup.name} -{' '}
                {getUserRoleById(member.userRoleId)?.name}
              </Text>
            ))}
          </Stack>
        </Card>
      )}

      <Space h={20} />
      <Card shadow="sm" padding="lg" radius="md" w={250}>
        <Button onClick={handleButtonClick}>Change Password</Button>
        {/* <InternalPasswordResetModal
          isOpen={isPasswordResetModalOpen}
          onClose={() => setIsPasswordResetModalOpen(false)}
          onSubmit={handlePasswordReset}
        /> */}
      </Card>
    </HeaderLayout>
  );
};

export default MyProfilePage;
