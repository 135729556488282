import {
  FieldConfig,
  NumberFieldProperties,
  NumberFieldType,
} from '@btrway/api-workflow';
import {
  Chip,
  Group,
  NumberInput,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

interface NumberFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<NumberFieldProperties>) => void;
}

const defaultNumberProperties: NumberFieldProperties = {
  numberType: NumberFieldType.decimal,
  allowNegative: true,
  decimalPlaces: 2,
  minValue: undefined,
  maxValue: undefined,
};

export const NumberFieldSettings: React.FC<NumberFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const numberProps = {
    ...defaultNumberProperties,
    ...(field.fieldProperties as NumberFieldProperties),
  };

  const handleChange = (key: keyof NumberFieldProperties, value: any) => {
    onChange({
      ...numberProps,
      [key]: value,
    });
  };

  // Set sensible defaults based on number type
  React.useEffect(() => {
    if (numberProps.numberType === NumberFieldType.integer) {
      handleChange('decimalPlaces', 0);
    } else if (numberProps.numberType === NumberFieldType.currency) {
      handleChange('decimalPlaces', 2);
    }
  }, [numberProps.numberType]);

  return (
    <Stack gap="md">
      <Text fz="sm" fw={600}>
        Number Type
      </Text>
      <Group wrap="nowrap" gap="xs">
        <Chip.Group
          value={numberProps.numberType}
          onChange={(value) => handleChange('numberType', value)}
        >
          <Group gap="xs">
            <Tooltip
              label="Numbers with decimal places"
              refProp="rootRef"
              zIndex={1001}
            >
              <Chip value={NumberFieldType.decimal}>Decimal</Chip>
            </Tooltip>
            <Tooltip label="Whole numbers only" refProp="rootRef" zIndex={1001}>
              <Chip value={NumberFieldType.integer}>Integer</Chip>
            </Tooltip>
            <Tooltip label="Monetary values" refProp="rootRef" zIndex={1001}>
              <Chip value={NumberFieldType.currency}>Currency</Chip>
            </Tooltip>
          </Group>
        </Chip.Group>
      </Group>

      <Text fz="sm" fw={600}>
        Value Range
      </Text>
      <Group grow>
        <NumberInput
          label="Minimum"
          description="The smallest number that can be entered"
          value={numberProps.minValue ?? ''}
          onChange={(value) => handleChange('minValue', value)}
        />
        <NumberInput
          label="Maximum"
          description="The largest number that can be entered"
          value={numberProps.maxValue ?? ''}
          onChange={(value) => handleChange('maxValue', value)}
        />
      </Group>

      {numberProps.numberType !== NumberFieldType.integer && (
        <NumberInput
          label="Decimal Places"
          description="Number of digits after the decimal point"
          value={numberProps.decimalPlaces ?? 2}
          min={0}
          max={10}
          onChange={(value) => handleChange('decimalPlaces', value)}
        />
      )}

      <Text fz="sm" fw={600}>
        Additional Options
      </Text>
      <Switch
        label="Allow Negative Numbers"
        description="Whether negative values can be entered"
        checked={numberProps.allowNegative ?? true}
        onChange={(event) =>
          handleChange('allowNegative', event.currentTarget.checked)
        }
      />
    </Stack>
  );
};

export default NumberFieldSettings;
