import { useCountries } from '@btrway/reference-data-manager';
import { Select, Stack, Text } from '@mantine/core';
import React from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface CountryFieldProps extends BaseFieldProps<string> {}

export const CountryField: React.FC<CountryFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const { countries, isLoading } = useCountries();
  const readonly = isReadOnly(displayMode) || field.readOnly;

  const countryOptions = React.useMemo(() => {
    return countries.map((country) => ({
      value: country.code,
      label: country.name,
    }));
  }, [countries]);

  // Find country name for display
  const selectedCountry = React.useMemo(() => {
    if (!value) return null;
    return countries.find((country) => country.code === value);
  }, [value, countries]);

  if (isDisplayMode(displayMode)) {
    return (
      <Stack gap="xs">
        <Text size="sm" c={selectedCountry ? 'inherit' : 'dimmed'}>
          {selectedCountry ? selectedCountry.name : 'No country selected'}
        </Text>
      </Stack>
    );
  }

  const commonProps = {
    label: field.label,
    description: field.description,
    required: field.required,
    disabled: readonly,
  };

  return (
    <Select
      {...commonProps}
      data={countryOptions}
      value={value || null}
      onChange={onChange}
      searchable
      clearable
      nothingFoundMessage="No countries available"
    />
  );
};

export default CountryField;
