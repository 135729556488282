/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskStatisticsGroupByEnum = typeof TaskStatisticsGroupByEnum[keyof typeof TaskStatisticsGroupByEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatisticsGroupByEnum = {
  workgroup: 'workgroup',
  person: 'person',
} as const;
