import { ApproverSelect } from '@btrway/process-builder-approver';
import {
  Box,
  Center,
  Group,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React from 'react';
import { useProcessBuilder } from '../../providers/ProcessBuilderProvider';

export const ApprovalsStep: React.FC = () => {
  const {
    approvalSteps: {
      approvalSteps,
      addApprovalStep,
      removeApprovalStep,
      updateApprovalStepTitle,
    },
  } = useProcessBuilder();

  return (
    <Center>
      <Stack gap="xl">
        <Stack gap="md">
          <Group gap={0}>
            <Box w="48px" />
            <Box w="300px">
              <Text size="sm" fw={500}>
                Approver
              </Text>
            </Box>
            <Box ml="md" w="300px">
              <Text size="sm" fw={500}>
                Step Title
              </Text>
            </Box>
          </Group>
          {approvalSteps.map((step, index) => {
            const hasConfig = !!step.taskAssignmentConfig;
            const stepNumber = approvalSteps
              .slice(0, index + 1)
              .filter((s) => s.taskAssignmentConfig).length;

            return (
              <Group key={step.id} align="center" gap={0}>
                <Box w="48px" ta="center">
                  {hasConfig && (
                    <ThemeIcon size="lg" radius="xl" variant="light">
                      {stepNumber}
                    </ThemeIcon>
                  )}
                </Box>

                <Box w="300px">
                  <ApproverSelect
                    taskAssignmentConfig={step.taskAssignmentConfig}
                    onConfigChange={(config, description) =>
                      addApprovalStep(config, description)
                    }
                  />
                </Box>

                <Box ml="md" w="300px">
                  <Group gap="xs">
                    <TextInput
                      value={step.title}
                      onChange={(e) =>
                        updateApprovalStepTitle(step.id, e.currentTarget.value)
                      }
                      placeholder={
                        hasConfig
                          ? 'Enter step title'
                          : 'Select an approver first'
                      }
                      disabled={!hasConfig}
                      style={{ flex: 1 }}
                    />
                    {hasConfig && (
                      <ThemeIcon
                        variant="light"
                        color="red"
                        size="sm"
                        radius="sm"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeApprovalStep(step.id)}
                      >
                        <IconTrash size={16} />
                      </ThemeIcon>
                    )}
                  </Group>
                </Box>
              </Group>
            );
          })}
        </Stack>
      </Stack>
    </Center>
  );
};

export default ApprovalsStep;
