import { CertificationConfig, WorkflowConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useWorkflowUpdater } from '@btrway/workflow-common-provider';
import { useCallback } from 'react';
import { CertificationActions } from '../types/actions';

export const useCertificationActions = (
  workflowUpdater: ReturnType<typeof useWorkflowUpdater>,
  workflowConfig: WorkflowConfig,
  setWorkflowConfig: React.Dispatch<React.SetStateAction<WorkflowConfig>>
): CertificationActions => {
  const addCertification = useCallback(
    async (newCertification: CertificationConfig) => {
      const certificationWithKey = {
        ...newCertification,
        certificationKey: newCertification.certificationKey || uuid(),
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        certifications: [...(prev.certifications || []), certificationWithKey],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { certifications: [certificationWithKey] },
      });

      return certificationWithKey;
    },
    [workflowUpdater]
  );

  const removeCertification = useCallback(
    async (certificationKey: string) => {
      setWorkflowConfig((prev) => ({
        ...prev,
        certifications: prev.certifications?.filter(
          (cert) => cert.certificationKey !== certificationKey
        ),
      }));
      await workflowUpdater.updateConfig({
        deleteConfigs: [certificationKey],
      });
    },
    [workflowUpdater]
  );

  const updateCertification = useCallback(
    async (
      certificationKey: string,
      updatedCertification: Partial<CertificationConfig>
    ) => {
      const certifications = workflowConfig.certifications || [];
      const existingIndex = certifications.findIndex(
        (cert) => cert.certificationKey === certificationKey
      );

      if (existingIndex === -1) {
        throw new Error(`Certification with key ${certificationKey} not found`);
      }

      const updatedConfig = {
        ...certifications[existingIndex],
        ...updatedCertification,
        certificationKey,
      };

      setWorkflowConfig((prev) => ({
        ...prev,
        certifications:
          prev.certifications?.map((cert) =>
            cert.certificationKey === certificationKey ? updatedConfig : cert
          ) || [],
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { certifications: [updatedConfig] },
      });

      return updatedConfig;
    },
    [workflowConfig.certifications, workflowUpdater]
  );

  return {
    addCertification,
    removeCertification,
    updateCertification,
  };
};
