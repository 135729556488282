import {
  FieldConfig,
  FieldTypeEnum,
  FormConfig,
  PropertyReference,
} from '@btrway/api-workflow';
import {
  FormConfigProvider,
  useFormConfig,
} from '@btrway/form-configuration-manager';
import { FormToolbox } from '@btrway/form-toolbox';
import {
  useGroupedPropertyReferences,
  usePropertyReferences,
} from '@btrway/property-reference-manager';
import { FlyoutMenu, FlyoutPanelProvider } from '@btrway/shared-components';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import { Box, Drawer } from '@mantine/core';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
} from 'react';
import DragPreview from '../../drag/DragPreview/DragPreview';
import FieldSettings from '../../field/FieldSettings/FieldSettings';
import FormBuilderContent from '../FormBuilderContent/FormBuilderContent';
import { ToolboxPortal } from '../ToolboxPortal/ToolboxPortal';

interface FormBuilderProps {
  stepKey?: string;
  initialConfig?: FormConfig;
  formTitle?: ReactNode | string;
  onSave?: (config: FormConfig) => void;
  onCancel?: () => void;
  onChange?: (config: FormConfig) => void;
  readOnly?: boolean;
  availableProperties?: PropertyReference[];
  emptyFormMessage?: string;
}

export interface FormBuilderRef {
  handleSave: () => void;
  handleCancel: () => void;
}

const FormBuilderInner = forwardRef<
  FormBuilderRef,
  Omit<FormBuilderProps, 'initialConfig' | 'onChange'>
>(
  (
    {
      stepKey,
      formTitle,
      onSave,
      onCancel,
      readOnly = false,
      availableProperties,
      emptyFormMessage,
    },
    ref
  ) => {
    const {
      formConfig,
      fields,
      addField,
      updateField,
      removeField,
      updateFormConfig,
      selectedFieldKey,
      getField,
      selectField,
    } = useFormConfig();

    const { rulePropertyReferences, bindingPropertyReferences } =
      usePropertyReferences(stepKey || '');
    const { groupedBindingPropertyReferences } = useGroupedPropertyReferences(
      stepKey || ''
    );

    const selectedField = selectedFieldKey ? getField(selectedFieldKey) : null;

    const handleSave = () => {
      if (onSave && !readOnly) {
        onSave(formConfig);
      }
    };

    const handleCancel = () => {
      if (onCancel) {
        onCancel();
      }
    };

    useImperativeHandle(ref, () => ({
      handleSave,
      handleCancel,
    }));

    const handleFieldsReorder = (newFields: FieldConfig[]) => {
      if (readOnly) return;

      const updatedConfig = {
        ...formConfig,
        fields: newFields,
        modified: new Date().toISOString(),
      };
      updateFormConfig(updatedConfig);
    };

    const handleAddNewField = useCallback(
      (type: FieldTypeEnum, options?: Partial<FieldConfig>, index?: number) => {
        if (readOnly) return;

        const currentFields = [...fields];
        const finalIndex = Math.max(
          0,
          Math.min(index || currentFields.length, currentFields.length)
        );

        addField(type, options, finalIndex);
      },
      [readOnly, fields, addField]
    );

    const handleFieldRemove = (fieldKey: string) => {
      removeField(fieldKey);
    };

    const mainContentStyles: React.CSSProperties = {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 180px)',
      flex: 1,
    };

    return (
      <div style={{ height: '100%', minHeight: '500px', display: 'flex' }}>
        <div style={mainContentStyles}>
          <div style={{ flex: 1, display: 'flex' }}>
            <FormBuilderContent
              fields={fields}
              onUpdate={updateField}
              onRemove={handleFieldRemove}
              onFieldsReorder={handleFieldsReorder}
              onAddField={handleAddNewField}
              readOnly={readOnly}
              emptyFormMessage={emptyFormMessage}
            />
          </div>
          {!readOnly && (
            <>
              <DragPreview />
              <Drawer
                opened={!!selectedFieldKey}
                onClose={() => selectField(null)}
                position="right"
                padding={0}
                withOverlay={false}
                closeOnClickOutside={false}
                trapFocus={false}
                size={400}
                shadow="lg"
                withCloseButton={false}
                lockScroll={false}
                styles={{
                  content: {
                    marginTop: 70,
                    marginBottom: 10,
                    marginRight: 10,
                    borderRadius: 'var(--mantine-radius-lg)',
                    height: 'calc(100vh - 80px)', // 80px accounts for top (70px) and bottom (10px) margins
                  },
                  inner: {
                    height: '100%',
                  },
                  body: {
                    height: '100%',
                  },
                  header: {
                    borderTopLeftRadius: 'var(--mantine-radius-md)',
                    borderTopRightRadius: 'var(--mantine-radius-md)',
                  },
                }}
              >
                {selectedField && (
                  <FieldSettings
                    rulePropertyReferences={rulePropertyReferences}
                    bindingPropertyReferences={bindingPropertyReferences}
                    groupedBindingPropertyReferences={
                      groupedBindingPropertyReferences
                    }
                  />
                )}
              </Drawer>
            </>
          )}
        </div>
      </div>
    );
  }
);

const FormBuilder = forwardRef<FormBuilderRef, FormBuilderProps>(
  ({ initialConfig, onChange, ...props }, ref) => {
    const { selectedStepKey } = useWorkflowConfig();

    return (
      <FormConfigProvider
        initialConfig={initialConfig}
        onChange={onChange}
        stepKey={props.stepKey}
      >
        <FlyoutPanelProvider>
          {selectedStepKey && (
            <ToolboxPortal>
              <Box
                style={{
                  position: 'absolute',
                  top: '76px',
                  left: '16px',
                }}
              >
                <FlyoutMenu width={390}>
                  <FormToolbox
                    stepKey={selectedStepKey}
                    onFieldSelect={() => {}}
                  />
                </FlyoutMenu>
              </Box>
            </ToolboxPortal>
          )}
          <FormBuilderInner ref={ref} {...props} />
        </FlyoutPanelProvider>
      </FormConfigProvider>
    );
  }
);

FormBuilder.displayName = 'FormBuilder';
FormBuilderInner.displayName = 'FormBuilderInner';

export default FormBuilder;
