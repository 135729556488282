import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { WorkflowBuilderPanelProvider } from '@btrway/workflow-builder-state';
import { CommonWorkflow } from '@btrway/workflow-common-provider';
import { WorkflowSettingsActionIcon } from '@btrway/workflow-settings';
import { DeploymentFilterView } from '@btrway/workflow-template-deployment';
import { Box, Group, Stack, Text } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import React, { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkflowDefinitionMetadataView } from '../WorkflowDefinitionMetadataView/WorkflowDefinitionMetadataView';

interface WorkflowBuilderLayoutProps {
  children: React.ReactNode;
  workflow?: CommonWorkflow;
  workflowKey?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (workflow: CommonWorkflow) => Promise<void>;
  onEditClick: () => void;
}

const getBackLinkText = (
  workflowType: WorkflowTypeEnum | undefined
): string => {
  switch (workflowType) {
    case WorkflowTypeEnum.form:
      return 'Back to Forms';
    case WorkflowTypeEnum.automation:
      return 'Back to Automations';
    case WorkflowTypeEnum.curriculum:
      return 'Back to Curriculums';
    case WorkflowTypeEnum.certification:
      return 'Back to Certifications';
    default:
      return 'Back';
  }
};

export const WorkflowBuilderLayout: React.FC<WorkflowBuilderLayoutProps> = ({
  children,
  workflow,
  workflowKey,
  isOpen,
  onClose,
  onSave,
  onEditClick,
}) => {
  const navigate = useNavigate();
  const mainContentBackground = '#f0f0f0';

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const backLinkText = getBackLinkText(workflow?.workflowType);

  return (
    <Stack h="100vh" gap={0} style={{ width: '100%' }}>
      <Group
        align="center"
        bg="dark.6"
        h={60}
        px="sm"
        style={{ position: 'relative', width: '100%' }}
      >
        <Box style={{ position: 'absolute', left: 16 }}>
          <div
            onClick={() => navigate(-1)}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
          >
            <Group wrap="nowrap">
              <IconChevronLeft size={24} color="white" />
              <Text size="md" c="white" fw={600}>
                {backLinkText}
              </Text>
            </Group>
          </div>
        </Box>

        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          {workflowKey && workflow && (
            <WorkflowDefinitionMetadataView workflow={workflow} />
          )}
        </Box>
      </Group>

      <Box
        style={{
          backgroundColor: mainContentBackground,
          display: 'flex',
          height: 'calc(100vh - 60px)',
          overflow: 'hidden',
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <Box style={{ position: 'absolute', top: 76, left: 16, zIndex: 1 }}>
          <WorkflowSettingsActionIcon />
        </Box>
        <Box style={{ position: 'absolute', top: 76, right: 16, zIndex: 1 }}>
          {workflow && <DeploymentFilterView workflow={workflow} />}
        </Box>

        <Box
          style={{
            flex: '1 1 auto',
            overflow: 'auto',
            transition: 'flex 0.3s ease',
            width: '100%',
            minWidth: 0, // Prevents flex items from overflowing
          }}
        >
          <Suspense fallback={<div>Loading builder...</div>}>
            <Box
              style={{
                position: 'relative',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <RightSectionProvider>
                <SettingsDisplayProvider>
                  <WorkflowBuilderPanelProvider>
                    {children}
                  </WorkflowBuilderPanelProvider>
                </SettingsDisplayProvider>
              </RightSectionProvider>
            </Box>
          </Suspense>
        </Box>
      </Box>
    </Stack>
  );
};
