import { PropertyReference } from '@btrway/api-workflow';
import { usePropertyReferenceDescription } from '@btrway/property-reference-manager';
import { Badge, Tooltip } from '@mantine/core';
import React from 'react';

interface FieldBindingProps {
  propertyReference: PropertyReference | undefined;
}

export const FieldBinding: React.FC<FieldBindingProps> = ({
  propertyReference,
}) => {
  const description = usePropertyReferenceDescription(propertyReference);

  if (!propertyReference) {
    return null;
  }

  return (
    <Tooltip label={description} multiline position="top">
      <Badge
        size="sm"
        variant="light"
        styles={{
          root: {
            maxWidth: '250px',
          },
          label: {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            direction: 'rtl',
            textAlign: 'left',
          },
        }}
      >
        {description}
      </Badge>
    </Tooltip>
  );
};

export default FieldBinding;
