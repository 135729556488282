import { FieldConfig, FieldTypeEnum } from '@btrway/api-workflow';
import { useCallback, useMemo, useRef } from 'react';
import { DependencyNode, FieldDependency } from '../types/fieldDependency';

export const useFieldDependencyGraph = (
  fields: FieldConfig[],
  formValues: Record<string, any>
) => {
  const multipleFieldsRef = useRef<Record<string, number>>({});

  const extractDependencies = useCallback(
    (field: FieldConfig, parentNode?: DependencyNode): FieldDependency[] => {
      const dependencies = new Set<FieldDependency>();

      if (field.triggerSets?.length) {
        field.triggerSets.forEach((triggerSet) => {
          triggerSet.triggers?.forEach((trigger) => {
            if (trigger.property?.propertyKey) {
              const isWithinRecordDependency =
                parentNode?.parent?.isMultipleField &&
                parentNode?.childNodes.has(trigger.property.propertyKey);

              const scope = isWithinRecordDependency ? 'record' : 'global';

              dependencies.add({
                fieldKey: trigger.property.propertyKey,
                parentNode,
                scope,
                triggerSet,
                trigger,
              });
            }
          });
        });
      }

      return Array.from(dependencies);
    },
    []
  );

  const findNodeInRecord = useCallback(
    (
      multipleNode: DependencyNode,
      fieldKey: string,
      recordIndex?: number
    ): DependencyNode | undefined => {
      if (recordIndex === undefined) return undefined;

      const recordNode = multipleNode.childNodes.get(String(recordIndex));
      if (!recordNode) return undefined;

      return recordNode.childNodes.get(fieldKey);
    },
    []
  );

  const findNode = useCallback(
    (root: DependencyNode, fieldKey: string): DependencyNode | undefined => {
      if (root.childNodes.has(fieldKey)) {
        return root.childNodes.get(fieldKey);
      }

      for (const childNode of root.childNodes.values()) {
        const found = findNode(childNode, fieldKey);
        if (found) {
          return found;
        }
      }

      return undefined;
    },
    []
  );

  const buildDependencyGraph = useCallback(
    (
      fields: FieldConfig[],
      parentNode?: DependencyNode,
      recordIndex?: number
    ): DependencyNode => {
      const childNodes = new Map<string, DependencyNode>();

      fields.forEach((field) => {
        const node: DependencyNode = {
          fieldKey: field.fieldKey,
          type: field.type,
          parent: parentNode,
          recordIndex,
          dependencies: [],
          dependents: new Set(),
          childNodes: new Map(),
          isMultipleField: field.type === FieldTypeEnum.multiple,
          isWithinMultiple: parentNode?.isMultipleField || false,
          field,
        };

        node.dependencies = extractDependencies(field, node);

        if (field.type === FieldTypeEnum.group && field.childFields) {
          const groupNode = buildDependencyGraph(field.childFields, node);
          groupNode.childNodes.forEach((childNode, key) => {
            node.childNodes.set(key, childNode);
          });
        }

        if (
          field.type === FieldTypeEnum.multiple &&
          field.childFields?.length
        ) {
          const records = formValues[field.fieldKey] || [];
          const recordCount = Math.max(records.length || 0, 1);

          multipleFieldsRef.current[field.fieldKey] = recordCount;

          for (let index = 0; index < recordCount; index++) {
            const recordNode: DependencyNode = {
              fieldKey: `${field.fieldKey}_${index}`,
              type: FieldTypeEnum.group,
              parent: node,
              recordIndex: index,
              dependencies: [],
              dependents: new Set(),
              childNodes: new Map(),
              isMultipleField: false,
              isWithinMultiple: true,
              field: null,
            };

            field.childFields.forEach((childField) => {
              const childNode: DependencyNode = {
                fieldKey: childField.fieldKey,
                type: childField.type,
                parent: recordNode,
                recordIndex: index,
                dependencies: extractDependencies(childField, recordNode),
                dependents: new Set(),
                childNodes: new Map(),
                isMultipleField: false,
                isWithinMultiple: true,
                field: childField,
              };

              recordNode.childNodes.set(childField.fieldKey, childNode);
            });

            node.childNodes.set(String(index), recordNode);
          }
        }

        childNodes.set(field.fieldKey, node);
      });

      return {
        fieldKey: 'root',
        type: FieldTypeEnum.group,
        dependencies: [],
        dependents: new Set(),
        childNodes,
        parent: undefined,
        isMultipleField: false,
        isWithinMultiple: false,
        field: null,
      };
    },
    [extractDependencies, formValues]
  );

  const buildDependents = useCallback(
    (node: DependencyNode) => {
      node.childNodes.forEach((childNode) => {
        childNode.dependencies.forEach((dep) => {
          let targetNode: DependencyNode | undefined;

          if (dep.scope === 'record' && childNode.parent?.isMultipleField) {
            targetNode = findNodeInRecord(
              childNode.parent,
              dep.fieldKey,
              childNode.recordIndex
            );
          } else {
            targetNode = findNode(node, dep.fieldKey);
          }

          if (targetNode) {
            targetNode.dependents.add(childNode);
          }
        });

        buildDependents(childNode);
      });
    },
    [findNode, findNodeInRecord]
  );

  const recordCounts = useMemo(() => {
    const counts: Record<string, number> = {};
    const checkFields = (fields: FieldConfig[]) => {
      fields.forEach((field) => {
        if (field.type === FieldTypeEnum.multiple) {
          const records = formValues[field.fieldKey] || [];
          counts[field.fieldKey] = Math.max(records.length || 0, 1);
        }
        if (field.childFields) {
          checkFields(field.childFields);
        }
      });
    };
    checkFields(fields);
    return counts;
  }, [fields, formValues]);

  const dependencyGraph = useMemo(() => {
    const graph = buildDependencyGraph(fields);
    buildDependents(graph);
    return graph;
  }, [
    fields,
    buildDependencyGraph,
    buildDependents,
    JSON.stringify(recordCounts),
  ]);

  return {
    dependencyGraph,
    findNode,
    findNodeInRecord,
  };
};
