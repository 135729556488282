import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ProcessBuilderWizardButton } from '@btrway-frontend/process-builder-add-wizard';
import {
  TaskTypeEnum,
  useDeployWorkflowTemplates,
  WorkflowConfig,
  WorkflowTemplateDeploymentActionProperties,
  WorkflowTemplateRequest,
  WorkflowTemplateResponse,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { uuid } from '@btrway/utils';
import { useWorkflowTemplates } from '@btrway/workflow-manager';
import { DeploymentButton } from '@btrway/workflow-template-deployment';
import { Button, Group, Stack, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddWorkflowTemplateModal from '../../components/AddWorkflowTemplateModal/AddWorkflowTemplateModal';
import CloneWorkflowModal from '../../components/CloneWorkflowModal/CloneWorkflowModal';
import TaskListImportModal from '../../components/TaskListImportModal/TaskListImportModal';
import { getWorkflowTemplatePath } from '../../config/workflowTypeConfig';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

interface WorkflowTypeTemplatesPageProps {
  workflowType: WorkflowTypeEnum;
}
const regenerateWorkflowConfigKeys = (
  config?: WorkflowConfig
): WorkflowConfig | undefined => {
  if (!config) return undefined;

  const newConfig: WorkflowConfig = {
    ...config,
    // Filter out completeTaskOption type tasks and generate new keys for remaining tasks
    tasks:
      config.tasks
        ?.filter((task) => task.taskType !== TaskTypeEnum.completeTaskOption)
        .map((task) => ({
          ...task,
          taskKey: uuid(),
        })) || [],
    // Handle certifications
    certifications:
      config.certifications?.map((cert) => ({
        ...cert,
        certificationKey: uuid(),
      })) || [],
    // Handle steps with their nested taskConfig
    steps: config.steps?.map((step) => ({
      ...step,
      // Regenerate taskKey for the nested taskConfig if it exists and isn't a completeTaskOption
      taskConfig:
        step.taskConfig &&
        step.taskConfig.taskType !== TaskTypeEnum.completeTaskOption
          ? {
              ...step.taskConfig,
              taskKey: uuid(),
            }
          : undefined,
    })),
  };

  return newConfig;
};

const WorkflowTypeTemplatesPage: React.FC<WorkflowTypeTemplatesPageProps> = ({
  workflowType,
}) => {
  useAddHelpTags(['workflow-templates']);
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [cloneModalOpened, { open: openCloneModal, close: closeCloneModal }] =
    useDisclosure(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<WorkflowTemplateResponse | null>(null);

  const {
    workflowTemplates,
    isLoading,
    error,
    addWorkflowTemplate,
    addFormWorkflowTemplate,
    deleteWorkflowTemplate,
  } = useWorkflowTemplates();
  const { mutateAsync: deployWorkflowTemplates } = useDeployWorkflowTemplates();

  const filteredWorkflowTemplates = workflowTemplates.filter(
    (workflowTemplate) => workflowTemplate.workflowType === workflowType
  );

  const handleClone = useCallback(
    async (newName: string) => {
      if (!selectedTemplate) return;

      const cloneRequest: WorkflowTemplateRequest = {
        name: newName,
        description: selectedTemplate.description,
        entityName: selectedTemplate.entityName,
        entityType: selectedTemplate.entityType,
        published: false,
        templateKey: uuid(),
        templateSettings: selectedTemplate.templateSettings,
        workflowConfiguration: regenerateWorkflowConfigKeys(
          selectedTemplate.workflowConfiguration
        ),
        workflowMetadata: selectedTemplate.workflowMetadata,
        workflowType: selectedTemplate.workflowType,
      };

      const newTemplate = await addWorkflowTemplate(cloneRequest);
      if (newTemplate?.templateKey) {
        const redirectPath = getWorkflowTemplatePath(
          workflowType,
          newTemplate.templateKey
        );
        if (redirectPath) {
          navigate(redirectPath);
        }
      }
    },
    [selectedTemplate, addWorkflowTemplate, workflowType, navigate]
  );

  const actionCellRenderer = useCallback(
    (params: any) => (
      <Group wrap="nowrap" gap="xs" align="center">
        <Button
          size="compact-sm"
          variant="outline"
          color="blue"
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = async (e) => {
              e.stopPropagation();
              const deploymentProps: WorkflowTemplateDeploymentActionProperties =
                {
                  workflowTemplateIds: [params.data.id],
                };
              await deployWorkflowTemplates({ data: deploymentProps });
            };
          }}
        >
          Deploy
        </Button>
        <Button
          size="compact-sm"
          variant="outline"
          color="gray"
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              setSelectedTemplate(params.data);
              openCloneModal();
            };
          }}
        >
          Clone
        </Button>
        <Button
          size="compact-sm"
          variant="outline"
          color="red"
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              deleteWorkflowTemplate(params.data.id);
            };
          }}
        >
          Delete
        </Button>
      </Group>
    ),
    [deployWorkflowTemplates, deleteWorkflowTemplate, openCloneModal]
  );

  const onRowClick = useCallback(
    (event: any) => {
      const currentPath = location.pathname;
      navigate(`${currentPath}/${event.data.templateKey}`);
    },
    [navigate]
  );

  const columnDefs: ColDef[] = useMemo(() => {
    const baseColumns: ColDef[] = [
      {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
        sortable: true,
        flex: 1,
        suppressHeaderMenuButton: true,
      },
      // {
      //   headerName: 'Published',
      //   field: 'published',
      //   cellRenderer: (params: { value: boolean }) =>
      //     params.value ? 'Yes' : 'No',
      //   flex: 1,
      //   suppressHeaderMenuButton: true,
      // },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: actionCellRenderer,
        editable: false,
        width: 250,
        suppressHeaderMenuButton: true,
        cellStyle: { display: 'flex', alignItems: 'center' },
        cellRendererParams: {
          catchEvent: true,
        },
      },
    ];

    if (
      workflowType === WorkflowTypeEnum.taskList ||
      workflowType === WorkflowTypeEnum.certification
    ) {
      baseColumns.splice(1, 0, {
        headerName: 'Type',
        field: 'workflowMetadata.taskListType',
        sortable: true,
        flex: 1,
        suppressHeaderMenuButton: true,
      });
    }

    return baseColumns;
  }, [workflowType, actionCellRenderer]);

  const getTypeLabel = (type: WorkflowTypeEnum): string => {
    switch (type) {
      case WorkflowTypeEnum.automation:
        return 'Automation';
      case WorkflowTypeEnum.curriculum:
        return 'Curriculum';
      case WorkflowTypeEnum.taskList:
        return 'To-Do List';
      case WorkflowTypeEnum.form:
        return 'Form';
      case WorkflowTypeEnum.certification:
        return 'Certification';
      case WorkflowTypeEnum.formPacket:
        return 'Form Packet';
      default:
        return 'Workflow';
    }
  };

  const typeLabel = getTypeLabel(workflowType);
  const pageTitle = `${typeLabel} Templates`;
  const buttonText = `New ${typeLabel}`;

  const handleCreateTaskTemplate = async (
    partialWorkflowTemplate: Partial<WorkflowTemplateRequest>
  ) => {
    let newTemplate;
    newTemplate = await addWorkflowTemplate(partialWorkflowTemplate);

    // After successful creation, redirect to the template
    if (newTemplate?.templateKey) {
      navigateToWorkflow(newTemplate.templateKey);
    }
  };

  const navigateToWorkflow = (workflowKey: string) => {
    const redirectPath = getWorkflowTemplatePath(workflowType, workflowKey);
    if (redirectPath) {
      close();
      navigate(redirectPath);
    }
  };

  const handleProcessBuilderComplete = (workflowKey: string) => {
    navigateToWorkflow(workflowKey);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Stack h="100%" gap={0}>
        <Group justify="space-between" mb="md">
          <Title order={3}>{pageTitle}</Title>
          <Group justify="flex-end">
            {workflowType === WorkflowTypeEnum.taskList && (
              <TaskListImportModal />
            )}
            {workflowType === WorkflowTypeEnum.form ? (
              <ProcessBuilderWizardButton
                sourceType="template"
                workflowType={WorkflowTypeEnum.form}
                onComplete={handleProcessBuilderComplete}
              />
            ) : (
              <Button onClick={() => open()}>{buttonText}</Button>
            )}
            <DeploymentButton workflowType={workflowType} />
          </Group>
        </Group>
        <div
          className="ag-theme-quartz"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredWorkflowTemplates}
            onRowClicked={onRowClick}
          />
        </div>
      </Stack>

      <AddWorkflowTemplateModal
        workflowType={workflowType}
        workflowTypeDescription={typeLabel}
        opened={opened}
        onClose={close}
        onSave={handleCreateTaskTemplate}
      />
      <CloneWorkflowModal
        opened={cloneModalOpened}
        onClose={closeCloneModal}
        onClone={handleClone}
        originalName={selectedTemplate?.name || ''}
      />
    </>
  );
};

export default WorkflowTypeTemplatesPage;
