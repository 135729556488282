import { AddressInput, AddressValidationState } from '@btrway/address-input';
import { AddressRequest, AddressResponse } from '@btrway/api-core';
import { AddressFieldProperties } from '@btrway/api-workflow';
import { useDebouncedCallback } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';
import { AddressFieldDisplay } from './AddressFieldDisplay';

interface AddressFieldProps
  extends BaseFieldProps<AddressResponse, AddressRequest> {}

export const AddressField: React.FC<AddressFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;
  const addressFieldProperties =
    field.fieldProperties as AddressFieldProperties;

  // Local state for address and validation
  const [localValue, setLocalValue] = useState<AddressResponse | undefined>(
    value
  );
  const [validationState, setValidationState] =
    useState<AddressValidationState>({
      isValid: true,
      errors: {},
    });

  // Update local state when parent value changes
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Debounced handler for updating parent state
  const debouncedOnChange = useDebouncedCallback((newValue: AddressRequest) => {
    onChange?.(newValue);
  }, 300);

  // Handle address changes
  const handleAddressChange = (newValue: AddressRequest) => {
    // Ensure we maintain the required fields when updating local state
    if (localValue) {
      setLocalValue({
        ...localValue,
        ...newValue,
      });
    }

    // Debounce update to parent
    debouncedOnChange(newValue);
  };

  // Handle validation changes
  const handleValidationChange = (validation: AddressValidationState) => {
    setValidationState(validation);

    // Update aria-invalid based on both required field and validation state
    const element = document.querySelector(
      `[data-field-key="${field.fieldKey}"]`
    );
    if (element) {
      const isInvalid = field.required && !validation.isValid;
      if (isInvalid) {
        element.setAttribute('aria-invalid', 'true');
      } else {
        element.removeAttribute('aria-invalid');
      }
      element.setAttribute('data-valid', validation.isValid.toString());
    }
  };

  if (isDisplayMode(displayMode)) {
    return <AddressFieldDisplay value={localValue} />;
  }

  return (
    <div data-field-key={field.fieldKey}>
      <AddressInput
        value={localValue}
        onChange={handleAddressChange}
        onValidationChange={handleValidationChange}
        readOnly={readonly}
        required={field.required}
        defaultCountryCode={addressFieldProperties?.defaultCountryCode}
      />
    </div>
  );
};

export default AddressField;
