// FormField.tsx
import { FieldConfig } from '@btrway/api-workflow';
import { labelNotApplicable } from '@btrway/form-fields';
import { Box, Stack } from '@mantine/core';
import React from 'react';
import FieldLabel from '../FieldLabel/FieldLabel';
import FormFieldInput from '../FormFieldInput/FormFieldInput';

interface FormFieldProps {
  field: FieldConfig;
  value: any;
  onChange: (value: any) => void;
  readOnly?: boolean;
}

export const FormField: React.FC<FormFieldProps> = ({
  field,
  value,
  onChange,
  readOnly = false,
}) => {
  const shouldShowLabel = !labelNotApplicable?.(field.type);

  return (
    <Stack gap={0}>
      {shouldShowLabel && (
        <FieldLabel
          label={field.label}
          description={field.description}
          required={!readOnly && field.required}
          readOnly={readOnly}
        />
      )}

      <Box mt={!readOnly ? 'xs' : 0}>
        <FormFieldInput
          field={field}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      </Box>
    </Stack>
  );
};

export default FormField;
