import { FieldTypeEnum } from '@btrway/api-workflow';
import {
  IconAB,
  IconAddressBook,
  IconAdjustments,
  IconArrowsVertical,
  IconBuildingFactory,
  IconBuildingSkyscraper,
  IconCalculator,
  IconCalendar,
  IconCheck,
  IconCheckbox,
  IconClock,
  IconFlag,
  IconHeading,
  IconLayoutGrid,
  IconLibraryPlus,
  IconLink,
  IconMap,
  IconMapPin,
  IconNumbers,
  IconPhoto,
  IconPilcrow,
  IconPlayerPlay,
  IconRuler,
  IconScale,
  IconSignature,
  IconSquareMinus,
  IconStar,
  IconToggleRight,
  IconUpload,
  IconUserSearch,
  IconUsers,
} from '@tabler/icons-react';
import React from 'react';

export const getFieldIcon = (type: FieldTypeEnum): React.ReactNode => {
  switch (type) {
    // Heading Fields
    case FieldTypeEnum.heading:
      return <IconHeading size={18} />;
    case FieldTypeEnum.paragraph:
      return <IconPilcrow size={18} />;
    case FieldTypeEnum.video:
      return <IconPlayerPlay size={18} />;
    case FieldTypeEnum.link:
      return <IconLink size={18} />;

    // Basic Input Fields
    case FieldTypeEnum.text:
      return <IconAB size={18} />;
    case FieldTypeEnum.number:
      return <IconNumbers size={18} />;
    case FieldTypeEnum.height:
      return <IconRuler size={18} />;
    case FieldTypeEnum.weight:
      return <IconScale size={18} />;

    // Selection Fields
    case FieldTypeEnum.select:
    case FieldTypeEnum.checkbox:
      return <IconCheckbox size={18} />;
    case FieldTypeEnum.switch:
      return <IconToggleRight size={18} />;
    case FieldTypeEnum.boolean:
      return <IconCheck size={18} />;
    case FieldTypeEnum.rating:
      return <IconStar size={18} />;
    case FieldTypeEnum.slider:
      return <IconAdjustments size={18} />;

    // Date/Time Fields
    case FieldTypeEnum.date:
    case FieldTypeEnum.dateTime:
      return <IconCalendar size={18} />;
    case FieldTypeEnum.time:
      return <IconClock size={18} />;

    // Location Fields
    case FieldTypeEnum.address:
      return <IconAddressBook size={18} />;
    case FieldTypeEnum.stateProvince:
      return <IconMapPin size={18} />;
    case FieldTypeEnum.country:
      return <IconFlag size={18} />;
    case FieldTypeEnum.postalcode:
      return <IconMap size={18} />;

    // File Fields
    case FieldTypeEnum.file:
      return <IconUpload size={18} />;
    case FieldTypeEnum.image:
      return <IconPhoto size={18} />;

    // Special Fields
    case FieldTypeEnum.signature:
      return <IconSignature size={18} />;
    case FieldTypeEnum.calculation:
      return <IconCalculator size={18} />;

    // Entity Reference Fields
    case FieldTypeEnum.organization:
      return <IconBuildingSkyscraper size={18} />;
    case FieldTypeEnum.workgroup:
      return <IconUsers size={18} />;
    case FieldTypeEnum.person:
      return <IconUserSearch size={18} />;
    case FieldTypeEnum.facility:
      return <IconBuildingFactory size={18} />;

    // Layout Fields
    case FieldTypeEnum.group:
      return <IconLayoutGrid size={18} />;
    case FieldTypeEnum.multiple:
      return <IconLibraryPlus size={18} />;
    case FieldTypeEnum.divider:
      return <IconSquareMinus size={18} />;
    case FieldTypeEnum.space:
      return <IconArrowsVertical size={18} />;

    default:
      return <IconAB size={18} />;
  }
};

export const getFieldLabel = (type: FieldTypeEnum): string => {
  return (
    type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, ' $1')
  );
};

export const getFieldDescription = (type: FieldTypeEnum): string => {
  switch (type) {
    // Text Fields
    case FieldTypeEnum.heading:
      return 'Heading text and optional subheading';
    case FieldTypeEnum.paragraph:
      return 'Paragraph text';
    case FieldTypeEnum.video:
      return 'Video embed or upload';
    case FieldTypeEnum.link:
      return 'Link to another page or resource';

    // Basic Input Fields
    case FieldTypeEnum.text:
      return 'Text input';
    case FieldTypeEnum.number:
      return 'Number input';
    case FieldTypeEnum.height:
      return 'Height measurement input';
    case FieldTypeEnum.weight:
      return 'Weight measurement input';

    // Selection Fields
    case FieldTypeEnum.select:
      return 'Single option selection';
    case FieldTypeEnum.checkbox:
      return 'True/false checkbox';
    case FieldTypeEnum.switch:
      return 'Toggle switch input';
    case FieldTypeEnum.boolean:
      return 'True/false input';
    case FieldTypeEnum.rating:
      return 'Star rating input';
    case FieldTypeEnum.slider:
      return 'Slider selection';

    // Date/Time Fields
    case FieldTypeEnum.date:
      return 'Date picker';
    case FieldTypeEnum.time:
      return 'Time picker';
    case FieldTypeEnum.dateTime:
      return 'Date and time picker';

    // File Fields
    case FieldTypeEnum.file:
      return 'File upload input';
    case FieldTypeEnum.image:
      return 'Image upload input';

    // Special Fields
    case FieldTypeEnum.signature:
      return 'Signature input';
    case FieldTypeEnum.calculation:
      return 'Calculated field';

    // Location Fields
    case FieldTypeEnum.address:
      return 'Complete address input';
    case FieldTypeEnum.stateProvince:
      return 'State or province selection';
    case FieldTypeEnum.country:
      return 'Country selection';
    case FieldTypeEnum.postalcode:
      return 'Postal/ZIP code input';

    // Entity Reference Fields
    case FieldTypeEnum.organization:
      return 'Organization selection';
    case FieldTypeEnum.workgroup:
      return 'Workgroup selection';
    case FieldTypeEnum.person:
      return 'Person selection';
    case FieldTypeEnum.facility:
      return 'Facility selection';

    // Layout Fields
    case FieldTypeEnum.group:
      return 'Group of related fields';
    case FieldTypeEnum.multiple:
      return 'Enter multiple entries for a set of fields';
    case FieldTypeEnum.divider:
      return 'Visual separator';
    case FieldTypeEnum.space:
      return 'Vertical space';

    default:
      return '';
  }
};

// Field grouping configuration
export const fieldGroups = [
  {
    title: 'Content',
    fields: ['heading', 'paragraph', 'video', 'link'] as const,
  },
  {
    title: 'Text & Input',
    fields: ['text', 'number', 'height', 'weight'] as const,
  },
  {
    title: 'Selection Fields',
    fields: [
      'select',
      'checkbox',
      'switch',
      'boolean',
      'rating',
      'slider',
    ] as const,
  },
  {
    title: 'Date & Time',
    fields: ['date', 'time', 'dateTime'] as const,
  },
  {
    title: 'Files & Signatures',
    fields: ['file', 'image', 'signature'] as const,
  },
  {
    title: 'Location Fields',
    fields: ['address', 'stateProvince', 'country', 'postalcode'] as const,
  },
  {
    title: 'Entity Reference Fields',
    fields: ['person', 'workgroup', 'facility'] as const,
  },
  {
    title: 'Automated Fields',
    fields: ['calculation'] as const,
  },
  {
    title: 'Layout',
    fields: ['group', 'multiple', 'divider', 'space'] as const,
  },
] as const;
