import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ProcessBuilderWizardButton } from '@btrway-frontend/process-builder-add-wizard';
import {
  WorkflowDefinitionRequest,
  WorkflowDefinitionResponse,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { GridCardView } from '@btrway/grid-components';
import { useWorkflowDefinitions } from '@btrway/workflow-manager';
import { Button, Group, Stack, Title } from '@mantine/core';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import AddWorkflowDefinitionModal from '../../components/AddWorkflowDefinitionModal/AddWorkflowDefinitionModal';
import WorkflowDefinitionCard from '../../components/WorkflowDefinitionCard/WorkflowDefinitionCard';
import {
  getTypeLabel,
  getWorkflowDefinitionPath,
} from '../../config/workflowTypeConfig';
import { useWorkflowDefinitions as useWorkflowDefinitionsProvider } from '../../providers/WorkflowDefinitionsProvider';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

interface WorkflowDefinitionsPageProps {
  workflowType: WorkflowTypeEnum;
}

const WorkflowDefinitionsPage: React.FC<WorkflowDefinitionsPageProps> = ({
  workflowType,
}) => {
  const { workflowDefinitions } = useWorkflowDefinitionsProvider();
  const { addWorkflow } = useWorkflowDefinitions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const filteredWorkflowDefinitions = workflowDefinitions.filter(
    (workflowDefinition) => workflowDefinition.workflowType === workflowType
  );

  const getNavigationPath = (workflow: WorkflowDefinitionResponse) =>
    getWorkflowDefinitionPath(workflow.workflowType, workflow.workflowKey);

  const columnDefs: ColDef[] = useMemo(() => {
    const baseColumns: ColDef[] = [
      {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
        sortable: true,
        flex: 1,
      },
      {
        headerName: 'Published',
        field: 'published',
        cellRenderer: (params: { value: boolean }) =>
          params.value ? 'Yes' : 'No',
        flex: 1,
      },
      {
        headerName: 'Provided',
        valueGetter: (params: { data: WorkflowDefinitionResponse }) =>
          params.data.workflowTemplate ? 'BetterWay Provided' : '',
        flex: 1,
      },
    ];

    if (
      workflowType === WorkflowTypeEnum.taskList ||
      workflowType === WorkflowTypeEnum.certification
    ) {
      baseColumns.splice(1, 0, {
        headerName: 'Type',
        field: 'workflowMetadata.taskListType',
        sortable: true,
        flex: 1,
      });
    }

    return baseColumns;
  }, [workflowType]);

  const handleAddWorkflow = async (
    partialWorkflow: Partial<WorkflowDefinitionRequest>
  ) => {
    const newWorkflow = await addWorkflow(partialWorkflow);

    if (newWorkflow?.workflowKey) {
      navigateToWorkflow(newWorkflow.workflowKey);
    }
  };

  const navigateToWorkflow = (workflowKey: string) => {
    const redirectPath = getWorkflowDefinitionPath(workflowType, workflowKey);
    if (redirectPath) {
      setIsModalOpen(false);
      navigate(redirectPath);
    }
  };

  const handleProcessBuilderComplete = (workflowKey: string) => {
    navigateToWorkflow(workflowKey);
  };

  const typeLabel = getTypeLabel(workflowType);
  const pageTitle = pluralize(typeLabel);
  const buttonText = `New ${typeLabel}`;

  return (
    <>
      <Stack h="100%" gap={0}>
        <Group justify="space-between" mb="md">
          <Title order={3}>{pageTitle}</Title>
          {workflowType === WorkflowTypeEnum.form ? (
            <ProcessBuilderWizardButton
              sourceType="definition"
              workflowType={WorkflowTypeEnum.form}
              onComplete={handleProcessBuilderComplete}
            />
          ) : (
            <Button
              // leftSection={<IconPlus size={16} />}
              onClick={() => setIsModalOpen(true)}
            >
              {buttonText}
            </Button>
          )}
        </Group>
        <Stack h="100%" gap={0}>
          <GridCardView
            data={filteredWorkflowDefinitions}
            gridCols={columnDefs}
            cardComponent={WorkflowDefinitionCard}
            defaultView="grid"
            getNavigationPath={getNavigationPath}
          />
        </Stack>
      </Stack>

      <AddWorkflowDefinitionModal
        workflowType={workflowType}
        workflowTypeDescription={typeLabel}
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddWorkflow}
      />
    </>
  );
};

export default WorkflowDefinitionsPage;
