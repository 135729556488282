import {
  DataTypeEnum,
  FieldTypeEnum,
  RuleOperatorTypeEnum,
} from '@btrway/api-workflow';

interface OperatorMetadata {
  label: string;
}

// Single source of truth for operator labels with user-friendly descriptions
export const OPERATOR_METADATA: Record<RuleOperatorTypeEnum, OperatorMetadata> =
  {
    [RuleOperatorTypeEnum.equal]: { label: 'is' },
    [RuleOperatorTypeEnum.notequal]: { label: 'is not' },
    [RuleOperatorTypeEnum.contains]: { label: 'contains' },
    [RuleOperatorTypeEnum.notcontains]: { label: 'does not contain' },
    [RuleOperatorTypeEnum.startswith]: { label: 'starts with' },
    [RuleOperatorTypeEnum.endswith]: { label: 'ends with' },
    [RuleOperatorTypeEnum.greater]: { label: 'is greater than' },
    [RuleOperatorTypeEnum.greaterorequal]: { label: 'is at least' },
    [RuleOperatorTypeEnum.less]: { label: 'is less than' },
    [RuleOperatorTypeEnum.lessorequal]: { label: 'is at most' },
    [RuleOperatorTypeEnum.includes]: { label: 'includes' },
    [RuleOperatorTypeEnum.excludes]: { label: 'excludes' },
    [RuleOperatorTypeEnum.hasvalue]: { label: 'has any value' },
    [RuleOperatorTypeEnum.novalue]: { label: 'has no value' },
    [RuleOperatorTypeEnum.before]: { label: 'is before' },
    [RuleOperatorTypeEnum.after]: { label: 'is after' },
    [RuleOperatorTypeEnum.onorbefore]: { label: 'is on or before' },
    [RuleOperatorTypeEnum.onorafter]: { label: 'is on or after' },
  };

// Define operator groups for reuse
const NUMBER_OPERATORS = [
  RuleOperatorTypeEnum.equal,
  RuleOperatorTypeEnum.notequal,
  RuleOperatorTypeEnum.greater,
  RuleOperatorTypeEnum.greaterorequal,
  RuleOperatorTypeEnum.less,
  RuleOperatorTypeEnum.lessorequal,
  RuleOperatorTypeEnum.hasvalue,
  RuleOperatorTypeEnum.novalue,
] as const;

const DATE_OPERATORS = [
  RuleOperatorTypeEnum.equal,
  RuleOperatorTypeEnum.notequal,
  RuleOperatorTypeEnum.before,
  RuleOperatorTypeEnum.after,
  RuleOperatorTypeEnum.onorbefore,
  RuleOperatorTypeEnum.onorafter,
  RuleOperatorTypeEnum.hasvalue,
  RuleOperatorTypeEnum.novalue,
] as const;

const TEXT_OPERATORS = [
  RuleOperatorTypeEnum.equal,
  RuleOperatorTypeEnum.notequal,
  RuleOperatorTypeEnum.contains,
  RuleOperatorTypeEnum.notcontains,
  RuleOperatorTypeEnum.startswith,
  RuleOperatorTypeEnum.endswith,
  RuleOperatorTypeEnum.hasvalue,
  RuleOperatorTypeEnum.novalue,
] as const;

const BOOLEAN_OPERATORS = [
  RuleOperatorTypeEnum.equal,
  RuleOperatorTypeEnum.notequal,
  RuleOperatorTypeEnum.hasvalue,
  RuleOperatorTypeEnum.novalue,
] as const;

// Map specific data types to their operators
const DATATYPE_OPERATOR_MAP = {
  // Number types
  [DataTypeEnum.number]: NUMBER_OPERATORS,
  [DataTypeEnum.integer]: NUMBER_OPERATORS,
  [DataTypeEnum.systemId]: NUMBER_OPERATORS,

  // Date types
  [DataTypeEnum.date]: DATE_OPERATORS,
  [DataTypeEnum.dateTime]: DATE_OPERATORS,
  [DataTypeEnum.time]: DATE_OPERATORS,

  // Boolean type
  [DataTypeEnum.boolean]: BOOLEAN_OPERATORS,

  // Text types
  [DataTypeEnum.text]: TEXT_OPERATORS,
  [DataTypeEnum.email]: TEXT_OPERATORS,
  [DataTypeEnum.phone]: TEXT_OPERATORS,
} as const;

// Special operators for select fields
export const SELECT_FIELD_OPERATORS = [
  RuleOperatorTypeEnum.equal,
  RuleOperatorTypeEnum.notequal,
  RuleOperatorTypeEnum.includes,
  RuleOperatorTypeEnum.excludes,
  RuleOperatorTypeEnum.hasvalue,
  RuleOperatorTypeEnum.novalue,
] as const;

export const getOperatorMetadata = (
  operator: RuleOperatorTypeEnum
): OperatorMetadata => {
  return OPERATOR_METADATA[operator] || { label: operator };
};

export const getOperatorDisplay = (operator: RuleOperatorTypeEnum): string => {
  return OPERATOR_METADATA[operator]?.label || operator;
};

export const getAvailableOperators = (
  dataType?: DataTypeEnum,
  fieldType?: FieldTypeEnum
): RuleOperatorTypeEnum[] => {
  // Special case for Select fields
  if (fieldType === FieldTypeEnum.select) {
    return [...SELECT_FIELD_OPERATORS];
  }

  // Return operators for explicitly defined data types
  if (dataType && dataType in DATATYPE_OPERATOR_MAP) {
    return [
      ...DATATYPE_OPERATOR_MAP[dataType as keyof typeof DATATYPE_OPERATOR_MAP],
    ];
  }

  // Return empty array for undefined or unmapped data types
  return [];
};
