import { FieldConfig, FieldTypeEnum, FormConfig } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { DefaultFieldConfigs } from '../utils/defaultFieldConfigs';

interface FormConfigContextType {
  formConfig: FormConfig;
  fields: FieldConfig[];
  allFields: FieldConfig[];
  selectedFieldKey: string | null;
  addField: (
    type: FieldTypeEnum,
    options?: Partial<FieldConfig>,
    index?: number
  ) => void;
  updateField: (fieldKey: string, updates: Partial<FieldConfig>) => void;
  removeField: (fieldKey: string) => void;
  moveField: (
    fromIndex: number,
    toIndex: number,
    shouldStartNewLine?: boolean
  ) => void;
  moveFieldBetweenLevels: (
    fieldToMove: FieldConfig,
    sourceParentKey: string | undefined,
    targetParentKey: string | undefined,
    targetIndex: number,
    startWithNewLine: boolean
  ) => void;
  duplicateField: (fieldKey: string) => void;
  updateFormConfig: (newConfig: FormConfig) => void;
  reorderFields: (newFields: FieldConfig[]) => void;
  clearFields: () => void;
  getField: (fieldKey: string) => FieldConfig | undefined;
  selectField: (fieldKey: string | null) => void;
  isFieldSelected: (fieldKey: string) => boolean;
}

const FormConfigContext = createContext<FormConfigContextType | null>(null);

interface FormConfigProviderProps {
  children: React.ReactNode;
  initialConfig?: FormConfig;
  onChange?: (config: FormConfig) => void;
  stepKey?: string;
}

export const FormConfigProvider: React.FC<FormConfigProviderProps> = ({
  children,
  initialConfig,
  onChange,
  stepKey,
}) => {
  const [formState, setFormState] = useState<FormConfig>(() => {
    const initial: FormConfig = initialConfig || {
      fields: [],
    };
    return initial;
  });

  const [selectedFieldKey, setSelectedFieldKey] = useState<string | null>(null);

  // Helper function to recursively find and update a field
  const findAndUpdateField = useCallback(
    (
      fields: FieldConfig[],
      fieldKey: string,
      updates: Partial<FieldConfig>
    ): [boolean, FieldConfig[]] => {
      const updatedFields = fields.map((field) => {
        if (field.fieldKey === fieldKey) {
          // Special handling for fieldProperties to preserve existing properties
          const updatedFieldProperties = updates.fieldProperties
            ? {
                ...field.fieldProperties,
                ...updates.fieldProperties,
              }
            : field.fieldProperties;

          return {
            ...field,
            ...updates,
            fieldProperties: updatedFieldProperties,
          };
        }

        // Recursively search in childFields if they exist
        if (field.childFields?.length) {
          const [found, updatedChildFields] = findAndUpdateField(
            field.childFields,
            fieldKey,
            updates
          );
          if (found) {
            return {
              ...field,
              childFields: updatedChildFields,
            };
          }
        }
        return field;
      });

      // Check if we found and updated the field
      const wasUpdated = updatedFields.some(
        (field, idx) => !Object.is(field, fields[idx])
      );
      return [wasUpdated, updatedFields];
    },
    []
  );

  // Helper function to recursively find and duplicate a field
  const findAndDuplicateField = useCallback(
    (
      fields: FieldConfig[],
      fieldKey: string
    ): [FieldConfig | null, FieldConfig[]] => {
      let duplicatedField: FieldConfig | null = null;
      const updatedFields = [...fields];

      for (let i = 0; i < updatedFields.length; i++) {
        const field = updatedFields[i];

        if (field.fieldKey === fieldKey) {
          duplicatedField = {
            ...field,
            fieldKey: uuid(),
            label: `${field.label} (Copy)`,
            childFields: field.childFields ? [...field.childFields] : undefined,
          };
          updatedFields.splice(i + 1, 0, duplicatedField);
          break;
        }

        if (field.childFields?.length) {
          const [duplicated, newChildFields] = findAndDuplicateField(
            field.childFields,
            fieldKey
          );
          if (duplicated) {
            updatedFields[i] = {
              ...field,
              childFields: newChildFields,
            };
            duplicatedField = duplicated;
            break;
          }
        }
      }

      return [duplicatedField, updatedFields];
    },
    []
  );

  const selectField = useCallback((fieldKey: string | null) => {
    setSelectedFieldKey(fieldKey);
  }, []);

  const isFieldSelected = useCallback(
    (fieldKey: string) => {
      return selectedFieldKey === fieldKey;
    },
    [selectedFieldKey]
  );

  const addField = useCallback(
    (
      type: FieldTypeEnum,
      options: Partial<FieldConfig> = {},
      index?: number
    ) => {
      const newField: FieldConfig = {
        fieldKey: uuid(),
        type,
        label: `New ${type} Field`,
        startWithNewLine: true,
        required: false,
        ...DefaultFieldConfigs[type],
        ...options,
      };

      const updatedFields = [...formState.fields];
      if (typeof index === 'number') {
        updatedFields.splice(index, 0, newField);
      } else {
        updatedFields.push(newField);
      }

      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: updatedFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange]
  );

  const updateField = useCallback(
    (fieldKey: string, updates: Partial<FieldConfig>) => {
      const [wasUpdated, updatedFields] = findAndUpdateField(
        formState.fields,
        fieldKey,
        updates
      );

      if (!wasUpdated) return;

      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: updatedFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange, findAndUpdateField]
  );

  const removeField = useCallback(
    (fieldKey: string) => {
      if (selectedFieldKey === fieldKey) {
        setSelectedFieldKey(null);
      }

      const removeFieldRecursive = (
        fields: FieldConfig[]
      ): [boolean, FieldConfig[]] => {
        let wasRemoved = false;
        const updatedFields = [...fields];

        const fieldIndex = updatedFields.findIndex(
          (f) => f.fieldKey === fieldKey
        );

        if (fieldIndex !== -1) {
          updatedFields.splice(fieldIndex, 1);

          // Handle startWithNewLine adjustments for the next field
          if (
            fieldIndex < updatedFields.length &&
            updatedFields[fieldIndex].startWithNewLine === false
          ) {
            const prevField = fields[fieldIndex - 1];
            if (prevField && prevField.startWithNewLine !== false) {
              updatedFields[fieldIndex] = {
                ...updatedFields[fieldIndex],
                startWithNewLine: true,
              };
            }
          }
          return [true, updatedFields];
        }

        // Search in child fields
        for (let i = 0; i < updatedFields.length; i++) {
          if (updatedFields[i].childFields?.length) {
            const [removed, newChildFields] = removeFieldRecursive(
              updatedFields[i].childFields!
            );
            if (removed) {
              updatedFields[i] = {
                ...updatedFields[i],
                childFields: newChildFields,
              };
              wasRemoved = true;
              break;
            }
          }
        }

        return [wasRemoved, updatedFields];
      };

      const [wasRemoved, updatedFields] = removeFieldRecursive(
        formState.fields
      );

      if (!wasRemoved) return;

      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: updatedFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange, selectedFieldKey]
  );

  const moveField = useCallback(
    (fromIndex: number, toIndex: number, shouldStartNewLine?: boolean) => {
      const updatedFields = [...formState.fields];
      const [movedField] = updatedFields.splice(fromIndex, 1);

      if (shouldStartNewLine !== undefined) {
        movedField.startWithNewLine = shouldStartNewLine;
      }

      updatedFields.splice(toIndex, 0, movedField);

      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: updatedFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange]
  );

  const duplicateField = useCallback(
    (fieldKey: string) => {
      const [duplicated, updatedFields] = findAndDuplicateField(
        formState.fields,
        fieldKey
      );

      if (!duplicated) return;

      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: updatedFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange, findAndDuplicateField]
  );

  const updateFormConfig = useCallback(
    (newConfig: FormConfig) => {
      setFormState(newConfig);
      onChange?.(newConfig);
    },
    [onChange]
  );

  const reorderFields = useCallback(
    (newFields: FieldConfig[]) => {
      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: newFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange]
  );

  const clearFields = useCallback(() => {
    const updatedState = {
      ...formState,
      modified: new Date().toISOString(),
      fields: [],
    };

    setFormState(updatedState);
    onChange?.(updatedState);
  }, [formState, onChange]);

  const getField = useCallback(
    (fieldKey: string): FieldConfig | undefined => {
      const findField = (fields: FieldConfig[]): FieldConfig | undefined => {
        for (const field of fields) {
          if (field.fieldKey === fieldKey) {
            return field;
          }
          if (field.childFields?.length) {
            const found = findField(field.childFields);
            if (found) return found;
          }
        }
        return undefined;
      };

      return findField(formState.fields);
    },
    [formState.fields]
  );

  // Helper function to find and remove a field from anywhere in the form
  const findAndRemoveField = useCallback(
    (
      fields: FieldConfig[],
      fieldKey: string
    ): [FieldConfig | null, FieldConfig[]] => {
      let removedField: FieldConfig | null = null;

      const removeFromArray = (arr: FieldConfig[]): FieldConfig[] => {
        const newArr = [...arr];
        const index = newArr.findIndex((f) => f.fieldKey === fieldKey);

        if (index !== -1) {
          [removedField] = newArr.splice(index, 1);
          return newArr;
        }

        // Search in child fields
        return newArr.map((field) => {
          if (field.childFields) {
            field.childFields = removeFromArray(field.childFields);
          }
          return field;
        });
      };

      const updatedFields = removeFromArray(fields);
      return [removedField, updatedFields];
    },
    []
  );

  // Helper function to find a group field and add a field to its children
  const findAndAddToGroup = useCallback(
    (
      fields: FieldConfig[],
      targetGroupKey: string,
      fieldToAdd: FieldConfig,
      targetIndex: number
    ): FieldConfig[] => {
      return fields.map((field) => {
        if (field.fieldKey === targetGroupKey) {
          const newChildFields = [...(field.childFields || [])];
          newChildFields.splice(targetIndex, 0, fieldToAdd);
          return { ...field, childFields: newChildFields };
        }
        if (field.childFields) {
          return {
            ...field,
            childFields: findAndAddToGroup(
              field.childFields,
              targetGroupKey,
              fieldToAdd,
              targetIndex
            ),
          };
        }
        return field;
      });
    },
    []
  );

  const moveFieldBetweenLevels = useCallback(
    (
      fieldToMove: FieldConfig,
      sourceParentKey: string | undefined,
      targetParentKey: string | undefined,
      targetIndex: number,
      startWithNewLine: boolean
    ) => {
      const updatedFieldToMove = {
        ...fieldToMove,
        startWithNewLine,
      };

      let updatedFields = [...formState.fields];

      // First remove the field from its current location
      const [, fieldsAfterRemoval] = findAndRemoveField(
        updatedFields,
        fieldToMove.fieldKey
      );
      updatedFields = fieldsAfterRemoval;

      // Then add it to its new location
      if (targetParentKey) {
        // Adding to a group
        updatedFields = findAndAddToGroup(
          updatedFields,
          targetParentKey,
          updatedFieldToMove,
          targetIndex
        );
      } else {
        // Adding to root level
        updatedFields.splice(targetIndex, 0, updatedFieldToMove);
      }

      const updatedState = {
        ...formState,
        modified: new Date().toISOString(),
        fields: updatedFields,
      };

      setFormState(updatedState);
      onChange?.(updatedState);
    },
    [formState, onChange, findAndRemoveField, findAndAddToGroup]
  );

  const getAllFields = useCallback((fields: FieldConfig[]): FieldConfig[] => {
    return fields.reduce((allFields: FieldConfig[], field) => {
      allFields.push(field);
      if (field.childFields) {
        allFields.push(...getAllFields(field.childFields));
      }
      return allFields;
    }, []);
  }, []);

  const value = {
    formConfig: formState,
    fields: formState.fields,
    allFields: getAllFields(formState.fields),
    selectedFieldKey,
    addField,
    updateField,
    removeField,
    moveField,
    moveFieldBetweenLevels,
    duplicateField,
    updateFormConfig,
    reorderFields,
    clearFields,
    getField,
    selectField,
    isFieldSelected,
  };

  return (
    <FormConfigContext.Provider value={value}>
      {children}
    </FormConfigContext.Provider>
  );
};

export const useFormConfig = () => {
  const context = useContext(FormConfigContext);
  if (!context) {
    throw new Error('useFormConfig must be used within a FormConfigProvider');
  }
  return context;
};
