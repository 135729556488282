import { saveAs } from 'file-saver';
import React, { ReactNode, useCallback, useState } from 'react';

interface FileDownloadProps {
  url: string;
  filename: string;
  children: ReactNode;
  onDownloadStart?: () => void;
  onDownloadComplete?: () => void;
  onDownloadError?: (error: Error) => void;
}

export function FileDownload({
  url,
  filename,
  children,
  onDownloadStart,
  onDownloadComplete,
  onDownloadError,
}: FileDownloadProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    if (isDownloading) return;

    setIsDownloading(true);
    onDownloadStart?.();

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      saveAs(blob, filename);
      onDownloadComplete?.();
    } catch (error) {
      onDownloadError?.(
        error instanceof Error ? error : new Error('Download failed')
      );
    } finally {
      setIsDownloading(false);
    }
  }, [
    url,
    filename,
    isDownloading,
    onDownloadStart,
    onDownloadComplete,
    onDownloadError,
  ]);

  // Clone the child element and add the onClick handler
  const child = React.Children.only(children);
  return React.cloneElement(child as React.ReactElement, {
    onClick: handleDownload,
    disabled: isDownloading || (child as React.ReactElement).props.disabled,
  });
}

export default FileDownload;
