import { FieldConfig } from '@btrway/api-workflow';
import { useFormConfig } from '@btrway/form-configuration-manager';
import { ActionIcon, Card } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React from 'react';

interface SelectedFieldProps {
  field: FieldConfig;
  readOnly?: boolean;
  onRemove: (fieldKey: string) => void;
  children: React.ReactNode;
}

const SelectedField: React.FC<SelectedFieldProps> = ({
  field,
  readOnly = false,
  onRemove,
  children,
}) => {
  const { selectField } = useFormConfig();

  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRemove(field.fieldKey);
  };

  const handleBackgroundClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    selectField(null);
  };

  // const showConfigureButton = !configurationNotApplicable(field.type);

  return (
    <div style={{ position: 'relative' }}>
      <Card
        withBorder
        radius="md"
        p={0}
        style={{
          position: 'relative',
          border: '1px solid var(--mantine-color-blue-5)',
        }}
        onClick={handleBackgroundClick}
      >
        <div>{children}</div>
      </Card>

      {!readOnly && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: '20px',
            transform: 'translateY(100%)',
            zIndex: 100,
          }}
        >
          <div
            style={{
              background: '#228be6',
              padding: '4px 12px',
              borderRadius: '0 0 8px 8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            {/* {showConfigureButton && (
              <ActionIcon
                size="md"
                variant="subtle"
                color="white"
                onClick={handleConfigureClick}
              >
                <IconSettings size={20} />
              </ActionIcon>
            )} */}
            <ActionIcon
              size="md"
              variant="subtle"
              color="white"
              onClick={handleRemoveClick}
            >
              <IconTrash size={20} />
            </ActionIcon>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedField;
